import { RootState } from 'app/store';

export const selectNotifications = (state: RootState) =>
  state.alerts.notifications;

export const selectAlertFlag = (state: RootState) =>
  state.alerts.showNewAlertNotificationFlag;

export const selectShowCategories = (state: RootState) =>
  state.alerts.isShowCategories;
