import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './style.scss';

const PageContainer = ({ children, className, withoutHeight, isQuestPublic }) => {
  return (
    <div className={cn('page-container', className, { withoutHeight }, {'public-page': isQuestPublic})}>
      <div className="page-content">{children}</div>
    </div>
  );
};

PageContainer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  withoutHeight: PropTypes.bool,
  isQuestPublic: PropTypes.bool,
};

export default PageContainer;
