// import { actions, constants } from 'ducks-helpers';
// import { handleActions } from 'redux-actions';

// export const TYPE = constants('about', [
//   '~GET_ABOUT_DATA',
//   '~GET_SECTION_DATA',
//   '~GET_SUB_SECTION_DATA',
//   'SET_ACTIVE_SUB_SECTION',
// ]);

// export const ACTION = actions(TYPE);

// const initialState = {
//   isFetching: false,
//   aboutData: {},
//   aboutSloohSections: {},
//   subSectionData: {},
//   activeSubSections: null,
//   serverError: null,
// };

// export default handleActions(
//   {
//     [TYPE.GET_ABOUT_DATA]: start,
//     [TYPE.GET_ABOUT_DATA_SUCCESS]: getAboutDataSuccess,
//     [TYPE.GET_ABOUT_DATA_ERROR]: error,

//     [TYPE.GET_SECTION_DATA]: start,
//     [TYPE.GET_SECTION_DATA_SUCCESS]: getSectionDataSuccess,
//     [TYPE.GET_SECTION_DATA_ERROR]: error,

//     [TYPE.GET_SUB_SECTION_DATA]: start,
//     [TYPE.GET_SUB_SECTION_DATA_SUCCESS]: getSubSectionDataSuccess,
//     [TYPE.GET_SUB_SECTION_DATA_ERROR]: error,

//     [TYPE.SET_ACTIVE_SUB_SECTION]: setActiveSubSection,
//   },
//   initialState
// );

// function start(state) {
//   return {
//     ...state,
//     isFetching: true,
//   };
// }

// function error(state, { payload }) {
//   return {
//     ...state,
//     isFetching: false,
//     serverError: payload,
//   };
// }

// function getAboutDataSuccess(state, { payload }) {
//   return {
//     ...state,
//     isFetching: false,
//     aboutData: payload,
//   };
// }

// function getSectionDataSuccess(state, { payload }) {
//   const { aboutSloohSections } = state;
//   const { sectionTag } = payload;
//   return {
//     ...state,
//     isFetching: false,
//     aboutSloohSections: { ...aboutSloohSections, [sectionTag]: payload },
//     activeSubSections: null,
//   };
// }

// function getSubSectionDataSuccess(state, { payload }) {
//   return {
//     ...state,
//     isFetching: false,
//     subSectionData: payload,
//   };
// }

// function setActiveSubSection(state, { payload }) {
//   return {
//     ...state,
//     activeSubSections: payload,
//   };
// }


import { state } from "../missions/selectors";
import createReducer from "../utils/createReducer";
import { GET_ABOUT_DATA_START, GET_ABOUT_DATA_SUCCESS, GET_SECTION_DATA_START, GET_SECTION_DATA_SUCCESS, GET_SUB_SECTION_DATA_START, GET_SUB_SECTION_DATA_SUCCESS } from "./actions";

const initialState = {
  isFetching: false,
  aboutData: {},
  aboutSloohSections: {},
  subSectionData: {},
  activeSubSections: null,
  serverError: null,
};

export default createReducer(initialState, {
  [GET_ABOUT_DATA_SUCCESS](state, {payload}){
    return{
      ...state,
      aboutData: payload,
      isFetching: false,
    }
  },
  [GET_ABOUT_DATA_START](state){
    return {
      ...state,
      isFetching: true,
    }    
  },
  [GET_SECTION_DATA_SUCCESS](state, {payload}){
    const { aboutSloohSections } = state;
    const { sectionTag } = payload;
    return{
      ...state,
      aboutSloohSections: { ...aboutSloohSections, [sectionTag]: payload },
      activeSubSections: null,
      isFetching: false,
    }
  },
  [GET_SECTION_DATA_START](state){
    return {
      ...state,
      isFetching: false,      
    }    
  },
  [GET_SUB_SECTION_DATA_SUCCESS](state, {payload}){
    return{
      ...state,
      subSectionData: payload,
      isFetching: false,
    }
  },
  [GET_SUB_SECTION_DATA_START](state){
    return {
      ...state,
      isFetching: true,
    }    
  },

})