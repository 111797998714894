export const lightGray = '#9B9B9B';
export const gray = '#D9D9D9';
export const grayer = '#333';
export const darkGray = '#1E1E1E';
export const pink = '#F310A7';
export const turqoise = '#589A9B';
export const blueGreen = '#7EC3C4';
export const lightTurqoise = '#b1cfcf';
export const lightBlue = '#B9D9D9';
export const darkBlueGray = '#3C4A55';
export const curiousBlue = '#337ab7';
export const blueBlack = '#131D21';
export const fontBlack = '#3c4a55';
export const red = '#FC1048';
export const black = '#000';
export const white = '#fff';
export const gold = '#F3D316';
export const brightGreen = '#0afc0a';
export const brightBlue = '#0084b4';
export const yellow = '#d7ba16';
export const brightYellow = '#F0D048';
export const lightBlack = '#1F1F1F';
export const lightHeadedAstronaut = '#41566f';
export const brightBlue900 = '#1177D0';

/* VARIABLES = COLOR
 * */

export const sloohWhite = 'rgb(255, 255, 255)';

export const sloohPrimaryXxlight = 'rgb(255, 15, 190)';
export const sloohPrimaryXlight = 'rgb(245, 15, 180)';
export const sloohPrimaryLight = 'rgb(235, 15, 170)';
export const sloohPrimary = 'rgb(225, 15, 160)';
export const sloohPrimaryDark = 'rgb(215, 15, 150)';
export const sloohPrimaryXdark = 'rgb(200, 15, 140)';
export const sloohPrimaryXxdark = 'rgb(190, 15, 130)';

export const sloohGrayXxlight = 'rgb(185, 215, 215)';
export const sloohGrayXlight = 'rgb(160, 190, 190)';
export const sloohGrayLight = 'rgb(145, 175, 175)';
export const sloohGray = 'rgb(130, 160, 160)';
export const sloohGrayDark = 'rgb(115, 145, 145)';
export const sloohGrayXdark = 'rgb(100, 130, 130)';
export const sloohGrayXxdark = 'rgb(85, 115, 115)';

export const sloohBlueGray = 'rgb(60, 70, 85)';

export const sloohBlueXxlight = 'rgb(60, 70, 145)';
export const sloohBlueXlight = 'rgb(50, 60, 125)';
export const sloohBlueLight = 'rgb(40, 50, 105)';
export const sloohBlue = 'rgb(30, 40, 85)';
export const sloohBlueDark = 'rgb(20, 30, 65)';
export const sloohBlueXdark = 'rgb(10, 20, 45)';
export const sloohBlueXxdark = 'rgb(0, 10, 25)';

export const sloohBlack = 'rgb(0, 0, 0)';
