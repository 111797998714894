import createReducer from 'app/modules/utils/createReducer';
import { GET_ASSIGNMENT_LIST_ERROR, GET_ASSIGNMENT_LIST_START, GET_ASSIGNMENT_LIST_SUCCESS } from './actions';

const initialState = {
    isFetching: true,
    assignmentList: [],    
}

export default createReducer(initialState, {
    [GET_ASSIGNMENT_LIST_SUCCESS](state, { payload }){
        return {
            ...state,
            assignmentList: payload.questList,
            noAssignmentsMessage: payload.noAssignmentsMessage,
            questLinkText: payload.questLinkText,
            curvedArrowIconURL: payload.curvedArrowIconURL,
            emptyStateIconURL: payload.emptyStateIconURL,
            questLinkURL: payload.questLinkURL,
            totalCount: payload.totalCount,
            isFetching: false,
        }
    },
    [GET_ASSIGNMENT_LIST_START](state){
        return{
            ...state,
            assignmentList:[],
            isFetching: true,
        }
    },
    [GET_ASSIGNMENT_LIST_ERROR](state){
        return{
            ...state,
            isFetching: false,
        }
    },
});