import Request from "app/components/common/network/Request";
import React, { Fragment, useState } from "react";
import { Spinner } from 'app/components/spinner/index';
import { LMS_CLASSROOM_URL } from "./api";
import styled from "styled-components";
import { primaryFont, secondaryFont } from "app/styles/variables/fonts";
import { blue400, brightBlue1000, gray200, gray300, gray600, romance } from "app/styles/variables/colors_tiles_v4";
import { connect } from "react-redux";
import { Field, reduxForm } from 'redux-form';
import InputField from 'app/components/form/InputFieldNew';
import { InstructionItem } from "app/components/instruction-item";
import { checkDivider, getWorkspaceTitle } from "./utils";

const LMSClassroomContainer = styled.div`
    padding: 60px;
`;

const HeadingElement = styled.h2`
    color: black;
    text-align: center;
    font-size: 32px;
    font-family: ${secondaryFont};
    font-style: normal;
    font-weight: 400;
    line-height: 38px;
`;

const SubHeadingElement = styled.h6`
    color: ${gray600};
    text-align: center;
    font-size: 14px;
    font-family: ${primaryFont};
    font-weight: 390;
    line-height: normal;
    margin-bottom: 32px;
    font-style: normal;
`;

const TitleElement = styled.h5`
    color: ${gray600};
    font-size: 12px;
    font-family: ${primaryFont};
    font-weight: 450;
    line-height: 100%;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 10px;
`;

const ClassroomListContainer = styled.div`
    border: 1px solid ${gray200};
    border-radius: 8px;
    padding: 20px;

    &:last-child{
        padding-bottom: 0px;
    }
`;

const ClassroomItemContainer = styled.div`
    display: flex;
    align-items: start;
    gap: 10px;
    padding-bottom: ${(props)=> props.addDivider ? '20px' : '16px'};
    margin-bottom: ${(props) => props.addDivider ? '20px' : '0px'};
    border-bottom: ${(props) => props.addDivider ? `1px solid ${gray200}` : 'none'};
`;

const ClassroomItemTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const ClassroomSubTitle = styled.h5`
    color: ${gray600};
    font-size: 14px;
    font-family: ${primaryFont};
    font-weight: 300;
`;

const ClassroomTitle = styled.span`
    color: black;
    font-size: 16px;
    font-family: ${primaryFont};
    font-weight: 420;
    margin-bottom: 4px;
`;

const CheckboxElement = styled.input`
    min-width: 20px;
    height: 20px;
    margin-top: 2px;
`;

const ButtonContainer = styled.div`

`;

const SubmitButton = styled.button`
    background: none;
    border: none;
    margin: 0;
    padding: 12px 48px;
    transition: background-color 0.25s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: ${(props) => props.disabled ? 'none' : 'pointer'};
    border-radius: 100px;
    background-color: ${(props) => props.disabled ? gray300 : brightBlue1000};
    font-weight: bold;
    font-family: ${primaryFont};
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: ${romance};
    width: 100%;
    box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.12), 0px 2px 5px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.08);
    outline: none;
    margin-top: 20px;

    :focus:not(.focus-visible){
        outline: none;
    }
`;

const ClassroomDescription = styled(Field)`
    input{
        color: black;
        font-variant-numeric: lining-nums proportional-nums;
        font-family: ${primaryFont};
        font-size: 16px;
        font-style: normal;
        font-weight: 420;
        line-height: 22px;
        outline: none;
    }

    input:focus{
        border-radius: 4px;
        border: 2px solid ${brightBlue1000};
        background: #FFF;
        box-shadow: 0px 0px 4px 0px rgba(17, 119, 208, 0.41);
    }
`;

const NoteText = styled.span`
    color: ${blue400};
    font-family: ${primaryFont};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
`;

const SelectNoteElement = styled.h6`
    color: ${gray600};
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: ${primaryFont};
    font-size: 14px;
    font-style: normal;
    font-weight: 390;
    line-height: 20px;
    margin-top: 32px;
    margin-bottom: 0px;
`;

const importClassroomModel = {
    name: 'importClassroomRes',
    model: (resp: any) => (
        {
            ...resp,
            classroomList: resp.workspaceArray
        }
    ),
}

const ImportLMSClassroom = (props: any) => {

    const { token, type, email, authorizeType, id_token } = props;

    const [loading, setLoading] = useState(false);
    const [selectedClassroom, setSelectedClassroom] = useState<Record<string,any>>({});

    const handleClassRoomSelection = (checked: boolean, data: any) => {
        const key = data.courseId;

        if(checked){
            if(!selectedClassroom.hasOwnProperty(key)){
                let temp = {...selectedClassroom};
                temp[key] = data;
                setSelectedClassroom(temp);
            }
        }
        else{
            if(selectedClassroom.hasOwnProperty(key)){
                let temp = {...selectedClassroom};
                delete temp[key]; 
                setSelectedClassroom(temp);
            }
        }
    }

    const handleDescriptionChange = (id: string, value: string) => {
        setSelectedClassroom({...selectedClassroom, [id]: {...selectedClassroom[id], desc: value}});
    }

    const handleSubmitButton = (type: string) => {
        switch(type){
            case "close":
                props.handleSelectedGoogleClassroom("close")
                break;
            case "next":
                props.handleSelectedGoogleClassroom("showStudents", selectedClassroom)
                break;
        }
    }

    const getButtonText = () => {
        const count = Object.keys(selectedClassroom).length;
        return `CREATE ${count} ${count > 1 ? 'Workspaces' : 'Workspace'}`;
    }

    return(
        <LMSClassroomContainer>
            <Request
                serviceURL={LMS_CLASSROOM_URL}
                requestBody={{
                    lmsType: type,
                    loginEmailAddress: email,
                    oauth_token: token,
                    callsource: authorizeType,
                    id_token: id_token,
                }}
                models={[importClassroomModel]}
                render={({ fetchingContent, modeledResponses: {importClassroomRes} }: any) => (
                    <Fragment>
                        <Spinner
                            loading={fetchingContent || loading}
                        />
                        {!fetchingContent && (
                            <Fragment>
                                <div>
                                    <HeadingElement>{importClassroomRes?.pageHeading}</HeadingElement>
                                    <SubHeadingElement>{importClassroomRes?.pageSubHeading}</SubHeadingElement>
                                </div>
                                <div>
                                    <TitleElement>{importClassroomRes?.importTitle}</TitleElement>
                                    <ClassroomListContainer>
                                        {importClassroomRes.classroomList.map((classroom: any, index: number) => (
                                            <ClassroomItemContainer addDivider={checkDivider(classroom, importClassroomRes.classroomList, index)}>
                                                <CheckboxElement defaultChecked={classroom?.disabled} onChange={(e: any) => handleClassRoomSelection(e.target.checked, classroom)} disabled={classroom?.disabled} type="checkbox" name={classroom?.courseId} />
                                                <ClassroomItemTitleContainer>
                                                    <ClassroomTitle>
                                                        {getWorkspaceTitle(classroom.sectionTitle, classroom?.courseTitle)}
                                                    </ClassroomTitle>
                                                    {selectedClassroom.hasOwnProperty(classroom.courseId) && (
                                                        <ClassroomDescription
                                                            name={"description"}
                                                            type="text"
                                                            placeholder="Enter a description or message to your students"
                                                            component={InputField}
                                                            onChange={(event: any) => {
                                                                handleDescriptionChange(classroom.courseId, event.target.value);
                                                            }}
                                                        />
                                                    )}
                                                </ClassroomItemTitleContainer>
                                            </ClassroomItemContainer>
                                        ))}
                                        {importClassroomRes?.showCloseButton && (
                                            importClassroomRes.instructionList.map((instruction: string, index: number) => (
                                                <InstructionItem  instruction={instruction} number={index + 1} />
                                            ))
                                        )}
                                    </ClassroomListContainer>
                                    {!importClassroomRes?.showCloseButton && (
                                        Object.keys(selectedClassroom).length === 0 ? (
                                            <SelectNoteElement>Select at least one to continue.</SelectNoteElement>
                                        ) : (
                                            <SubmitButton onClick={()=>{handleSubmitButton(importClassroomRes?.showCloseButton ? "close" : "next")}} type="button">
                                                {importClassroomRes?.showCloseButton ? "Close" : getButtonText()}
                                            </SubmitButton>
                                        )
                                    )}
                                </div>
                            </Fragment>
                        )}
                    </Fragment>
                )}
            />
        </LMSClassroomContainer>
    )
}

const mapStateToProps = ({ user }: any) => ({ user });

export default connect(
    mapStateToProps,
    null
  )(
    reduxForm({
    form: 'classroomSelectionForm'  // a unique identifier for this form
})(ImportLMSClassroom));