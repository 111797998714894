import {
  astronaut,
  red900,
  romance,
} from 'app/styles/variables/colors_tiles_v4';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

const AlertsIcon = ({ alertFlag }) => (
  <span className="root">
    <span className="i-notification" />
    {alertFlag && <span className="count" />}

    <style jsx>{`
      .root {
        display: block;
        position: relative;
        height: 20px;
      }

      .icon-notifications {
        font-size: 20px;
        line-height: 20px;
        height: 20px;
        display: inline-block;
      }

      .count {
        position: absolute;
        top: -3px;
        right: -3px;
        color: ${romance};
        background-color: ${red900};
        border-radius: 50%;
        width: 8px;
        height: 8px;
        font-size: 11px;
      }

      .zero {
        background-color: ${astronaut};
        color: ${romance};
      }
    `}</style>
  </span>
);

AlertsIcon.propTypes = {
  alertFlag: PropTypes.bool,
};

AlertsIcon.defaultProps = {
  alertFlag: false,
};

const mapStateToProps = ({ alerts }) => ({
  alertFlag: alerts.showNewAlertNotificationFlag,
});

export default connect(
  mapStateToProps,
  null
)(AlertsIcon);
