import './config/sentry.config';
import { EventSourcePolyfill, NativeEventSource } from 'event-source-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import AppRouter from 'app/router';
// load monitoring and global error handling
import './monitoring';
// redux store
import store from 'app/store';
// providers
// utilities
import './utils/manual-polyfills';
import './i18n';

// styles
import './styles/index.scss';
import { getUserInfo } from './modules/User';
import { CHURN_ZERO_APP_KEY } from './config/project-config';

window.EventSource = NativeEventSource || EventSourcePolyfill;

let attempts = 0; //attempts in ms(Milli Seconds)
let attemptsCallback = 100; //callback in ms(Milli Seconds)
let timeout = 10000; //timeout for attempts ms(Milli Seconds)

function replaceClasses() {
  function replaceElementClasses(classesToRemove, newClass) {
    classesToRemove.forEach(currentClass => {
      const elementsWithClass = document.querySelectorAll(`.${currentClass}`);
      elementsWithClass.forEach(element => {
        if (currentClass !== newClass) {
          element.classList.remove(currentClass);
          element.classList.add(newClass);
        }
      });
    });
  }

  const classesConfig = [
    {
      classes: [
        'helperbird-reading-font-size-100',
        'helperbird-reading-font-size-102',
        'helperbird-reading-font-size-104',
        'helperbird-reading-font-size-106',
        'helperbird-reading-font-size-108',
        'helperbird-reading-font-size-110',
      ],
      newClass: 'helperbird-reading-font-size-104',
    },
    {
      classes: [
        'helperbird-reading-letter-spacing-100',
        'helperbird-reading-letter-spacing-102',
        'helperbird-reading-letter-spacing-104',
        'helperbird-reading-letter-spacing-106',
        'helperbird-reading-letter-spacing-108',
        'helperbird-reading-letter-spacing-110',
      ],
      newClass: 'helperbird-reading-letter-spacing-102',
    },
    {
      classes: [
        'helperbird-reading-line-height-100',
        'helperbird-reading-line-height-102',
        'helperbird-reading-line-height-104',
        'helperbird-reading-line-height-106',
        'helperbird-reading-line-height-108',
        'helperbird-reading-line-height-110',
      ],
      newClass: 'helperbird-reading-line-height-102',
    },
  ];

  classesConfig.forEach(({ classes, newClass }) =>
    replaceElementClasses(classes, newClass)
  );
}

function removeElementsWithFaAndAriaHidden() {
  const elements = document.querySelectorAll('[class*="fa"][aria-hidden]');
  elements.forEach(element => {
    element.removeAttribute('aria-hidden');
  });
}

const observer = new MutationObserver(() => {
  if (
    Array.from(document.body.classList).some(className =>
      className.includes('helperbird-reading')
    )
  ) {
    replaceClasses();
  }
  removeElementsWithFaAndAriaHidden();
});

observer.observe(document.body, {
  childList: true,
  subtree: true,
  attributes: true,
  characterData: true,
});

document.body.addEventListener('keydown', function(e) {
  if (e.key === 'Tab') {
    document.body.classList.add('using-keyboard');
  }
});

document.body.addEventListener('mousedown', function() {
  document.body.classList.remove('using-keyboard');
});

const checkAmplitudeAndLoadSloohApp = () => {
  const amplitudeId = window.getAmplitudeDeviceID();
  if ((amplitudeId !== '' && amplitudeId !== null) || attempts > timeout) {
    attempts = 0;
    ReactDOM.render(
      <Provider store={store}>
        <AppRouter />
      </Provider>,
      window.document.getElementById('app')
    );
  } else {
    attempts += attemptsCallback;
    setTimeout(checkAmplitudeAndLoadSloohApp, attemptsCallback);
  }
};

const configureChurnZero = () => {
  const { enableCZ, czAccountId, czContactId } = getUserInfo();
  if (czContactId && enableCZ && czAccountId && CHURN_ZERO_APP_KEY) {
    window.ChurnZero?.push(['setAppKey', CHURN_ZERO_APP_KEY]);
    window.ChurnZero?.push(['setContact', czAccountId, czContactId]);
  }
};

//ToddR - 7/14/2021 - The Slooh App requires the Amplitude Device ID to be present, wait for the device id....
checkAmplitudeAndLoadSloohApp();
configureChurnZero();

// ReactDOM.render(
//   <Provider store={store}>
//     <AppRouter />
//   </Provider>,
//   window.document.getElementById('app')
// );
