import React from 'react';
import StarPartiesRedesign from 'app/components/GlobalNavigation/Menus/starPartiesRedesign/StarPartiesRedesign';
import SearchRedesign from 'app/components/GlobalNavigation/Menus/searchRedesign/SearchRedesign';
import Main from './Main';
import TelescopesRedesign from './telescopesRedesign/TelescopesRedesign';
import Help from './Help';
import NotificationsRedux from './Notifications.redux';
import ProfileRedux from './Profile.redux';

export const LEFT_MENU = {
  DEFAULT: {
    name: 'DEFAULT',
    title: null,
    render: () => null,
  },
  MAIN: {
    name: 'MAIN',
    title: 'Menu',
    render: props => <Main {...props} />,
  },
  TELESCOPES: {
    name: 'TELESCOPES',
    title: 'Telescopes',
    render: props => <TelescopesRedesign {...props} />,
  },
};

const RIGHT_MENU = {
  HELP: {
    name: 'HELP',
    title: 'Account info',
    render: props => <Help {...props} />,
  },
  ALERTS: {
    name: 'ALERTS',
    title: 'Notifications',
    render: props => <NotificationsRedux {...props} />,
  },
  STAR_PARTIES: {
    name: 'STAR_PARTIES',
    title: 'Star Parties',
    render: props => <StarPartiesRedesign {...props} />,
  },
  PROFILE: {
    name: 'PROFILE',
    title: 'Profile',
    menuWidth: 310,
    menuWidthUnits: 'px',
    render: props => <ProfileRedux {...props} />,
  },
  SEARCH: {
    name: 'SEARCH',
    title: 'Search',
    menuWidth: 400,
    menuWidthUnits: 'px',
    className: 'search-menu',
    render: props => <SearchRedesign {...props} />,
  },
};

export function isRight(menuName) {
  return RIGHT_MENU.hasOwnProperty(menuName);
}

export function isLeft(menuName) {
  return LEFT_MENU.hasOwnProperty(menuName);
}

export default Object.assign({}, LEFT_MENU, RIGHT_MENU);
