import React from 'react';
import PropTypes from 'prop-types';
import { EditorState } from 'draft-js';

import StyleButton from './StyleButton';
import { BLOCK_TYPES } from './constants';
import InlineStyleControls from "app/components/rich-text-editor/InlineStyleControls";

const { func, any, instanceOf } = PropTypes;

const BlockStyleControls = props => {
  const { editorState, isMacOS } = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  const mainKeyCombination = isMacOS ? 'Command + Option' : 'Ctrl +Alt';
  const additionalKey = isMacOS ? 'Command' : 'Ctrl';
  const blackQuoteCombination = isMacOS ? 'Command + Option + 7' : 'Ctrl + K';

  const textEditorAriaLabels = [
    `First level header, press ${mainKeyCombination} +1 for formatting`,
    `Second level header, press ${mainKeyCombination} +2 for formatting`,
    `Third level header, press ${mainKeyCombination} +3 for formatting`,
    `Fourth level header, press ${mainKeyCombination} +4 for formatting`,
    `Fifth level header, press ${mainKeyCombination} +5 for formatting`,
    `Sixth level header, press ${mainKeyCombination} +6 for formatting`,
    `Make the text a quote by highlighting it and using the ${blackQuoteCombination} key combination`,
    `Make the text a unordered list by highlighting it and using the ${mainKeyCombination} + 8 key combination`,
    `Make the text an ordered list by highlighting it and using the ${additionalKey} +  key combination`,
  ];



  return (
    <div className="RichEditor-controls">
      {BLOCK_TYPES.map((type, index) => (
        <StyleButton
          ariaLabel={textEditorAriaLabels[index]}
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};

BlockStyleControls.propTypes = {
  onToggle: func.isRequired,
  isMacOS: any,
  editorState: instanceOf(EditorState).isRequired,
};

BlockStyleControls.defaultProps = {
  isMacOS: true,
};


export default BlockStyleControls;
