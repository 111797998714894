import { validateResponseAccess } from "../authorization/actions";
import { getWorkspaceSettingsApi, setWorkspaceSettingsApi } from "./api";

export const GET_WORKSPACE_SETTINGS_START = "GET_WORKSPACE_SETTINGS_START";
export const GET_WORKSPACE_SETTINGS_SUCCESS = "GET_WORKSPACE_SETTINGS_SUCCESS";
export const SET_WORKSPACE_SETTINGS_START = "SET_WORKSPACE_SETTINGS_START";
export const SET_WORKSPACE_SETTINGS_SUCCESS = "SET_WORKSPACE_SETTINGS_SUCCESS";

const getWorkspaceSettingsStart = () => ({
    type: GET_WORKSPACE_SETTINGS_START    
});

const getWorkspaceSettingsSuccess = (payload) => ({
    type: GET_WORKSPACE_SETTINGS_SUCCESS,
    payload    
});

const setWorkspaceSettingsStart = () => ({
    type: SET_WORKSPACE_SETTINGS_START    
});

const setWorkspaceSettingsSuccess = (payload) => ({
    type: SET_WORKSPACE_SETTINGS_SUCCESS,
    payload    
});

export const getWorkspaceSettings = (data) => (dispatch, getState) => {
    dispatch(getWorkspaceSettingsStart());
    const { token, at, cid } = getState().user;
    return getWorkspaceSettingsApi({token, at, cid, ...data}).then(result=>{
        const res = result.data;
        if(res.apiError)
            dispatch(validateResponseAccess(res));
        else    
            dispatch(getWorkspaceSettingsSuccess(res))
    })
}

export const setWorkspaceSettings = (data) => (dispatch, getState) => {
    dispatch(setWorkspaceSettingsStart());
    const { token, at, cid } = getState().user;
    return setWorkspaceSettingsApi({token, at, cid, ...data}).then(result => {
        const res = result.data;
        if(res.apiError)
            dispatch(validateResponseAccess(res));
        else
            // dispatch(setWorkspaceSettingsSuccess({data}))
            dispatch(getWorkspaceSettings({}));
    });
}

