import createReducer from '../utils/createReducer';
import { GET_WORKSPACE_SETTINGS_START, GET_WORKSPACE_SETTINGS_SUCCESS, SET_WORKSPACE_SETTINGS_START, SET_WORKSPACE_SETTINGS_SUCCESS } from './actions';

const initialState = {
    isFetching: true,    
}

export default createReducer(initialState, {
    [GET_WORKSPACE_SETTINGS_SUCCESS](state, { payload }){
        return {
            ...state,
            myWorkspaceSettings: payload,
            isFetching: false,
        }
    },
    [GET_WORKSPACE_SETTINGS_START](state){
        return{
            ...state,
            isFetching: true,
        }
    },
    [SET_WORKSPACE_SETTINGS_START](state){
        return{
            ...state,
            isFetching: true,
        }
    },
    [SET_WORKSPACE_SETTINGS_SUCCESS](state, { payload }){
        var workspaceSettingsForm = state?.myWorkspaceSettings?.formLabels;
        workspaceSettingsForm.map((form, index)=>{
            if(form?.uniqueName === payload?.data?.key){
                if(payload?.data?.ownerFlag){
                    workspaceSettingsForm[index].myCapabilitiesValue = payload.data.value;
                    if(payload)
                    return;
                }
                if(form?.myStudentCapabilitiesType === "dropdown")
                    workspaceSettingsForm[index].myStudentCapabilitiesSelected = payload.data.value;
                else
                    workspaceSettingsForm[index].myStudentCapabilitiesValue = payload.data.value;                
            }                
        })      
        return {
            ...state,
            myWorkspaceSettings: { ...state.myWorkspaceSettings, formLabels: workspaceSettingsForm},
            isFetching: false,
        }
    }
});