import css from 'styled-jsx/css';
import { faintShadow } from 'app/styles/variables/shadows';
import {
  astronaut,
  romance,
  golden_yellow,
  shadows,
} from 'app/styles/variables/colors_tiles_v4';
import { primaryFont, secondaryFont } from 'app/styles/variables/fonts';
import { screenLarge, screenMedium, screenSmallMobile, screenXLarge } from 'app/styles/variables/breakpoints';
import {
  backgroundImageCover,
  dropShadowContainer,
} from 'app/styles/mixins/utilities';

export default css`
  .step-root {
    margin: 0 auto;
    width: 100%;
    color: ${astronaut};
  }

  .step-root-new {
    margin: 0 auto;
    width: 100%;
    color: ${astronaut};
  }

  .inner-container {
    padding: 25px;
    width: 100%;
    background-color: ${romance};
    ${faintShadow}
  }

  .section-heading {
    font-size: 14px;
    font-family: ${primaryFont};
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
    padding: 25px 0;
    letter-spacing: 2px;
  }

  @media ${screenMedium} {
    .step-root {
      
    }

    .step-root-new {
      width: 100%;
    }
  }

  iframe{
    min-height: 850px;
  }

  .plan-det-card{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 12px;
    background: #FFFFFF;
    border: 1px solid #CBCBCB;
    border-radius: 4px;
    margin-bottom: 32px;
  }

  .plan-det-card .level-text{
    font-family: $primary-font;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 8px;
  }

  .plan-det-card .plan-price-details{
    font-family: $primary-font;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 34px;
    color: #000000;
    margin-bottom: 0px;
  }

  .plan-det-card .plan-price-details .plan-duration{
    font-family: $primary-font;
    font-style: normal;
    font-weight: 390;
    font-size: 24px;
    line-height: 34px;
    color: #545454;
  }

  .plan-det-card .original-price{
    font-family: $primary-font;
    font-style: normal;
    font-weight: 390;
    font-size: 14px;
    line-height: 20px;
    color: #545454;
    text-decoration: line-through;
    margin-bottom: 0px;
  }

  .plan-det-card .offer-first-year{
    padding: 0px 4px;
    background: #E5F6E9;
    border-radius: 4px;
    margin-bottom: 8px;
  }

  .plan-det-card .offer-first-year .text{
    font-family: $primary-font;
    font-style: normal;
    font-weight: 390;
    font-size: 14px;
    line-height: 20px;
    color: #00841D;
    margin-bottom: 0px;
  }

  .plan-det-card .not-charged-text{
    font-family: $primary-font;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000000;
    margin-top: 8px;
    margin-bottom: 0px;
  }

  @media ${screenLarge}, ${screenMedium}, ${screenXLarge} {
    iframe{
      min-height: 750px;
    }
  }
`;
