import { API } from "app/api";

export const processLMSSignIn = (data: any) => {
    return API.post("/api/registration/processLMSSignin", data);
}

export const setLMSWorkspace = (data: any) => {
    return API.post("/api/registration/setLMSWorkspaces", data);
}

export const LMS_CLASSROOM_URL = "/api/page/importLMSWorkspaces";