import createReducer from '../utils/createReducer';

import {
  SET_PAGE_TITLE,
  RESET_PAGE_META,
  SET_STANDARD_META,
  SET_OPEN_GRAPH_META,
  SET_OG_CANONICAL_URL, SET_CARD_LABEL,
} from './seo-actions';

export const initialState = {
  cardLabel: '',
  title: 'Explore Space',
  standard: {
    description: 'Do you wonder what is out there? Join a community of fellow Earthlings looking through powerful telescopes into outer space.',
  },
  og: {
    title: 'Slooh.com is Teaching the world to explore space.',
    audio: '',
    video: '',
    description: 'Do you wonder what is out there? Join a community of fellow Earthlings looking through powerful telescopes into outer space.',
    type: 'website',
    url: 'https://slooh.com',
    image: 'https://vega.slooh.com/icons/sharing/slooh_logo_sky_Square.jpg',
    site_name: 'Slooh',
    site: 'slooh.com',
  },
};

export default createReducer(initialState, {
  [SET_STANDARD_META](state, { payload }) {
    return {
      ...state,
      standard: Object.assign({}, initialState.standard, payload),
    };
  },
  [SET_OPEN_GRAPH_META](state, { payload }) {
    return {
      ...state,
      og: Object.assign({}, state.og, payload),
    };
  },
  [SET_PAGE_TITLE](state, { title }) {
    return {
      ...state,
      title,
    };
  },
  [SET_CARD_LABEL](state, { cardLabel }) {
    return {
      ...state,
      cardLabel,
    };
  },
  [RESET_PAGE_META](state) {
    return {
      ...initialState,
      cardLabel: state.cardLabel,
    };
  },
  [SET_OG_CANONICAL_URL](state, { pageURL }) {
    return {
      ...state,
      og: {
        ...state.og,
        url: pageURL,
      },
    };
  },
});
