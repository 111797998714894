import React from 'react';
import { browserHistory } from 'react-router';

const contentClickHandler = (link) => {  
    browserHistory.push(link);
};

const QuestBreadCrumb = ({
  sloohQuestBreadCrumbQuestTitle,
  sloohQuestBreadCrumbQuestLinkURL,
  questStep,
  showBreadCrumb,
  questStepTitle,
 }) => (
  ((showBreadCrumb && sloohQuestBreadCrumbQuestTitle) ?
    <div>
    <div className="breadcrumb">  
	    <span onClick={() => contentClickHandler(sloohQuestBreadCrumbQuestLinkURL)} role="button">
          <img className="icon" src="https://vega.slooh.com/assets/v4/dashboard-new/back_to_quest_icon.svg" />
        	<h4 className="breadcrumbBoldtxt">Return to Quest:&nbsp;</h4>
        	<h4 className="breadcrumbNormaltxt">
            {sloohQuestBreadCrumbQuestTitle}
            {questStepTitle && (
              <>
                &nbsp;-&nbsp;{questStepTitle}
              </>
            )}
          </h4>
    	    </span>
    </div>

<style jsx>
{`

  .breadcrumb{
    position:  fixed;
    margin-top: 48px;
    background-color: #fad599;
    width: 100%;
    padding: 10px 0px 10px 10px;    
    border-bottom: 2px solid #ced2d8;
  }

  .breadcrumb .icon{
    display: inline;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    width: 25px;
    height: 25px;
    margin-right: 8px;
  }

  .breadcrumbNormaltxt{
    font-family: "Brandon Grotesque Regular", "Helvetica Neue", Helvetica, sans-serif;
    font-size: 16px;
    color: #41566F;
    margin-bottom: 0px;
  }

  .breadcrumbBoldtxt{
    font-family: "Brandon Grotesque Regular", "Helvetica Neue", Helvetica, sans-serif;
    font-size: 16px;
    color: #41566F;
    margin-bottom: 0px;
  }

  .breadcrumb span{
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .breadcrumb span h4{
    display: inline; 
  }
  
  `}
  </style>
  </div>:null)
);

export default QuestBreadCrumb;