import { API } from 'app/api';
import { projectProductId } from 'app/config/project-config';

export function upcomingShows({
  count,
  page,
}) {
  return API.get(`/api/events/upcoming?productId=${projectProductId}`, {
    params: {
      count,
      page,
    },
  });
}
