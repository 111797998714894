import React, { useState } from 'react';

import BaseModal from 'app/components/modal-redesign';
import { primaryFont } from 'app/styles/variables/fonts';
import { UpgradeModalDashboard } from './upgrade-modal-new';

export const UpgradeConfirmModal = props => {
  const {
    isOpen,
    onClose,
    heading,
    description,
    buttonText,
    pricingText,
    talkWithUsText,
    talkWithUsUrl,
    showUpgradeDirectly,
    showUpgradeBtn,
    showTalkToUsBtn,
    iconUrl,
    openInNewWindow = true,
  } = props;

  const [upgradeModal, setUpgradeModal] = useState({
    isShown: showUpgradeDirectly || false,
    callSource: 'upgrade',
  });

  const toggleUpgradeModal = () => {
    setUpgradeModal(prev => ({
      isShown: !prev.isShown,
      callSource: 'upgrade',
    }));
  };

  const closeModal = () => {
    toggleUpgradeModal();
    onClose();
  };

  return (
    <div>
      {!upgradeModal.isShown ? (
        <BaseModal
          shouldCloseOnOverlayClick={false}
          isOpen={isOpen}
          handleCloseModal={onClose}
          className="base-modal"
        >
          <div className="modal-container">
            <img
              className="lock-icon"
              src={
                iconUrl ||
                'https://vega.slooh.com/assets/v4/site-redesign/icons/lock_icon_with_light_red_bg.svg'
              }
            />

            <h4 className="heading">{heading}</h4>

            <h6 className="description">{description}</h6>

            {showUpgradeBtn && (
              <>
                <button onClick={toggleUpgradeModal} className="upgrade-button">
                  {buttonText}
                </button>
                <div className="footer-container">
                  <span className="pricing-text">{pricingText}</span>
                  <a
                    target="_blank"
                    className="price-link"
                    href={talkWithUsUrl}
                  >
                    {talkWithUsText}
                  </a>
                </div>
              </>
            )}

            {showTalkToUsBtn && (
              <a target={openInNewWindow ? '_blank' : ''} href={talkWithUsUrl}>
                <button className="upgrade-button">{talkWithUsText}</button>
              </a>
            )}
          </div>

          <style jsx>
            {`
              .modal-container {
                display: flex;
                padding: 40px 20px;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 24px;
              }

              .lock-icon {
                width: 80px;
                height: 80px;
              }

              .heading {
                color: #000;
                font-family: ${primaryFont};
                font-size: 16px;
                font-style: normal;
                font-weight: 450;
                line-height: 140%;
                letter-spacing: 1px;
                text-transform: uppercase;
                margin-bottom: 0px;
              }

              .description {
                color: #333;
                text-align: center;
                font-family: ${primaryFont};
                font-size: 16px;
                font-style: normal;
                font-weight: 390;
                line-height: 140%;
              }

              .upgrade-button {
                padding: 10px 28px;
                border-radius: 40px;
                background: #1177d0;
                color: #fff;
                text-align: center;
                font-family: ${primaryFont};
                font-size: 11px;
                font-style: normal;
                font-weight: 450;
                line-height: normal;
                letter-spacing: 1px;
                text-transform: uppercase;
                border: none;
              }

              .footer-container {
                display: flex;
                padding-top: 20px;
                justify-content: center;
                align-items: flex-start;
                gap: 4px;
                align-self: stretch;
                border-top: 1px solid #d9dee4;
                background: none;
              }

              .pricing-text {
                color: #333;
                text-align: center;
                font-family: ${primaryFont};
                font-size: 16px;
                font-style: normal;
                font-weight: 390;
                line-height: 140%;
              }

              .price-link {
                color: #1177d0;
                text-align: center;
                font-family: ${primaryFont};
                font-size: 16px;
                font-style: normal;
                font-weight: 390;
                line-height: 140%; /* 22.4px */
                text-decoration-line: underline;
              }
            `}
          </style>
        </BaseModal>
      ) : (
        <UpgradeModalDashboard
          showUpgradeModal={upgradeModal.isShown}
          callSource={upgradeModal.callSource}
          onHide={closeModal}
        />
      )}
    </div>
  );
};
