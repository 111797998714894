import React, { FC, memo, useEffect, useMemo, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isUndefined from 'lodash/isUndefined';

import {
  selectDashboardButtons,
  selectUserGravityStatus,
} from 'app/modules/new-dashboard/selectors';
import {
  getDashboardButtonAction,
  resetDashboardButtonsAction,
} from 'app/modules/new-dashboard/actions';
import ModalFullscreen from 'app/components/modal-fullscreen';

const ObjectMap = React.lazy(() => import('app/modules/new-dashboard/components/object-map-new'));
const ObjectMapLazy = (props: any) => (
  <Suspense fallback={<div>Loading...</div>}>
      <ObjectMap {...props} />
  </Suspense>
)
interface Props {
  handleHideModal: () => void;
}

const Slooh1KModal: FC<Props> = memo(props => {
  const { handleHideModal } = props;

  const dispatch = useDispatch();

  const dashboardButtons = useSelector(selectDashboardButtons);
  const { slooh1kObjectCollectedCount } =
    useSelector(selectUserGravityStatus) || {};

  useEffect(() => () => dispatch(resetDashboardButtonsAction()), []);

  useEffect(() => {
    dispatch(getDashboardButtonAction());
  }, [dispatch]);

  const objectMapSection = useMemo(
    () =>
      dashboardButtons
        ? dashboardButtons.sections.find(
            (item: any) => item.sectionName === 'objectMapSection'
          )
        : {},
    [dashboardButtons]
  );

  return (
    <ModalFullscreen
      show
      onHide={handleHideModal}
      title="Slooh 1K"
      subtitle={
        !isUndefined(slooh1kObjectCollectedCount) &&
        `You’ve collected ${slooh1kObjectCollectedCount} objects of the Slooh 1K challenge`
      }
    >
      <div className="slooh-1k-wrapper">
        {objectMapSection.showObjectMap && (
          <ObjectMapLazy
            scrollToRef={() => {}}
            refreshPhotoHub={() => {}}
            validateResponseAccess={() => {}}
            setPublicCardStatusAction={() => {}}
            handleHideModal={handleHideModal}
          />
        )}
      </div>
    </ModalFullscreen>
  );
});

export default Slooh1KModal;
