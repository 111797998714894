// @flow
import React, { Component } from 'react';
import Modal from 'react-modal';
import { withRouter } from 'react-router';
import xorBy from 'lodash/xorBy';
import { primaryFont } from 'app/styles/variables/fonts';
import { getRelativePath } from 'app/utils/urlParser';
import NotificationsRedesign from 'app/components/GlobalNavigation/Menus/notificationsRedesign/NotificationsRedesign';
import AlertTile from './AlertTile';

type TTimedNotifications = {
  alertsOnly: Array<{
    active: boolean,
    eventCountdown: number,
    eventId: number,
  }>,
};

class TimedNotifications extends Component<TTimedNotifications> {
  state = {
    alerts: [],
    dismissedAlerts: [],
    timers: [],
    showPrompt: false,
    promptText: '',
  };

  componentWillReceiveProps(nextProps) {
    const { notificationsCount, updateNotificationsCount } = this.props;
    const { alerts, dismissedAlerts } = this.state;
    if (xorBy(alerts, nextProps.alertsOnly, 'eventId').length > 0) {
      // remove all dismissed alerts
      let newAlerts = nextProps.alertsOnly;
      dismissedAlerts.forEach(_dismissed => {
        newAlerts = newAlerts.filter(_alert => _alert.eventId !== _dismissed);
      });
      this.setState(() => ({
        alerts: newAlerts,
      }));

      this.createTimers(nextProps.alertsOnly);
    }
    if (notificationsCount !== nextProps.notificationsCount) {
      updateNotificationsCount({
        count: nextProps.notificationsCount,
      });
    }
  }

  componentWillUnmount() {
    const { timers } = this.state;
    timers.map(timer => clearTimeout(timer));
    this.setState(() => ({
      timers: [],
    }));
  }

  createTimers = alerts => {
    const {
      updateNotificationsCount,
      location: { pathname },
    } = this.props;
    const timers = [];
    alerts.map(_alert => {
      if (!_alert.active) {
        timers.push(
          setTimeout(() => {
            const { alerts: alertArray } = this.state;
            const newAlerts = alertArray.map(_storedAlert => {
              const { notificationsCount } = this.props;
              if (_storedAlert.eventId === _alert.eventId) {
                _storedAlert.active = true;
                updateNotificationsCount({
                  count: notificationsCount + 1,
                });
                this.setState(() => ({
                  showPrompt: getRelativePath(_alert.linkUrl) !== pathname,
                  promptText: (
                    <div>
                      <AlertTile
                        {..._storedAlert}
                        canDismiss={false}
                        dismissAlert={this.dismissAlert}
                      />
                    </div>
                  ),
                }));
              }
              return _storedAlert;
            });

            this.setState(() => ({
              alerts: newAlerts,
            }));
          }, _alert.eventCountdown * 1000)
        );
      }
      this.setState(() => ({
        timers,
      }));
      return _alert;
    });
  };

  dismissAlert = eventId => {
    const { dismissNotification } = this.props;
    const { alerts, dismissedAlerts: dismissedAlertsFromState } = this.state;

    dismissNotification({
      eventId,
    }).then(res => {
      if (res.successFlag) {
        const dismissedAlerts = [].concat(dismissedAlertsFromState, eventId);
        const newAlerts = alerts.filter(
          _storedAlert => _storedAlert.eventId !== eventId
        );
        this.setState(() => ({
          alerts: newAlerts,
          dismissedAlerts,
        }));
      }

      if (!res.error) {
        this.setState({
          showPrompt: res.showResponse,
          promptText: res.response,
        });
      } else {
        this.setState({
          showPrompt: true,
          promptText: 'There was an error.',
        });
      }
    });
  };

  dismissAllAlert = () => {
    const { alerts, dismissedAlerts } = this.state;
    const { dismissNotification, updateNotificationsCount } = this.props;

    dismissNotification({
      eventId: 'all',
    }).then(res => {
      if (res.successFlag) {
        const newDismissedAlerts = alerts.map(item => item.eventId);

        this.setState(() => ({
          alerts: [],
          dismissedAlerts: [...dismissedAlerts, ...newDismissedAlerts],
        }));

        updateNotificationsCount({
          count: 0,
        });
      }

      if (!res.error) {
        this.setState({
          showPrompt: res.showResponse,
          promptText: res.response,
        });
      } else {
        this.setState({
          showPrompt: true,
          promptText: 'There was an error.',
        });
      }
    });
  };

  closeModal = () => {
    this.setState({
      showPrompt: false,
      promptText: '',
    });
  };

  render() {
    const { notificationConfig, notificationsCount } = this.props;
    const { alerts, showPrompt, promptText } = this.state;

    const customModalStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '650px',
        padding: '50px 25px',
        fontFamily: primaryFont,
      },
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, .7)',
      },
    };

    const items = notificationConfig({
      alerts,
      dismissAlert: this.dismissAlert,
    });

    return (
      <div>
        {/*<MenuTitleBar*/}
        {/*  title="Alerts"*/}
        {/*  dismissAllAlert={this.dismissAllAlert}*/}
        {/*  disableAlert={!notificationsCount}*/}
        {/*/>*/}
        {/*<MenuList*/}
        {/*  items={notificationConfig({*/}
        {/*    alerts,*/}
        {/*    dismissAlert: this.dismissAlert,*/}
        {/*  })}*/}
        {/*/>*/}

        <NotificationsRedesign
          items={items && items.content}
          dismissAll={this.dismissAllAlert}
          dismissAlert={this.dismissAlert}
        />

        <Modal
          ariaHideApp={false}
          isOpen={showPrompt}
          style={customModalStyles}
          contentLabel="Notifications"
          onRequestClose={this.closeModal}
        >
          <i
            className="fa fa-close"
            onClick={this.closeModal}
            tabIndex="0"
            role="button"
          />
          {promptText}
        </Modal>
        <style jsx>{`
          .fa-close {
            position: absolute;
            top: 5px;
            right: 10px;
            cursor: pointer;
          }
        `}</style>
      </div>
    );
  }
}

export default withRouter(TimedNotifications);
