import React from 'react';
import TheSpinner from './Spinner';

export const Spinner = props => {
  const { loading, text, transparent, blurBg, isLocal, inSlider } = props;
  return loading ? (
    <TheSpinner
      text={text}
      transparent={transparent}
      blurBg={blurBg}
      isLocal={isLocal}
    />
  ) : null;
};
