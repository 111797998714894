import { AnyAction } from 'redux';
import { handleActions } from 'redux-actions';
import { actions, constants } from 'ducks-helpers';
import {
  ObservationLikeDataResponse,
  ObservationsResponse,
} from 'app/modules/observations/types';

export interface ObservationState {
  observationList: ObservationsResponse;
  observationLikeData: Record<string, ObservationLikeDataResponse>;
  isFetching: boolean;
  isLoaded: boolean;
  serverError: string | null;
}

export const TYPE = constants('observations', [
  '~CLEAR_OBSERVATION_LIST',
  '~GET_OBSERVATION_LIST',
  '~LIKE_OBSERVATION',
]);

export const ACTION = actions(TYPE);

export const initialState: ObservationState = {
  observationList: {} as ObservationsResponse,
  observationLikeData: {} as Record<string, ObservationLikeDataResponse>,
  isFetching: false,
  isLoaded: false,
  serverError: null,
};

const setFetching = (state: ObservationState) => ({
  ...state,
  isFetching: true,
  isLoaded: false,
});

const setServerError = (state: ObservationState, action: AnyAction) => ({
  ...state,
  isFetching: false,
  serverError: action.payload,
  isLoaded: false,
});

const clearObservationList = (state: ObservationState) => ({
  ...state,
  observationList: {},
});

const getObservationListSuccess = (
  state: ObservationState,
  action: AnyAction
) => ({
  ...state,
  isFetching: false,
  isLoaded: true,
  observationList: action.payload,
});

const likeObservationSuccess = (
  state: ObservationState,
  action: AnyAction
) => ({
  ...state,
  isFetching: false,
  isLoaded: true,
  observationLikeData: {
    ...state.observationLikeData,
    [action.meta]: action.payload,
  },
});

export default handleActions<ObservationState>(
  {
    [TYPE.CLEAR_OBSERVATION_LIST]: clearObservationList,
    [TYPE.GET_OBSERVATION_LIST]: setFetching,
    [TYPE.GET_OBSERVATION_LIST_SUCCESS]: getObservationListSuccess,
    [TYPE.GET_OBSERVATION_LIST_ERROR]: setServerError,
    [TYPE.LIKE_OBSERVATION]: setFetching,
    [TYPE.LIKE_OBSERVATION_SUCCESS]: likeObservationSuccess,
    [TYPE.LIKE_OBSERVATION_ERROR]: setServerError,
  },
  initialState
);
