import { actions, constants } from 'ducks-helpers';
import { apply } from 'qim';
import { handleActions } from 'redux-actions';

export const TYPE = constants('image-details', [
  '~GET_IMAGE_DETAILS',
  '~GET_IMAGES_DETAILS',
  '~CLEAR_IMAGE_DETAILS',
  '~DELETE_IMAGE',

  // tags
  '~SET_TAG',
  '~GET_TAGS',
  '~DELETE_TAG',

  // galleries
  '~GET_GALLERIES',
  '~CREATE_GALLERY',
  '~ADD_IMAGE_TO_GALLERY',

  // observation submit form
  '~SET_OBSERVATION_TAGS',
]);
export const ACTION = actions(TYPE);

export const initialState = {
  isFetching: false,
  serverError: null,
  data: {},
  imageDetailsListById: {},

  tagsData: {
    isFetching: false,
    data: {},
    tagList: [],
  },

  galleriesData: {
    isFetching: false,
    data: {
      galleryList: [],
    },
    // tagList: [],
  },

  observationTagsError: null,
};

const clearImageDetails = state => {
  return {
    ...state,
    imageDetailsListById: {},
  };
};

export default handleActions(
  {
    [TYPE.CLEAR_IMAGE_DETAILS]: clearImageDetails,
    [TYPE.GET_IMAGE_DETAILS]: setFetchingImageDetails,
    [TYPE.GET_IMAGE_DETAILS_SUCCESS]: getImageDetailsSuccess,
    [TYPE.GET_IMAGE_DETAILS_ERROR]: setServerError,

    [TYPE.GET_IMAGES_DETAILS]: setFetchingImagesDetails,
    [TYPE.GET_IMAGES_DETAILS_SUCCESS]: getImagesDetailsSuccess,
    [TYPE.GET_IMAGES_DETAILS_ERROR]: setServerError,

    // TAGS
    [TYPE.GET_TAGS]: setTagFetching,
    [TYPE.GET_TAGS_SUCCESS]: getTagsSuccess,
    [TYPE.GET_TAGS_ERROR]: setServerError,

    [TYPE.SET_TAG]: setTagFetching,
    [TYPE.SET_TAG_SUCCESS]: setTagSuccess,
    [TYPE.SET_TAG_ERROR]: setServerError,

    [TYPE.DELETE_TAG]: setTagFetching,
    [TYPE.DELETE_TAG_SUCCESS]: setTagSuccess,
    [TYPE.DELETE_TAG_ERROR]: setServerError,

    // GALLERIES
    [TYPE.GET_GALLERIES]: setGalleriesFetching,
    [TYPE.GET_GALLERIES_SUCCESS]: getGalleriesSuccess,
    [TYPE.GET_GALLERIES_ERROR]: setServerError,

    [TYPE.ADD_IMAGE_TO_GALLERY]: setGalleriesFetching,
    [TYPE.ADD_IMAGE_TO_GALLERY_SUCCESS]: addImageToGallerySuccess,
    [TYPE.ADD_IMAGE_TO_GALLERY_ERROR]: setServerError,

    [TYPE.CREATE_GALLERY]: setGalleriesFetching,
    [TYPE.CREATE_GALLERY_SUCCESS]: addImageToGallerySuccess,
    [TYPE.CREATE_GALLERY_ERROR]: setServerError,

    // OBSERVATION SUBMIT FORM
    [TYPE.SET_OBSERVATION_TAGS]: setFetching,
    [TYPE.SET_OBSERVATION_TAGS_SUCCESS]: setObservationTagsSuccess,
    [TYPE.SET_OBSERVATION_TAGS_ERROR]: setServerError,
  },
  initialState
);

function setFetching(state) {
  return { ...state, isFetching: true };
}

function setServerError(state, action) {
  return {
    ...state,
    isFetching: false,
    serverError: action.payload,
    isLoded: false,
  };
}

function setObservationTagsSuccess(state, action) {
  return {
    ...state,
    isFetching: false,
    observationTagsError: action.payload.apiError,
  };
}

function setFetchingImageDetails(state) {
  return { ...state, isFetching: true, data: {} };
}

function getImageDetailsSuccess(state, action) {
  return {
    ...state,
    isFetching: false,
    data: action.payload,
    imageDetailsListById: {
      ...state.imageDetailsListById,
      ...action.payload,
    },
  };
}

function setFetchingImagesDetails(state) {
  return { ...state, isFetching: true, data: {} };
}

function getImagesDetailsSuccess(state, action) {
  const data = action.payload;

  return {
    ...state,
    isFetching: false,
    data: action.payload,
    imageDetailsListById: {
      ...state.imageDetailsListById,
      ...data,
    },
  };
}

const setTagsDataImmutable = (data, state) =>
  apply(['tagsData'], () => data, state);

function getTagsSuccess(state, action) {
  return setTagsDataImmutable(
    {
      isFetching: false,
      data: action.payload,
      tagList: action.payload.tagList,
    },
    state
  );
}

function setTagSuccess(state, action) {
  return setTagsDataImmutable(
    {
      isFetching: false,
      data: action.payload,
      tagList: action.payload.tagList,
    },
    state
  );
}

function setTagFetching(state) {
  return setTagsDataImmutable(
    {
      isFetching: true,
      data: state.tagsData.data,
      tagList: state.tagsData.tagList,
    },
    state
  );
}

const setGalleriesDataImmutable = (data, state) =>
  apply(['galleriesData'], () => data, state);

function setGalleriesFetching(state) {
  return setGalleriesDataImmutable(
    {
      isFetching: true,
      data: state.galleriesData.data,
    },
    state
  );
}

function getGalleriesSuccess(state, action) {
  return setGalleriesDataImmutable(
    {
      isFetching: false,
      data: action.payload,
    },
    state
  );
}

function addImageToGallerySuccess(state) {
  return setGalleriesDataImmutable(
    {
      isFetching: false,
      data: state.galleriesData.data,
    },
    state
  );
}
