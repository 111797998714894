import { storeQuestBreadCrumbDetails } from "app/modules/User";
import { connect } from "react-redux";

const listOfQuestPath = [
    '/guides/',
    '/object-details/',
    '/photo-stack/',
    '/my-pictures/show-image/',
]

export function checkAndSetQuestNav(state = {}, lastRoute = '', pathName = ''){ 
    const isPathMatched = listOfQuestPath.some(path => pathName.includes(path));
    if(lastRoute.includes('/quest-details/') && isPathMatched){
        storeQuestBreadCrumbDetails({
            showBreadCrumb: true,
            questLink: lastRoute,
        })
    }
}


  
