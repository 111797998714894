import React, { FC, ReactNode } from 'react';
import Modal from 'react-modal';

import './styles.scss';

const MODAL_STYLES = {
  overlay: {
    background: 'rgba(0, 0, 0, 0.8)',
  },
};

interface Props {
  isOpen: boolean;
  handleCloseModal: () => void;
  children: ReactNode;
  className?: string;
  shouldCloseOnOverlayClick?: boolean;
  isDarkMode?: boolean;
}

const BaseModal: FC<Props> = ({
  isOpen,
  handleCloseModal,
  children,
  className,
  shouldCloseOnOverlayClick = true,
  isDarkMode = false,
}) => {
  const imgSrc = isDarkMode
    ? 'https://vega.slooh.com/assets/v4/site-redesign/icons/icon_close_white.svg'
    : 'https://vega.slooh.com/assets/v4/site-redesign/icons/icon_close.svg';

  return (
    <Modal
      className={`base-modal ${className}`}
      isOpen={isOpen}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      onRequestClose={handleCloseModal}
      style={MODAL_STYLES}
    >
      <img
        src={imgSrc}
        className="base-modal-close"
        onClick={handleCloseModal}
        alt="close"
      />
      {children}
    </Modal>
  );
};

export default BaseModal;
