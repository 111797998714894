import classnames from 'classnames';
import React, {
  forwardRef,
  Ref,
  ReactNode,
  MouseEvent,
  KeyboardEvent,
} from 'react';
import {
  astronaut,
  blue200,
  romance,
} from 'app/styles/variables/colors_tiles_v4';
import { primaryFont } from 'app/styles/variables/fonts';

interface Props {
  children: ReactNode;
  handleClick: (e: MouseEvent<HTMLButtonElement>) => void;
  isActive?: boolean;
  theme?: object;
  mod?: string;
  ariaLabel?: string;
  onKeyDown?: (e: React.KeyboardEvent<HTMLButtonElement>) => void;
  id?: string;
}

const Button = forwardRef(
  (
    {
      children,
      handleClick,
      isActive,
      theme,
      mod,
      ariaLabel,
      onKeyDown,
      id,
    }: Props,
    ref: Ref<any>
  ) => {
    return (
      <div className="btn-root">
        <button
          onClick={handleClick}
          onKeyDown={(e: React.KeyboardEvent<HTMLButtonElement>) => {
            if (e.key === 'Enter' && onKeyDown) {
              e.preventDefault();
              onKeyDown(e);
            }
          }}
          className={classnames(mod ? `action ${mod}` : 'action', {
            active: isActive,
          })}
          style={theme}
          aria-label={ariaLabel && ariaLabel}
          id={id}
          tabIndex={0}
          ref={ref}
        >
          {children}
        </button>

        <style>
          {`
            .btn-root {
              display: inline-block;
              font-family: ${primaryFont};
            }

            .action {
              display: flex;
              align-items: center;
              justify-content: center;
              border: none;
              cursor: pointer;
              color: ${astronaut};
              border-right: 1px solid ${blue200};
              text-align: center;
              background: none;
              width: 52px;
               height: 48px;
              outline: none;

              transition-property: color background;
              transition-duration: 0.15s;
              transition-timing-function: ease-in-out;
            }

            .i-notification {
            height: 48px;
            }

            .action.no-border {
              border: none;
            }

            // .action.active {
            //   color: ${romance};
            //   background: ${astronaut};
            // }

            // .action:focus {
            //   outline: none;
            // }
          `}
        </style>
      </div>
    );
  }
);

export default Button;
