import { API } from 'app/api';
import { getUserInfo } from '../User';

export function fetchStartPartyList(data) {
  return API.get('/api/events/upcoming', data);
}

export function getUserGravityStatus(data) {
  return API.post('/api/newdashboard/getUserGravityStatus', data);
}

export function getGravityTierLeadersStatus(data) {
  return API.post('/api/leaderboards/getGravityTierLeaders', data);
}
export function getMemberInvitation(data) {
  return API.post('/api/classroom/getInvitation ', data);
}

export function getQuestsCompletedProgrs(data) {
  return API.post('/api/discussiongroups/getUserQuestCompletePro ', data);
}
export function getQuestsStepUserProgress(data) {
  return API.post('/api/quests/getStepUserProgress', data);
}

export function getMyPictures(data) {
  return API.post('/api/images/getMyPictures', data);
}

export function getDashboardFeaturedObjects(data) {
  return API.post('/api/reservation/getDashboardFeaturedObjects', data);
}

export function getMyClubList(data) {
  return API.post('/api/profile/groups', data);
}

export function getBookmarksList(data) {
  return API.post('/api/readinglists/getReadingList', data);
}

export function getPrivateProfile(data) {
  return API.post('/api/page/getPrivateProfile', data);
}

export function getPrivateProfileMission(data) {
  return API.post('/api/page/getPrivateProfileMissions', data);
}

export function getUserActiveObject(data) {
  return API.post('/api/newdashboard/getUserActiveObjects', data);
}

export function getUserPouplarObservation(data) {
  return API.post('/api/newdashboard/getUserPopularObservations', data);
}

export function getMissionImages(data) {
  return API.post('/api/images/getMissionImages', data);
}

export function getGalleryList(data) {
  return API.post('/api/images/getGalleryList', data);
}

export function getQuestMediaList(data) {
  return API.post('/api/quests/getQuestMediaList', data);
}

export function getRecentGravityActions(data) {
  return API.post('/api/newdashboard/getRecentGravityActions', data);
}

export function getWeatherActions(data) {
  return API.get('/api/obs/getWXData', { params: data });
}

export function getSkyRating(data) {
  return API.post('/api/widget/seeingConditions', data);
}

export function getQuestMapControls(data) {
  return API.post('/api/quests/getQuestMapControls', data);
}

export function getObjectMapControls(data) {
  return API.post('/api/images/getObjectMapControls', data);
}

export function getQuestCard(data) {
  return API.post('/api/quests/getQuestCard', data);
}

export function getObjectCard(data) {
  return API.post('/api/images/getObjectCard', data);
}

export function getObservatoryList(data) {
  return API.post('/api/obs/list', data);
}

export function getQuestMap(data) {
  return API.post('/api/quests/getQuestMap', data);
}

export function getNewQuestCard(data) {
  return API.post('/api/quests/getQuestCardNew', data);
}

export function getTenStepQuestMap(data) {
  return API.post('/api/quests/getQuestMapTenSteps', data);
}

export function getObjectMap(data) {
  return API.post('/api/images/getObjectMap', data);
}

export function getSocialSharingInfo(data) {
  return API.post('/api/content/getSocialSharingInfo', data);
}

export function getNewDahObs(data) {
  return API.post('/api/widget/newDashboardObs', data);
}

export function getObsStatus(obsId) {
  const { at, cid, token } = getUserInfo();
  // return API.get(`/api/obs/getObservatoryStatus?at=${at}&cid=${cid}&token=${token}&obsId=${obsId}`);
  return API.post(`/api/obs/getObservatoryStatus`, { at, cid, token, obsId });
}

export function getImageDetails(data) {
  return API.post('/api/images/getImageDetails', data);
}

export function getCommunityExploration(data) {
  return API.post(
    '/api/newdashboard/getFeaturedObservationsAndActivities',
    data
  );
}

export function getObservations(data) {
  return API.post('/api/newdashboard/getObservations', data);
}

/*
export function getActivityFeed(data) {
  return API.post('/api/newdashboard/getActivityFeed', data)
}
*/

export function handleLikeReq(data) {
  return API.post('/api/images/like', data);
}

export function getCommunityFame(data) {
  return API.post('/api/newdashboard/getCommunityStats', data);
}

export function getMyRank(data) {
  return API.post('/api/leaderboards/myRank', data);
}

export function getTopMembers(data) {
  return API.post('/api/leaderboards/topMembers', data);
}

export function getTopStudents(data) {
  return API.post('/api/leaderboards/topStudents', data);
}

export function getTopFamilyMembers(data) {
  return API.post('/api/leaderboards/topFamilyMembers', data);
}

export function getMostActiveClubs(data) {
  return API.post('/api/leaderboards/mostActiveClubs', data);
}

export function getTopSchoolClubs(data) {
  return API.post('/api/leaderboards/topSchoolClubs', data);
}

export function getTopFamilyWorkspaces(data) {
  return API.post('/api/leaderboards/topFamilyWorkspaces', data);
}

export function getGravityByDomain(data) {
  return API.post('/api/newdashboard/getGravityByDomain', data);
}

export function getDashboardMissionList(data) {
  return API.post('/api/reservation/getDashboardMissionList', data);
}

export function getTopCommunityObjects(data) {
  return API.post('/api/leaderboards/topCommunityObjects', data);
}

export function getTopCommunityObservations(data) {
  return API.post('/api/leaderboards/topCommunityObservations', data);
}

export function getPhotoHubHeadings(data) {
  return API.post('/api/images/newDashboardPhotoHubSection', data);
}

export function setObjectMap(data) {
  return API.post('/api/images/setObjectMap', data);
}
export function getDownloadQuestsReportPDF(data) {
  return API.post('/api/quests/downloadQuestReportPDF', data);
}
export function getDownloadButtons(data) {
  return API.post('/api/app/getDownloadButtons', data);
}

export function getDashboardButtons(data) {
  return API.post('/api/newdashboard/getDashboardButtons', data);
}

export function getQuestMapLocked(data) {
  return API.post('/api/quests/getQuestMapLocked', data);
}

export function getObjectMapLocked(data) {
  return API.post('/api/objects/getObjectMapLocked', data);
}

export function getBookmarkLocked(data) {
  return API.post('/api/newdashboard/gotoBookmarkLocked', data);
}

export function getMissionsLocked(data) {
  return API.post('/api/page/missionsLocked', data);
}

export function uploadMyPicturesLocked(data) {
  return API.post('/api/images/uploadToMyPicturesPageLocked', data);
}

export function getObservatoryStatusLocked(data) {
  return API.post('/api/obs/gotoToTelescopePageLocked', data);
}

export function getTakeATourLockedInfoPanel(data) {
  return API.post('/api/quests/getTakeATourLockedInfoPanel', data);
}

export function getFeaturedQuestMap(data) {
  return API.post('/api/quests/getQuestMapFeatured', data);
}

export function getFeaturedQuestMapLocked(data) {
  return API.post('/api/quests/getQuestMapFeaturedLocked', data);
}

export function getGuideLocked(data) {
  return API.post('/api/page/guideLocked', data);
}

export function getobjectDetailsLocked(data) {
  return API.post('/api/page/objectDetailsLocked', data);
}

export function getDeshboardLeaderboard(data) {
  return API.post('/api/newdashboard/getDashboardLeaderboards', data);
}

export function joinHouse(data) {
  return API.post('/api/community/joinHouse', data);
}

export function leaveHouse(data) {
  return API.post('/api/community/leaveHouse', data);
}

export function joinCommunity(data) {
  return API.post('/api/community/joinCommunity', data);
}

export function leaveCommunity(data) {
  return API.post('/api/community/leaveCommunity', data);
}

export function dashboardQuestHub(data) {
  return API.post('/api/newdashboard/getDashboardQuests', data);
}

export function dashboardBrowseQuest(data) {
  return API.post('/api/newdashboard/getDashboardQuestsBrowseFilterList', data);
}

export function getAssignableItems(data) {
  return API.post('/api/classroom/assignments/getAssignableItems', data);
}

export function getAssignableItem(data) {
  return API.post('/api/classroom/assignments/getAssignableItem', data);
}

export function getAssignItem(data) {
  return API.post('/api/classroom/assignments/assignItem', data);
}

export function getDistrictAdminList(data){
  return API.post('/api/newdashboard/getDistrictsList', data);
}

export function getTryItFreeAccount(data){
  return API.post('/api/registration/getDashboardPurchaseButton', data);
}
