import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

import { blue500, brightBlue100 } from 'app/styles/variables/colors_tiles_v4';
import { primaryFont } from 'app/styles/variables/fonts';

const ProfileMenuItem = ({
  text,
  anchor,
  icon,
  isAction,
  onClick,
  isExternalLink,
}) => {
  return (
    <div className="root">
      {isExternalLink ? (
        <a
          href={anchor}
          className="menu-item"
          target="_blank"
          rel="noreferrer"
          tabIndex={0}
          role="link"
        >
          <img
            className="menu-icon"
            src={
              icon ||
              'https://vega.slooh.com/assets/v4/site-redesign/icons/calendar_icon.svg'
            }
            alt="menu-icon"
          />
          <span className="menu-value">{text}</span>
        </a>
      ) : isAction ? (
        <div
          className="menu-item"
          onClick={onClick}
          tabIndex={0}
          role="link"
          onKeyDown={e => {
            if (e.key === 'Enter') {
              onClick();
            }
          }}
        >
          <img
            className="menu-icon"
            src={
              icon ||
              'https://vega.slooh.com/assets/v4/site-redesign/icons/calendar_icon.svg'
            }
            alt="menu-icon"
          />
          <span className="menu-value">{text}</span>
        </div>
      ) : (
        <Link
          onKeyDown={e => {
            if (e.key === 'Enter') {
              window.open(anchor, '_blank');
            }
          }}
          to={anchor}
          tabIndex={0}
          role="link"
        >
          <div className="menu-item">
            <img
              className="menu-icon"
              src={
                icon ||
                'https://vega.slooh.com/assets/v4/site-redesign/icons/calendar_icon.svg'
              }
              alt="menu-icon"
            />
            <span className="menu-value">{text}</span>
          </div>
        </Link>
      )}

      <style jsx>{`
        .root {
          font-family: ${primaryFont};
          letter-spacing: 1px;
        }

        .menu-icon {
          width: 18px;
          height: 18px;
          margin: 0 12px 0 24px;
        }

        .menu-item {
          display: flex;
          align-items: center;
          color: ${blue500};
          font-size: 12px;
          line-height: 19px;
          padding: 12px 0;
          text-transform: uppercase;
          cursor: pointer;
        }

        .menu-value {
          height: 17px;
        }

        .menu-item:hover {
          background: ${brightBlue100};
        }
      `}</style>
    </div>
  );
};

ProfileMenuItem.propTypes = {
  text: PropTypes.string.isRequired,
  anchor: PropTypes.string.isRequired,
  icon: PropTypes.string,
  isAction: PropTypes.bool,
  onClick: PropTypes.func,
};

ProfileMenuItem.defaultProps = {};

export default ProfileMenuItem;
