/**
  generic rules that could be applied to most things
  represented as single strings for composition in other
  css rules
*/

export const resetMarginPadding = `
  margin: 0;
  padding: 0;
`;
