import React, { FC, memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectCustomUserGravityStatus,
  selectIsFetching,
} from 'app/modules/new-dashboard/selectors';
import { getUserGravityDataByIdAction } from 'app/modules/new-dashboard/actions';
import { Spinner } from 'app/components/spinner/index';
import BaseModal from 'app/components/modal-redesign';
import CommonButton from 'app/components/button';

import './style.scss';

interface Props {
  customerUUID: string;
  onClose: () => void;
}

const MiniProfile: FC<Props> = memo(props => {
  const { customerUUID, onClose } = props;

  const dispatch = useDispatch();

  const customUserGravityStatus = useSelector(selectCustomUserGravityStatus);
  const isFetching = useSelector(selectIsFetching);

  const {
    avatarURL,
    currentTierLevelNbr,
    currentTierName,
    displayName,
    gravityPoints,
    slooh1kObjectCollectedCount,
    currentBadgeCount,
  } = customUserGravityStatus || {};

  useEffect(() => {
    if (!customerUUID) {
      return;
    }

    dispatch(
      getUserGravityDataByIdAction({
        customerUUID,
      })
    );
  }, [customerUUID, dispatch]);

  return (
    <BaseModal isOpen handleCloseModal={onClose} className="mini-profile-modal">
      <div className="mini-profile">
        <Spinner blurBg loading={isFetching} />

        <div className="avatar-container">
          <img src={avatarURL} alt="Avatar" />
          <div className="user-badge">{currentBadgeCount}</div>
          <div className="avatar" />
        </div>

        <div className="mini-profile-name">{displayName}</div>

        <div className="mini-profile-level">
          Level {currentTierLevelNbr}: {currentTierName}
        </div>

        <div className="mini-profile-stats">
          <div className="profile-stat-item">
            {gravityPoints}
            <div className="stat-title">Gravity Points</div>
          </div>

          <div className="profile-stat-item">
            {slooh1kObjectCollectedCount}
            <div className="stat-title">Objects Collected</div>
          </div>
        </div>

        <CommonButton
          className="profile-button"
          href={`/profile/${customerUUID}`}
          onClick={onClose}
          fullWidth
        >
          View profile
        </CommonButton>
      </div>
    </BaseModal>
  );
});

export default MiniProfile;
