import React from 'react';
import PropTypes from 'prop-types';
import BookmarksModal from 'app/components/bookmarksModal';
import GravityPointsModal from 'app/components/gravityPointsModal';
import Slooh1KModal from 'app/components/1K-modal';

const MODAL_LINKS = {
  bookmarks: '/bookmarks',
  gp: '/gravity-points',
  slooh1k: '/slooh-challenge',
  telescopes: '/telescopes',
};

const MODAL_LINKS_LIST = [
  MODAL_LINKS.bookmarks,
  MODAL_LINKS.gp,
  MODAL_LINKS.slooh1k,
  MODAL_LINKS.telescopes,
];

const MENU_LINKS_LIST = ['/telescopes'];

const ProfileMenuList = ({ items, menuOnClick, role, tabIndex }) => {
  const [openModalItem, setOpenModalItem] = React.useState({});

  const handleModal = item => {
    setOpenModalItem(item);
  };

  return (
    <div className="root">
      <div className="menu-list">
        {items.content.map(item => (
          <div key={item._ID} className="menu-item">
            {items.render({
              ...item,
              role,
              tabIndex,
              onClick: () =>
                MENU_LINKS_LIST.includes(item.anchor)
                  ? menuOnClick()
                  : handleModal(item),
              isAction: MODAL_LINKS_LIST.includes(item.anchor),
            })}
          </div>
        ))}
      </div>

      <BookmarksModal
        isOpen={MODAL_LINKS.bookmarks === openModalItem.anchor}
        onClose={() => handleModal({})}
      />

      <GravityPointsModal
        isOpen={MODAL_LINKS.gp === openModalItem.anchor}
        onClose={() => handleModal({})}
      />

      {MODAL_LINKS.slooh1k === openModalItem.anchor && (
        <Slooh1KModal handleHideModal={() => handleModal({})} />
      )}

      <style jsx>{`
        .root {
        }
      `}</style>
    </div>
  );
};

ProfileMenuList.propTypes = {
  items: PropTypes.shape({
    render: PropTypes.func.isRequired,
    content: PropTypes.arrayOf(
      PropTypes.shape({
        _ID: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
};

export default ProfileMenuList;
