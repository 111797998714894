import { fetchEventDetails, fetchHomeData } from "./guidedQuestapi";

export const FETCH_TOP_NAV_DATA_START = "FETCH_TOP_NAV_DATA_START";
export const FETCH_TOP_NAV_DATA_SUCCESS = "FETCH_TOP_NAV_DATA_SUCCESS";
export const FETCH_HOME_DATA_START = "FETCH_HOME_DATA_START";
export const FETCH_HOME_DATA_SUCCESS = "FETCH_HOME_DATA_SUCCESS";
export const FETCH_EVENT_DATA_START = "FETCH_EVENT_DATA_START";
export const FETCH_EVENT_DATA_SUCCESS = "FETCH_EVENT_DATA_SUCCESS";

const getHomeDataStart = () => ({
    type: FETCH_HOME_DATA_START
});

const getHomeDataSuccess = (payload) => ({
    type: FETCH_HOME_DATA_SUCCESS,
    payload
});

const getEventDataStart = () => ({
    type: FETCH_EVENT_DATA_START
});

const getEventDataSuccess = (payload) => ({
    type: FETCH_EVENT_DATA_SUCCESS,
    payload
});

export const getHomeDataAction = (data) => (dispatch, getState) => {
    dispatch(getHomeDataStart());
    const { token, at, cid } = getState().user;
    return fetchHomeData({token, at, cid, ...data}).then(result=>{
        const res = result.data;
        if(res.apiError)
            dispatch(errorHandling(res));
        else
            dispatch(getHomeDataSuccess(res));
    });
}

export const getEventDataAction = (data) => (dispatch, getState) => {
    dispatch(getEventDataStart());
    const { token, at, cid } = getState().user;
    return fetchEventDetails({token, at, cid, ...data}).then(result=>{
        const res = result.data;
        if(res.apiError)
            dispatch(errorHandling(res));
        else
            dispatch(getEventDataSuccess(res));
    });
}