import React, { FC, ReactNode } from 'react';

import { Spinner } from 'app/components/spinner/index';
import BaseModal from 'app/components/modal-redesign';

import './styles.scss';
import ModalEmptySectionCard from 'app/modules/modal-empty-section-card/modal-empty-section-card';

interface Props {
  isLoading?: boolean;
  isOpen: boolean;
  handleCloseModal: () => void;
  title: string;
  list: {
    title: string;
    value: string;
    action?: () => void;
    rightPanel?: ReactNode;
  }[];
  className?: string;
  emptyMessage?: string;
  emptyLinkURL?: string;
  emptyLinkText?: string;
}

const ModalList: FC<Props> = ({
  isLoading,
  isOpen,
  handleCloseModal,
  title,
  list,
  className,
  emptyLinkText,
  emptyLinkURL,
  emptyMessage,
}) => {
  const handleAction = (item: any) => {
    if (!item.action) return;
    item.action(item);
    handleCloseModal();
  };

  return (
    <BaseModal
      isOpen={isOpen}
      handleCloseModal={handleCloseModal}
      className={className}
    >
      <div className="modal-list">
        <div className="modal-list-header">{title}</div>

        <Spinner loading={isLoading} />

        <div className="modal-list-items">
          {list
            .filter(item => item.value)
            .map(item => (
              <div
                key={item.title}
                className={`modal-item ${item.action && 'modal-item-pointer'}`}
                onClick={() => handleAction(item)}
              >
                <div>
                  <div className="item-title">{item.title}</div>
                  <div className="item-value">{item.value}</div>
                </div>
                {item.rightPanel}
              </div>
            ))}
        </div>
        {list.length === 0 && (
          <ModalEmptySectionCard
            noMessageText={emptyMessage}
            linkUrl={emptyLinkURL}
            linkText={emptyLinkText}
          />
        )}
      </div>
    </BaseModal>
  );
};

export default ModalList;
