import { useCallback, useEffect, useState } from "react";
import moment from 'moment';

const interval = (delay = 0) => (callback: any) =>
  useEffect(() => {
    const id = setInterval(callback, delay);

    return () => clearInterval(id);
  }, [callback]);

const use1Second = interval(1000);
const addZero = (value: string | number): string =>
  `${value.toString().length === 1 ? '0' : ''}${value}`;

export const useClock = (
  initialMs = Date.now()
) => {
  const [clock, setClock] = useState({ ms: initialMs, time: "" });
  const [running, setRunning] = useState(true);
  const tick = useCallback(
    () => {
      if (running) {
        setClock((clock) => {
          const ms = clock.ms + 1000;
          const duration = moment.duration(ms);
          const time = `${addZero(duration.hours())}:${addZero(duration.minutes())} UTC`;
          return { ms, time }
        })
      }
    },
    [running]
  );
  const start = () => setRunning(true);
  const stop = () => setRunning(false);

  use1Second(tick);

  return { running, clock, start, stop };
};

