import React, {
  forwardRef,
  KeyboardEvent,
  useImperativeHandle,
  useRef,
} from 'react';
import { Link } from 'react-router';

import './style.scss';

interface Props {
  ariaLabel?: string;
  title?: string;
  className?: string;
  icon?: string;
  id?: string;
  link?: string;
  action?: () => void;
  role?: string;
  tabIndex?: number;
  altText?: string;
  onTabKeyDown?: (event: KeyboardEvent<HTMLElement>) => void;
  onSkipLinksHandler?: (event: KeyboardEvent<HTMLElement>) => void;
}

const PageButton = forwardRef((props: Props, ref) => {
  const internalRef = useRef(null);

  useImperativeHandle(ref, () => ({
    focus: () => {
      if (internalRef.current) {
        internalRef.current.focus();
      }
    },
    click: () => {
      if (internalRef.current) {
        internalRef.current.click();
      }
    },
  }));

  if (props.link) {
    return (
      <Link
        aria-label={props.ariaLabel}
        innerRef={(ref: any) => {
          internalRef.current = ref;
        }}
        className={`page-button ${props.className}`}
        to={props.link}
        role={props.role}
        tabIndex={props.tabIndex}
        id={props.id && props.id}
        onKeyDown={props.onSkipLinksHandler}
      >
        {props.icon && (
          <img
            className="page-button-icon"
            src={props.icon}
            alt={props.altText}
          />
        )}
        <span aria-hidden="true">{props.title}</span>
      </Link>
    );
  }

  return (
    <button
      ref={internalRef}
      className={`page-button ${props.className}`}
      onClick={props.action}
      role={props.role}
      tabIndex={props.tabIndex}
      onKeyDown={(event: KeyboardEvent<HTMLElement>) => {
        props.onSkipLinksHandler && props.onSkipLinksHandler(event);
        props.onTabKeyDown && props.onTabKeyDown(event);
      }}
      id={props.id && props.id}
    >
      {props.icon && (
        <img
          className="page-button-icon"
          src={props.icon}
          alt={props.altText}
        />
      )}
      <span aria-hidden="true">{props.title}</span>
    </button>
  );
});

export default PageButton;
