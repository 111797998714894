import React, { Component } from "react";
import "./styles.scss"
import Modal from 'react-modal';
import InputField from 'app/components/form/InputFieldNew';
import { Field, reduxForm } from 'redux-form';
import { connect } from "react-redux";
import PersonalInfoRegistrationNew from "app/pages/checkout-student-plan/personalInfoRegistration-new";
import { logGoogleUserIn, logUserIn, resetLogIn } from "../login/actions";
import { bindActionCreators } from "redux";
import { Spinner } from 'app/components/spinner/index';
import { ErrorPopup } from 'app/modules/new-dashboard/common/errorPopup';
import { browserHistory } from 'react-router';
import { getUserInfo } from "../User";
import { fetchSubscriptionPlan } from "../new-guest-dashboard/actions";
import { makeFetchSubscriptionPlanSelector } from "../new-guest-dashboard/selector";
import { createStructuredSelector } from "reselect";
import PaymentDetails from "app/pages/checkout-student-plan/paymentDetails";
import classnames from 'classnames';
import { JOIN_ACTIVATE_PENDING_CUSTOMER_ENDPOINT_URL } from "app/services/registration/registration";
import { API } from "app/api";
import { fireSloohFBPurchaseEvent } from "app/utils/fb-wrapper";
import { fireSloohTwitterPurchaseEvent } from "app/utils/twitter-wrapper";

const customModalStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      minWidth: '300px',
      width: '500px',
      maxWidth: '650px',
      padding: '25px 25px',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
    },
  };

class CheckoutRegistrationPage extends Component{

    constructor(props){
        super(props);
        this.state={
            showInfoModal: false,
            modalTitle: "",
            modalContent: "",
            accoridianActiveKey: "0",
            collapseID: '',
            errorInfo: null,
            isFetching: false,
            subscriptionPlans: props.subscriptionPlans,
            showPlanDet: false,
            totalStepCount: 2,
        }
        const self = props;
        props.actions.fetchSubscriptionPlan({
            callSource: getUserInfo()._sloohatid ? 'join' : 'joinbyguestlanding',
            selectedPlanId: window.localStorage.getItem('selectedPlanId'),
            signupPageURI: window.location.pathname,
            enableHiddenPlanHashCode: window.localStorage.getItem('enableHiddenPlanHashCode'),
            sloohMarketingTrackingId: getUserInfo()._sloohatid
        })
    }

    componentDidUpdate(oldProps){
        if(this.state.subscriptionPlans !== this.props.subscriptionPlans){
            window.localStorage.setItem('selectedPlanId', this.props.subscriptionPlans?.subscriptionPlans[0]?.planID || "")
            this.setState({subscriptionPlans: this.props.subscriptionPlans});
        }
    }  
    
    onPlanChange = (plans, fromPlanIndex, toPlanIndex) => {
        window.localStorage.setItem('selectedPlanId', plans[fromPlanIndex].planID);
        var element = plans[fromPlanIndex];
        plans.splice(fromPlanIndex, 1);
        plans.splice(toPlanIndex, 0, element);        
        this.setState({subscriptionPlans: {subscriptionPlans: plans, ...this.state.subscriptionPlans}});

	    window.recordAmplitudeEvent('event_checkoutPage_changedPlan', {sloohSiteSessionToken: getUserInfo()._sloohsstkn, sloohMarketingTrackingId: getUserInfo()._sloohatid, planID: plans[fromPlanIndex].planID, planName: plans[fromPlanIndex].planName});
    }

    handleStepOneComplete = stepId => {
        if(this.props.subscriptionPlans?.subscriptionPlans[0]?.skipPaymentStepInCheckoutFlow){
            this.setState({isFetching: true});
            const activatePendingCustomerData = {
                // paymentMethod,
                // paymentToken: paymentNonceTokenData,
                customerId: window.localStorage.getItem('pending_cid'),
                selectedSchoolId: window.localStorage.getItem('selectedSchoolId'),
                isAstronomyClub:
                    window.localStorage.getItem('isAstronomyClub') === 'true',
                clubCodeA: window.localStorage.getItem('clubCodeA'),
                clubCodeB: window.localStorage.getItem('clubCodeB'),
                // billingAddressString: paymentDataString[3],
                // sloohSiteSessionToken: _sloohsstkn,
                sloohMarketingTrackingId: getUserInfo()._sloohatid,
                conditionType: getUserInfo()._sloohatid ? 'join' : 'joinbyguestlanding',
            };

            API.post(
                JOIN_ACTIVATE_PENDING_CUSTOMER_ENDPOINT_URL,
                activatePendingCustomerData
            )
                .then(response => {
                    const res = response.data;
                    this.setState({isFetching: false})
                    if (!res.apiError) {
                        if (res.status === 'success') {
                            const { actions } = this.props;

                            //fire off the Purchase Facebook Event
                            const myCID = window.localStorage.getItem('pending_cid');
                            fireSloohFBPurchaseEvent({
                                cid: myCID,
                                planName: res.PlanName,
                                planCostInUSD: res.PlanCostInUSD,
                            });

                            fireSloohTwitterPurchaseEvent({
                                cid: myCID,
                                planName: res.PlanName,
                                planCostInUSD: res.PlanCostInUSD,
                            });
                            //Cleanup local localStorage
                            //cleanup any hidden plan that was accessed now that a plan was redeemed.
                            window.localStorage.removeItem('enableHiddenPlanHashCode');

                            //cleanup other localstorage elements
                            window.localStorage.removeItem('pending_cid');
                            window.localStorage.removeItem('selectedPlanId');
                            window.localStorage.removeItem('isAstronomyClub');
                            window.localStorage.removeItem('clubCodeA');
                            window.localStorage.removeItem('clubCodeB');
                            // log the user in (userpass or googleaccount logins supported)
                            const { accountCreationType } = window.localStorage;
                            if (accountCreationType === 'userpass') {
                                const loginDataPayload = {
                                    username: window.localStorage.username,
                                    pwd: window.localStorage.password,
                                };

                                /* cleanup local storage */
                                window.localStorage.removeItem('accountCreationType');
                                window.localStorage.removeItem('username');
                                window.localStorage.removeItem('password');


                                actions.logUserIn(loginDataPayload, { reload: false }).then(() => {
                                    const welcomepage = getUserInfo()._sloohatid ? "/join/purchaseConfirmation/join" : "/join/purchaseConfirmation/joinbyguestlanding";
                                    browserHistory.push(welcomepage);
                                });
                            } else if (accountCreationType === 'googleaccount') {
                                const loginDataPayload = {
                                    googleProfileId: window.localStorage.googleProfileId,
                                    googleProfileEmail: window.localStorage.username,
                                };

                                window.localStorage.removeItem('accountCreationType');
                                actions.logGoogleUserIn(loginDataPayload, { reload: false }).then(() => {
                                    const welcomepage = getUserInfo()._sloohatid ? "/join/purchaseConfirmation/join" : "/join/purchaseConfirmation/joinbyguestlanding";
                                    browserHistory.push(welcomepage);
                                });
                            }
                        } else {
                            this.setState({errorInfo: res});                            
                            /* process / display error to user */
                            // document
                            //     .getElementById('embeddedHostedPaymentForm')
                            //     .contentWindow.captureActivationError(res);
                        }
                    }
                })
                .catch(err => {
                    throw ('Error: ', err);
                });
        }
        else{
            this.setState({
                accoridianActiveKey: stepId,
                isFetching: false,
            });
        }
    }

    handleShowInfo = (title, content) => {
        this.setState({showInfoModal: true, modalTitle: title, modalContent: content})
    }

    handleCloseInfo = () => {
        this.setState({showInfoModal: false, modalTitle: "", modalContent: ""})
    }

    handleTotalStepCount = () => {
        this.setState({totalStepCount: 3});
    }


    render(){

        const { 
            showInfoModal, 
            modalTitle, 
            modalContent, 
            errorInfo, 
            isFetching,
            selectedPlan,
            subscriptionPlans,
            accoridianActiveKey,
            showPlanDet,
            totalStepCount,
        } = this.state;

        const { loadingText } = getUserInfo();

        return(
            <div className="checkout-page">
                <Spinner loading={subscriptionPlans.isFetching || isFetching } text={loadingText} />
                {!subscriptionPlans.isFetching && (
                    <div className="checkout-container">
                        <div className="left-content">
                            <div className="overlay-container">
                                {subscriptionPlans?.subscriptionPlans.length > 0 && (
                                    <div className="overlay-card">
                                            <img className="slooh-logo" src="https://vega.slooh.com/assets/v4/site-redesign/icons/Logo-white.svg" />
                                            {subscriptionPlans?.subscriptionPlans[0]?.showIntroductoryOfferContent && (
                                                <div>
                                                    <h2 className="introductory-price">{subscriptionPlans?.subscriptionPlans[0]?.introductoryOfferCost}</h2>
                                                    <h3 className="introductory-heading">{subscriptionPlans?.subscriptionPlans[0]?.introductoryOfferHeading}</h3>
                                                </div>
                                            )}
                                            <h2 className="plan-name">{subscriptionPlans?.subscriptionPlans[0]?.headingLabel}</h2>
                                            <hr className={classnames("separator-line", {show: showPlanDet})}/>
                                            <h5 className="introductory-terms-heading">{subscriptionPlans?.subscriptionPlans[0]?.introductoryOfferTerms}</h5>
                                            {Object.keys(subscriptionPlans?.subscriptionPlans[0]?.planTextBlockDetails).map((key, index)=>(
                                                subscriptionPlans?.subscriptionPlans[0]?.planTextBlockDetails[key].showSection &&
                                                <div className={classnames("plan-det-container", {show: showPlanDet})}>
                                                    {index > 0 && (
                                                        <hr className="access-list-separator" />
                                                    )}
                                                    <div className="plan-access-list">
                                                        {subscriptionPlans?.subscriptionPlans[0]?.planTextBlockDetails[key].showTextTitle && (
                                                            <h3 className="list-title">{subscriptionPlans?.subscriptionPlans[0]?.planTextBlockDetails[key].textTitle}</h3>
                                                        )}
                                                        {subscriptionPlans?.subscriptionPlans[0]?.planTextBlockDetails[key]?.itemArray?.map(item=>(
                                                            <div className="plan-item">
                                                                <img className="access-check" src={item?.available ? "https://vega.slooh.com/assets/v4/site-redesign/icons/Checkmark.svg" : "https://vega.slooh.com/assets/v4/site-redesign/icons/cross_icon.svg"} />
                                                                <span className="access-label">
                                                                    <span dangerouslySetInnerHTML={{__html: item?.label}} />
                                                                    {item.showInfoIcon && (
                                                                        <img className="info-icon" onClick={()=>this.handleShowInfo(item.infoTitle, item.infoContent)} src={item.infoIconURL} />
                                                                    )}
                                                                </span>                                                                
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            ))}
                                            <h4 className="see-plan-det" onClick={()=>this.setState({showPlanDet: !showPlanDet})}>{showPlanDet ? "Hide plan details" : "See plan details"}</h4>
                                    </div>
                                )}
                            </div>
                            
                        </div>
                        <div className="right-content">
                            {accoridianActiveKey == "0" && (
                                <PersonalInfoRegistrationNew 
                                    totalStep={this.props.subscriptionPlans?.subscriptionPlans[0]?.skipPaymentStepInCheckoutFlow ? 1 : 2} 
                                    onStepOneComplete={this.handleStepOneComplete} 
                                    handleTotalStepCount={this.handleTotalStepCount}
                                />
                            )}
                            {accoridianActiveKey == "1" && (
                                <PaymentDetails totalStepCount={totalStepCount}></PaymentDetails>
                            )}
                        </div>
                    </div>
                )}
                <Modal
                    isOpen={showInfoModal}
                    contentLabel="Info Modal"
                    onRequestClose={this.handleCloseInfo}
                    style={customModalStyles}
                >
                    <img onClick={this.handleCloseInfo} className="closeIcon" align="right" src="https://vega.slooh.com/assets/v4/site-redesign/icons/icon_close.svg" />
                    <br/>
                    <br/>
                    <div className="info-modal-title">
                        {modalTitle}
                    </div>
                    <div className="info-modal-content" dangerouslySetInnerHTML={{__html: modalContent}} />
                </Modal>
                {errorInfo &&(
                    <ErrorPopup
                        errorstate={errorInfo}
                        onHide={()=>this.setState({errorInfo: null})}
                    />
                )}
            </div>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    subscriptionPlans: makeFetchSubscriptionPlanSelector(),
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            resetLogIn,
            logUserIn,
            logGoogleUserIn,
            fetchSubscriptionPlan,
        },
        dispatch
    ),
    
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({ form: 'RegistrationForm', enableReinitialize: true })(
      CheckoutRegistrationPage
    )
  );