import React from 'react';
import PropTypes from 'prop-types';

import { primaryFont } from 'app/styles/variables/fonts';
import { golden, hawkesBlue } from 'app/styles/variables/colors_tiles_v4';

const Progress = ({ title, progressLabel, progress, role, tabIndex }) => {
  return (
    <div role={role} tabIndex={tabIndex} className="root">
      <div className="title">
        <span>{title}</span>
        {progressLabel && <span>{progressLabel}</span>}
      </div>
      <div className="progress">
        <div
          className="progress-bar"
          role="progressbar"
          style={{ width: `${progress}%` }}
          aria-valuenow={progress}
          aria-valuemin="0"
          aria-valuemax="100"
        />
      </div>

      <style jsx>
        {`
          .root {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            height: 100%;
          }

          .title {
            display: flex;
            justify-content: space-between;
            font-family: ${primaryFont};
            font-size: 10px;
            line-height: 1.4;
            letter-spacing: 1px;
            text-transform: uppercase;
          }

          .progress {
            background-color: ${hawkesBlue};
            width: 100%;
            height: 8px;
            border-radius: 40px;
          }

          .progress-bar {
            background-color: ${golden};
          }
        `}
      </style>
    </div>
  );
};

Progress.propTypes = {
  title: PropTypes.string.isRequired,
  progressLabel: PropTypes.string,
  progress: PropTypes.number.isRequired,
};

Progress.defaultProps = {};

export default Progress;
