import store from '../store';
import { deactivateMenu } from '../modules/menu/actions';
import {
  resetPageMeta,
  setOGCanonicalURL,
} from '../modules/pageLevelMetaContent/seo-actions';

export default function globalOnRouteUpdate() {
  scrollToTop();
  store.dispatch(deactivateMenu());
  store.dispatch(resetPageMeta());
  store.dispatch(setOGCanonicalURL(window.location.href));
}

export const scrollToTop = () => {
  const element = document.getElementById('app-content');
  if(element?.childNodes?.length > 0){
    element.scrollTop = 0;
  }
  else{
    window.scrollTo(0, 0);
  }
}
