import { API } from 'app/api';
import { projectProductId } from 'app/config/project-config';

export default function fetchUpcomingEvents(data) {
  return API.get(`/api/events/upcoming`, {
    params: { limit: 50, productId: projectProductId, ...data },
  });
}

export function fetchObservatoryList(data) {
  return API.post('/api/obs/getAllObservatoryStatus', data);
}

export function fetchMissionQuotaData(data) {
  return API.post('/api/reservation/getMissionLimits', data);
}

