// @flow

import * as React from 'react';
import { Modal as BModal } from 'react-bootstrap';
import './styles.scss';
import cx from 'classnames';
import { resetErrorHandling } from 'app/modules/new-dashboard/actions';

type TModalDialog = {
  children?: React.Node,
  onHide: Function,
  goBackText: string,
  mobileGoBackText: string,
  disableGoBack: boolean,
};

const ModalDialog = (props: TModalDialog) => {
  const {
    children,
    onHide,
    goBackText,
    mobileGoBackText,
    disableGoBack,
  } = props;

  const backClick = e => {
    e.preventDefault();
    //reset dashboard error handling
    resetErrorHandling();
    return onHide();
  };

  return (
    <div className="custom-modal-new">
      <div>        
        <div className="container modal-content">
          {disableGoBack != true && (
            <span
              role="presentation"
              className="modal-back-btn"
              onClick={backClick}
            >
              <span className="d-sm-none">
                <span className="icon icon-arrow-left" />{' '}
                {mobileGoBackText || 'GO BACK'}
              </span>
              <span className="d-none d-sm-block">
                <span className="icon icon-arrow-left" /> {goBackText || 'GO BACK'}
              </span>
            </span>
          )}
          {children}
        </div>
      </div>
      
    </div>
  );
};

type TModal = {
  children?: React.Node,
  mobileStyle?: boolean,
  onHide: Function,
  goBackText?: string,
  mobileGoBackText?: string,
  show: boolean,
  disableGoBack: boolean,
};

export const Modal = (props: TModal) => {
  const { children, mobileStyle, customClass } = props;
  const cls = cx({ 'mobile-style': mobileStyle }, customClass);
  return (
    <BModal
      {...props}
      dialogAs={() => <ModalDialog {...props} />}
      backdrop="static"
      backdropClassName={`custom-modal-backdrop-new ${customClass || ''}`}
      className={cls}
    >
      {children}
    </BModal>
  );
};
