import { AnyAction } from 'redux';
import { handleActions } from 'redux-actions';
import { actions, constants } from 'ducks-helpers';
import {
  IQuestsFiltersResponse,
  IQuestsResponse,
} from 'app/modules/quests/types';

export interface PublicQuestsState {
  questsList: IQuestsResponse;
  questsFiltersList: IQuestsFiltersResponse;
  isFetching: boolean;
  isLoaded: boolean;
  serverError: string | null;
}

export const TYPE = constants('public-quests', [
  '~GET_PUBLIC_QUESTS_LIST',
  '~GET_PUBLIC_QUESTS_BROWSE_FILTER_LIST',
]);

export const ACTION = actions(TYPE);

export const initialState: PublicQuestsState = {
  questsList: {} as IQuestsResponse,
  questsFiltersList: {} as IQuestsFiltersResponse,
  isFetching: false,
  isLoaded: false,
  serverError: null,
};

const setFetching = (state: PublicQuestsState) => ({
  ...state,
  isFetching: true,
  isLoaded: false,
});

const setServerError = (state: PublicQuestsState, action: AnyAction) => ({
  ...state,
  isFetching: false,
  serverError: action.payload,
  isLoaded: false,
});

const getPublicQuestsListSuccess = (
  state: PublicQuestsState,
  action: AnyAction
) => ({
  ...state,
  isFetching: false,
  isLoaded: true,
  questsList: action.payload,
});

const getPublicQuestsBrowseFilterListSuccess = (
  state: PublicQuestsState,
  action: AnyAction
) => ({
  ...state,
  isFetching: false,
  isLoaded: true,
  questsFiltersList: action.payload,
});

export default handleActions<PublicQuestsState>(
  {
    [TYPE.GET_PUBLIC_QUESTS_LIST]: setFetching,
    [TYPE.GET_PUBLIC_QUESTS_LIST_SUCCESS]: getPublicQuestsListSuccess,
    [TYPE.GET_PUBLIC_QUESTS_LIST_ERROR]: setServerError,
    [TYPE.GET_PUBLIC_QUESTS_BROWSE_FILTER_LIST]: setFetching,
    [TYPE.GET_PUBLIC_QUESTS_BROWSE_FILTER_LIST_SUCCESS]: getPublicQuestsBrowseFilterListSuccess,
    [TYPE.GET_PUBLIC_QUESTS_BROWSE_FILTER_LIST_ERROR]: setServerError,
  },
  initialState
);
