export const BLOCK_TYPES = [
  { label: '<i class="fa fa-header header-icon-h1" key="h1" />', style: 'header-one' },
  { label: '<i class="fa fa-header header-icon-h2" key="h2" />', style: 'header-two' },
  { label: '<i class="fa fa-header header-icon-h3" key="h3" />', style: 'header-three' },
  { label: '<i class="fa fa-header header-icon-h4" key="h4" />', style: 'header-four' },
  { label: '<i class="fa fa-header header-icon-h5" key="h5" />', style: 'header-five' },
  { label: '<i class="fa fa-header header-icon-h6" key="h6" />', style: 'header-six' },
  { label: '<i class="fa fa-quote-left" />', style: 'blockquote' },
  { label: '<i class="fa fa-list-ul" />', style: 'unordered-list-item' },
  { label: '<i class="fa fa-list-ol" />', style: 'ordered-list-item' },
];


export const INLINE_STYLES = [
  { label: '<i class="fa fa-bold" />', style: 'BOLD' },
  { label: '<i class="fa fa-italic" />', style: 'ITALIC' },
  { label: '<i class="fa fa-underline" />', style: 'UNDERLINE' },
];
