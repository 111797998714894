import { func } from "prop-types";
import React, { Component, Fragment } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import InputField from 'app/components/form/InputField';
import InputFieldNew from 'app/components/form/InputFieldNew';
import cloneDeep from 'lodash/cloneDeep';
import { GOOGLE_CLIENT_ID_ENDPOINT_URL, GOOGLE_SSO_SIGNIN_ENDPOINT_URL, VALIDATE_NEW_PENDING_CUSTOMER_DETAILS_ENDPOINT_URL, VERIFY_CAPTCHA_CODE_URL, JOIN_PAGE_ENDPOINT_URL, JOIN_CREATE_PENDING_CUSTOMER_ENDPOINT_URL, VERIFY_CLUB_CODE_ENDPOINT_URL } from 'app/services/registration/registration.js';
import Request from 'app/components/common/network/Request';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { API } from 'app/api';
import ReCAPTCHA from "react-google-recaptcha";
import { googleRecaptchaConfig } from 'app/config/project-config';
import { deleteLoadingText, getUserInfo } from 'app/modules/User';
import { DeviceContext } from 'app/providers/DeviceProvider';
import { withTranslation } from 'react-i18next';
import "./personalInfo.scss";
import classnames from 'classnames';
import { Spinner } from 'app/components/spinner/index';
import { GoogleSignInButton } from "app/components/google-sign-in-button";
import { triggerGoogleSignUp } from "app/modules/Login";

@withTranslation()

class personalInfoRegistrationNew extends Component {

    constructor(props) {
        super(props);

        window.localStorage.setItem('accountCreationType', 'userpass');



        this.state = {
            selectedSignUpOption: '',
            step: 1,
            accountCreationType: 'userpass',
            showGoogleSSOButtonDescription: '',
            googleSSOButtonDescription: '',
            isAgeRestricted: true,
			captchaVerified: true, /* ToddR - 7/27/2022 - override captcha and set to true... */
            /* captchaVerified: false, */
            showGooglePopup: true,
            googleSignInVerified: false,
            googleInitialState: true,
            isLoading: false,
            joinText: "",
            googleProfileData: {
                googleProfileId: '',
                googleProfileEmail: '',
                googleProfileGivenName: '',
                googleProfileFamilyName: '',
                googleProfilePictureURL: '',
            },
            accountFormDetails: {


                is13YearsAndOlder: {
                    label: '',
                    visible: true,
                    value: null,
                    hintText: '',
                    errorText: '',
                },
                not13YearsOldLegalGuardianOk: {
                    label: '',
                    visible: true,
                    value: false,
                    hintText: '',
                    errorText: '',
                },

                parentEmailAddress: {
                    label: '',
                    visible: true,
                    value: '',
                    hintText: '',
                    errorText: '',
                },
                school: {
                    label: '',
                    value: '',
                    hintText: '',
                    errorText: '',
                },
                givenName: {
                    label: '',
                    value: '',
                    hintText: '',
                    errorText: '',
                },
                familyName: {
                    label: '',
                    value: '',
                    hintText: '',
                    errorText: '',
                },
                displayName: {
                    label: '',
                    value: '',
                    hintText: '',
                    errorText: '',
                },
                loginEmailAddress: {
                    label: '',
                    editable: true,
                    value: '',
                    hintText: '',
                    errorText: '',
                },
                password: {
                    label: '',
                    visible: true,
                    value: '',
                    hintText: '',
                    errorText: '',
                },
                codeA: {
                    label: '',
                    visible: true,
                    value: '',
                    hintText: '',
                    errorText: '',
                },
                codeB: {
                    label: '',
                    visible: true,
                    value: '',
                    hintText: '',
                    errorText: '',
                },

            }

        }
    }
    handleLanguage = (langValue) => {

        this.setState({ language: langValue });
    }

    handleCaptchaCode = (token) => {
        const { _sloohsstkn } = getUserInfo();
        if (token !== null) {

            window.recordAmplitudeEvent('event_checkoutPage_captchaVerificationInProgress', { sloohSiteSessionToken: getUserInfo()._sloohsstkn, sloohMarketingTrackingId: getUserInfo()._sloohatid });

            API.post(VERIFY_CAPTCHA_CODE_URL,
                {
                    siteSessionToken: _sloohsstkn,
                    recaptchaResponse: token

                }).then(response => {
                    const res = response.data;
                    if (!res.apiError) {
                        if (res.status === "success") {
                            window.recordAmplitudeEvent('event_checkoutPage_captchaVerificationSuccess', { sloohSiteSessionToken: getUserInfo()._sloohsstkn, sloohMarketingTrackingId: getUserInfo()._sloohatid });
                            window.recordAmplitudeEvent('event_checkoutPage_stateChanged', { fieldName: "continueButton", fieldValue: "enabled", sloohSiteSessionToken: getUserInfo()._sloohsstkn, sloohMarketingTrackingId: getUserInfo()._sloohatid });
                            this.setState({ captchaVerified: true });
                        }
                        else {
                            window.recordAmplitudeEvent('event_checkoutPage_captchaVerificationFailed', { sloohSiteSessionToken: getUserInfo()._sloohsstkn, sloohMarketingTrackingId: getUserInfo()._sloohatid });
                        }
                    }

                });
        }
        else {
            this.setState({ captchaVerified: false });
        }

    }
    /* This function handles a field change in the form and sets the state accordingly */
    handleFieldChange = ({ field, value, type }) => {
        var { formFields } = this.state;
        formFields.forEach((element, index) => {
            if (element.key === field) {
                formFields[index].currentValue = value;
                return;
            }
            if (element.fieldOptions) {
                element.fieldOptions.forEach((innerElement, innerIndex) => {
                    if (innerElement.nestedFields && innerElement.key === formFields[index].currentValue) {
                        innerElement.nestedFields.forEach((nestedelement, nestedindex) => {
                            if (nestedelement.key === field) {
                                formFields[index].fieldOptions[innerIndex].nestedFields[nestedindex].currentValue = value;
                                return;
                            }
                        })
                    }
                })
            }
        });

        switch (type) {
            case "radio":
            case "checkbox":
            case "select":
                window.recordAmplitudeEvent('event_checkoutPage_stateChanged', { fieldName: field, fieldValue: value, sloohSiteSessionToken: getUserInfo()._sloohsstkn, sloohMarketingTrackingId: getUserInfo()._sloohatid });
                break;
            // case "password":
            // case "name":
            // case "email":
            // case "number":
            // case "text":
            //     if(value.length === 1)
            //         window.recordAmplitudeEvent('event_checkoutPage_stateChanged', {fieldName: field, fieldValue: value, sloohSiteSessionToken: getUserInfo()._sloohsstkn, sloohMarketingTrackingId: getUserInfo()._sloohatid});
            //     break;    
        }

        this.setState({ formFields })
        /* Get the existing state of the signup form, modify it and re-set the state */
        // const newAccountFormData = cloneDeep(this.state.accountFormDetails);

        // newAccountFormData[field].value = value;

        // this.setState(() => ({
        //     accountFormDetails: newAccountFormData,
        // }));
    };

    formValidationSuccess = true;

    checkFormValues = (arrayOfFields) => {
        arrayOfFields.forEach((element, index) => {
            if (element.required && (element.currentValue === false || (typeof element.currentValue === 'string' && element.currentValue.trim() === ""))) {
                Object.assign(arrayOfFields[index], { showError: true });
                this.formValidationSuccess = false;
            }
            else {
                Object.assign(arrayOfFields[index], { showError: false });
            }
            if (element.fieldOptions) {
                element.fieldOptions.forEach((innerElement, innerIndex) => {
                    if (innerElement.nestedFields && innerElement.key === arrayOfFields[index].currentValue) {
                        arrayOfFields[index].fieldOptions[innerIndex].nestedFields = this.checkFormValues(innerElement.nestedFields);
                    }
                })
            }
        });
        return arrayOfFields;
    }

    handleValidation = formValues => {
        formValues.preventDefault();
        let { formFields } = this.state;
        this.formValidationSuccess = true;
        const validatedData = this.checkFormValues(formFields);
        if (this.formValidationSuccess) {
            this.handleApiValidation();
        }
        else
            this.setState({ formFields: validatedData });
    }

    handleFormatdataConversion = (array) => {
        let formData = {};
        array.forEach((element, index) => {
            formData[element.key] = { "currentValue": element.currentValue, "required": element.required };
            if (element.fieldOptions) {
                element.fieldOptions.forEach((innerElement, innerIndex) => {
                    if (innerElement.nestedFields && innerElement.key === array[index].currentValue) {
                        formData = { ...formData, ...this.handleFormatdataConversion(innerElement.nestedFields) }
                    }
                })
            }
        });
        return formData;
    }

    handleErrorFormatdata = (array, errorArray) => {
        array.forEach((element, index) => {

            if (errorArray[element.key]) {
                Object.assign(array[index], { showError: errorArray[element.key].showError, errorText: errorArray[element.key].errorText });
            }
            if (element.fieldOptions) {
                element.fieldOptions.forEach((innerElement, innerIndex) => {
                    if (innerElement.nestedFields && innerElement.key === array[index].currentValue) {
                        innerElement.nestedFields.forEach((nestedElement, nestedIndex) => {
                            if (errorArray[nestedElement.key]) {
                                Object.assign(array[index].formFields[innerIndex].nestedFields[nestedIndex], { showError: errorArray[element.key].showError, errorText: errorArray[element.key].errorText });
                            }
                        })
                    }

                })
            }
        });
        return array;
    }

    handleApiValidation = () => {
        this.setState({isLoading: true})
        let { formFields } = this.state;
        let formData = this.handleFormatdataConversion(formFields);
        API.post(VALIDATE_NEW_PENDING_CUSTOMER_DETAILS_ENDPOINT_URL,
            {
                accountFormFields: formData,
                conditionType: getUserInfo()._sloohatid ? 'join' : 'joinbyguestlanding',
                selectedPlanId: window.localStorage.selectedPlanId,
                sloohMarketingTrackingId: getUserInfo()._sloohatid,
            }
        ).then(response => {
            const res = response.data;
            if (res.apiError == false) {
                if (res.accountFormHasErrors) {
                    const errorFormData = this.handleErrorFormatdata(formFields, res.accountFormFields);
                    this.setState({ formFields: errorFormData, isLoading: false });
                }
                else {
                    //create pending customer
                    window.localStorage.setItem('clubCodeA', formData.discussionGroupCodeA.currentValue);
                    window.localStorage.setItem('clubCodeB', formData.discussionGroupCodeB.currentValue);
                    this.handleCreatePendingCustomer(formData);
                }
            }
        })
    }

    handleGoogleApiValidation = () => {
        let { formFields } = this.state;
        let formData = this.handleFormatdataConversion(formFields);
        formData.password.currentValue = "Test123456";

        window.recordAmplitudeEvent('event_checkoutPage_googleStateChanged', { isError: false, state: "verificationInProgress", field: "loginEmailAddress", value: formData.loginEmailAddress.currentValue, sloohSiteSessionToken: getUserInfo()._sloohsstkn, sloohMarketingTrackingId: getUserInfo()._sloohatid });

        API.post(VALIDATE_NEW_PENDING_CUSTOMER_DETAILS_ENDPOINT_URL,
            {
                accountFormFields: formData,
                conditionType: getUserInfo()._sloohatid ? 'join' : 'joinbyguestlanding',
                selectedPlanId: window.localStorage.selectedPlanId,
                sloohMarketingTrackingId: getUserInfo()._sloohatid,
            }
        ).then(response => {
            const res = response.data;
            if (!res.apiError) {
                if (res.accountFormFields?.loginEmailAddress?.showError) {
                    this.setState({ googleSignInVerified: false, googleInitialState: false });
                    window.recordAmplitudeEvent('event_checkoutPage_googleStateChanged', { isError: true, isGoogleError: false, state: "verificationFailed", field: "loginEmailAddress", value: formData.loginEmailAddress.currentValue, errorDetails: res.accountFormFields.errorText, sloohSiteSessionToken: getUserInfo()._sloohsstkn, sloohMarketingTrackingId: getUserInfo()._sloohatid });
                }
                else {
                    this.setState({ googleSignInVerified: true, googleInitialState: false });
                    window.recordAmplitudeEvent('event_checkoutPage_googleStateChanged', { isError: false, state: "verificationSuccess", field: "loginEmailAddress", value: formData.loginEmailAddress.currentValue, sloohSiteSessionToken: getUserInfo()._sloohsstkn, sloohMarketingTrackingId: getUserInfo()._sloohatid });
                }
            }
        })
    }

    handleCreatePendingCustomer = (formData) => {
        const selectedSchoolId = window.localStorage.getItem('selectedSchoolId');
        let createPendingCustomerData = {
            accountCreationType: this.state.accountCreationType,
            selectedPlanId: window.localStorage.selectedPlanId,
            googleProfileId: this.state.googleProfileData.googleProfileId,
            accountFormDetails: formData,
            selectedSchoolId,
            conditionType: getUserInfo()._sloohatid ? 'join' : 'joinbyguestlanding',
            // isAgeRestricted: this.state.isAgeRestricted,
            sloohSiteSessionToken: getUserInfo()._sloohsstkn,
            sloohMarketingTrackingId: getUserInfo()._sloohatid,
        };

        API.post(JOIN_CREATE_PENDING_CUSTOMER_ENDPOINT_URL, createPendingCustomerData).then(response => {
            const res = response.data;
            if (!res.apiError) {
                const pendingCustomerResult = {
                    status: res.status,
                    customerId: res.customerId,
                    message: res.message,
                };

                if (pendingCustomerResult.status === 'success') {

                    window.localStorage.setItem('pending_cid', pendingCustomerResult.customerId);
                    window.localStorage.setItem('username', formData.loginEmailAddress.currentValue);
                    window.localStorage.setItem('password', formData.password.currentValue);

                    /*  this.setState( () =>({
                         accoridianActiveKey:"1"
                     })) */

                    //DO NOT Set the Amplitude User ID to the Pending Customer ID
                    //Send the Event to Amplitude
                    window.recordAmplitudeEvent('event_checkoutPage_pendingCustomerCreated', { pendingCid: pendingCustomerResult.customerId, sloohSiteSessionToken: getUserInfo()._sloohsstkn, sloohMarketingTrackingId: getUserInfo()._sloohatid });

                    const { onStepOneComplete } = this.props;

                    onStepOneComplete("1", );
                    //  .log('Proceeding to create the customers pending account');
                    // browserHistory.push('/join/step3');
                } else {
                    // accountFormDetailsData.loginEmailAddress.errorText =
                    //     pendingCustomerResult.message;

                    // this.setState(() => ({ accountFormDetails: accountFormDetailsData }));

                    //show custom error dialog

                }
            }
        })
            .catch(err => {
                throw ('Error: ', err);
            });

    }


    handleClubCode = (codeA, codeB) => {
        if (codeA !== "" || codeB !== "") {
            API.post(VERIFY_CLUB_CODE_ENDPOINT_URL,
                {
                    clubCodeA: codeA,
                    clubCodeB: codeB,
                    selectedPlanId: window.localStorage.selectedPlanId,
                }
            ).then(response => {
                const res = response.data;
                if (!res.apiError && res.status !== "failed") {
                    window.localStorage.setItem('clubCodeA', codeA);
                    window.localStorage.setItem('clubCodeB', codeB);
                }
                // this.handleSubmit();
            });
        }
        else {
            //   this.handleSubmit();      
        }

    }

    handleSubmit = formValues => {
        formValues.preventDefault();

        const { accountFormDetails, accountCreationType, captchaVerified } = this.state;
        const { t } = this.props;


        if (!captchaVerified) {
            return;
        }

        //assume the form is ready to submit unless validation issues occur.
        let formIsComplete = true;

        const accountFormDetailsData = cloneDeep(accountFormDetails);

        /* reset the error conditions */

        accountFormDetailsData.givenName.errorText = '';
        accountFormDetailsData.familyName.errorText = '';
        accountFormDetailsData.displayName.errorText = '';
        accountFormDetailsData.loginEmailAddress.errorText = '';
        accountFormDetailsData.school.errorText
        accountFormDetailsData.password.errorText = '';

        accountFormDetailsData.is13YearsAndOlder.errorText = '';
        accountFormDetailsData.not13YearsOldLegalGuardianOk.errorText = '';
        accountFormDetailsData.parentEmailAddress.errorText = '';

        if (accountCreationType === 'userpass') {

            if (accountFormDetailsData.givenName.value === '') {
                accountFormDetailsData.givenName.errorText = t(
                    'Ecommerce.FirstNameRequierMessage'
                );
                formIsComplete = false;
            }

            if (accountFormDetailsData.familyName.value === '') {
                accountFormDetailsData.familyName.errorText = t(
                    'Ecommerce.LastNameRequierMessage'
                );
                formIsComplete = false;
            }

            if (accountFormDetailsData.loginEmailAddress.value === '') {
                accountFormDetailsData.loginEmailAddress.errorText = t(
                    'Ecommerce.EmailRequierMessage'
                );
                formIsComplete = false;
            } else {
                /* verify the email address and the verification email address fields match */
                /*  accountFormDetailsData.loginEmailAddress.errorText = '';
                 if (
                     accountFormDetailsData.loginEmailAddress.value !==
                     accountFormDetailsData.loginEmailAddressVerification.value
                 ) {
                     accountFormDetailsData.loginEmailAddressVerification.errorText = t(
                         'Ecommerce.EmailsDontMatchMessage'
                     );
                     formIsComplete = false;
                 } */
            }
            /* if (accountFormDetailsData.school.value === '') {
                accountFormDetailsData.school.errorText =
                    'Please enter in your  school name';
                formIsComplete = false;
            } */

            if (accountFormDetailsData.password.value === '') {
                accountFormDetailsData.password.errorText =
                    'Please enter in a password.';
                formIsComplete = false;
            }

        }
        else if (accountCreationType === 'googleaccount') {
            /* Verify that the user has provided:
              Firstname
              Lastname
            */

            if (accountFormDetailsData.givenName.value === '') {
                accountFormDetailsData.givenName.errorText = t(
                    'Ecommerce.FirstNameRequireMessage'
                );
                formIsComplete = false;
            }

            if (accountFormDetailsData.familyName.value === '') {
                accountFormDetailsData.familyName.errorText = t(
                    'Ecommerce.LastNameRequireMessage'
                );
                formIsComplete = false;
            }
        }



        if (this.state.isAgeRestricted === true) {
            /* Make sure that the 13/Older indicator is selected with a value */
            if (accountFormDetailsData.is13YearsAndOlder.value === null) {
                accountFormDetailsData.is13YearsAndOlder.errorText = t(
                    'Ecommerce.AgeRequierMessage'
                );
                formIsComplete = false;
            } else if (accountFormDetailsData.is13YearsAndOlder.value === false) {
                //make sure the user has certified that they have their Legal Guardian's permission to sign up.
                if (
                    accountFormDetailsData.not13YearsOldLegalGuardianOk.value === false
                ) {
                    accountFormDetailsData.not13YearsOldLegalGuardianOk.errorText = t(
                        'Ecommerce.MinAgeErrorMessage'
                    );
                    formIsComplete = false;
                }

                //make sure the parent email address field is filled in.
                if (accountFormDetailsData.parentEmailAddress.value === '') {
                    accountFormDetailsData.parentEmailAddress.errorText = t(
                        'Ecommerce.ParentEmailRequierMessage'
                    );
                    formIsComplete = false;
                }
            }
        }


        /* a password is assigned to a Google account even though they can sign-in using google, this way they can login without google if needed */
        if (accountFormDetailsData.password.value === '') {
            accountFormDetailsData.password.errorText = t(
                'Ecommerce.PasswordRequierMessage'
            );
            formIsComplete = false;
        } else {
            /* verify the password and the verification password fields match */
            /* accountFormDetailsData.password.errorText = '';
            if (
                accountFormDetailsData.password.value !==
                accountFormDetailsData.passwordVerification.value
            ) {
                accountFormDetailsData.passwordVerification.errorText = t(
                    'Ecommerce.PasswordsDontMatchMessage'
                );
                formIsComplete = false;
            } */
        }

        this.setState(() => ({ formIsComplete: formIsComplete }));

        if (formIsComplete === true) {

            const customerDetailsMeetsRequirementsResult = API.post(
                VALIDATE_NEW_PENDING_CUSTOMER_DETAILS_ENDPOINT_URL,
                {
                    userEnteredPassword: this.state.accountFormDetails.password.value,
                    userEnteredLoginEmailAddress: this.state.accountFormDetails
                        .loginEmailAddress.value,
                    //clubCodeA: this.state.accountFormDetails.codeA.value,
                    //clubCodeB: this.state.accountFormDetails.codeB.value,
                    conditionType: getUserInfo()._sloohatid ? 'join' : 'joinbyguestlanding',
                    selectedPlanId: window.localStorage.selectedPlanId,
                    sloohMarketingTrackingId: getUserInfo()._sloohatid,
                }
            )
                .then(response => {
                    const res = response.data;
                    if (res.apiError == false) {
                        const validationResults = {
                            passwordAcceptable: res.passwordAcceptable,
                            passwordNotAcceptedMessage: res.passwordNotAcceptedMessage,
                            emailAddressAcceptable: res.emailAddressAcceptable,
                            emailAddressNotAcceptedMessage:
                                res.emailAddressNotAcceptedMessage,
                        };

                        if (validationResults.passwordAcceptable === false) {
                            /* Password did not meet Slooh requirements, provide the error messaging */
                            accountFormDetailsData.password.errorText =
                                validationResults.passwordNotAcceptedMessage;

                            /* make sure to persist any changes to the account signup form (error messages) */
                            this.setState({ accountFormDetails: accountFormDetailsData });

                            formIsComplete = false;
                        }

                        if (validationResults.emailAddressAcceptable === false) {
                            /* Email address is already taken or some other validation error occurred. */
                            accountFormDetailsData.loginEmailAddress.errorText =
                                validationResults.emailAddressNotAcceptedMessage;

                            /* make sure to persist any changes to the account signup form (error messages) */
                            this.setState({ accountFormDetails: accountFormDetailsData });
                            formIsComplete = false;
                        } else {
                            accountFormDetailsData.loginEmailAddress.errorText =
                                validationResults.emailAddressNotAcceptedMessage;
                            this.setState({ accountFormDetails: accountFormDetailsData });
                            formIsComplete = true;

                        }

                        if (formIsComplete === true) {
                            /* create the pending customer result */
                            this.createPendingCustomerRecordAndNextScreen();
                        }
                    }
                })
                .catch(err => {
                    throw ('Error: ', err);
                });

        } else {
            /* make sure to persist any changes to the account signup form (error messages) */
            this.setState(() => ({ accountFormDetails: accountFormDetailsData }));
        }

    }

    createPendingCustomerRecordAndNextScreen = () => {
        const { accountFormDetails, } = this.state;

        const selectedSchoolId = window.localStorage.getItem('selectedSchoolId');
        const accountFormDetailsData = cloneDeep(accountFormDetails);

        let createPendingCustomerData = {
            accountCreationType: this.state.accountCreationType,
            selectedPlanId: window.localStorage.selectedPlanId,
            googleProfileId: this.state.googleProfileData.googleProfileId,
            accountFormDetails: this.state.accountFormDetails,
            selectedSchoolId,
            conditionType: getUserInfo()._sloohatid ? 'join' : 'joinbyguestlanding',
            isAgeRestricted: this.state.isAgeRestricted,
            sloohMarketingTrackingId: getUserInfo()._sloohatid,
        };


        // JOIN_CREATE_PENDING_CUSTOMER_ENDPOINT_URL
        API.post(
            JOIN_CREATE_PENDING_CUSTOMER_ENDPOINT_URL,
            createPendingCustomerData
        )
            .then(response => {
                const res = response.data;
                if (!res.apiError) {
                    const pendingCustomerResult = {
                        status: res.status,
                        customerId: res.customerId,
                        message: res.message,
                    };

                    if (pendingCustomerResult.status === 'success') {
                        window.localStorage.setItem(
                            'pending_cid',
                            pendingCustomerResult.customerId
                        );
                        window.localStorage.setItem(
                            'username',
                            this.state.accountFormDetails.loginEmailAddress.value
                        );
                        window.localStorage.setItem(
                            'password',
                            this.state.accountFormDetails.password.value
                        );

                        /*  this.setState( () =>({
                             accoridianActiveKey:"1"
                         })) */

                        const { onStepOneComplete } = this.props;

                        onStepOneComplete("1");

                        //  .log('Proceeding to create the customers pending account');
                        // browserHistory.push('/join/step3');
                    } else {
                        accountFormDetailsData.loginEmailAddress.errorText =
                            pendingCustomerResult.message;

                        this.setState(() => ({ accountFormDetails: accountFormDetailsData }));

                    }
                }
            })
            .catch(err => {
                throw ('Error: ', err);
            });
    }


    /* The API response to the Google SSO Request was successful, process the response data elements accordingly and send the information back to the Slooh servers */
    processGoogleSuccessResponse = googleTokenData => {
        /* Process the Google SSO tokens and get back information about this user via the Slooh APIs/Google APIs, etc. */

        API.post(GOOGLE_SSO_SIGNIN_ENDPOINT_URL, {
            authenticationCode: googleTokenData.code
        })
            .then(response => {
                const res = response.data;
                if (!res.apiError) {
                    const googleProfileResult = {
                        googleProfileId: res.googleProfileId,
                        googleProfileEmail: res.googleProfileInfo.email,
                        googleProfileGivenName: res.googleProfileInfo.givenName,
                        googleProfileFamilyName: res.googleProfileInfo.familyName,
                        googleProfilePictureURL: res.googleProfileInfo.profilePictureURL,
                    };

                    let { formFields } = this.state;
                    formFields.forEach((element, index) => {
                        if (res.googleProfileInfo[element.key]) {
                            Object.assign(formFields[index], { editable: true, currentValue: res.googleProfileInfo[element.key].currentValue, fieldType: res.googleProfileInfo[element.key].fieldType });
                        }
                        if (element.fieldOptions) {
                            element.fieldOptions.forEach((innerElement, innerIndex) => {
                                if (innerElement.nestedFields && innerElement.key === formFields[index].currentValue) {
                                    innerElement.forEach((nestedElement, nestedIndex) => {
                                        if (res.googleProfileInfo[nestedElement.key])
                                            Object.assign(formFields[index].fieldOptions[innerIndex].nestedFields[nestedIndex], { editable: true, currentValue: res.googleProfileInfo[nestedElement.key].currentValue });
                                    })
                                }
                            })
                        }
                    });
                    /* Capture the Google Profile Data and store it in state */
                    this.setState(() => ({ googleProfileData: googleProfileResult, formFields: formFields, accountCreationType: 'googleaccount', }));

                    /* Set the account creation type as Google and the Google Profile Id in browser storage */
                    window.localStorage.setItem('accountCreationType', 'googleaccount');
                    window.localStorage.setItem(
                        'googleProfileId',
                        googleProfileResult.googleProfileId
                    );
                    window.localStorage.setItem(
                        'googleProfileEmail',
                        googleProfileResult.googleProfileEmail
                    );
                    this.handleGoogleApiValidation();
                    this.setState({step: 2});
                }
            })
            .catch(err => {
                throw ('Error: ', err);
            });
    };

    processGoogleFailureResponse = googleMessageData => {
        window.recordAmplitudeEvent('event_checkoutPage_googleStateChanged', { isError: true, state: "verificationFailed", isGoogleError: true, googleData: googleMessageData, sloohSiteSessionToken: getUserInfo()._sloohsstkn, sloohMarketingTrackingId: getUserInfo()._sloohatid });
    };

    handleJoinPageServiceResponse = result => {
        this.setState({
            formFields: result.formFieldLabels,
            showGoogleSSOButtonDescription: result.showGoogleSSOButtonDescription,
            googleSSOButtonDescription: result.googleSSOButtonDescription,
            sloohBypassCaptcha: result.sloohBypassCaptcha,
			/* ToddR - 7/29/2022 - Override captcha handling - disable captcha as potential production bug fix
				captchaVerified: result.sloohBypassCaptcha === true, */
			captchaVerified: true,
            termsandcond: result.termsAndConditions,
            joinText: result?.selectedSubscriptionPlan?.formHeading,
        })

        deleteLoadingText();
    }




    getFormField = (fieldType, label, hintText, keyName, onChange, errorText, fieldOptions, value, showError, required, fieldSize, editable) => {
        switch (fieldType) {
            case "select":
                return (<div className={"form-section " + fieldSize}>
                    <div className="form-field-container">
                        <span className="label-text"
                            dangerouslySetInnerHTML={{ __html: label }} />

                    </div>
                    {/* <Field
                        name={keyName}
                        type="select"
                        className="field-input"
                        label={hintText}
                        component={"select"}
                        defaultValue={"US"}
                        onChange={event => { onChange({ field: keyName, value: event.target.value, type: "select" }); }} >
                        {fieldOptions.map(field => (
                          
                          <option value={field.key} >{field.label}</option>
                    
                        ))}
                    </Field> */}

                    {showError && (
                        <div className="error-div">
                            <img className="alert-icon" src="https://vega.slooh.com/assets/v4/site-redesign/icons/alert.svg" />
                            <span className="form-error"
                                dangerouslySetInnerHTML={{ __html: errorText }} />
                        </div>
                    )}

                    <select name={keyName}
                        className="field-input mb-0"
                        onChange={event => { onChange({ field: keyName, value: event.target.value, type: "select" }); }}
                    >
                        {fieldOptions.map((field) => (
                            <option key={field.key} value={field.key} selected={field.selected == true ? 'selected' : ''}>{field.label}</option>
                        ))}
                    </select>

                    {fieldOptions.map(field => (
                        field.key === value && field.nestedFields && (
                            field.nestedFields.map(nestItem => (
                                <fieldset style={{ paddingLeft: '25px' }}>
                                    {this.getFormField(nestItem.fieldType, nestItem.label, nestItem.hintText, nestItem.key, onChange, nestItem.errorText, nestItem.fieldOptions, nestItem.value, nestItem.showError, nestItem.required, nestItem.fieldSize, nestItem.editable)}
                                </fieldset>
                            )
                            )
                        )))}

                </div>);
                break;
            case "radio":
                return (<div className={"form-section " + fieldSize}>
                    <span className="label-text"
                        dangerouslySetInnerHTML={{ __html: label }} />

                    <fieldset className="row">
                        {fieldOptions.map(item => (
                            <div>
                                <label className="ageGroupStyle" style={{ paddingLeft: '25px' }}>
                                    <Field
                                        name={item.key}
                                        placeholder={item.label}
                                        component="input"
                                        type="radio"
                                        value={item.key}
                                        checked={value === item.key}
                                        onClick={event => { onChange({ field: keyName, value: item.key, type: "radio" }); }}
                                    />
                                    {" " + item.label}
                                </label>

                                {value === item.key && item.nestedFields && (
                                    item.nestedFields.map(nestItem => (
                                        <fieldset style={{ paddingLeft: '25px' }}>
                                            {this.getFormField(nestItem.fieldType, nestItem.label, nestItem.hintText, nestItem.key, onChange, nestItem.errorText, nestItem.fieldOptions, nestItem.value, nestItem.showError, nestItem.required, nestItem.fieldSize, nestItem.editable)}
                                        </fieldset>

                                    ))
                                )}
                            </div>
                        ))}
                    </fieldset>
                    {showError && (
                        <div className="error-div">
                            <img className="alert-icon" src="https://vega.slooh.com/assets/v4/site-redesign/icons/alert.svg" />
                                <span className="form-error"
                                    dangerouslySetInnerHTML={{ __html: errorText }} />
                        </div>
                    )}
                </div>);

            case "checkbox":
                return (<div>
                    <div className={"form-section " + fieldSize}>
                        <Field
                            name={keyName}
                            type="checkbox"
                            className="form-field"
                            placeholder={label}
                            component="input"
                            value={value}
                            onClick={event => { onChange({ field: keyName, value: !value, type: "checkbox" }); }} />
                        <div className="form-field-container inline">
                            <span className="checkbox-text"
                                dangerouslySetInnerHTML={{ __html: label }} />

                            <span className="form-error"
                                dangerouslySetInnerHTML={{ __html: showError ? errorText : '' }} />
                        </div>
                    </div>
                    <br />
                </div>)

            case "hidden":
                return (
                    <div style={{ display: 'none' }}>
                        <Field
                            value={value}
                            className=""
                            component={InputFieldNew}
                            name={keyName}
                            type="hidden"
                        />

                    </div>
                );
            case "password":
            case "name":
            case "email":
            case "number":
            case "text":

                return (<div className={"form-section " + fieldSize}>
                    <div className="form-field-container">
                        <span className="label-text"
                            dangerouslySetInnerHTML={{ __html: label }} />

                    </div>
                    <Field
                        editable={editable}
                        name={keyName}
                        currentValue={value}
                        type={fieldType}
                        className={classnames("form-field", {error: showError})}
                        placeholder={hintText}
                        component={InputFieldNew}
                        onBlur={() => this.onLeaveTextField({ field: keyName, value: fieldType === "password" ? "leftthefield" : event.target.value })}
                        onChange={event => { onChange({ field: keyName, value: event.target.value, type: fieldType }); }} />
                    
                    {showError && (
                        <div className="error-div">
                            <img className="alert-icon" src="https://vega.slooh.com/assets/v4/site-redesign/icons/alert.svg" />
                            <span className="form-error"
                                dangerouslySetInnerHTML={{ __html: errorText }} />
                        </div>
                    )}
                    
                </div>);

            case "label":
                return (<div className={"form-section " + fieldSize}>
                    <div className="form-field-container">
                        <span className="label-text"
                            dangerouslySetInnerHTML={{ __html: label }} />

                        <span className="form-error"
                            dangerouslySetInnerHTML={{ __html: showError ? errorText : '' }} />
                    </div>
                    <span name={keyName} className="form-field" >{value}</span>
                    <br />
                    <br />
                </div>);

        }
    }

    onLeaveTextField = ({ field, value }) => {
        if (value.length > 0)
            window.recordAmplitudeEvent('event_checkoutPage_stateChanged', { fieldName: field, fieldValue: value, sloohSiteSessionToken: getUserInfo()._sloohsstkn, sloohMarketingTrackingId: getUserInfo()._sloohatid });
    }

    handleResetGoogleSignin = () => {

        let { formFields } = this.state;
        formFields.map((field, index) => {
            if (field.key === "loginEmailAddress") {
                formFields[index].fieldType = "text";
                formFields[index].currentValue = "";
                formFields[index].editable = undefined;
            }
            switch (field.fieldType) {
                case "text":
                case "password":
                case "name":
                case "number":
                case "password":
                case "email":
                    formFields[index].currentValue = "";
                    formFields[index].editable = undefined;
                    break;
            }
        })

        window.localStorage.removeItem(
            'googleProfileId'
        );
        window.localStorage.removeItem(
            'googleProfileEmail'
        );
        window.localStorage.setItem('accountCreationType', 'userpass');
        this.setState({ accountCreationType: "userpass", formFields: formFields, showGooglePopup: false });
    }

    handleSignUpOptionSubmit = () => {
        const { selectedSignUpOption } = this.state;
        if(selectedSignUpOption === "googleclassroom" ){
            this.props.actions.triggerGoogleSignUp();
        }
        else{
            this.setState({step: 2});
        }
        this.props.handleTotalStepCount(3);
    }

    render() {
        const { pathname, t, totalStep } = this.props;

        const {
            accountFormDetails,
            captchaVerified,
            accountCreationType,
            googleSSOButtonDescription,
            showGoogleSSOButtonDescription,
            sloohBypassCaptcha,
            showGooglePopup,
            googleInitialState,
            googleSignInVerified,
            formFields,
            termsandcond,
            isLoading,
            joinText,
            selectedSignUpOption,
            step,
        } = this.state;
        
        const selectedPlanId = window.localStorage.getItem('selectedPlanId');
        const { _sloohatid } = getUserInfo();

        return (
            <div className="personal-info-reg">
                <Request
                    serviceURL={JOIN_PAGE_ENDPOINT_URL}
                    requestBody={{
                        callSource: 'setupCredentials',
                        selectedPlanId,
                        sloohMarketingTrackingId: _sloohatid,
                        conditionType: _sloohatid ? 'join' : 'joinbyguestlanding',
                        enableHiddenPlanHashCode: window.localStorage.getItem(
                            'enableHiddenPlanHashCode'
                        ),
                    }}
                    serviceResponseHandler={this.handleJoinPageServiceResponse}
                    render={({ fetchingContent, serviceResponse: joinPageRes }) => (
                        <Fragment>
                            {!fetchingContent && (
                                <DeviceContext.Consumer>
                                    {({ isMobile, isDesktop, isTablet }) => (
                                        <Fragment>
                                            <div className="inner-container">
                                                {joinPageRes.signupWithGoogleClassroomAvailable && showGooglePopup && step === 1 && (
                                                    <Fragment>
                                                        <div className="step-root">
                                                            <div className="inner-container">
                                                                <div className="header-container">
                                                                    <h2 className="join-text">Buy Slooh Today!</h2>
                                                                    <h6 className="step-text">STEP 1 of {totalStep + 1}</h6>
                                                                    <hr className="separator-line"/>
                                                                </div>
                                                                {(!googleInitialState && googleSignInVerified) ? (
                                                                    <div>
                                                                        <p className="google-ques-text" dangerouslySetInnerHTML={{ __html: 'Your Google Account has been verified.' }} />
                                                                        <br />
                                                                        <center><button className="astronomy" onClick={() => this.setState({ showGooglePopup: false })}>Continue</button></center>
                                                                        <br />
                                                                    </div>
                                                                ) : (
                                                                    <GoogleOAuthProvider clientId={joinPageRes.googleClientID}>
                                                                        <form>
                                                                            <div className="form-section">
                                                                                <div className="form-field-container">
                                                                                    {joinPageRes?.signupOptionsList.map(item=>(
                                                                                        <div className="form-field-radio-container">
                                                                                            <input id={item.key} onChange={()=>this.setState({selectedSignUpOption: item.key})} name="signup-option" className="form-radio-field" type="radio" />
                                                                                            <div>
                                                                                                <h5 className="sign-up-title">{item.title}</h5>
                                                                                                <h6 className="sign-up-subtitle">{item.desc}</h6>
                                                                                            </div>
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            </div>
                                                                            <div className="button-container">
                                                                                <button onClick={this.handleSignUpOptionSubmit} className="submit-button" disabled={!selectedSignUpOption} type="button">
                                                                                    Continue
                                                                                </button>
                                                                            </div>
                                                                        </form>
                                                                        <Request
                                                                            serviceURL={GOOGLE_CLIENT_ID_ENDPOINT_URL}
                                                                            requestBody={{
                                                                                callSource: 'join',
                                                                            }}
                                                                            render={({
                                                                                fetchingContent: fetchingGoogleClient,
                                                                                serviceResponse: googleClientResponse,
                                                                            }) => (
                                                                                <div className="hide">
                                                                                    {!fetchingGoogleClient && (
                                                                                        <div className="google-overlay-content">
                                                                                            <GoogleOAuthProvider clientId={googleClientResponse.googleClientID}>
                                                                                                <GoogleSignInButton
                                                                                                    buttonText={joinPageRes.signupWithGoogleClassroomDetails.promptText_Yes}
                                                                                                    scope={googleClientResponse.googleClientScope}
                                                                                                    onSuccess={this.processGoogleSuccessResponse}
                                                                                                    onFailure={this.processGoogleFailureResponse}
                                                                                                />
                                                                                            </GoogleOAuthProvider>
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            )}
                                                                        />
                                                                    </GoogleOAuthProvider>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </Fragment>
                                                    // <div className="overlay-astronomy">
                                                    //     {!googleInitialState ? (googleSignInVerified ? (
                                                    //         <div className="google-overlay-div">
                                                    //             <br />
                                                    //             <div>
                                                    //                 <p className="google-ques-text" dangerouslySetInnerHTML={{ __html: 'Your Google Account has been verified.' }} />
                                                    //                 <br />
                                                    //                 <center><button className="astronomy" onClick={() => this.setState({ showGooglePopup: false })}>Continue</button></center>
                                                    //                 <br />
                                                    //             </div>
                                                    //         </div>
                                                    //     ) : (
                                                    //         <div>
                                                    //             <div>
                                                    //                 <Request
                                                    //                     serviceURL={GOOGLE_CLIENT_ID_ENDPOINT_URL}
                                                    //                     requestBody={{
                                                    //                         callSource: 'join',
                                                    //                     }}
                                                    //                     render={({
                                                    //                         fetchingContent: fetchingGoogleClient,
                                                    //                         serviceResponse: googleClientResponse,
                                                    //                     }) => (
                                                    //                         <div className="google-overlay-div">
                                                    //                             <br />
                                                    //                             <p className="google-ques-text" dangerouslySetInnerHTML={{ __html: `Oops - the email address (${formFields?.filter(a => a.key === "loginEmailAddress")[0].currentValue}) is already in use.` }} />
                                                    //                             <br />
                                                    //                             <br />
                                                    //                             {!fetchingGoogleClient && (
                                                    //                                 <div className="google-overlay-content">
                                                    //                                     <div className="flex-1 br-2">
                                                    //                                         <p className="google-ques-text" dangerouslySetInnerHTML={{ __html: 'To use a different email address for Google Classroom with Slooh, click or tap on the button below:' }} />
                                                    //                                         <br />
                                                    //                                         <GoogleOAuthProvider clientId={googleClientResponse.googleClientID}>
                                                    //                                             <GoogleSignInButton
                                                    //                                                 buttonText={joinPageRes.signupWithGoogleClassroomDetails.promptText_Yes}
                                                    //                                                 scope={googleClientResponse.googleClientScope}
                                                    //                                                 onSuccess={this.processGoogleSuccessResponse}
                                                    //                                                 onFailure={this.processGoogleFailureResponse}
                                                    //                                             />
                                                    //                                         </GoogleOAuthProvider>

                                                    //                                     </div>
                                                    //                                     <div className="flex-1">
                                                    //                                         <p className="google-ques-text" dangerouslySetInnerHTML={{ __html: 'To sign in to Slooh using a different email address, click or tap on the button below:' }} />
                                                    //                                         <button className="astronomy" onClick={this.handleResetGoogleSignin}>{joinPageRes.signupWithGoogleClassroomDetails.promptText_No}</button>

                                                    //                                     </div>


                                                    //                                     {showGoogleSSOButtonDescription && (
                                                    //                                         <div style={{ padding: '10px' }}>{googleSSOButtonDescription}</div>
                                                    //                                     )}

                                                    //                                 </div>
                                                    //                             )}

                                                    //                             <br />
                                                    //                         </div>
                                                    //                     )}
                                                    //                 />
                                                    //             </div>
                                                    //         </div>
                                                    //     )) : (
                                                    //         <Request
                                                    //             serviceURL={GOOGLE_CLIENT_ID_ENDPOINT_URL}
                                                    //             requestBody={{
                                                    //                 callSource: 'join',
                                                    //             }}
                                                    //             render={({
                                                    //                 fetchingContent: fetchingGoogleClient,
                                                    //                 serviceResponse: googleClientResponse,
                                                    //             }) => (
                                                    //                 <div className="google-overlay-div">
                                                    //                     <br />
                                                    //                     {!fetchingGoogleClient && (
                                                    //                         <div className="google-overlay-content">
                                                    //                             <div className="flex-1 br-2">
                                                    //                                 <p className="google-ques-text" dangerouslySetInnerHTML={{ __html: joinPageRes.signupWithGoogleClassroomDetails.questionText_Yes }} />
                                                    //                                 <br />
                                                    //                                 <br />
                                                    //                                 <GoogleOAuthProvider clientId={googleClientResponse.googleClientID}>
                                                    //                                     <GoogleSignInButton
                                                    //                                         buttonText={joinPageRes.signupWithGoogleClassroomDetails.promptText_Yes}
                                                    //                                         scope={googleClientResponse.googleClientScope}
                                                    //                                         onSuccess={this.processGoogleSuccessResponse}
                                                    //                                         onFailure={this.processGoogleFailureResponse}
                                                    //                                     />
                                                    //                                 </GoogleOAuthProvider>
                                                    //                             </div>
                                                    //                             <div className="flex-1">
                                                    //                                 <p className="google-ques-text" dangerouslySetInnerHTML={{ __html: joinPageRes.signupWithGoogleClassroomDetails.questionText_No }} />
                                                    //                                 <br />
                                                    //                                 <br />
                                                    //                                 <button className="astronomy" onClick={() => this.setState({ showGooglePopup: false })}>{joinPageRes.signupWithGoogleClassroomDetails.promptText_No}</button>
                                                    //                             </div>


                                                    //                             {showGoogleSSOButtonDescription && (
                                                    //                                 <div style={{ padding: '10px' }}>{googleSSOButtonDescription}</div>
                                                    //                             )
                                                    //                             }

                                                    //                         </div>
                                                    //                     )}
                                                    //                     <br />
                                                    //                     <p className="google-ques-text" dangerouslySetInnerHTML={{ __html: joinPageRes.signupWithGoogleClassroomDetails.disclaimerTextHtml }} />
                                                    //                     <br />
                                                    //                 </div>
                                                    //             )}
                                                    //         />
                                                    //     )}

                                                    // </div>
                                                )}
                                                <div className={(joinPageRes.signupWithGoogleClassroomAvailable ? (step !== 2 ? 'hide' : '') : '')}>
                                                    <h2 className="join-text">{joinText}</h2>
                                                    <h6 className="step-text">Step {step} of {joinPageRes.signupWithGoogleClassroomAvailable ? (totalStep + 1) : totalStep}: Personal information</h6>
                                                    <hr className="separator-line"/>
                                                    <br />
                                                    <form className="row ml-0 mr-0" onSubmit={this.handleValidation}>

                                                        {this.state.formFields.map(field => (
                                                            this.getFormField(field.fieldType, field.label, field.hintText, field.key, this.handleFieldChange, field.errorText, field.fieldOptions, field.currentValue, field.showError, field.required, field.fieldSize, field.editable)
                                                        ))}

                                                        {/* ToddR - 7/29/2022 - Override captcha handling, disable based on potential bug..	
                                                        {!sloohBypassCaptcha && (
                                                            <div className="form-section mb-4">
                                                                <div className="form-field-container">
                                                                    <ReCAPTCHA
                                                                        sitekey={googleRecaptchaConfig.CAPTCHA_KEY_V2}
                                                                        onChange={this.handleCaptchaCode}
                                                                    />

                                                                </div>
                                                            </div>
                                                        )}

                                                        */}
                                                        {termsandcond && termsandcond.showtandc && (
                                                            <div className="terms-cond" dangerouslySetInnerHTML={{__html: termsandcond?.tandcHTML}} /> 
                                                        )}
                                                        
                                                        <button className={"reg-continue-btn " + (!captchaVerified ? "disabled" : "")} type="submit" disabled={!captchaVerified}>
                                                            {joinPageRes.continueBtnTxt}
                                                        </button>
                                                    </form>
                                                </div>

                                            </div>
                                        </Fragment>
                                    )}
                                </DeviceContext.Consumer>
                            )}
                        </Fragment>


                    )}
                />
                <Spinner loading={isLoading } />
            </div>

        );
    }
}

const mapStateToProps = ({ joinAccountForms }) => ({
    joinAccountForms,
});


const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            triggerGoogleSignUp,
            //resetLogIn,
            // logUserIn,
            //logGoogleUserIn,
        },
        dispatch
    ),
});
let dataValue = "US";
personalInfoRegistrationNew = reduxForm({
    form: 'joinAccountForms',
    enableReinitialize: true,
    initialValues: {
        country: dataValue
    }
})(personalInfoRegistrationNew);


export default connect(state => 
    mapStateToProps,
    mapDispatchToProps
)(personalInfoRegistrationNew)

