import { AnyAction } from 'redux';
import { handleActions } from 'redux-actions';
import { actions, constants } from 'ducks-helpers';

import { AnnouncementsResponse } from 'app/modules/announcements/types';

export interface AnnouncementsState {
  announcementsList: AnnouncementsResponse;
  isFetching: boolean;
  isLoaded: boolean;
  serverError: string | null;
}

export const TYPE = constants('announcements', ['~GET_ANNOUNCEMENTS_LIST']);

export const ACTION = actions(TYPE);

export const initialState: AnnouncementsState = {
  announcementsList: {} as AnnouncementsResponse,
  isFetching: false,
  isLoaded: false,
  serverError: null,
};

const setFetching = (state: AnnouncementsState) => ({
  ...state,
  isFetching: true,
  isLoaded: false,
});

const setServerError = (state: AnnouncementsState, action: AnyAction) => ({
  ...state,
  isFetching: false,
  serverError: action.payload,
  isLoaded: false,
});

const getAnnouncementsListSuccess = (
  state: AnnouncementsState,
  action: AnyAction
) => ({
  ...state,
  isFetching: false,
  isLoaded: true,
  announcementsList: action.payload,
});

export default handleActions<AnnouncementsState>(
  {
    [TYPE.GET_ANNOUNCEMENTS_LIST]: setFetching,
    [TYPE.GET_ANNOUNCEMENTS_LIST_SUCCESS]: getAnnouncementsListSuccess,
    [TYPE.GET_ANNOUNCEMENTS_LIST_ERROR]: setServerError,
  },
  initialState
);
