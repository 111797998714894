import React,{ useEffect, useState } from 'react';
import { logUserIn } from '../login/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'app/components/spinner/index';
import { UpgradeConfirmModal } from '../new-dashboard/components/upgrade-modal-dashboard/confirm-upgrade-popup';
import { browserHistory } from 'react-router';

export const ImpersonatePage = (props: any) => {

    const loginFailed = useSelector((state: any) => state.logIn.loginFailed);
    const [showPopup, setShowPopup] = useState(false);
    const [isFetching, setIsFetching] = useState(true);
    const dispatch = useDispatch();

    const handleLoginResponse = (data: any) => {
        //handle error case
        setIsFetching(false);
        setShowPopup(true);
    }

    useEffect(()=>{
        const { customerUUID, token } = props.params;
        dispatch(logUserIn({customerUUID, impToken: token}, {}, handleLoginResponse));
    },[])

    useEffect(()=>{
        if(loginFailed){
            //handle error case
        }
    },[loginFailed]);

    const handlePopupClose = () => {
        setShowPopup(false);
        browserHistory.push("/login");
    }

    return (
        <>
            <Spinner loading={isFetching} text="Authenticating the Impersonate Account. Please Wait..."  />
            <UpgradeConfirmModal
                isOpen={showPopup}
                onClose={handlePopupClose}
                heading={"Session Expired"}
                description={"Your Impersonate Access Expired"}
                talkWithUsText="Back to Login Page"
                talkWithUsUrl="/login"
                showUpgradeDirectly={false}
                showUpgradeBtn={false}
                showTalkToUsBtn={true}
            />
        </>
       
    )
}