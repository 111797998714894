import React from 'react';
import { SVGRoot } from './';

const Chevron = () => (
  <SVGRoot width={12} height={8}>
    <g id="ONLINE" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="10_Scope_Full_Screen_Tablet_a" transform="translate(-534.000000, -101.000000)" fill="#41566F" fillRule="nonzero">
        <g id="Top_Controls_FullScreen" transform="translate(0.000000, 60.000000)">
          <g id="Scope_Picker" transform="translate(346.000000, 20.000000)">
            <path
              d="M197.712171,25.5940617 L191.677699,30.7537109 C191.293833,31.0820964 190.671461,31.0820964 190.287783,30.7537109 C189.904072,30.4256175 189.904072,29.8934816 190.287783,29.5654148 L195.627359,24.9999137 L190.287938,20.4345719 C189.904228,20.1063457 189.904228,19.574263 190.287938,19.2461696 C190.671648,18.9179435 191.293989,18.9179435 191.677854,19.2461696 L197.712326,24.4058985 C197.904181,24.5700248 198,24.7849029 198,24.9998871 C198,25.2149776 197.903995,25.430015 197.712171,25.5940617 Z"
              id="Shape"
              transform="translate(194.000000, 25.000000) rotate(-270.000000) translate(-194.000000, -25.000000)"
            >
            </path>
          </g>
        </g>
      </g>
    </g>
  </SVGRoot>
);

export { Chevron };
