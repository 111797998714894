import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './LargeButtonWithRightIcon.style';

const { func, number, oneOfType, string } = PropTypes;

const LargeButtonWithRightIcon = ({
  text,
  onClickEvent,
  icon,
  renderIcon,
  theme,
  disabled,
}) => (
  <button
    className={classnames('generic-button', 'button-container', {
      circular: icon && !text,
    })}
    style={theme}
    onClick={onClickEvent}
    disabled={disabled}
  >
    <span className="text" dangerouslySetInnerHTML={{ __html: text }} />
    {icon ? <img className="icon" src={icon} /> : null}
    {renderIcon ? renderIcon() : null}
    <style jsx>{styles}</style>
  </button>
);

LargeButtonWithRightIcon.propTypes = {
  icon: string,
  text: oneOfType([string, number]).isRequired,
  onClickEvent: func.isRequired,
  renderIcon: func,
};
LargeButtonWithRightIcon.defaultProps = {
  icon: null,
  renderIcon: null,
};

export default LargeButtonWithRightIcon;
