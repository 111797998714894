import css from 'styled-jsx/css';
import { faintShadow } from 'app/styles/variables/shadows';
import {
  astronaut,
  romance,
  golden_yellow,
  shadows,
  gray600,
  gray300,
  brightBlue1000,
  gray700,
} from 'app/styles/variables/colors_tiles_v4';
import { primaryFont, secondaryFont } from 'app/styles/variables/fonts';
import { screenLarge, screenMedium, screenMobile, screenSmallMobile } from 'app/styles/variables/breakpoints';
import {
  backgroundImageCover,
  dropShadowContainer,
} from 'app/styles/mixins/utilities';

export default css`
  .step-root {
    margin: 0 auto;
    width: 100%;
    color: ${astronaut};
    margin-top: 150px;
  }

  .step2-root {
    margin: 0 auto;
    width: 100%;
    color: ${astronaut};
    margin-top: 50px;
  }

  .step-root-upsell {
    margin: 0 auto;
    width: 100%;
    color: ${astronaut};
  }

  .inner-container {
    padding: 85px;
    width: 100%;
    background-color: ${romance};
    ${faintShadow}
  }
  .section-heading {
    font-size: 14px;
    font-family: ${primaryFont};
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
    padding: 25px 0;
    letter-spacing: 2px;
    border-bottom: 1px solid ${shadows};
  }

  .google-login-button {
    margin: 15px auto;
    text-align: center;
  }

  .or-separator-container{
    display flex;
    padding: 32px;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .border-line{
    border-bottom: 1px solid #E2E2E2;
    width: 40px;
  }

  .or-text{
    font-family: ${primaryFont};
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #545454;
  }

  .form {
    margin: 15px 0;
    color: ${astronaut};
  }

  .form-section {
    padding: 15px 0;
  }

  .form-section-padding{    
    padding: 15px 0;
  }

  .form-section.split {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .header-invite-subheading{
    font-family: ${secondaryFont};
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 38px;
    text-align: center;
  }

  .age-field-set{
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #CBCBCB;
    border-radius: 4px;
    width: fit-content;
  }

  .form-radio-container{
    display: flex;
    align-items: center;
    font-family: ${primaryFont};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #545454;
    padding: 9px 24px 9px 12px;
  }

  .form-radio-container + .form-radio-container {
    border-left: 1px solid #CBCBCB;
  }

  .form-radio-field{
    min-width: 20px;
  }
  
  .guardian-container{
    margin-top: 15px;
  }

  .form-field-radio-container{
    border-radius: 8px;
    border: 1px solid #CBCBCB;
    padding: 12px;
    display: flex;
    align-items: start;
    gap: 10px;
    margin-bottom: 12px;
  }

  .sign-up-title{
    color: #000;
    font-size: 16px;
    font-family: ${primaryFont};
    font-weight: 400;
    margin-bottom: 8px;
  }

  .sign-up-subtitle{
    color: #545454;
    font-size: 14px;
    font-family: Brandon Grotesque;
    font-weight: 390;
    line-height: 20px;
  }

  .form-label {
    display: inline-block;
    font-family: ${primaryFont};
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: ${gray600};
    margin-bottom: 8px;
  }

  .form-error {
    display: inline-block;
    color: red;
    font-weight: bold;
  }
  .form-success {
    display: inline-block;
    color: green;
    font-weight: bold;
  }

  .form-field {

  }

  .google-field {
    padding: 9px 12px;
    gap: 8px;
    background: #E2E2E2;
    border: 1px solid #CBCBCB;
    border-radius: 4px;
    font-family: ${primaryFont};
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #000000;
    width: 100%;
  }

  .form-field-container {
  }

  .invited-by {
    border: 1px solid ${shadows};
    padding: 40px;
    background-color: ${romance};
  }

  .invited-by .form-label {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 2px;
  }

  .invited-by .inviter {
    border-top: 1px solid #ced2d8;
    border-bottom: 1px solid #ced2d8;
    padding-left: 40px;
    padding-right: 40px;
    margin: 5px 0;
    font-size: 24px;
    font-family: ${secondaryFont};
    width: 100%;
    text-transform: capitalize;
    font-weight: normal;
  }

  .form-field-half {
    width: 100%;
  }

  .button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px 0;
  }

  button.submit-button {
    background: none;
    border: none;
    margin: 0;
    padding: 12px 48px;
    transition: background-color 0.25s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 100px;
    background-color: ${brightBlue1000};
    font-weight: bold;
    font-family: ${primaryFont};
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: ${romance};
    width: 100%;
    box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.12), 0px 2px 5px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.08);
  }

  button.submit-button:disabled, button.submit-button[disabled]{
    background-color:  ${gray300}
  }

  button.submit-button:focus {
    outline: none;
  }

  .flex-container{
    display: flex;
  }

  .header-container{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .logo{
    margin-bottom: 20px;
  }

  .header-subheading{
    font-family: ${primaryFont};
    font-style: normal;
    font-weight: 390;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: ${gray600};
    margin-bottom: 16px;
  }

  .hide{
    display: none;
  }

  .show{
    display: block;
  }

  .error-container{
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }

  .error-title{
    color: black;
    font-size: 16px;
    font-family: ${primaryFont};
    font-style: normal;
    font-weight: 450;
    line-height: 140%;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 12px;
    text-align: center;
  }

  .error-subtitle{
    color: ${gray700};
    text-align: center;
    font-size: 16px;
    font-family: Brandon Grotesque;
    font-style: normal;
    font-weight: 390;
    line-height: 140%;
  }

  @media ${screenMedium} {
    .step-root {
      width: 600px;
    }

    .step2-root {
      width: 600px;
    }

    .step-root-upsell {
      width: 75%;
    }

    .form-section.split {
      flex-direction: row;
    }

    .form-field-half {
      width: 50%;
    }

    .form-field-quater {
      width: 25%;
    }
  }
  
  @media ${screenMobile} {
    .form-field-quater {
      width: 45%;
    }
    .inner-container{
      padding: 30px;
    }
    .step-root {
      margin-top: 5px;
    }
    .step2-root {
      margin-top: 5px;
    }
    .confirm-upsell .button-container{
      flex-wrap: wrap;
      justify-content: center;
    }
    .confirm-upsell .button-container button.submit-button{
      width: fit-content;
      margin-top: 10px;
    }
  }
  @media ${screenSmallMobile} {
    .form-field-quater {
      width: 45%;
    }
  }


  h1{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 50px;
  }

  .left-align{
    justify-content: start;
  }
  
  .disabled{
    background-color: gray !important;
  }
`;
