const initialState = {
  pageMeta: {},
  modal: {
    showModal: false,
    modalComponent: null,
    modalStyles: null,
  },
  step: {},
  complete: {
    stepList: [],
    relatedQuestsList: [],
  },
};
export default initialState;
