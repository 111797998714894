import React from 'react';

export const DEFAULT_JOIN_TABS = [
  {
    label: 'Step 1',
    value: '/join/step1',
  },
  {
    label: 'Step 2',
    value: '/join/step2',
  },
  {
    label: 'Step 3',
    value: '/join/step3',
  },
];

export const JOIN_BY_INVITE_TABS = [
  {
    label: 'Step 1',
    value: '/join/inviteByCodeStep1',
  },
  {
    label: 'Step 2',
    value: '/join/inviteByCodeStep2',
  },
];

export const JOIN_BY_WORKSPACESIGNUP_TABS = [
  {
    label: 'Step 1',
    value: '/join/workspace',
  },
  {
    label: 'Step 2',
    value: '/join/inviteByCodeStep2',
  },
];


export const CLASSROOM_JOIN_TABS = [
  {
    label: 'Step 1',
    value: '/join/step1',
  },
  {
    label: 'Step 2',
    value: 'join/step1SchoolSelection',
  },
  {
    label: 'Step 3',
    value: '/join/step2',
  },
  {
    label: 'Step 4',
    value: '/join/step3',
  },
];

export const PLAN_DETAILS_JOIN_TABS = [
  {
    label: 'Plan Details',
    value: '/join/membershipPlanDetailsStep',
  },
  {
    label: 'Step 2',
    value: '/join/step2',
  },
  {
    label: 'Step 3',
    value: '/join/step3',
  },
];
