/* eslint-disable */
import {AtomicBlockUtils, CompositeDecorator, Editor, EditorState, RichUtils,} from 'draft-js';
import {convertFromHTML, convertToHTML} from 'draft-convert';
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import AddDividerBlock from './divider-block/AddDividerBlock';
import DividerBlock from './divider-block/DividerBlock';
import BlockStyleControls from './BlockStyleControls';
import InlineStyleControls from './InlineStyleControls';
import 'draft-js/dist/Draft.css';
import './RichTextEditor.scss';
import getDefaultKeyBinding from "draft-js/lib/getDefaultKeyBinding";

function getBlockStyle(block) {
  switch (block.getType()) {
    case 'blockquote':
      return 'RichEditor-blockquote';
    case 'atomic':
      return 'RichEditor-horizontalRule';
    default:
      return null;
  }
}

function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity();
    return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'LINK'
    );
  }, callback);
}

const Link = ({contentState, entityKey, children}) => {
  const {url} = contentState.getEntity(entityKey).getData();
  return (
      <a href={url} className="link" target="_blank" rel="noopener noreferrer">
        {children}
      </a>
  );
};

const editorStateDecorator = {
  strategy: findLinkEntities,
  component: Link,
};

const Decorated = ({ children }) => {
  return <span style={{ color: 'red' }}>{children}</span>;
};

export const getEditorStateFromHtml = html => {
  return EditorState.createWithContent(
      convertFromHTML({
        htmlToEntity: (nodeName, node, createEntity) => {
          if (nodeName === 'a') {
            return createEntity('LINK', 'MUTABLE', { url: node.href });
          }
        },
      })(html),
      new CompositeDecorator([editorStateDecorator])
  );
};

class RichTextEditor extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.addHorizontalRule = this.addHorizontalRule.bind(this);

    const highlightDecorator = {
      strategy: this.highlightStrategy,
      component: Decorated,
    };

    const decorators = new CompositeDecorator([
      editorStateDecorator,
      highlightDecorator,
    ]);

    this.state = {
      editorState: EditorState.createEmpty(decorators),
      showURLInput: false,
      urlValue: '',
      countDownvalue: '',
      characterLimitmsgs: '',
      isMacOS: false,
    };
  }


  componentDidMount() {
    this.setState({
      isMacOS: navigator.userAgent.indexOf("Mac OS X") !== -1,
    });
  }

  componentDidUpdate(prevProps, prevState) {
if (prevProps !== this.props || prevState.isMacOS !== this.state.isMacOS){
  this.setState({
    isMacOS: navigator.userAgent.indexOf("Mac OS X") !== -1,
  });
}
  }


  handleBeforeInput(e) {
    if (e.metaKey && e.which === 81) {
      e.preventDefault();
      this.handleKeyCommand('blockquote');
      return 'handled';
    }
    return 'not-handled';
  }

  keyBindingFn = e => {
    const mainKey = navigator.userAgent.indexOf("Mac OS X") !== -1 ? e.metaKey : e.ctrlKey;
    this.setState({lastKeyEvent: e});
    if (mainKey && e.altKey) {
      switch (e.keyCode) {
        case 49:
          return 'h1';// Ctrl + Alt + 1
        case 50:
          return 'h2';// Ctrl + Alt + 2
        case 51:
          return 'h3';// Ctrl + Alt + 3
        case 52:
          return 'h4';// Ctrl + Alt + 4
        case 53:
          return 'h5';// Ctrl + Alt + 5
        case 54:
          return 'h6';// Ctrl + Alt + 6
        case 55:
          return 'blockquote';  // Ctrl + Alt + 7
        case 56:
          return 'unordered-list';  // Ctrl + Alt + 8
        case 87:
          return 'toggle-underline';//Ctrl + Alt + W
        default:
          return getDefaultKeyBinding(e);
      }
    } else if (mainKey && e.shiftKey) {
      switch (e.keyCode) {
        case 75:
          return 'remove-link';  // Ctrl +Shift + K
        default:
          return getDefaultKeyBinding(e);
      }
    } else if (mainKey) {
      switch (e.keyCode) {
        case 75:
          return 'add-link';  // Ctrl + K
        case 81:
          return 'blockquote';  // Ctrl + Q
        // case 190:
        //   return 'unordered-list';  // Ctrl + .
        case 191:
          return 'ordered-list';   // Ctrl + /
        default:
          return getDefaultKeyBinding(e);
      }
    }
    return getDefaultKeyBinding(e);
  }


  static getDerivedStateFromProps(nextProps, prevState) {
    const { editorValue, value, hasInitialValue } = nextProps;
    const { editorState } = prevState;

    if (value) {
      return {
        editorState: value,
      };
    }

    let editorStateNew = editorState;
    if (hasInitialValue && !editorState.getCurrentContent().hasText()) {
      editorStateNew = getEditorStateFromHtml(editorValue);
    } else if (!editorValue && editorState.getCurrentContent().hasText()) {
      editorStateNew = EditorState.createEmpty(editorStateDecorator);
    }

    return {
      editorState: editorStateNew,
    };
  }

  highlightStrategy = (contentBlock, callback, contentState) => {
    const { maxLength } = this.props;
    if (maxLength) {
      callback(maxLength, contentState.getPlainText().trim().length);
    }
  };

  onChange = editorState => {
    const {onChange} = this.props;
    this.setState({
      editorState,
    });
    const content = editorState.getCurrentContent();
    const threadContent =
        content.getPlainText().trim() &&
        convertToHTML({
          blockToHTML: block => {
            if (block.type === 'atomic') {
              return {
                start: '<hr>',
                end: '</hr>',
              };
            }
            if (block.text === '') {
              return <p></p>;
            }
          },
          entityToHTML: (entity, originalText) => {
            if (entity.type === 'LINK') {
              return (
                  <a
                      href={entity.data.url}
                      target="_blank"
                      rel="noopener noreferrer"
                  >
                    {originalText}
                  </a>
              );
            }
            return originalText;
          },
        })(content);
    onChange(threadContent, editorState);
  };

  onTab = e => {
    const {editorState} = this.state;
    // sets tab spacing (i.e.: ul)
    const maxDepth = 4;
    this.onChange(RichUtils.onTab(e, editorState, maxDepth));
  };

  onURLChange = e => {
    this.setState({
      urlValue: e.target.value,
    });
  };

  onLinkInputKeyDown = e => {
    if (e.which === 13) {
      this.confirmLink(e);
    }
  };

  handleKeyCommand = (command) => {
    // allows ctl+b ctl+i like commands
    const {editorState} = this.state;
    const event = this.state.lastKeyEvent;
    let newState;

    switch (command) {
      case 'h1':
        newState = RichUtils.toggleBlockType(editorState, 'header-one');
        break;
      case 'h2':
        newState = RichUtils.toggleBlockType(editorState, 'header-two');
        break;
      case 'h3':
        newState = RichUtils.toggleBlockType(editorState, 'header-three');
        break;
      case 'h4':
        newState = RichUtils.toggleBlockType(editorState, 'header-four');
        break;
      case 'h5':
        newState = RichUtils.toggleBlockType(editorState, 'header-five');
        break;
      case 'h6':
        newState = RichUtils.toggleBlockType(editorState, 'header-six');
        break;
      case 'blockquote':
        newState = RichUtils.toggleBlockType(editorState, 'blockquote');
        break;
      case 'add-link':
        this.promptForLink(event);
        return true;
      case 'remove-link':
        this.removeLink(event);
        return true;
      case 'toggle-underline':
        this.addHorizontalRule(event)
        return true;
      case 'unordered-list':
        newState = RichUtils.toggleBlockType(editorState, 'unordered-list-item');
        break;
      case 'ordered-list':
        newState = RichUtils.toggleBlockType(editorState, 'ordered-list-item');
        break;
      default:
        newState = RichUtils.handleKeyCommand(editorState, command);
    }

    if (newState) {
      this.onChange(newState);
      return true;
    }
    return false;
  };

  // "BlockType" is the top line of tools on the editor,
  // block elements affect all text on that line of the editor
  toggleBlockType = blockType => {
    const {editorState} = this.state;
    this.onChange(RichUtils.toggleBlockType(editorState, blockType));
  };

  // "inline styles" (second line of the editor) styles will not affect the whole line of text,
  // just what is highlighted
  toggleInlineStyle = inlineStyle => {
    const {editorState} = this.state;
    this.onChange(RichUtils.toggleInlineStyle(editorState, inlineStyle));
  };

  promptForLink = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({urlValue: ''});
    const {editorState} = this.state;
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      const contentState = editorState.getCurrentContent();
      const startKey = editorState.getSelection().getStartKey();
      const startOffset = editorState.getSelection().getStartOffset();
      const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
      const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);
      const url = linkKey ? contentState.getEntity(linkKey).getData().url : '';

      this.setState(
          {
            showURLInput: true,
            urlValue: url,
          },
          () => {
            this.urlInput.focus();
          }
      );
    }
  };

  confirmLink = e => {
    e.preventDefault();
    const {editorState, urlValue} = this.state;
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
        'LINK',
        'MUTABLE',
        { url: urlValue }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });
    this.setState(
        {
          editorState: RichUtils.toggleLink(
              newEditorState,
              newEditorState.getSelection(),
              entityKey
          ),
          showURLInput: false,
          urlValue: '',
        },
        () => {
          this.editor.focus();
        }
    );
  };

  removeLink = e => {
    e.preventDefault();
    const {editorState} = this.state;
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      this.setState({
        editorState: RichUtils.toggleLink(editorState, selection, null),
      });
    }
  };

  blockRenderer(block) {
    const {editorState} = this.state;
    const contentState = editorState.getCurrentContent();
    let entity;
    let isHorizontalRule;
    let ret;
    switch (block.getType()) {
      case 'atomic':
        entity = contentState.getEntity(block.getEntityAt(0));
        isHorizontalRule = entity.type === 'HORIZONTAL_RULE';
        if (isHorizontalRule) {
          ret = {
            component: DividerBlock,
            editable: false,
            props: {},
          };
        }
        break;
      default:
        ret = null;
        break;
    }

    return ret;
  }

  addHorizontalRule = e => {
    e.preventDefault();
    const {editorState, onChange} = this.state;
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      'HORIZONTAL_RULE',
      'IMMUTABLE',
      {}
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = AtomicBlockUtils.insertAtomicBlock(
      editorState,
      entityKey,
      ' '
    );

    this.onChange(newEditorState);
  };

  render() {
    let { editorState, showURLInput, urlValue, isMacOS } = this.state;
    const { className, readOnly, textrichTextHtml, maxLength } = this.props;

    const linkClass = cx('RichEditor-styleButton', {
      'RichEditor-activeButton': showURLInput,
    });
    // If the user changes block type before entering any text, we can
    // either style the placeholder or hide it. Let's just hide it now.
    let editorClassName = 'RichEditor-editor';
    const contentState = editorState.getCurrentContent();
    let urlInput;
    if (showURLInput) {
      urlInput = (
          <div className="urlInputContainer">
            <input
                tabIndex={0}
                onChange={this.onURLChange}
                className="urlInput"
                type="text"
                value={urlValue}
                onKeyDown={this.onLinkInputKeyDown}
                placeholder="http://"
                ref={input => {
                  this.urlInput = input;
                }}
            />
            <button type="button" onMouseDown={this.confirmLink}>
              Add
            </button>
          </div>
      );
    }
    if (!contentState.hasText()) {
      if (
          contentState
              .getBlockMap()
              .first()
              .getType() !== 'unstyled'
      ) {
        editorClassName += ' RichEditor-hidePlaceholder';
      }
    }

    // const highlight = [[maxLength, contentState.getPlainText().trim().length]];
    // const decorator = createDecorator(contentState, highlight);
    // editorState = EditorState.set(editorState, {
    //   decorator: decorator
    // });

    return (
        <>
          <div className={cx(['RichEditor-root', 'RichTextEditor', className])}>
            <div
                className={cx('RichEditor-controls-container', {
                  readonly: readOnly,
                })}
            >
              <BlockStyleControls
                  className="RichEditor-controls"
                  editorState={editorState}
                  onToggle={this.toggleBlockType}
                  isMacOS={isMacOS}
              />
              <InlineStyleControls
                  readOnly={readOnly}
                  editorState={editorState}
                  onToggle={this.toggleInlineStyle}
                  isMacOS={isMacOS}
              />
              <div className="RichEditor-controls-inline">
              <span
                  className={`fa fa-link ${linkClass}`}
                  role="img"
                  tabIndex={0}
                  aria-label={`Add a link from the text by selecting it and pressing the ${isMacOS ? 'Command' : 'Сtrl'} + K key combination`}
                  onClick={this.promptForLink}
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      this.promptForLink(e)
                    }
                  }}
              />
                <span
                    className="fa fa-chain-broken RichEditor-styleButton"
                    role="button"
                    tabIndex="0"
                    onClick={this.removeLink}
                    onKeyDown={(e)=>{
                  if(e.key==='Enter'){
                        this.removeLink(e)
                    }
                }}
                    aria-label={`Remove a link from the text by selecting it and pressing the ${isMacOS ? 'Command + Option' : 'Сtrl + Alt'} + K key combination`}
                />
                <AddDividerBlock
                    editorState={editorState}
                    onChange={this.onChange}
                    isMacOS={isMacOS}
                    onAddHorizontalRule={this.addHorizontalRule}/>
              </div>
              {urlInput}
            </div>
            <div className={editorClassName}>
              <Editor
                  keyBindingFn={this.keyBindingFn}
                  onBeforeInput={this.handleBeforeInput}
                  readOnly={readOnly}
                  id="rich-editor"
                  blockStyleFn={getBlockStyle}
                  blockRenderFn={this.blockRenderer}
                  editorState={editorState}
                  handleKeyCommand={this.handleKeyCommand}
                  onChange={this.onChange}
                  onTab={this.onTab}
                  ref={input => {
                    this.editor = input;
                  }}
              />
            </div>
          </div>
          {/* {textrichTextHtml.length > maxLength &&
          <p style={{ color: 'red', margin: '0px 8px 12px 2px', marginTop: '-9px' }}> Your character limit is over.</p>
        } */}
        </>
    );
  }
}

export default RichTextEditor;
