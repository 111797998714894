import Request from "app/components/common/network/Request";
import { GOOGLE_SSO_SIGNIN_ENDPOINT_URL, JOIN_PAGE_SIGN_UP_OPTION_ENDPOINT_URL } from "app/services/registration/registration";
import React, { Fragment, useRef, useState } from "react";
import { Spinner } from 'app/components/spinner/index';
import { Field } from 'redux-form';
import styles from './JoinStep2.style';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import { API } from "app/api";
import JoinInviteByEmailStep1 from 'app/pages/registration/JoinInviteByEmailStep1';
import { triggerGoogleSignUp } from "app/modules/Login";
import { useDispatch } from "react-redux";
import classnames from 'classnames';
import BaseModal from "app/components/modal-redesign";

const JoinInviteByDistrictAdmin = (props) => {
    
    const { params } = props;
    const [selectedSignUpOption, setSelectedSignUpOption] = useState("");
    const [scope, setScope ] = useState("");
    const [showStep2, setShowStep2] = useState(false);
    const [errordata, setErrorData] = useState(undefined);
    const dispatch = useDispatch();

    // const processGoogleSuccessResponse = googleTokenData => {  
    //     this.clearCurrentErrors();
    //     /* Process the token and get back information about this user, etc. */
    //     const googleSSOResult = API.post(GOOGLE_SSO_SIGNIN_ENDPOINT_URL, {
    //       authenticationCode: googleTokenData.code
    //     }).then(response => {
    
    //         const res = response.data;
    //         if (res.apiError == false) {
    //           const googleProfileResult = {
    //             googleProfileId: res.googleProfileId,
    //             googleProfileEmail: res.googleProfileInfo.email,
    //             googleProfileGivenName: res.googleProfileInfo.givenName,
    //             googleProfileFamilyName: res.googleProfileInfo.familyName,
    //             googleProfilePictureURL: res.googleProfileInfo.profilePictureURL,
    //           };
    
    //           window.localStorage.setItem(
    //             'googleProfileId',
    //             googleProfileResult.googleProfileId
    //           );

    //           window.localStorage.setItem(
    //             'googleProfileData',
    //             googleProfileResult
    //           )
    //         }
    //     }).catch(err => {
    //         throw ('Error: ', err);
    //     });
    // };

    // const googleSignUp = useGoogleLogin({
    //     scope: scope,
    //     onSuccess: codeResponse => processGoogleSuccessResponse(codeResponse),
    //     flow: 'auth-code',
    //     onError: error => console.log(tokenResponse),
    // });


    const handleSubmit = () => {
        if(selectedSignUpOption === "googleclassroom" ){
            dispatch(triggerGoogleSignUp())
        }
        else{
            setShowStep2(true);
        }
    }

    const handleShowStep2 = (data) => {
        if(data?.error){
            setErrorData(data)
        }
        else
            setShowStep2(true);
    }

    const handleJoinPageServiceResponse = result => {
        setScope(result.googleClientScope);
    }

    return(
        <div>
            {!showStep2 && (
                <Request
                    serviceURL={JOIN_PAGE_SIGN_UP_OPTION_ENDPOINT_URL}
                    requestBody={{ callSource: 'joinByInvitationEmail', invitationCodeHash: params.invitationCodeHash, invitationCreationEpoch: params.invitationCreationEpoch }}
                    serviceResponseHandler={handleJoinPageServiceResponse}
                    render={({ fetchingContent, serviceResponse: joinPageRes }) => (
                        <Fragment>
                            <Spinner
                                loading={fetchingContent}
                                text="Please wait..."
                            />
                            {!fetchingContent && (
                                <Fragment>
                                    <div className="step-root">
                                        <div className="inner-container">
                                            <div className="header-container">
                                                <img className="logo" src={joinPageRes.sloohIconURL} />
                                                <h6 className="header-invite-subheading">{joinPageRes?.pageHeading}</h6>
                                            </div>
                                            <GoogleOAuthProvider clientId={joinPageRes.googleClientID}>
                                                <form>
                                                    <div className="form-section">
                                                        <div className="form-field-container">
                                                            {joinPageRes?.signupOptionsList.map(item=>(
                                                                <div className="form-field-radio-container">
                                                                    <input id={item.key} onChange={()=>setSelectedSignUpOption(item.key)} name="signup-option" className="form-radio-field" type="radio" />
                                                                    <div>
                                                                        <h5 className="sign-up-title">{item.title}</h5>
                                                                        <h6 className="sign-up-subtitle">{item.desc}</h6>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    <div className="button-container">
                                                        <button onClick={handleSubmit} className="submit-button" disabled={!selectedSignUpOption} type="button">
                                                            Continue
                                                        </button>
                                                    </div>
                                                </form>
                                            </GoogleOAuthProvider>
                                        </div>
                                    </div>
                                </Fragment>
                            )}
                        </Fragment>
                    )}
                />
            )}
            <style jsx>{styles}</style>
            <div className={classnames({show: showStep2, hide: !showStep2})}>
                <JoinInviteByEmailStep1
                    params={params}
                    handleShowStep2={handleShowStep2}
                    showStep2={showStep2}
                /> 
            </div>
            
            <BaseModal
                shouldCloseOnOverlayClick={false}
                isOpen={errordata?.error}
                handleCloseModal={()=>setErrorData(undefined)}
                className="base-modal"
            >
                <div className="error-container">
                    <img src="https://vega.slooh.com/assets/v4/site-redesign/icons/email_with_bg_circle_red.svg" />
                    <div>
                        <h5 className="error-title">That email address doesn’t match</h5>
                        <p className="error-subtitle">To connect with Google Classroom, your Google email address must match the email address from your Slooh invitation. To update your Slooh invitation email address, contact the person who created the invitation. </p>
                    </div>
                </div>
            </BaseModal>
        </div>
    )
}

export default JoinInviteByDistrictAdmin;