import React, { Fragment, useState } from 'react';
import Request from 'app/components/common/network/Request';
import { JOIN_PAGE_SIGN_UP_OPTION_ENDPOINT_URL } from 'app/services/registration/registration';
import { Spinner } from 'app/components/spinner/index';
import styles from './GroupCreateOptions.style';

export const GroupCreateOptions = props => {
  const [selectedOption, setSelectedOption] = useState('');

  const handleJoinPageServiceResponse = (response) => {
    const selected = response?.signupOptionsList?.filter(item => item.selected);
    if(selected?.length > 0)
      setSelectedOption(selected[0].key);
  }

  return (
    <div className="group-create-option-container">
      <Request
        serviceURL={JOIN_PAGE_SIGN_UP_OPTION_ENDPOINT_URL}
        requestBody={{ 
          callSource: props.callSource || 'createWorkspace',
          loginEmailAddress: props.loginEmailAddress,
          lmsType: props.type
        }}
        serviceResponseHandler={handleJoinPageServiceResponse}
        render={({ fetchingContent, serviceResponse: joinPageRes }) => (
          <Fragment>
            <Spinner loading={fetchingContent} text="Please wait..." />
            {!fetchingContent && (
              <div>
                <h2 className="group-create-option-heading">
                  {joinPageRes.pageHeading}
                </h2>
                <h6 className="group-create-option-subheading">
                  {joinPageRes.pageSubHeading}
                </h6>
                {joinPageRes?.workspaceIntroArray?.linkURL && (
                  <div
                    className="group-create-option-video-link-container"
                    onClick={() =>
                      window.open(
                        joinPageRes?.workspaceIntroArray?.linkURL,
                        '_blank'
                      )
                    }
                  >
                    <img
                      className="group-create-option-video-link-icon"
                      src={joinPageRes.workspaceIntroArray.videoIconURL}
                    />
                    <span className="group-create-option-video-link-text">
                      {joinPageRes.workspaceIntroArray.videoLabel}
                    </span>
                  </div>
                )}
                <form>
                  <div className="form-section">
                    <div className="form-field-container">
                      {joinPageRes?.signupOptionsList.map(item => (
                        <div className="form-field-radio-container">
                          <input
                            id={item.key}
                            onClick={() => setSelectedOption(item.key)}
                            name="signup-option"
                            className="form-radio-field"
                            type="radio"
                            checked={selectedOption === item.key}
                          />
                          <div>
                            <h5 className="sign-up-title">{item.title}</h5>
                            <h6 className="sign-up-subtitle">{item.desc}</h6>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="button-container">
                    <button
                      className="submit-button"
                      onClick={() =>
                        props.handleCreateWorkspace(selectedOption, joinPageRes)
                      }
                      disabled={!selectedOption}
                      type="button"
                    >
                      {'Next   >'}
                    </button>
                  </div>
                </form>
              </div>
            )}
          </Fragment>
        )}
      />
      <style jsx>{styles}</style>
    </div>
  );
};
