// @flow

import { SubscriptionPlan } from 'app/modules/account-settings/components/upgrade-modal/subscription-plan/index';
import React from 'react';
import Btn from 'app/atoms/Btn';
import SubscriptionPlanCardSmall from 'app/pages/registration/partials/SubscriptionPlanCardSmall';

type TSelectPlanStep = {
  goNext: Function,
  setSelectedPlan?: Function,
  subscriptionPlansData: any,
  selectedPlan?: Shape,
  user?: Shape,
};

export const SelectPlanStep = (props: TSelectPlanStep) => {
  const {
    subscriptionPlansCallSource,
    subscriptionPlansData,
    selectedPlan,
    goNext,
    setSelectedPlan,
  } = props;

  const {
    subscriptionPlans = [],
    pageHeading1,
    pageHeading2,
    textBlock1,
    staticDescriptionCard,
  } = subscriptionPlansData;

  const onSelect = plan => {
    goNext(subscriptionPlansCallSource, plan);
    setSelectedPlan(plan);
  };

  return (
    <>
      <h1 className="modal-h text-center" dangerouslySetInnerHTML={{ __html: pageHeading1 }}/>
      <h2 style={{color: '#648282'}} className="modal-h2 text-center" dangerouslySetInnerHTML={{ __html: pageHeading2 }}/>
      {textBlock1 && <div><p style={{paddingBottom: '5px'}}className="modal-p mb- text-center" dangerouslySetInnerHTML={{ __html: textBlock1}}/></div>}
      <div style={{display: 'flex', justifyContent: "space-evenly", flexWrap: "wrap"}}>
        {staticDescriptionCard && (
          <div className="modal-left-img-container">
            <img className="modal-online-telescope-image" src={staticDescriptionCard?.checkoutFlowPlanHeadingGraphicUrl} />  
            <div className="modal-online-text" dangerouslySetInnerHTML={{ __html: staticDescriptionCard?.instructionalTextBlock}}/>
          </div>
        )}        
        {subscriptionPlans.map(plan => (
          // <SubscriptionPlan
          //   plan={plan}
          //   expanded={false}
          //   onSelect={onSelect}
          // />
          <SubscriptionPlanCardSmall
            {...plan}
            expanded={false}
            setSelectedPlan={()=>onSelect(plan)}
            showInfo={false}
          />
        ))}
      </div>
    </>
  );
};
