import css from 'styled-jsx/css';

export default css`
  .fa {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 10;
  }
`;
