import { veritySloohId } from "app/utils/slooh-pageview-wrapper";
import React, { Component } from "react";
import { Modal } from 'app/components/modal';
import { Spinner } from 'app/components/spinner/index';
import { fireSloohMarketingTrackingStartEvent } from "app/utils/slooh-marketing-wrapper";
import { connect } from "react-redux";
import { guestDashboardUrl } from "app/config/project-config";
import { getUserInfo } from "app/modules/User";

const mapStateToProps = ({ user }) => ({
   user
  });

@connect(
    mapStateToProps,
    null
)
class MarketingTracking extends Component{

    constructor(props){
        super(props);
        const { router, user, params } = this.props;        
        if (!user.isAuthorized) {
            if (params.marketingTrackingId) {
                fireSloohMarketingTrackingStartEvent(params.marketingTrackingId)
                setTimeout(veritySloohId, 1000);
            }
            else{
                window.location.href=guestDashboardUrl;
            }
        }
        else{
            router.push('/NewDashboard');
        }        
    }


    render(){

        const { loadingText } = getUserInfo();
        return(
            <div>
                <Spinner loading={true} text={loadingText} />                   
            </div>
        );
    }

}

export default MarketingTracking;