import React from "react";
import { golden, gray600 } from "app/styles/variables/colors_tiles_v4";
import { primaryFont } from "app/styles/variables/fonts";
import styled from "styled-components";

const InstructionItemContainer = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    margin-bottom: 12px;
`;

const NumberingElement = styled.h5`
    display: flex;
    width: 24px;
    height: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    border: 1px solid ${golden};
    color: ${golden};
    text-align: center;
    font-family: ${primaryFont};
    font-size: 16px;
    font-style: normal;
    font-weight: 390;
    line-height: 160%;
    letter-spacing: 0.15px;
    margin-bottom: 0px;
`;

const InstructionElement = styled.h6`
    color: ${gray600};
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: ${primaryFont};
    font-size: 16px;
    font-style: normal;
    font-weight: 390;
    line-height: normal;
    margin-bottom: 0px;
    flex: 1;
`;

interface Props {
    instruction: string;
    number: number;
}

export const InstructionItem = (props: Props) => {
    const { instruction, number } = props;

    return (
        <InstructionItemContainer>
            <NumberingElement>{number}</NumberingElement>
            <InstructionElement>{instruction}</InstructionElement>
        </InstructionItemContainer>
    )
}