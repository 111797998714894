import { AnyAction } from 'redux';
import { handleActions } from 'redux-actions';
import { actions, constants } from 'ducks-helpers';
import { IQuestMediaResponse } from 'app/modules/my-quest-media/types';

export interface QuestMediaState {
  questList: IQuestMediaResponse;
  isFetching: boolean;
  serverError: string | null;
}

export const TYPE = constants('questMedia', ['~GET_QUEST_MEDIA_LIST']);

export const ACTION = actions(TYPE);

export const initialState: QuestMediaState = {
  questList: {} as IQuestMediaResponse,
  isFetching: false,
  serverError: null,
};

const setFetching = (state: QuestMediaState) => ({
  ...state,
  isFetching: true,
});

const setServerError = (state: QuestMediaState, action: AnyAction) => ({
  ...state,
  isFetching: false,
  serverError: action.payload,
});

const getQuestMediaListSuccess = (
  state: QuestMediaState,
  action: AnyAction
) => ({
  ...state,
  isFetching: false,
  questList: action.payload,
});

export default handleActions<QuestMediaState>(
  {
    [TYPE.GET_QUEST_MEDIA_LIST]: setFetching,
    [TYPE.GET_QUEST_MEDIA_LIST_SUCCESS]: getQuestMediaListSuccess,
    [TYPE.GET_QUEST_MEDIA_LIST_ERROR]: setServerError,
  },
  initialState
);
