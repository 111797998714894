import React, {useState} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  seashell,
  shadows,
  gray200,
  gray600,
  gray300,
} from 'app/styles/variables/colors_tiles_v4';
import { primaryFont, secondaryFont } from 'app/styles/variables/fonts';
const { shape, string } = PropTypes;

const renderField = ({
  input,
  label,
  type,
  className,
  id,
  disabled,
  meta: { touched, error },
  currentValue,
  editable,
  placeholder,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <label
      className={classnames(className ? `${className} root` : 'root', {
        error: touched && error,
      })}
    >
      <input id={id} readOnly={editable} className="field-input" type={showPassword ? 'text' : type} disabled={disabled} {...input} placeholder={placeholder} value={currentValue} />
      {type === "password" && (
        <span className="show-pwd" onClick={togglePasswordVisibility}>{showPassword ? 'Hide' : 'Show'}</span>
      )}
      {touched && error && <span className="error-text">{error}</span>}
      <style jsx>{`
        .root {
          display: block;
        }

        .error{
          color: red;
        }

        .field-input {
          background: #FFFFFF;
          border: 1px solid #CBCBCB;
          border-radius: 4px;
          display: block;
          width: 100%;
          padding: 9px 12px;
          padding-right: 50px;
          font-size: 16px;
          font-family: ${primaryFont};
          font-weight: 420;
          line-height: 22px;
          color: black;
          background-clip: padding-box;
          margin-bottom: 0px;
        }

        .field-input:disabled, .field-input[disabled]{
          background-color:  ${gray300}
        }

        .show-pwd{
          color: ${gray600};
          text-align: right;
          font-family: ${primaryFont};
          font-size: 16px;
          font-style: normal;
          font-weight: 390;
          line-height: 22px;
          text-decoration-line: underline;
          cursor: pointer;
          float: right;
          margin-right: 12px;
          transform: translateY(-32px);
        }

        .error-text {
          display: block;
          margin: 5px 0 10px 0;
          color: red;
          font-size: 11px;
          text-align: right;
        }
      `}</style>
    </label>
  )
};

renderField.propTypes = {
  input: shape({}).isRequired,
  label: string.isRequired,
  type: string.isRequired,
  meta: shape({}).isRequired,
};

export default renderField;
