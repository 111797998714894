import React, { FC, useEffect, useMemo, useRef } from 'react';
import { browserHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import flatten from 'lodash/flatten';

import { makeUserSelector } from 'app/modules/user/selectors';
import { selectObservatoryList } from 'app/modules/observatory-list/observatory-selectors';
import { getObservatoryStatusLockedAction } from 'app/modules/new-dashboard/actions';
import TELESCOPE_CONFIGURATION from 'app/components/GlobalNavigation/Menus/telescopeConfiguration';
import TelescopeButton from 'app/components/GlobalNavigation/Menus/partials/buttons/TelescopeButton/TelescopeButton';
import MenuHeader from 'app/components/GlobalNavigation/Menus/menuHeader/MenuHeader';

import './styles.scss';
import PageButton from 'app/components/page-button';

const TelescopesRedesign: FC = () => {
  const dispatch = useDispatch();
  const user = useSelector(makeUserSelector());
  const observatoryObj = useSelector(selectObservatoryList);
  const { observatoryList = [], showLocked } = observatoryObj || {};

  const pageButtonRef = useRef(null);

  useEffect(() => {
    if (pageButtonRef?.current) {
      pageButtonRef.current.focus();
    }
  }, []);

  const handlePadLockButtonClick = (linkUrl: string) => {
    if (showLocked) {
      dispatch(getObservatoryStatusLockedAction());
    } else {
      browserHistory.push(linkUrl);
    }
  };

  const telescopes = useMemo(() => {
    if (!observatoryList) return [];

    let obsList = observatoryList;
    obsList = obsList.sort((a: { obsIndex: number }, b: { obsIndex: number }) =>
      a.obsIndex > b.obsIndex ? 1 : -1
    );
    const telescopesByObservatory = obsList.map(
      (_observatory: { obsTelescopes: [] }) => _observatory.obsTelescopes
    );
    const filteredTelescopes = flatten(telescopesByObservatory);

    return TELESCOPE_CONFIGURATION(
      filteredTelescopes,
      user,
      showLocked,
      () => {}
    ).content;
  }, [observatoryList, user, showLocked]);

  return (
    <aside className="telescopes-redesign">
      <MenuHeader
        title="Telescopes"
        button={
          <PageButton
            ref={pageButtonRef}
            className="filled-blue-button"
            title="Schedule Mission"
            link="/missions/bySlooh1000"
            role="button"
            tabIndex={0}
          />
        }
      />

      <nav className="telescopes-list">
        {!!telescopes &&
          telescopes.map((item: any) => (
            <TelescopeButton
              key={item._ID}
              text={item.text}
              isOnline={item.isOnline}
              showEnhancedStatusMessage={item.showEnhancedStatusMessage}
              enhancedStatusMessageTelescopeMenu={
                item.enhancedStatusMessageTelescopeMenu
              }
              logoURL={item.logoURL}
              handleClickOnItem={() => handlePadLockButtonClick(item.anchor)}
              onKeyDown={e => {
                if (e.key === 'Enter' || e.key === ' ') {
                  handlePadLockButtonClick(item.anchor);
                }
              }}
              tabIndex={0}
              role="button"
            />
          ))}
      </nav>
    </aside>
  );
};

export default TelescopesRedesign;
