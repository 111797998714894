import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import noop from 'lodash/noop';
import { withTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import RequestGroupForm from 'app/components/community-groups/request-group-form';
import PromptWithClose from 'app/components/community-groups/prompt-with-close';
import RequestGroupFormFeedback from 'app/components/community-groups/request-group-form-feedback';
import CenterColumn from 'app/components/common/CenterColumn';
import { validateResponseAccess } from 'app/modules/authorization/actions';
import { requestGroup } from 'app/services/community-groups/request-group';
import { browserHistory } from 'react-router';

import { API } from 'app/api';
import Request from 'app/components/common/network/Request';
import {
  CLASSROOM_CREATENEWGROUP_PAGE_ENDPOINT_URL,
  CLASSROOM_CREATENEWGROUP_ENDPOINT_URL,
  GOOGLE_CLASSROOM_IMPORT_CLASSROOMS_ENDPOINT_URL,
  LMS_LTI_CREATE_WORKSPACE_URL,
} from 'app/services/classroom/classroom';

import { Field, reduxForm } from 'redux-form';
import InputField from 'app/components/form/InputFieldNew';
import { Spinner } from 'app/components/spinner/index';
import { getUserInfo } from 'app/modules/User';
import style3 from './GroupCreateNew.style';
import { InstructionItem } from 'app/components/instruction-item';

const COUNT = 9;
const DEFAULT_PAGE = 1;

const groupsHubModel = {
  name: 'GROUP_HUB_MODEL',
  model: resp => ({
    filterOptions: resp.navigationConfig,
    sortOptions: resp.filterOptions.options,
  }),
};
@withTranslation()
class GroupCreate extends Component {
  static propTypes = {
    validateResponseAccess: PropTypes.func,
    params: PropTypes.shape({
      filterType: PropTypes.string,
    }),
    isCreateMode: PropTypes.bool,
  };

  static defaultProps = {
    validateResponseAccess: noop,
    params: {
      filterType: 'owner',
    },
    isCreateMode: true,
  };

  state = {
    groups: [],
    showPrompt: false,
    promptText: '',
    createClubLinkUrl: '',
    loading: false,
    isAstronomyClub: false,
    courseId: '',
    newGroupFormDetails: {
      schoolName: {
        label: '',
        value: '',
        hintText: '',
        errorText: '',
        list: [],
        disabled: false,
      },
      schoolYear: {
        label: '',
        value: '',
        hintText: '',
        errorText: '',
        list: [],
        disabled: false,
      },
      groupName: {
        label: '',
        value: '',
        hintText: '',
        errorText: '',
        disabled: false,
      },
      groupDescription: {
        label: '',
        value: '',
        hintText: '',
        errorText: '',
        disabled: false,
      },
    },
  };

  /* Save the Create Club Link Url */
  handlePageServiceResponse = result => {
    this.setState(() => ({
      createClubLinkUrl: result.createNewClubLinkUrl,
    }));
  };

  updateGroupsList = resData => {
    this.setState(() => ({
      groups: resData.groups,
    }));
  };

  updateGroupItemInfo = (id, resData) => {
    let newGroupsList = [].concat(this.state.groups);
    newGroupsList = newGroupsList.map(group => {
      if (group.discussionGroupId === id) {
        return Object.assign(group, resData);
      }
      return group;
    });

    this.setState(() => ({
      groups: newGroupsList,
    }));
  };

  appendToGroupsList = resData => {
    this.setState(state => {
      const groups = [].concat(state.groups, resData.groups);
      return {
        groups,
      };
    });
  };

  createClub = () => {
    browserHistory.push(this.state.createClubLinkUrl);
  };

  submitRequestForm = ({
    requestFormTitle,
    requestFormText,
    requestFormPrivacy,
  }) => {
    const { actions, user, t } = this.props;
    requestGroup({
      at: user.at,
      token: user.token,
      cid: user.cid,
      title: requestFormTitle,
      access: requestFormPrivacy,
      definition: requestFormText,
    }).then(res => {
      if (!res.data.apiError) {
        this.setState({
          showPrompt: res.data.showResponse,
          promptText: (
            <RequestGroupFormFeedback
              promptText={res.data.response}
              closeForm={this.closeModal}
              requestNew={this.requestGroup}
            />
          ),
        });
      } else {
        this.setState({
          showPrompt: true,
          promptText: (
            <RequestGroupFormFeedback
              promptText={t('Alerts.errorSubmitting')}
              closeForm={this.closeModal}
              requestNew={this.requestGroup}
            />
          ),
        });
      }
      actions.validateResponseAccess(res);
    });
  };

  requestGroup = () => {
    this.setState({
      showPrompt: true,
      promptText: (
        <RequestGroupForm
          submitForm={this.submitRequestForm}
          closeForm={this.closeModal}
        />
      ),
    });
  };

  updatePrompt = data => {
    this.setState({
      showPrompt: data.showPrompt,
      promptText: (
        <PromptWithClose
          promptText={data.promptText}
          closeForm={this.closeModal}
        />
      ),
    });
  };

  closeModal = () => {
    this.setState({
      showPrompt: false,
      promptText: '',
    });
  };

  // Obtain access to the create new group api service response and update the newGroupFormDetails state to reflect the Page response (set form labels)
  handleCreateNewGroupPageServiceResponse = result => {
    const newGroupFormData = cloneDeep(this.state.newGroupFormDetails);
    if (result.isAstronomyClub) {
      newGroupFormData.schoolName.label =
        result.formFieldLabels?.schoolname?.label;
      newGroupFormData.schoolName.disabled =
        result.formFieldLabels?.schoolname?.disabled;
      newGroupFormData.schoolName.list = result.formFieldLabels.schoolname.list;
      newGroupFormData.schoolName.value = result.formFieldLabels.schoolname.key;
      newGroupFormData.schoolYear.label =
        result.formFieldLabels.schoolyear.label;
      newGroupFormData.schoolYear.list = result.formFieldLabels.schoolyear.list;
      newGroupFormData.schoolYear.value = result.formFieldLabels.schoolyear.key;
    }

    newGroupFormData.groupName.label = result.formFieldLabels.groupname.label;
    newGroupFormData.groupName.disabled =
        result.formFieldLabels?.groupname?.disabled;
    newGroupFormData.groupDescription.label =
      result.formFieldLabels.groupdescription.label;
    newGroupFormData.groupName.value =
      result?.formFieldLabels?.groupname?.key ||this.props?.selectedClassroom?.name || '';
    newGroupFormData.groupName.hintText =
      result.formFieldLabels.groupname.hintText;
    newGroupFormData.groupDescription.hintText =
      result.formFieldLabels.groupdescription.hintText;

    /* update the new group form details state so the correct hinText will show on each form field */
    this.setState(() => ({
      isAstronomyClub: result.isAstronomyClub,
      newGroupFormDetails: newGroupFormData,
      courseId: result.courseId,
    }));
  };

  /* This function handles a field change in the form and sets the state accordingly */
  handleFieldChange = ({ field, value }) => {
    /* Get the existing state of the signup form, modify it and re-set the state */
    const newGroupFormData = cloneDeep(this.state.newGroupFormDetails);
    newGroupFormData[field].value = value;

    this.setState(() => ({
      newGroupFormDetails: newGroupFormData,
    }));
  };

  /* Submit the New Group Form and perform any validations as needed */
  handleSubmit = formValues => {
    formValues.preventDefault();

    //assume the form is ready to submit unless validation issues occur.
    let formIsComplete = true;
    const { newGroupFormDetails, isAstronomyClub, courseId } = this.state;

    const { user } = this.props;

    const newGroupFormDetailsData = cloneDeep(newGroupFormDetails);

    /* reset the error conditions */
    newGroupFormDetailsData.groupName.errorText = '';
    newGroupFormDetailsData.groupDescription.errorText = '';

    if (newGroupFormDetails.groupName.value === '') {
      newGroupFormDetailsData.groupName.errorText =
        'Please enter in a name for your classroom.';
      formIsComplete = false;
    }

    if (newGroupFormDetails.groupDescription.value === '') {
      newGroupFormDetailsData.groupDescription.errorText =
        'Please enter in a message or description for your classroom.';
      formIsComplete = false;
    }

    if (isAstronomyClub) {
      if (newGroupFormDetails.schoolName.value === '') {
        newGroupFormDetailsData.schoolName.errorText =
          'Please Select the School or Organisation.';
        formIsComplete = false;
      }
      if (newGroupFormDetails.schoolYear.value === '') {
        newGroupFormDetailsData.schoolYear.errorText =
          'Please Select the School Year.';
        formIsComplete = false;
      }
    }

    this.setState(() => ({
      newGroupFormDetails: newGroupFormDetailsData,
    }));

    if (formIsComplete) {
      if(this.props.isLTIAccount){
        const data = {
          [courseId]: {
            desc: newGroupFormDetailsData.groupDescription.value,
            sectionTitle: newGroupFormDetailsData.groupName.value,
          }
        };
        this.props.handleLTICreateWorkspace("showStudents", data);
        return;
      }
      this.setState({ loading: true });
      if (this.props?.selectedClassroom) {
        const user = getUserInfo();
        const importGoogleClassroomsResult = API.post(
          GOOGLE_CLASSROOM_IMPORT_CLASSROOMS_ENDPOINT_URL,
          {
            googleClassrooms: {
              [this.props.selectedClassroom.googleClassroomId]: {
                ...(this.state.isAstronomyClub
                  ? {
                      uniqueSchoolId: this.state.newGroupFormDetails.schoolName
                        .value,
                      schoolYearId: this.state.newGroupFormDetails.schoolYear
                        .value,
                    }
                  : {}),
                googleClassroomName: this.props.selectedClassroom.name,
                googleClassroomDescription: this.state.newGroupFormDetails
                  .groupDescription.value,
                googleClassroomId: this.props.selectedClassroom
                  .googleClassroomId,
                googleClassroomSelected: true,
              },
            },
            cid: user.cid,
            at: user.at,
            token: user.token,
          }
        )
          .then(response => {
            const res = response.data;
            if (res.apiError == false) {
              const importResult = {
                status: res.status,
                statusMessage: res.statusMessage,
                discussionGroupLinkUrl: res.discussionGroupLinkUrl,
              };

              if (importResult.status === 'success') {
                //force reload the import google classes list....
                browserHistory.push(`${importResult.discussionGroupLinkUrl}`);
              } else {
                //display an error message on the screen....
              }
            }
          })
          .catch(err => {
            throw ('Error: ', err);
          });
      } else {
        const createNewGroupResults = API.post(
          CLASSROOM_CREATENEWGROUP_ENDPOINT_URL,
          {
            ...(this.state.isAstronomyClub
              ? {
                  uniqueSchoolId: this.state.newGroupFormDetails.schoolName
                    .value,
                  schoolYearId: this.state.newGroupFormDetails.schoolYear.value,
                }
              : {}),
            groupName: this.state.newGroupFormDetails.groupName.value,
            groupDescription: this.state.newGroupFormDetails.groupDescription
              .value,
            cid: user.cid,
            at: user.at,
            token: user.token,
          }
        )
          .then(response => {
            const res = response.data;
            this.setState({ loading: false });
            if (res.apiError == false) {
              const createGroupResult = {
                status: res.status,
                discussionGroupLinkUrl: res.discussionGroupLinkUrl,
              };

              /* need to force evaulation of "true"/"false" vs. true/false. */
              /* `${createGroupResult.discussionGroupLinkUrl}/edit=:edit` */
              if (createGroupResult.status === 'success') {
                browserHistory.push(
                  `${createGroupResult.discussionGroupLinkUrl}`
                );
              } else {
                //error occured
              }
            }
          })
          .catch(err => {
            this.setState({ loading: false });
            throw ('Error: ', err);
          });
      }

      //status = success
      //discussionGroupLinkUrl - the url to redirect the user to.
    }
  };

  render() {
    const { user, actions, isLTIAccount, LTIParams } = this.props;
    const {
      groups,
      showPrompt,
      promptText,
      newGroupFormDetails,
      loading,
      isAstronomyClub,
    } = this.state;

    return (
      <div className="create-workspace-container">
        <Request
          serviceURL={isLTIAccount ? LMS_LTI_CREATE_WORKSPACE_URL : CLASSROOM_CREATENEWGROUP_PAGE_ENDPOINT_URL}
          requestBody={{
            activeGoogleClassroomName: this.props?.selectedClassroom?.name,
            ...(isLTIAccount ? LTIParams : {})
          }}
          serviceResponseHandler={this.handleCreateNewGroupPageServiceResponse}
          render={({ fetchingContent, serviceResponse: createNewGroupRes }) => (
            <Fragment>
              <Spinner loading={fetchingContent || loading} />
              {!fetchingContent && (
                <Fragment>
                  <div className="inner-header-container">
                    <h2 className="heading">{createNewGroupRes.pageHeading}</h2>
                    {!this.props?.selectedClassroom && (
                      <>
                        <h6 className="subheading">
                          {createNewGroupRes.pageSubHeading}
                        </h6>
                        {createNewGroupRes?.workspaceIntroArray?.videoIconURL && (
                          <div
                            className="video-link-container"
                            onClick={() =>
                              window.open(
                                createNewGroupRes.workspaceIntroArray.linkURL,
                                '_blank'
                              )
                            }
                          >
                            <img
                              className="video-link-icon"
                              src={
                                createNewGroupRes.workspaceIntroArray.videoIconURL
                              }
                            />
                            <span className="video-link-text">
                              {createNewGroupRes.workspaceIntroArray.videoLabel}
                            </span>
                          </div>
                        )}
                        
                      </>
                    )}
                  </div>
                  {createNewGroupRes?.showCloseButton ? (
                    <div className="ins-container">
                      {createNewGroupRes.instructionList.map((instruction, index) => (
                        <InstructionItem  instruction={instruction} number={index + 1} />
                      ))}
                    </div>
                  ):(
                    <form onSubmit={this.handleSubmit}>
                      <div className="form-section">
                        {isAstronomyClub && (
                          <div className="form-field-container">
                            <span
                              className="form-label"
                              dangerouslySetInnerHTML={{
                                __html: newGroupFormDetails.schoolName.label,
                              }}
                            />
                            :
                            <span
                              className="form-error"
                              dangerouslySetInnerHTML={{
                                __html: newGroupFormDetails.schoolName.errorText,
                              }}
                            />
                            <Field
                              name="schoolName"
                              className="form-dropdown"
                              label={newGroupFormDetails.schoolName.hintText}
                              component="select"
                              onChange={event => {
                                this.handleFieldChange({
                                  field: 'schoolName',
                                  value: event.target.value,
                                });
                              }}
                              disabled={newGroupFormDetails.schoolName.disabled}
                            >
                              {newGroupFormDetails.schoolName.list.map(item => (
                                <option value={item.key} key={item.key}>
                                  {item.value}
                                </option>
                              ))}
                            </Field>
                          </div>
                        )}

                        <div className="form-field-container">
                          <span
                            className="form-label"
                            dangerouslySetInnerHTML={{
                              __html: newGroupFormDetails.groupName.label,
                            }}
                          />
                          :
                          <span
                            className="form-error"
                            dangerouslySetInnerHTML={{
                              __html: newGroupFormDetails.groupName.errorText,
                            }}
                          />
                          <Field
                            name="groupName"
                            type="name"
                            className="form-field"
                            // placeholder={newGroupFormDetails.groupName.hintText}
                            component={InputField}
                            onChange={event => {
                              this.handleFieldChange({
                                field: 'groupName',
                                value: event.target.value,
                              });
                            }}
                            currentValue={newGroupFormDetails.groupName.value}
                            disabled={newGroupFormDetails.groupName.disabled || this.props.selectedClassroom}
                          />
                        </div>
                        {isAstronomyClub && (
                          <div className="form-field-container">
                            <span
                              className="form-label"
                              dangerouslySetInnerHTML={{
                                __html: newGroupFormDetails.schoolYear.label,
                              }}
                            />
                            :
                            <span
                              className="form-error"
                              dangerouslySetInnerHTML={{
                                __html: newGroupFormDetails.schoolYear.errorText,
                              }}
                            />
                            <Field
                              name="schoolYear"
                              type="name"
                              className="form-dropdown"
                              label={newGroupFormDetails.schoolYear.hintText}
                              component="select"
                              onChange={event => {
                                this.handleFieldChange({
                                  field: 'schoolYear',
                                  value: event.target.value,
                                });
                              }}
                            >
                              {newGroupFormDetails.schoolYear.list.map(item => (
                                <option
                                  value={item.key}
                                  key={item.key}
                                  selected={item.selected}
                                >
                                  {item.value}
                                </option>
                              ))}
                            </Field>
                          </div>
                        )}

                        <div className="form-field-container">
                          <span
                            className="form-label"
                            dangerouslySetInnerHTML={{
                              __html: newGroupFormDetails.groupDescription.label,
                            }}
                          />
                          :
                          <span
                            className="form-error"
                            dangerouslySetInnerHTML={{
                              __html:
                                newGroupFormDetails.groupDescription.errorText,
                            }}
                          />
                          <Field
                            name="groupDescription"
                            type="name"
                            className="form-field"
                            placeholder={
                              newGroupFormDetails.groupDescription.hintText
                            }
                            component={InputField}
                            onChange={event => {
                              this.handleFieldChange({
                                field: 'groupDescription',
                                value: event.target.value,
                              });
                            }}
                            value={newGroupFormDetails.groupDescription.value}
                          />
                        </div>
                      </div>
                      <div className="button-container">
                        <button className="submit-button" type="submit">
                          {
                            createNewGroupRes.formFieldLabels
                              .groupCreateButtonText
                          }
                        </button>
                      </div>
                      {isLTIAccount && (
                        <h5 className="skip-for-now" onClick={()=>this.props.handleLTICreateWorkspace("skip", createNewGroupRes)}>Or Skip for Now</h5>
                      )}
                    </form>
                  )}
                </Fragment>
              )}
            </Fragment>
          )}
        />
        <style jsx>{style3}</style>
      </div>
    );
  }
}

const mapStateToProps = ({ user, newGroupAccountForm }) => ({
  user,
  newGroupAccountForm,
}) => ({
  user,
  newGroupAccountForm,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      validateResponseAccess,
    },
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({ form: 'newGroupAccountForm', enableReinitialize: true })(
    GroupCreate
  )
);
