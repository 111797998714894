import React from 'react';
import uniqueId from 'lodash/uniqueId';
import ProfileMenuItem from './partials/buttons/ProfileMenuItem';

export default (userLinks = []) => ({
  render: props => <ProfileMenuItem {...props} />,
  content: userLinks.map(link => ({
    _ID: uniqueId(),
    text: link.name,
    anchor: link.link,
    indent: link.indent,
    isExternalLink: link.isExternalLink,
    icon: link.iconURL
  })),
});
