import React, { Component, Suspense } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import cx from 'classnames';

import DeviceProvider from 'providers/DeviceProvider';
import IssueWithUserAccount from 'app/modules/account-settings/containers/issue-with-user-account';
import { makeUserSelector } from 'app/modules/user/selectors';
import { fireSloohPageView } from 'app/utils/slooh-pageview-wrapper';
import { getQuestBreadCrumbDetails } from 'app/modules/User';
import { guidedQuestTopBar } from 'app/config/project-config';
import { GuidedQuestTopBar } from 'app/modules/guided-quests/common/topbar';
import TopButtonsBar from 'app/components/GlobalNavigation/TopButtonsBar';
import { isRouteWithoutTopBar } from 'app/utils/router';
import MiniProfile from 'app/modules/new-dashboard/components/mini-profile';
import CommonTopBar from 'app/components/common-topbar';
import PageMetaManagement from '../components/PageMetaManagement';
import GlobalNavigation from '../components/GlobalNavigation';
import Footer from '../components/Footer';
import {
  fetchEvents,
  setPublicCardStatusAction,
} from '../modules/upcoming-events/upcoming-events-actions';
import { fetchList } from '../modules/observatory-list/observatory-actions';
import QuestBreadCrumb from '../components/GlobalNavigation/breadcrumb';

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchEvents,
      setPublicCardStatusAction,
      fetchList,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  const { isLanding } = state;
  const { showPublicCard, customerUUID } = state.upcomingEvents;
  return {
    isLanding,
    user: makeUserSelector()(state),
    showPublicCard,
    customerUUID,
  };
}

@connect(
  mapStateToProps,
  mapDispatchToProps
)
class App extends Component {
  static propTypes = {
    // isSessionInitialized: PropTypes.bool,
    isLanding: PropTypes.bool,
    children: PropTypes.node.isRequired,
    fetchEvents: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isLanding: false,
    isSessionInitialized: false,
  };

  state = {
    isSessionInitialized: false,
  };

  constructor(props) {
    super(props);
    props.fetchEvents();
    props.fetchList();
  }

  // async componentWillMount(){
  //   const res1=await veritySloohId();
  // }

  async componentDidMount() {
    const { user } = this.props;
    // const res = await (initSessionToken(user,this));
    // this.setState({isSessionInitialized: res});
    this.setState({ isSessionInitialized: true });
    const {
      location: { pathname },
    } = this.props;
    // Slooh page view tracker for application load event
    fireSloohPageView({ pagePath: pathname });
    // const res1=await veritySloohId();
  }

  componentWillReceiveProps(nextProps) {
    const {
      location: { pathname },
    } = this.props;
    const {
      location: { pathname: currentPathname },
    } = nextProps;

    const routeChanged = pathname !== currentPathname;

    if (routeChanged) {
      // Slooh page view tracker when route changes
      fireSloohPageView({
        pagePath: currentPathname,
        referringPageURL: pathname,
      });
    }
  }

  render() {
    const {
      isLanding,
      showPublicCard,
      customerUUID,
      setPublicCardStatusAction,
      user,
      fetchEvents,
      children,
      location,
    } = this.props;

    const { isSessionInitialized } = this.state;

    const withoutTopBar = isRouteWithoutTopBar(location.pathname);

    const {
      questTitle = '',
      questLink = '',
      questStep = '',
      questStepTitle = '',
      showBreadCrumb = false,
    } = getQuestBreadCrumbDetails();

    return (
      <Suspense fallback={<div>Loading</div>}>
        <div
          style={{ overflow: 'hidden', height: '100%' }}
          className={`wrapper ${isLanding ? 'is-landing' : null}`}
        >
          {isSessionInitialized ? (
            <DeviceProvider>
              <PageMetaManagement />

              <IssueWithUserAccount />

              {guidedQuestTopBar ? (
                <nav className="navigation">
                  <GuidedQuestTopBar />
                </nav>
              ) : user.isAuthorized ? (
                <nav
                  className={cx('navigation', {
                    withoutTopBar,
                  })}
                >
                  <GlobalNavigation fetchEvents={fetchEvents} />
                  <QuestBreadCrumb
                    sloohQuestBreadCrumbQuestTitle={questTitle}
                    sloohQuestBreadCrumbQuestLinkURL={questLink}
                    questStep={questStep}
                    questStepTitle={questStepTitle}
                    showBreadCrumb={showBreadCrumb}
                  />
                </nav>
              ) : (
                withoutTopBar && (
                  <CommonTopBar isAuthorized={user.isAuthorized} />
                )
              )}
              <div 
                id="app-content"
                style={{ 
                  overflowY: 'scroll', 
                  height: !user.isAuthorized ? '100%' : 'calc(100% - 40px)', 
                  scrollMarginTop: !user.isAuthorized ? '0px': showBreadCrumb ? '95px' : '48px',
                  marginTop: !user.isAuthorized ? '0px': showBreadCrumb ? '95px' : '48px', 
                }}
              >
                {!guidedQuestTopBar && user.isAuthorized && !withoutTopBar && (
                  <TopButtonsBar />
                )}

                <section
                  className="app-content-container clearfix"
                  // style={{
                  //   marginTop: !user.isAuthorized
                  //     ? '0px'
                  //     : sloohQuestBreadCrumbQuestLinkURL
                  //       ? '85px'
                  //       : '0',
                  // }}
                >
                  <div className="clearfix">{children}</div>
                </section>

                {showPublicCard && (
                  <MiniProfile
                    customerUUID={customerUUID}
                    onClose={() => setPublicCardStatusAction(null, false)}
                  />
                )}

                <Footer />
              </div>
            </DeviceProvider>
          ) : null}
          <style jsx>
            {`
              .v4 {
                margin-top: 60px;
                position: relative;
                z-index: 1;
              }
              .navigation {
                position: relative;
                z-index: 10;
              }
              .withoutTopBar {
                margin-bottom: 48px;
              }
              ::-webkit-scrollbar {
                width: 10px;
                // background: transparent !important;
                // background-color: transparent !important;
              }
              
              /* Track */
              ::-webkit-scrollbar-track {
                background: linear-gradient(180deg, #253446 0%, #1E2A38 100%) !important;
                // background-color: transparent !important;
              }
              
              /* Handle */
              ::-webkit-scrollbar-thumb {
              
                background: #FFF;
                border-radius: 5px;
                border: 2px #253446 solid;
              }
              
            `}
          </style>
        </div>
      </Suspense>
    );
  }
}

export default withRouter(App);
