// services
import fetchObjectDetailsService from 'app/services/objects/object-details';
import fetchObjectDataService from 'app/services/objects/object-data';
import fetchObjectMissionsService from 'app/services/objects/object-missions';
import fetchObjectQuestsService from 'app/services/objects/object-quests';
import fetchObjectFollowService from 'app/services/objects/object-follow';
import fetchObjectSpecialistsService from 'app/services/objects/specialists';
import fetchLikeService from 'app/services/objects/object-details-like';
import fetchImageDetailsService from 'app/services/objects/object-details-image-details';
import fetchSharedMemberPhotosService from 'app/services/objects/object-details-shared-member-photos';
import { getCommunityMissionsApi, getMyPicturesApi, } from './api';
import { validateResponseAccess } from '../authorization/actions';

/* getObjectDetails */
export const FETCH_OBJECT_DETAILS = 'FETCH_OBJECT_DETAILS';
export const FETCH_OBJECT_DETAILS_START = 'FETCH_OBJECT_DETAILS_START';
export const FETCH_OBJECT_DETAILS_FAIL = 'FETCH_OBJECT_DETAILS_FAIL';
export const FETCH_OBJECT_DETAILS_SUCCESS = 'FETCH_OBJECT_DETAILS_SUCCESS';
export const RESET_OBJECT_DETAILS = 'RESET_OBJECT_DETAILS';

/* getObjectData */
export const FETCH_OBJECT_DATA = 'FETCH_OBJECT_DATA';
export const FETCH_OBJECT_DATA_START = 'FETCH_OBJECT_DATA_START';
export const FETCH_OBJECT_DATA_FAIL = 'FETCH_OBJECT_DATA_FAIL';
export const FETCH_OBJECT_DATA_SUCCESS = 'FETCH_OBJECT_DATA_SUCCESS';
export const RESET_OBJECT_DATA = 'RESET_OBJECT_DATA';

/* getObjectMissions */
export const FETCH_OBJECT_MISSIONS = 'FETCH_OBJECT_MISSIONS';
export const FETCH_OBJECT_MISSIONS_START = 'FETCH_OBJECT_MISSIONS_START';
export const FETCH_OBJECT_MISSIONS_FAIL = 'FETCH_OBJECT_MISSIONS_FAIL';
export const FETCH_OBJECT_MISSIONS_SUCCESS = 'FETCH_OBJECT_MISSIONS_SUCCESS';

/* getObjectQuests */
export const FETCH_OBJECT_QUESTS = 'FETCH_OBJECT_QUESTS';
export const FETCH_OBJECT_QUESTS_START = 'FETCH_OBJECT_QUESTS_START';
export const FETCH_OBJECT_QUESTS_FAIL = 'FETCH_OBJECT_QUESTS_FAIL';
export const FETCH_OBJECT_QUESTS_SUCCESS = 'FETCH_OBJECT_QUESTS_SUCCESS';

/* getObjectFollow */
export const FETCH_OBJECT_FOLLOW = 'FETCH_OBJECT_FOLLOW';
export const FETCH_OBJECT_FOLLOW_START = 'FETCH_OBJECT_FOLLOW_START';
export const FETCH_OBJECT_FOLLOW_FAIL = 'FETCH_OBJECT_FOLLOW_FAIL';
export const FETCH_OBJECT_FOLLOW_SUCCESS = 'FETCH_OBJECT_FOLLOW_SUCCESS';

/*getObjectSpecialists */
export const FETCH_OBJECT_SPECIALISTS = 'FETCH_OBJECT_SPECIALISTS';
export const FETCH_OBJECT_SPECIALISTS_START = 'FETCH_OBJECT_SPECIALISTS_START';
export const FETCH_OBJECT_SPECIALISTS_FAIL = 'FETCH_OBJECT_SPECIALISTS_FAIL';
export const FETCH_OBJECT_SPECIALISTS_SUCCESS =
  'FETCH_OBJECT_SPECIALISTS_SUCCESS';
export const RESET_OBJECT_SPECIALISTS = 'RESET_OBJECT_SPECIALISTS';

/*getLike */
export const FETCH_LIKE = 'FETCH_LIKE';

/* getImageDetails */
export const FETCH_IMAGE_DETAILS = 'FETCH_IMAGE_DETAILS';
export const FETCH_IMAGE_DETAILS_START = 'FETCH_IMAGE_DETAILS_START';
export const FETCH_IMAGE_DETAILS_FAIL = 'FETCH_IMAGE_DETAILS_FAIL';
export const FETCH_IMAGE_DETAILS_SUCCESS = 'FETCH_IMAGE_DETAILS_SUCCESS';

/* getSharedMemberPhotos */
export const FETCH_SHARED_MEMBER_PHOTOS = 'FETCH_SHARED_MEMBER_PHOTOS';
export const FETCH_SHARED_MEMBER_PHOTOS_START =
  'FETCH_SHARED_MEMBER_PHOTOS_START';
export const FETCH_SHARED_MEMBER_PHOTOS_SUCCESS =
  'FETCH_SHARED_MEMBER_PHOTOS_SUCCESS';

/* getMyPictures */
export const GET_MY_PICTURES = 'GET_MY_PICTURES';
export const GET_MY_PICTURES_START = 'GET_MY_PICTURES_START';
export const GET_MY_PICTURES_FAIL = 'GET_MY_PICTURES_FAIL';
export const GET_MY_PICTURES_SUCCESS = 'GET_MY_PICTURES_SUCCESS';

//////////////////////////
/* FETCH SHARED MEMBER PHOTOS */

export const fetchSharedMemberPhotosAction = requestBody => (
  dispatch,
  getState
) => {
  dispatch(fetchSharedMemberPhotosStart());

  const { objectId, discussionGroupId, pagingMode, count, page, v4Filter } = requestBody;
  const { token, at, cid } = getState().user;

  return fetchSharedMemberPhotosService({
    token,
    at,
    cid,
    objectId,
    discussionGroupId,
    pagingMode,
    count,
    page,
    v4Filter,
  }).then(result => {
    dispatch(fetchSharedMemberPhotosSuccess(result.data));
  });
};

//////////////////////////
/* FETCH OBJECT DETAILS */

export const fetchObjectDetailsAction = objectId => (dispatch, getState) => {
  dispatch(fetchObjectDetailsActionStart());

  const { token, at, cid } = getState().user;

  return fetchObjectDetailsService({
    token,
    at,
    cid,
    objectId,
  }).then(result => {
    const res = result.data;
    // if(res.apiError)
    //   validateResponseAccess(res);
    // else
      dispatch(fetchObjectDetailsActionSuccess(res));
  });
};

export const resetObjectDetails = () => ({
  type: RESET_OBJECT_DETAILS,
});

///////////////////////
/* FETCH OBJECT DATA */

export const fetchObjectDataAction = ({objectId, callSource}) => (dispatch, getState) => {
  dispatch(fetchObjectDataActionStart());

  const { token, at, cid } = getState().user;
  return fetchObjectDataService({
    token,
    at,
    cid,
    objectId,
    callSource,
  }).then(result => {
    const res = result.data;
    // if(res.apiError)
    //   validateResponseAccess(res);
    // else
      dispatch(fetchObjectDataActionSuccess(res));
  });
};

export const resetObjectData = () => ({
  type: RESET_OBJECT_DATA,
});

///////////////////////////
/* FETCH OBJECT MISSIONS */

export const getCommunityMissions = objectId => (dispatch, getState) => {
  dispatch(fetchObjectMissionsActionStart());

  const { token, at, cid } = getState().user;

  return getCommunityMissionsApi({
    token,
    at,
    cid,
    objectId,
  }).then(result => {
    dispatch(fetchObjectMissionsActionSuccess(result.data));
    return result;
  }).catch(error => dispatch(fetchObjectMissionsActionError(error)));
};

/////////////////////////
/* FETCH OBJECT QUESTS */

export const fetchObjectQuestsAction = objectId => (dispatch, getState) => {
  dispatch(fetchObjectQuestsActionStart());

  const { token, at, cid } = getState().user;

  return fetchObjectQuestsService({
    token,
    at,
    cid,
    objectId,
  }).then(result => {
    dispatch(fetchObjectQuestsActionSuccess(result.data));
  });
};

/////////////////////////
/* FETCH FOLLOW OBJECT */

export const fetchObjectFollowAction = objectId => (dispatch, getState) => {
  dispatch(fetchObjectFollowActionStart());

  const { token, at, cid } = getState().user;

  return fetchObjectFollowService({
    token,
    at,
    cid,
    objectId,
  }).then(result => {
    dispatch(fetchObjectFollowActionSuccess(result.data));
  });
};

//////////////////////////////
/* FETCH OBJECT SPECIALISTS */

export const fetchObjectSpecialistsAction = (objectId, maxCount) => (
  dispatch,
  getState
) => {
  dispatch(fetchObjectSpecialistsActionStart());

  const { token, at, cid } = getState().user;

  return fetchObjectSpecialistsService({
    token,
    at,
    cid,
    objectId,
    maxCount,
  }).then(result => {
    dispatch(fetchObjectSpecialistsActionSuccess(result.data));
  });
};

export const resetObjectSpecialists = () => ({
  type: RESET_OBJECT_SPECIALISTS,
});

//////////////////////////////
/* FETCH LIKE */

export const fetchLikeAction = likeId => (dispatch, getState) => {
  const { token, at, cid } = getState().user;

  return fetchLikeService({
    token,
    at,
    cid,
    likeId,
  });
};

//////////////////////////////
/* FETCH IMAGE DETAILS */

export const fetchImageDetailsAction = customerImageId => (
  dispatch,
  getState
) => {
  dispatch(fetchImageDetailsActionStart());
  const { token, at, cid } = getState().user;
  return fetchImageDetailsService({
    token,
    at,
    cid,
    customerImageId,
  })
    .then(result => {
      dispatch(fetchImageDetailsActionSuccess(result.data));
    })
    .catch(error => dispatch(fetchImageDetailsActionError(error)));
};

//////////////////////////////
/* GET MY PICTURES */

export const getMyPictures = data => (dispatch, getState) => {
  dispatch(getMyPicturesStart());
  const { token, at, cid } = getState().user;
  return getMyPicturesApi({
    token,
    at,
    cid,
    ...data,
  })
    .then(result => {
      dispatch(getMyPicturesSuccess(result.data));
    })
    .catch(error => dispatch(getMyPicturesError(error)));
}


////////////////////
/* fetch handlers */

// SHARED MEMBER PHOTOS
const fetchSharedMemberPhotosStart = () => ({
  type: FETCH_SHARED_MEMBER_PHOTOS_START,
});

const fetchSharedMemberPhotosSuccess = payload => ({
  type: FETCH_SHARED_MEMBER_PHOTOS_SUCCESS,
  payload,
});

// IMAGE DETAILS
const fetchImageDetailsActionStart = () => ({
  type: FETCH_IMAGE_DETAILS_START,
});

const fetchImageDetailsActionSuccess = payload => ({
  type: FETCH_IMAGE_DETAILS_SUCCESS,
  payload,
});

const fetchImageDetailsActionError = payload => ({
  type: FETCH_IMAGE_DETAILS_FAIL,
  payload,
});

// DETAILS
const fetchObjectDetailsActionStart = () => ({
  type: FETCH_OBJECT_DETAILS_START,
});

const fetchObjectDetailsActionSuccess = payload => ({
  type: FETCH_OBJECT_DETAILS_SUCCESS,
  payload,
});

const fetchObjectDetailsActionError = payload => ({
  type: FETCH_OBJECT_DETAILS_FAIL,
  payload,
});

// DATA
const fetchObjectDataActionStart = () => ({
  type: FETCH_OBJECT_DATA_START,
});

const fetchObjectDataActionSuccess = payload => ({
  type: FETCH_OBJECT_DATA_SUCCESS,
  payload,
});

const fetchObjectDataActionError = payload => ({
  type: FETCH_OBJECT_DATA_FAIL,
  payload,
});

// MISSIONS
const fetchObjectMissionsActionStart = () => ({
  type: FETCH_OBJECT_MISSIONS_START,
});

const fetchObjectMissionsActionSuccess = payload => ({
  type: FETCH_OBJECT_MISSIONS_SUCCESS,
  payload,
});

const fetchObjectMissionsActionError = payload => ({
  type: FETCH_OBJECT_MISSIONS_FAIL,
  payload,
});

// QUESTS
const fetchObjectQuestsActionStart = () => ({
  type: FETCH_OBJECT_QUESTS_START,
});

const fetchObjectQuestsActionSuccess = payload => ({
  type: FETCH_OBJECT_QUESTS_SUCCESS,
  payload,
});

const fetchObjectQuestsActionError = payload => ({
  type: FETCH_OBJECT_QUESTS_FAIL,
  payload,
});

// FOLLOW
const fetchObjectFollowActionStart = () => ({
  type: FETCH_OBJECT_FOLLOW_START,
});

const fetchObjectFollowActionSuccess = payload => ({
  type: FETCH_OBJECT_FOLLOW_SUCCESS,
  payload,
});

const fetchObjectFollowActionError = payload => ({
  type: FETCH_OBJECT_FOLLOW_FAIL,
  payload,
});

// SPECIALISTS
const fetchObjectSpecialistsActionStart = () => ({
  type: FETCH_OBJECT_SPECIALISTS_START,
});

const fetchObjectSpecialistsActionSuccess = payload => ({
  type: FETCH_OBJECT_SPECIALISTS_SUCCESS,
  payload,
});

const fetchObjectSpecialistsActionError = payload => ({
  type: FETCH_OBJECT_SPECIALISTS_FAIL,
  payload,
});

// LIKE
const fetchLike = () => ({
  type: FETCH_LIKE,
});

// GET MY PICTURES
const getMyPicturesStart = () => ({
  type: GET_MY_PICTURES_START,
});

const getMyPicturesSuccess = payload => ({
  type: GET_MY_PICTURES_SUCCESS,
  payload,
});

const getMyPicturesError = payload => ({
  type: GET_MY_PICTURES_FAIL,
  payload,
});

