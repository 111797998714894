import React, { Suspense } from 'react';

const DashboardRedesign = React.lazy(() =>
  import('./modules/new-dashboard/dashoard-redesign')
);
const DistrictAdminPage = React.lazy(() => import('./pages/district-admin'));
const BookmarkPage = React.lazy(() => import('./modules/bookmarks'));
const SloohChallengePage = React.lazy(() =>
  import('./modules/slooh-challenge')
);
const GravityActionPage = React.lazy(() => import('./modules/gravity-actions'));
const PhotosRedesign = React.lazy(() => import('./modules/photos'));
const GuidesRedesign = React.lazy(() => import('./modules/guides-redesign'));
const QuestsRedesign = React.lazy(() => import('./modules/quests-redesign'));
const TakeATourLink = React.lazy(() =>
  import('./modules/new-dashboard/take-a-tour-link')
);
const QuestImageDetials = React.lazy(() =>
  import('./modules/image-details/components/Quest-Details')
);
const MyCommunity = React.lazy(() =>
  import('./modules/my-community/my-community')
);
const AccountPreferences = React.lazy(() =>
  import('./modules/account-settings/containers/account-preferences')
);
const AccountDetails = React.lazy(() =>
  import('./modules/account-settings/containers/account-details')
);
const Observations = React.lazy(() => import('app/modules/observations'));
const MissionLog = React.lazy(() => import('app/modules/mission-log'));
const GalleriesRedesign = React.lazy(() =>
  import('app/modules/photos/galleries')
);
const OndemandVideoCategory = React.lazy(() =>
  import('app/modules/community/components/ondemand-video-category')
);
const WorkspaceSettings = React.lazy(() =>
  import('app/pages/workspace-settings')
);
const WorkspacesPage = React.lazy(() => import('app/pages/workspaces'));
const MyPastMissions = React.lazy(() => import('app/modules/my-past-missions'));
const Profile = React.lazy(() => import('app/modules/profile-new'));
const Workspaces = React.lazy(() => import('app/modules/workspaces'));
const Community = React.lazy(() => import('app/modules/community'));
const MyQuestMedia = React.lazy(() => import('app/modules/my-quest-media'));
const ItemPhotos = React.lazy(() => import('app/modules/item-photos'));
const Show = React.lazy(() => import('app/pages/show'));
const ReserveObjects = React.lazy(() =>
  import('app/pages/reserve/reserve-by-objects')
);
const ReserveByCatalog = React.lazy(() =>
  import('app/pages/reserve/reserve-by-catalog')
);
const ReserveByTelescope = React.lazy(() =>
  import('app/pages/reserve-by-telescope')
);
const PhotoRoll = React.lazy(() => import('app/pages/my-pictures/PhotoRoll'));
const Missions = React.lazy(() => import('app/pages/my-pictures/Missions'));
const MissionImages = React.lazy(() =>
  import('app/pages/my-pictures/MissionImages')
);
const GalleryImages = React.lazy(() =>
  import('app/pages/my-pictures/GalleryImages')
);
const GalleryImageDetails = React.lazy(() =>
  import('app/pages/my-pictures/GalleryImageDetails')
);
const Galleries = React.lazy(() => import('app/pages/my-pictures/Galleries'));
const ExistingMissions = React.lazy(() =>
  import('app/pages/existing-missions')
);
const GuideDetails = React.lazy(() =>
  import('app/pages/guide-details/GuideDetails')
);
const GroupOverviewInfo = React.lazy(() =>
  import('app/pages/community-groups/GroupOverviewInfo')
);
const CommunityGroupOverview = React.lazy(() =>
  import('app/pages/community-groups/GroupOverview')
);
const GroupImportGoogleClassrooms = React.lazy(() =>
  import('app/pages/community-groups/GroupImportGoogleClassrooms')
);
const GroupCreate = React.lazy(() =>
  import('app/pages/community-groups/GroupCreate')
);
const Telescope = React.lazy(() =>
  import('app/modules/missions/containers/telescope')
);
const Slooh1000 = React.lazy(() =>
  import('app/modules/missions/containers/slooh-1000')
);
const Constellation = React.lazy(() =>
  import('app/modules/missions/containers/constellation')
);
const Catalog = React.lazy(() =>
  import('app/modules/missions/containers/catalog')
);
const Dashboard = React.lazy(() =>
  import('app/modules/dashboard/containers/dashborad')
);
const StoriesHub = React.lazy(() => import('app/containers/stories-hub'));
const StaticAppContainer = React.lazy(() =>
  import('app/containers/static-app-container')
);
const SloohRecommends = React.lazy(() =>
  import('app/containers/SloohRecommends')
);
const ShowsHub = React.lazy(() => import('app/containers/shows-hub'));
const Reservations = React.lazy(() => import('app/containers/Reservations'));
const QuestComplete = React.lazy(() => import('app/containers/quest-complete'));
const ObjectList = React.lazy(() =>
  import('app/containers/object-post/ObjectList')
);
const ObjectDetailsStories = React.lazy(() =>
  import('app/containers/object-details/ObjectDetailsStories')
);
const ObjectDetailsShows = React.lazy(() =>
  import('app/containers/object-details/ObjectDetailsShows')
);
const ObjectDetailsQuests = React.lazy(() =>
  import('app/containers/object-details/ObjectDetailsQuests')
);
const ObjectDetailsOverview = React.lazy(() =>
  import('app/containers/object-details/ObjectDetailsOverview')
);
const ObjectDetailsObservations = React.lazy(() =>
  import('app/containers/object-details/ObjectDetailsObservations')
);
const ObjectDetailsMissions = React.lazy(() =>
  import('app/containers/object-details/ObjectDetailsMissions')
);
const MyPictures = React.lazy(() => import('app/containers/MyPictures'));
const TopicGuides = React.lazy(() =>
  import('app/containers/guides/TopicGuides')
);
const SubjectGuides = React.lazy(() =>
  import('app/containers/guides/SubjectGuides')
);
const ObjectCategoryGuide = React.lazy(() =>
  import('app/containers/guides/ObjectCategoryGuide')
);
const GroupsHub = React.lazy(() => import('app/containers/groups-hub'));
const NewMissions = React.lazy(() => import('app/pages/new-missions'));
const ObjectPosts = React.lazy(() =>
  import('app/pages/object-posts/ObjectPosts')
);
const ObjectDetails = React.lazy(() =>
  import('app/pages/object-details/ObjectDetails')
);
const GuidedQuestHome = React.lazy(() =>
  import('app/modules/guided-quests/index')
);
const CustomPosterPage = React.lazy(() => import('app/modules/custom-poster'));

//putting this component in lazy, we can remove it from here and routes, once we know we no longer using this
const UpgradeAstronomer = React.lazy(() =>
  import('app/pages/registration/UpgradeAstronomer')
);
const UpgradeApprentice = React.lazy(() =>
  import('app/pages/registration/UpgradeApprentice')
);
const Upgrade = React.lazy(() => import('app/pages/registration/Upgrade'));
const ResetPassword = React.lazy(() =>
  import('app/pages/registration/ResetPassword')
);
const PlaceholderPage = React.lazy(() => import('app/pages/Placeholder'));
const PublicGalleries = React.lazy(() =>
  import('app/pages/my-pictures/PublicGalleries')
);
const CustomerService = React.lazy(() =>
  import('app/pages/help/CustomerService')
);

export const DashboardRedesignLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <DashboardRedesign {...props} />
  </Suspense>
);

export const DistrictAdminPageLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <DistrictAdminPage {...props} />
  </Suspense>
);

export const BookmarkPageLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <BookmarkPage {...props} />
  </Suspense>
);

export const SloohChallengePageLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <SloohChallengePage {...props} />
  </Suspense>
);

export const GravityActionPageLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <GravityActionPage {...props} />
  </Suspense>
);

export const PhotosRedesignLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <PhotosRedesign {...props} />
  </Suspense>
);

export const GuidesRedesignLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <GuidesRedesign {...props} />
  </Suspense>
);

export const QuestsRedesignLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <QuestsRedesign {...props} />
  </Suspense>
);

export const CustomPosterPageLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <CustomPosterPage {...props} />
  </Suspense>
);

export const TakeATourLinkLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <TakeATourLink {...props} />
  </Suspense>
);

export const QuestImageDetialsLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <QuestImageDetials {...props} />
  </Suspense>
);

export const myCommunityLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <MyCommunity {...props} />
  </Suspense>
);

export const GuidedQuestHomeLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <GuidedQuestHome {...props} />
  </Suspense>
);

export const AccountPreferencesLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <AccountPreferences {...props} />
  </Suspense>
);

export const AccountDetailsLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <AccountDetails {...props} />
  </Suspense>
);

export const ObservationsLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <Observations {...props} />
  </Suspense>
);

export const MissionLogLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <MissionLog {...props} />
  </Suspense>
);

export const GalleriesRedesignLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <GalleriesRedesign {...props} />
  </Suspense>
);

export const OndemandVideoCategoryLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <OndemandVideoCategory {...props} />
  </Suspense>
);

export const WorkspaceSettingsLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <WorkspaceSettings {...props} />
  </Suspense>
);

export const WorkspacesPageLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <WorkspacesPage {...props} />
  </Suspense>
);

export const MyPastMissionsLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <MyPastMissions {...props} />
  </Suspense>
);

export const ProfileLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <Profile {...props} />
  </Suspense>
);

export const WorkspacesLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <Workspaces {...props} />
  </Suspense>
);

export const CommunityLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <Community {...props} />
  </Suspense>
);
export const MyQuestMediaLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <MyQuestMedia {...props} />
  </Suspense>
);
export const ItemPhotosLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <ItemPhotos {...props} />
  </Suspense>
);
export const ShowLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <Show {...props} />
  </Suspense>
);
export const ReserveObjectsLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <ReserveObjects {...props} />
  </Suspense>
);
export const ReserveByCatalogLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <ReserveByCatalog {...props} />
  </Suspense>
);
export const ReserveByTelescopeLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <ReserveByTelescope {...props} />
  </Suspense>
);
export const PlaceholderPageLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <PlaceholderPage {...props} />
  </Suspense>
);
export const ResetPasswordLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <ResetPassword {...props} />
  </Suspense>
);
export const UpgradeLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <Upgrade {...props} />
  </Suspense>
);
export const UpgradeApprenticeLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <UpgradeApprentice {...props} />
  </Suspense>
);
export const UpgradeAstronomerLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <UpgradeAstronomer {...props} />
  </Suspense>
);
export const ObjectPostsLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <ObjectPosts {...props} />
  </Suspense>
);
export const ObjectDetailsLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <ObjectDetails {...props} />
  </Suspense>
);
export const NewMissionsLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <NewMissions {...props} />
  </Suspense>
);
export const PublicGalleriesLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <PublicGalleries {...props} />
  </Suspense>
);
export const PhotoRollLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <PhotoRoll {...props} />
  </Suspense>
);
export const MissionsLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <Missions {...props} />
  </Suspense>
);
export const MissionImagesLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <MissionImages {...props} />
  </Suspense>
);
export const GalleryImagesLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <GalleryImages {...props} />
  </Suspense>
);
export const GalleryImageDetailsLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <GalleryImageDetails {...props} />
  </Suspense>
);
export const GalleriesLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <Galleries {...props} />
  </Suspense>
);
export const CustomerServiceLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <CustomerService {...props} />
  </Suspense>
);
export const GuideDetailsLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <GuideDetails {...props} />
  </Suspense>
);
export const ExistingMissionsLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <ExistingMissions {...props} />
  </Suspense>
);
export const GroupOverviewInfoLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <GroupOverviewInfo {...props} />
  </Suspense>
);
export const CommunityGroupOverviewLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <CommunityGroupOverview {...props} />
  </Suspense>
);
export const GroupImportGoogleClassroomsLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <GroupImportGoogleClassrooms {...props} />
  </Suspense>
);
export const GroupCreateLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <GroupCreate {...props} />
  </Suspense>
);
export const TelescopeLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <Telescope {...props} />
  </Suspense>
);
export const Slooh1000Lazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <Slooh1000 {...props} />
  </Suspense>
);
export const ConstellationLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <Constellation {...props} />
  </Suspense>
);
export const CatalogLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <Catalog {...props} />
  </Suspense>
);
export const DashboardLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <Dashboard {...props} />
  </Suspense>
);
export const StoriesHubLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <StoriesHub {...props} />
  </Suspense>
);
export const StaticAppContainerLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <StaticAppContainer {...props} />
  </Suspense>
);
export const SloohRecommendsLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <SloohRecommends {...props} />
  </Suspense>
);
export const ShowsHubLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <ShowsHub {...props} />
  </Suspense>
);
export const ReservationsLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <Reservations {...props} />
  </Suspense>
);
export const QuestCompleteLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <QuestComplete {...props} />
  </Suspense>
);
export const ObjectListLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <ObjectList {...props} />
  </Suspense>
);
export const ObjectDetailsStoriesLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <ObjectDetailsStories {...props} />
  </Suspense>
);
export const ObjectDetailsShowsLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <ObjectDetailsShows {...props} />
  </Suspense>
);
export const ObjectDetailsQuestsLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <ObjectDetailsQuests {...props} />
  </Suspense>
);
export const ObjectDetailsOverviewLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <ObjectDetailsOverview {...props} />
  </Suspense>
);
export const ObjectDetailsObservationsLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <ObjectDetailsObservations {...props} />
  </Suspense>
);
export const ObjectDetailsMissionsLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <ObjectDetailsMissions {...props} />
  </Suspense>
);
export const MyPicturesLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <MyPictures {...props} />
  </Suspense>
);
export const TopicGuidesLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <TopicGuides {...props} />
  </Suspense>
);
export const SubjectGuidesLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <SubjectGuides {...props} />
  </Suspense>
);
export const ObjectCategoryGuideLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <ObjectCategoryGuide {...props} />
  </Suspense>
);
export const GroupsHubLazy = props => (
  <Suspense fallback={<div>Loading...</div>}>
    <GroupsHub {...props} />
  </Suspense>
);
