import createReducer from '../utils/createReducer';
import {
  UPDATE_NOTIFICATIONS_COUNT,
  SET_NOTIFICATIONS,
  SET_NOTIFICATIONS_CATEGORIES,
} from './actions';

const initialState = {
  notificationsCount: 0,
  notifications: [],
  showNewAlertNotificationFlag: null,
  isShowCategories: false,
};

export default createReducer(initialState, {
  [UPDATE_NOTIFICATIONS_COUNT](state, { payload }) {
    const { count } = payload;
    return {
      ...state,
      notificationsCount: count,
    };
  },
  [SET_NOTIFICATIONS](state, { payload }) {
    const {
      notifications = [],
      showNewAlertNotificationFlag = false,
    } = payload;
    return {
      ...state,
      notifications,
      showNewAlertNotificationFlag,
    };
  },
  [SET_NOTIFICATIONS_CATEGORIES](state, { payload }) {
    const { isShowCategories = false } = payload;
    return {
      ...state,
      isShowCategories,
    };
  },
});
