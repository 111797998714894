/**
 a simple wrapper against the twq global to add any required
 defensive code to the application
 */
 import * as Sentry from '@sentry/browser';
 export default function fireSloohTwitterPageview({ pagePath }) {
   if (typeof twq  === 'undefined') {
     Sentry.captureMessage('twq() function does not exist');
     return;
   }
   //fire off a Twitter page-view
    twq('track','PageView');
 }

 export function fireSloohTwitterPurchaseEvent(myprops) {
    if (typeof twq === 'undefined') {	
      Sentry.captureMessage('gtag() function does not exist');
      return;
    }
      /* 8/2/2021 - Track Facebook - Complete Registration Events */
      twq('track','Purchase', {
           //required parameters
           value:  myprops.planCostInUSD,
           currency: 'USD',
           num_items: '1'
  });
  }