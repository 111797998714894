import React, { useState, useEffect } from 'react';
import { Spinner } from 'app/components/spinner/index';
import { API } from 'app/api';
import styled from 'styled-components';
import { screenMedium, screenMobile, screenTablet } from 'app/styles/variables/breakpoints';
import { primaryFont, secondaryFont } from 'app/styles/variables/fonts';
import { Field, reduxForm } from 'redux-form';
import InputField from 'app/components/form/InputFieldNew';
import { getLMSData, setLMSData, storeMarketingTrackingId } from '../User';
import { UpgradeConfirmModal } from '../new-dashboard/components/upgrade-modal-dashboard/confirm-upgrade-popup';
import { browserHistory } from 'react-router';
import { connect, useDispatch, useSelector } from 'react-redux';
import { logUserIn } from '../login/actions';
import { GroupCreateOptions } from 'app/pages/community-groups/GroupCreateOptions';
import { processLMSSignIn } from './api';
import { brightBlue1000, gray200, gray300, gray600, red1000, red1100 } from 'app/styles/variables/colors_tiles_v4';
import { TermsAndConditions } from 'app/components/tems-conditions';
import JoinStep3 from 'app/pages/registration/JoinStep3';

const ContainerWrapper = styled.div`
    width: 600px;
    margin: 50px auto 0px;

    @media ${screenMobile} {
        width: 100%;
    }
`;

const InnerWrapper = styled.div`
    padding: 80px;
    width: 100%;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(88, 88, 88, 0.5) 0px 0px 5px 0px;

    @media ${screenMobile} {
        padding: 20px;
    }

    @media ${screenTablet} {
        padding: 30px;
    }

    @media ${screenMedium}{
        padding: 50px;
    }
`;

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 32px;
`;

const StepText = styled.h6`
    font-family: ${primaryFont};
    font-style: bold;
    font-weight: 450;
    font-size: 12px;
    line-height: 17px;
    text-transform: uppercase;
    color: ${gray600};
    text-align: center;
    margin-top: 16px;
`;

const ImageLogo = styled.img`
    margin-bottom: 20px;
`;

const HeaderElement = styled.h6`
    color: black;
    font-family: ${secondaryFont};
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 38px;
    text-align: center;
    margin-bottom: 0px;
`;

const FormSection = styled.div`
    padding: 0px;
    margin-bottom: 32px;
`;

const FormFieldContainer = styled.div`

`;

const LabelElement = styled.span`
    font-family:  ${primaryFont};
    font-size: 12px;
    font-style: normal;
    font-weight: 450;
    line-height: 100%; /* 12px */
    letter-spacing: 1px;
    text-transform: uppercase;
    color: ${(props) => props.error ? red1000 : 'rgb(84, 84, 84)'};
    margin-bottom: 8px;
`;

const ErrorTextContainer = styled.div`
    display: flex;
    gap: 3px;
`;

const ErrorIcon = styled.img`
    width: 20px;
    height: 20px;
    flex-shrink: 0;
`;

const ErrorElement = styled.h6`
    color: ${red1000};
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Brandon Grotesque;
    font-size: 14px;
    font-style: normal;
    font-weight: 390;
    line-height: 20px; /* 142.857% */
`;

const InfoElement = styled.h6`
    color: ${gray600};
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: ${primaryFont};
    font-size: 14px;
    font-style: normal;
    font-weight: 390;
    line-height: 20px;
    margin-top: 3px;
`;

const FieldElement = styled(Field)`
    border-radius: 4px;
    border: 1px solid ${(props) => props.error ? red1000 : gray300};
    outline: none;

    input{
        background: ${(props) => props.error ? `${red1100} !important` : 'unset'};
        outline: none;

        :focus{
            border-radius: 4px;
            border: 2px solid ${(props) => props.error ? red1100 : brightBlue1000};
            box-shadow: 0px 0px 4px 0px rgba(17, 119, 208, 0.41);
            background: ${(props) => props.error ? `${red1100} !important` : 'unset'};
        }
    }
`;

const SelectField = styled(Field)`
    width: 100%;
    padding: 9px 12px;
    font-size: 16px;
    font-family: ${primaryFont};
    border-radius: 4px;
    border: 1px solid ${(props) => props.error ? red1000 : gray300};
    background: ${(props) => props.error ? red1100 : 'white'};
    outline: none;

    :focus{
        border-radius: 4px;
        border: 2px solid ${brightBlue1000};
        background: ${(props) => props.error ? red1100 : 'white'};
        box-shadow: 0px 0px 4px 0px rgba(17, 119, 208, 0.41);
    }
`;

const SubmitButton = styled.button`
    background: none rgb(17, 119, 208);
    border: none;
    margin: 0px;
    padding: 12px 48px;
    transition: background-color 0.25s ease-in-out 0s;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 100px;
    font-weight: bold;
    font-family: ${primaryFont};
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: rgb(255, 255, 255);
    width: 100%;
    margin: 32px 0px;
    outline: none;

    :focus{
        outline: none;
    }
`;

const Divider = styled.hr`
    margin-bottom: 32px;
`;

type DropDownItemProps = {
    label: string;
    value: string;
    showInfoText?: boolean;
    infoText?: string;
};

type FieldValueProps = {
    label: string;
    currentValue: string;
    required: boolean;
    placeHolderText?: string;
    type: string;
    items?: DropDownItemProps[];
    error?: string;
    disabled?: boolean;
    defaultErrorText?: string;
};

type SignUpDataProps = {
    heading: string;
    formData: Record<string, FieldValueProps>;
    loginEmailAddress?: string;
    subscriptions?: boolean;
};

const LMSAuthorize = (props: any) => {

    const [isLoading, setIsLoading] = useState(false);
    const [signUpData, setSignUpData] = useState<SignUpDataProps>(null);
    const [action, setAction] = useState('');

    const loginFailed = useSelector((state: any) => state.logIn.loginFailed);

    const dispatch = useDispatch();

    useEffect(()=>{
        const { location, params, user } = props;
        const { query } = location;
        const { oauth_token, id_token, code }  = query || {};

        if(user.isAuthorized){
            browserHistory.push("/NewDashboard");
            return;
        }

        if(oauth_token || id_token || code){
            setIsLoading(true);
            setLMSData({
                id_token,
                oauth_token: (oauth_token || code),
                type: params.type,
                authorizeType: params.authorizeType,
            });
            processLMSSignIn({id_token, oauth_token: (oauth_token || code), lmsType: params.type, callsource: params.authorizeType}).then(result=>{
                const res = result.data;
                if(!res.apiError){
                    handleApiResponse(res.action, res);
                }
                setIsLoading(false);
            });
        }
        else{
            browserHistory.push('/login');
        }
    }, []);

    useEffect(()=>{
        if(loginFailed)
            setIsLoading(false);
    },[loginFailed])

    const handleApiResponse = (action: string, res: any) => {
        const { params } = props;
        switch(action){
            case "showPlans":
                let data = res.formData;
                Object.keys(data).map(key=>{
                    if(!data[key])
                        delete data[key];
                })
                setSignUpData({
                    heading: res.heading,
                    formData: data,
                    loginEmailAddress: res.loginEmailAddress,
                    subscriptions: res.subscriptions,
                });
                setLMSData({...getLMSData(), email: res.loginEmailAddress});
                setIsLoading(false);
                break;
            case "login":
                setIsLoading(true);
                dispatch(logUserIn({
                    lmsLogin: res.loginArray.loginEmail,
                    lmsOauthToken: res.loginArray.oAuthToken,
                    lmsType: props.params.type,
                }));
                break;
            case "tokenExpired":
                break;
            case "showWorkspace":
                if(res.loginEmailAddress)
                    setLMSData({...getLMSData(), email: res.loginEmailAddress});
                browserHistory.push(`/lms/${params.authorizeType}/workspace-settings`)
                break;
            case "showPayment":
                if(params?.authorizeType === "LTI13" || params?.type === "brightspace")
                    setLMSData({...getLMSData(), oauth_token: res.oAuthToken});
                // store pending_cid, enableHiddenPlanHashCode and selectedPlanId in local storage
                window.localStorage.setItem('selectedPlanId', res.paymentArray.selectedPlanId);
                window.localStorage.setItem('pending_cid', res.paymentArray.studentCustomerId);
                window.localStorage.setItem('accountCreationType', 'userpass');
                storeMarketingTrackingId(res.paymentArray.marketingTrackingId);
                // window.localStorage.setItem('enableHiddenPlanHashCode', null);
                setIsLoading(false);
                break;
        }
        setAction(action);
    }

    const handleFieldChange = (changeField: any) => {
        setSignUpData({
            ...signUpData,
            formData:{
                ...signUpData.formData,
                [changeField.field]: {
                    ...signUpData.formData[changeField.field],
                    currentValue: changeField.currentValue,
                    error: "",
                    errorText: "",
                }
            }
        });
    }

    const getFieldsByType = (key: string, fieldObject: FieldValueProps) => {
        switch(fieldObject.type){
            case "text":
                return <FieldElement
                    name={key}
                    type={fieldObject.type}
                    label={fieldObject.label}
                    component={InputField}
                    disabled={fieldObject.disabled}
                    onChange={(event: any) => {
                        handleFieldChange({
                            field: key,
                            currentValue: event.target.value,
                        });
                    }}
                    currentValue={fieldObject.currentValue}
                    error={fieldObject?.error}
                />;
            case "dropdown":
                let selectedItem;
                if(fieldObject?.currentValue){
                    selectedItem = fieldObject?.items?.filter(item =>item.value === fieldObject?.currentValue)[0];
                }
                return (
                    <>
                        <SelectField
                            name={key}
                            label={fieldObject.label}
                            component="select"
                            onChange={(event: any) => {
                                handleFieldChange({
                                    field: key,
                                    currentValue: event.target.value,
                                });
                            }}
                            error={fieldObject?.error}
                        >
                            {fieldObject?.items?.map((item) => (
                                <option value={item.value} key={item.value}>
                                    {item.label}
                                </option>
                            ))}
                        </SelectField>
                        {selectedItem && selectedItem?.showInfoText && (
                            <InfoElement>{selectedItem.infoText}</InfoElement>
                        )}
                    </>
                );
        }

    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        let formValues = {};
        const { formData, loginEmailAddress, subscriptions }: SignUpDataProps = signUpData;
        const { location, params } = props;
        const { query } = location;
        const { oauth_token } = getLMSData();
        if(checkAllField()){
            Object.keys(formData).map((key: any) => {
                const value = `${formData[key].currentValue}`;
                Object.assign(formValues, {[key]: value});
            });
            setIsLoading(true);
            const payload = {
                subscriptions: subscriptions,
                ...formValues, 
                loginEmailAddress, 
                oauth_token, 
                lmsType: params.type,
                callsource: params.authorizeType,
                id_token: query.id_token,
            };
            processLMSSignIn(payload).then(result=>{
                const res = result.data;
                if(!res.apiError){
                    handleApiResponse(res.action, res);
                    return;
                }
                setIsLoading(false);
            });
        }
    }

    const handlePopupClose = () => {
        browserHistory.push('/login');
    }

    const checkAllField = () => {
        let { formData }: SignUpDataProps = signUpData;
        let result = true;
        Object.keys(formData).map((key: any) => {
            if(formData[key]?.required && !formData[key].currentValue){
                result = false;
                formData[key].error = formData[key].defaultErrorText;
            }
            else
                formData[key].error = "";
        });
        setSignUpData({...signUpData, formData: formData})
        return result;
    }

    return (
        <>
            <Spinner loading={isLoading} />
            <ContainerWrapper>
                <InnerWrapper>
                    {action === "showPlans" && (
                        <>
                            <HeaderContainer>
                                <ImageLogo src="https://vega.slooh.com/assets/v4/site-redesign/icons/slooh_logo_without_text.svg" />
                                <HeaderElement>{signUpData.heading}</HeaderElement>
                            </HeaderContainer>
                            <form onSubmit={handleSubmit}>
                                {Object.keys(signUpData.formData).map((element) => (
                                    <FormSection>
                                        <FormFieldContainer>
                                            <LabelElement error={signUpData.formData[element].error}>{signUpData.formData[element]?.label}</LabelElement>
                                            {getFieldsByType(element, signUpData.formData[element])}
                                            {(signUpData.formData[element].error) && (
                                                <ErrorTextContainer>
                                                    <ErrorIcon src="https://vega.slooh.com/assets/v4/site-redesign/icons/info_error_icon_red.svg"/>
                                                    <ErrorElement>{signUpData.formData[element]?.error}</ErrorElement>
                                                </ErrorTextContainer>
                                            )}
                                        </FormFieldContainer>
                                    </FormSection>
                                ))}
                                <Divider />
                                <TermsAndConditions/>
                                <SubmitButton type="submit">
                                    CREATE ACCOUNT
                                </SubmitButton>
                            </form>
                        </>
                    )}
                    {action === "showPayment" && (
                        <>
                            <HeaderContainer>
                                <ImageLogo src="https://vega.slooh.com/assets/v4/site-redesign/icons/slooh_logo_without_text.svg" />
                                <HeaderElement>{signUpData.heading}</HeaderElement>
                                <StepText>Step 2: Payment Information</StepText>
                            </HeaderContainer>
                            <JoinStep3 />
                        </>
                    )}
                </InnerWrapper>
            </ContainerWrapper>
            <UpgradeConfirmModal
                isOpen={action === "tokenExpired"}
                onClose={handlePopupClose}
                heading={"Session Expired"}
                description={"Your Token Expired"}
                talkWithUsText="Back to Login Page"
                talkWithUsUrl="/login"
                showUpgradeDirectly={false}
                showUpgradeBtn={false}
                showTalkToUsBtn={true}
                openInNewWindow={false}
            />
        </>
    )
}

const mapStateToProps = ({ user }: any) => ({ user });

export default connect(
    mapStateToProps,
    null
  )(
    reduxForm({
    form: 'signUpCompleteForm'  // a unique identifier for this form
})(LMSAuthorize));