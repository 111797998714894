import React from 'react';
import cx from 'classnames';
import './styles.scss';

export default props => {
  const { transparent, text, blurBg, isLocal } = props;
  return (
    <div
      className={cx(
        'spinner-wrapper animated fadeIn faster',
        { transparent },
        { blurBg },
        { 'local-spinner': isLocal }
      )}
    >
      <div className="spinner-container">
        <div className="spinner" />
        {text && <div className="spinner-text">{text}</div>}
      </div>
    </div>
  );
};
