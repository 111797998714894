import css from 'styled-jsx/css';
import { primaryFont, secondaryFont } from 'app/styles/variables/fonts';
import {
  brightBlue1000,
  gray300,
  gray600,
  romance,
} from 'app/styles/variables/colors_tiles_v4';

export default css`
  .group-create-option-container {
    padding: 60px;
  }

  .group-create-option-heading {
    color: black;
    text-align: center;
    font-size: 32px;
    font-family: ${secondaryFont};
    font-style: normal;
    font-weight: 400;
    line-height: 38px;
  }

  .group-create-option-subheading {
    color: ${gray600};
    text-align: center;
    font-size: 14px;
    font-family: ${primaryFont};
    font-style: normal;
    font-weight: 390;
    line-height: normal;
  }

  .group-create-option-video-link-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
  }

  .group-create-option-video-link-icon {
    width: 20px;
    height: 20px;
  }

  .group-create-option-video-link-text {
    color: ${brightBlue1000};
    font-size: 14px;
    font-family: ${primaryFont};
    font-weight: 390;
  }

  .form-section {
    padding: 15px 0;
  }

  .form-radio-container + .form-radio-container {
    border-left: 1px solid #cbcbcb;
  }

  .form-radio-field {
    min-width: 20px;
    height: 20px;
  }

  .form-field-radio-container {
    border-radius: 8px;
    border: 1px solid #cbcbcb;
    padding: 12px;
    display: flex;
    align-items: start;
    gap: 10px;
    margin-bottom: 12px;
  }

  button.submit-button {
    background: none;
    border: none;
    margin: 0;
    padding: 12px 48px;
    transition: background-color 0.25s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 100px;
    background-color: ${brightBlue1000};
    font-weight: bold;
    font-family: ${primaryFont};
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: ${romance};
    width: 100%;
    box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.12),
      0px 2px 5px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.08);
  }

  button.submit-button:focus:not(.focus-visible){
    outline: none;
  }

  .button-container{
    display: flex;
    gap: 30px;
    justify-content: space-between;
  }

  button.submit-button:disabled,
  button.submit-button[disabled] {
    background-color: ${gray300};
  }

  .sign-up-title {
    color: black;
    font-size: 16px;
    font-family: ${primaryFont};
    font-weight: 400;
    margin-bottom: 8px;
  }

  .sign-up-subtitle {
    color: ${gray600};
    font-size: 14px;
    font-family: Brandon Grotesque;
    font-weight: 390;
    line-height: 20px;
  }
`;
