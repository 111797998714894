export default function newMissionData() {
  return {
    missionIndex: 0,
    missionAvailable: false,
    missionObjective: '',
    missionLikeCount: 0,
    missionStart: 0,
    durationSec: 0,
    expires: 0,
    scheduledMissionId: 0,
    objectId: 0,
    objectTitle: '',
    objectIconURL: '',
    objectDescription: '',
    objectRA: '',
    objectDec: '',
    objectConstellation: '---',
    objectMagnitude: '0',
    objectSizeArcMinutes: 0,
    objectDistance: '---',
    objectRiseTime: '---',
    objectTransitTime: '---',
    objectSetTime: '---',
    objectMoonProximity: '---',
    objectMoonAltitude: '-9',
    objectMoonIllumination: '75',
    nextMissionAvailable: false,
    nextScheduledMissionId: '',
    nextStart: 0,
    nextTitle: '',
    nextObjectIconURL: '',
    ownerId: '',
    ownerLocation: '',
    ownerDisplayName: '',
    ownerMembershipType: '',
    ownerGuardianFlag: false,
    ownerMemberSince: '',
    ownerAvatarType: '',
    ownerAvatarURL: '',
    coordinateArray: [],
    missionData: [],
    objectShortTitle: '',
    processing: '',
    schedulingMember: '',
    timestamp: 0,
  };
}

/**
  example full response for the documentation:
  const exampleFullMissionData = {
      "ver": "v1",
      "lang": "en",
      "timestamp": 1479505537,
      "obsId": "teide",
      "domeId": 2,
      "telescopeId": "teide2",
      "format": "full",
      "onlineStatus": "online",
      "apiError": false,
      "errorCode": 0,
      "errorMsg": "",
      "statusCode": 200,
      "firstMissionTimestamp": 1479496020,
      "lastMissionTimestamp": 1479537420,
      "debugFirstMissionDateTime": "2016-11-18 19:07",
      "debugLastMissionDateTime": "2016-11-19 06:37",
      "missionCount": 1,
      "missionList": [{
          "missionIndex": 0,
          "missionAvailable": true,
          "missionObjective": "",
          "missionLikeCount": 0,
          "missionStart": 1479505320,
          "durationSec": 300,
          "expires": 1479505620,
          "scheduledMissionId": 1478560,
          "objectId": 0,
          "objectTitle": "2016 LX48 Celestial Coordinates - RA: 1.77722 Dec: 27.8008",
          "objectIconURL": "https:\/\/webassets.slooh.com\/images\/placeholders\/Jupiter.svg",
          "objectDescription": "",
          "objectRA": "1.77722",
          "objectDec": "27.8008",
          "objectConstellation": "---",
          "objectMagnitude": "0",
          "objectSizeArcMinutes": 0,
          "objectDistance": "---",
          "objectRiseTime": "---",
          "objectTransitTime": "---",
          "objectSetTime": "---",
          "objectMoonProximity": "---",
          "objectMoonAltitude": "-9",
          "objectMoonIllumination": "75",
          "nextMissionAvailable": true,
          "nextScheduledMissionId": "1478561",
          "nextStart": 1479505620,
          "nextTitle": "Target name is not available",
          "nextObjectIconURL": "https:\/\/webassets.slooh.com\/images\/placeholders\/Jupiter.svg",
          "ownerId": "187161",
          "ownerLocation": "",
          "ownerDisplayName": "WayneC.140428",
          "ownerMembershipType": "Astronomer",
          "ownerGuardianFlag": false,
          "ownerMemberSince": "2014",
          "ownerAvatarType": "dummy",
          "ownerAvatarURL": "http:\/\/images-account.slooh.com\/avatar-dummy.png"
      }]
  };
*/
