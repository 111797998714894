import React from 'react';
import { gray600 } from "app/styles/variables/colors_tiles_v4";
import { primaryFont } from "app/styles/variables/fonts";
import styled from "styled-components";

const TermsAndConditionElement = styled.h6`
    color: ${gray600};
    font-variant-numeric: lining-nums tabular-nums;
    font-family: ${primaryFont};
    font-size: 14px;
    font-style: normal;
    font-weight: 390;
    line-height: normal;
    margin-bottom: 0px;
    text-align: center;

    a{
        color: ${gray600};
        text-decoration: underline;

        :hover{
            color: ${gray600};
        }
    }
`;

const ByContinuingText = "By continuing, I agree to Slooh’s";
const TAndCText = "Terms & Conditions";
const PrivacyPolicyText = "Privacy Policy";
const PrivacyPolicyLink = "https://www.slooh.com/privacy-policy";
const TermsAndConditionsLink = "https://vega.slooh.com/docs/Terms_and_Conditions.pdf";

export const TermsAndConditions = () => {

    return(
        <TermsAndConditionElement>
            {ByContinuingText}&nbsp;
            <a target="_blank" href={PrivacyPolicyLink}>{PrivacyPolicyText}</a>&nbsp;and&nbsp;
            <a target="_blank" href={TermsAndConditionsLink}>{TAndCText}</a>.
        </TermsAndConditionElement>
    )
}