import css from 'styled-jsx/css';
import { faintShadow } from 'app/styles/variables/shadows';
import {
  astronaut,
  romance,
  golden_yellow,
  shadows,
  gray600,
  brightBlue1000,
  gray300,
  gray200,
} from 'app/styles/variables/colors_tiles_v4';
import { primaryFont, secondaryFont } from 'app/styles/variables/fonts';
import { screenLarge, screenMedium } from 'app/styles/variables/breakpoints';
import {
  backgroundImageCover,
  dropShadowContainer,
} from 'app/styles/mixins/utilities';

export default css`
  .step-root {
    margin: 0 auto;
    width: 100%;
    color: ${astronaut};
  }

  .root {
    background: white;
  }

  .wrapper {
    margin-bottom: 40px;
    box-shadow: 0px 3px 5px 0px rgba(88, 88, 88, 0.5);
    border-top: none;
    background: white;
  }

  .section-heading {
    font-size: 14px;
    font-family: ${primaryFont};
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
    padding: 25px;
    letter-spacing: 2px;
    color: ${astronaut};
  }

  .inner-container {
    padding: 25px;
    width: 100%;
    background-color: ${romance};
    ${faintShadow}
  }

  .inner-header-container {
    height: auto;
  }

  .create-workspace-container {
    padding: 60px;
  }

  .form {
    padding: 25px 15px;
    color: ${astronaut};
  }

  .form-section {
    padding: 15px 0;
  }

  .form-section.split {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .form-label {
    display: inline-block;
    font-family: ${primaryFont};
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: ${gray600};
  }

  .form-error {
    display: inline-block;
    color: red;
    font-weight: bold;
  }

  .form-field {
  }

  .form-field-container {
    margin-bottom: 20px;
  }

  .form-dropdown {
    padding: 9px 12px;
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #333;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #afafaf;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  select.form-dropdown:disabled, select.form-dropdown[disabled]{
    background-color: ${gray300};
    color: black;
  }

  .form-field-half {
    width: 100%;
  }

  .button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  button.submit-button {
    background: none;
    border: none;
    margin: 0;
    padding: 12px 48px;
    transition: background-color 0.25s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 100px;
    background-color: ${brightBlue1000};
    font-weight: bold;
    font-family: ${primaryFont};
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: ${romance};
    width: 100%;
    box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.12),
      0px 2px 5px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.08);
  }

  button.submit-button:disabled,
  button.submit-button[disabled] {
    background-color: ${gray300};
  }

  button.submit-button:focus {
    outline: none;
  }

  .heading {
    color: black;
    text-align: center;
    font-size: 32px;
    font-family: ${secondaryFont};
    font-style: normal;
    font-weight: 400;
    line-height: 38px;
  }

  .subheading {
    color: ${gray600};
    text-align: center;
    font-size: 14px;
    font-family: ${primaryFont};
    font-weight: 400;
    margin-bottom: 12px;
  }

  .video-link-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
  }

  .video-link-icon {
    width: 20px;
    height: 20px;
  }

  .video-link-text {
    color: ${brightBlue1000};
    font-size: 14px;
    font-family: ${primaryFont};
    font-weight: 390;
  }

  .ins-container{
    border: 1px solid ${gray200};
    border-radius: 8px;
    padding: 20px;
  }

  .skip-for-now{
    color: ${brightBlue1000};
    text-align: center;
    font-family: ${primaryFont};
    font-size: 11px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-top: 24px;
    cursor: pointer;
  }

  @media ${screenMedium} {
    .step-root {
      width: 600px;
    }

    .form-section.split {
      flex-direction: row;
    }

    .form-field-half {
      width: 50%;
    }

    .wrapper {
      padding: 0 40px;
    }
  }
`;
