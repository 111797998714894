import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'app/store';
import { IUpcomingEventsObject } from 'app/modules/upcoming-events/upcoming-events-interface';
import { selectUpcomingEvents } from 'app/modules/upcoming-events/upcoming-events-selectors';

import './styles.scss';

const CalendarIcon: FC = () => {
  const upcomingEventsObject = useSelector<RootState, IUpcomingEventsObject>(
    selectUpcomingEvents
  );

  const {
    upcomingEvents: { eventList = [] },
  } = upcomingEventsObject;

  const isOnline = useMemo(() => !!eventList.find(event => event.eventIsLive), [
    eventList,
  ]);

  return (
    <div className="icon-container">
      <div className="icon">
        <i className="i-calendar" />
        {isOnline && <span className="count" />}
      </div>
    </div>
  );
};

export default CalendarIcon;
