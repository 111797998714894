import { actions, constants } from 'ducks-helpers';
import { handleActions } from 'redux-actions';
import { AnyAction } from 'redux';

import { LeaderboardResponse } from 'app/modules/leaderboard/types';

export const TYPES = constants('leaderboard', ['~GET_LEADERBOARD']);
export const ACTIONS = actions(TYPES);

export type TInitialState = {
  isFetching: boolean;
  serverError: string;
  leaderboardData: LeaderboardResponse | {};
};

export const initialState: TInitialState = {
  isFetching: false,
  leaderboardData: {},
  serverError: '',
};

const setFetching = (state: TInitialState): TInitialState => ({
  ...state,
  isFetching: true,
});

const setServerError = (
  state: TInitialState,
  action: AnyAction
): TInitialState => ({
  ...state,
  isFetching: false,
  serverError: action.payload,
});

const getLeaderboardSuccess = (
  state: TInitialState,
  action: AnyAction
): TInitialState => ({
  ...state,
  isFetching: false,
  leaderboardData: action.payload,
});

export default handleActions(
  {
    [TYPES.GET_LEADERBOARD]: setFetching,
    [TYPES.GET_LEADERBOARD_SUCCESS]: getLeaderboardSuccess,
    [TYPES.GET_LEADERBOARD_ERROR]: setServerError,
  },
  initialState
);
