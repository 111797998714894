import React, { FC, forwardRef, ReactNode, Ref, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { toggleGlobalNavMenu } from 'app/modules/global-navigation/actions';

import './styles.scss';
import { setCardLabel } from 'app/modules/pageLevelMetaContent/seo-actions';

interface Props {
  title?: string;
  button?: ReactNode;
  role?: string;
  tabIndex?: number;
  ref?: Ref<HTMLImageElement>;
}

const MenuHeader: FC<Props> = forwardRef<HTMLImageElement, Props>(
  (props, ref) => {
    const { title, button, role, tabIndex } = props;

    const dispatch = useDispatch();

    const closeMenu = () => {
      const DEFAULT_MENU = 'DEFAULT';

      dispatch(
        toggleGlobalNavMenu({
          activeLeft: DEFAULT_MENU,
          activeMenu: DEFAULT_MENU,
          activeRight: DEFAULT_MENU,
          isLeftOpen: false,
          isRightOpen: false,
          isNotificationMenuOpen: false,
        })
      );
      dispatch(setCardLabel(''));
    };

    return (
      <header className="menu-header">
        <img
          src="https://vega.slooh.com/assets/v4/site-redesign/icons/icon_close.svg"
          className="menu-header-close"
          onClick={closeMenu}
          alt="close"
        />
        {title && (
          <div
            className="menu-header-title"
            ref={ref}
            role={role}
            tabIndex={tabIndex}
          >
            {title}
          </div>
        )}
        {button}
      </header>
    );
  }
);

export default MenuHeader;
