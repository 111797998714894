import React from 'react';
import PropTypes from 'prop-types';
import { EditorState } from 'draft-js';

import StyleButton from './StyleButton';
import { INLINE_STYLES } from './constants';

const { func, instanceOf, any } = PropTypes;

const InlineStyleControls = props => {
  const currentStyle = props.editorState.getCurrentInlineStyle();
  const { isMacOS } = props;
  const additionalKey = isMacOS ? 'Command' : 'Ctrl';

  const textEditorAriaLabels = [
    `Bold the text by highlighting it and using the ${additionalKey} + B key combination`,
    `Make the text italicized by highlighting it and using the ${additionalKey} + I key combination`,
    `Make the text underlined by highlighting it and using the ${additionalKey} + U key combination`,
  ];

  return (
    <div className="RichEditor-controls-inline">
      {INLINE_STYLES.map((type, index) => (
        <StyleButton
          key={type.label}
          ariaLabel={textEditorAriaLabels[index]}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
          readOnly={props.readOnly}
        />
      ))}
    </div>
  );
};

InlineStyleControls.propTypes = {
  onToggle: func.isRequired,
  isMacOS: any,
  editorState: instanceOf(EditorState).isRequired,
};

InlineStyleControls.defaultProps = {
  isMacOS: true,
};

export default InlineStyleControls;
