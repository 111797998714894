import React, { Component } from 'react';
import UpgradeModal from '../../../account-settings/containers/upgrade-modal';

export class UpgradeModalDashboard extends Component {
  shouldComponentUpdate(nextProps) {
    const { callSource, showUpgradeModal } = this.props;

    return (
      nextProps.showUpgradeModal !== showUpgradeModal ||
      nextProps.callSource !== callSource
    );
  }

  render() {
    const { showUpgradeModal, callSource, onHide } = this.props;

    return (
      showUpgradeModal && (
        <UpgradeModal
          subscriptionPlansCallSource={callSource}
          show={showUpgradeModal}
          onHide={onHide}
          returnLinkType="closeandrefresh"
          returnLinkLabel="CANCEL"
        />
      )
    );
  }
}
