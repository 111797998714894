import { AnyAction } from 'redux';
import { handleActions } from 'redux-actions';
import { actions, constants } from 'ducks-helpers';

import {
  IPhotoListByStackNameResponse,
  IPhotoPhotoStackListResponse,
} from 'app/modules/photos/types';

export interface PhotosState {
  photoStackList: IPhotoPhotoStackListResponse;
  photoListByStackName: IPhotoListByStackNameResponse;
  isFetching: boolean;
  isLoaded: boolean;
  serverError: string | null;
}

export const TYPE = constants('photos', [
  '~GET_PHOTO_STACK_LIST',
  '~GET_PHOTO_LIST_BY_STACK_NAME',
]);

export const ACTION = actions(TYPE);

export const initialState: PhotosState = {
  photoStackList: {} as IPhotoPhotoStackListResponse,
  photoListByStackName: {} as IPhotoListByStackNameResponse,
  isFetching: false,
  isLoaded: false,
  serverError: null,
};

const setFetching = (state: PhotosState) => ({
  ...state,
  isFetching: true,
  isLoaded: false,
});

const setServerError = (state: PhotosState, action: AnyAction) => ({
  ...state,
  isFetching: false,
  serverError: action.payload,
  isLoaded: false,
});

const getPhotoStackListSuccess = (state: PhotosState, action: AnyAction) => ({
  ...state,
  isFetching: false,
  isLoaded: true,
  photoStackList: action.payload,
});

const getPhotoListByStackNameSuccess = (
  state: PhotosState,
  action: AnyAction
) => ({
  ...state,
  isFetching: false,
  isLoaded: true,
  photoListByStackName: action.payload,
});

export default handleActions<PhotosState>(
  {
    [TYPE.GET_PHOTO_STACK_LIST]: setFetching,
    [TYPE.GET_PHOTO_STACK_LIST_SUCCESS]: getPhotoStackListSuccess,
    [TYPE.GET_PHOTO_STACK_LIST_ERROR]: setServerError,

    [TYPE.GET_PHOTO_LIST_BY_STACK_NAME]: setFetching,
    [TYPE.GET_PHOTO_LIST_BY_STACK_NAME_SUCCESS]: getPhotoListByStackNameSuccess,
    [TYPE.GET_PHOTO_LIST_BY_STACK_NAME_ERROR]: setServerError,
  },
  initialState
);
