import { syncHistoryWithStore } from 'react-router-redux';
import { bindActionCreators } from 'redux';

//eslint-disable-line
import App from 'app/containers/App';
import CreateStory from 'app/containers/create-story';
import { QuestionMain } from 'app/modules/ask-astronomer';
import { CommunityGroupEdit } from 'app/modules/community-group-overview';
import { GalleryDetailsMain } from 'app/modules/gallery-details';
import { ImageDetailsMain } from 'app/modules/image-details';
import { LeaderboardContainerLazy } from 'app/modules/leaderboard';
import { MissionDetailsMain } from 'app/modules/mission-details';
import { MissionsMain } from 'app/modules/missions/index';

import { PurchaseConfirmationMain } from 'app/modules/purchase-confirmation';
import {
  QuestCompleteLazy,
  QuestDetailsLazy,
  QuestStepLazy,
} from 'app/modules/quests';
import { QuestPublicLazy } from 'app/modules/public/quest-details';
import { setPreviousInstrument } from 'app/modules/starshare-camera/starshare-camera-actions';
import { TelescopeDetailsMain } from 'app/modules/telescope';
import { TelescopeNavigation } from 'app/modules/telescope/components/old/telescope-navigation';

import RedirectConfirmation from 'app/pages/redirect-confirmation/RedirectConfirmation';
import Join from 'app/pages/registration/Join';
import JoinInviteByCodeStep1 from 'app/pages/registration/JoinInviteByCodeStep1';
import JoinInviteByCodeStep2 from 'app/pages/registration/JoinInviteByCodeStep2';
import JoinWorkspaceBySignupCodeStep1 from 'app/pages/registration/JoinWorkspaceBySignupCodeStep1';
import JoinInviteByEmailStep1 from 'app/pages/registration/JoinInviteByEmailStep1';
import JoinStep1SchoolSelection from 'app/pages/registration/JoinStep1SchoolSelection';
import globalOnRouteUpdate from 'app/route-functions/globalOnRouteUpdate';
import validateRegistrationPaths from 'app/route-functions/validateRegistrationPaths';
import validateUser from 'app/route-functions/validateUser';
import store from 'app/store';
import fireSloohGAPageview from 'app/utils/ga-wrapper';
import fireSloohTwitterPageview from 'app/utils/twitter-wrapper';
import React from 'react';
import { connect } from 'react-redux';
import {
  browserHistory,
  IndexRedirect,
  IndexRoute,
  Redirect,
  Route,
  Router,
} from 'react-router';
import { AccountSettingsMain } from './modules/account-settings';
import { CustomerAdminToolsMain } from './modules/customer-admin-tools';
import { StoryDetailsMain } from './modules/story-details';
import MarketingTracking from './modules/dashboard/components/marketing-tracking';
import LoginPage from './modules/loginpage';
import CheckoutRegistrationPage from './modules/checkout';
import { getLastPath, getUserInfo, setLastPath } from './modules/User';
import JoinInviteByDistrictAdmin from './pages/registration/JoinInviteByDistrictAdmin';
import LMSAuthorize from 'app/modules/lms';
import LMSAuthorizeLTI from 'app/modules/lms/lms-lti-authorize';
import { ImpersonatePage } from './modules/impersonation';
import { LmsWorkspaceSetting } from './modules/lms/lms-workspace';
import {
  DashboardRedesignLazy as DashboardRedesign,
  DistrictAdminPageLazy as DistrictAdminPage,
  BookmarkPageLazy as BookmarkPage,
  SloohChallengePageLazy as SloohChallengePage,
  GravityActionPageLazy as GravityActionPage,
  PhotosRedesignLazy as PhotosRedesign,
  GuidesRedesignLazy as GuidesRedesign,
  QuestsRedesignLazy as QuestsRedesign,
  TakeATourLinkLazy as TakeATourLink,
  QuestImageDetialsLazy as QuestImageDetials,
  myCommunityLazy as myCommunity,
  AccountPreferencesLazy as AccountPreferences,
  AccountDetailsLazy as AccountDetails,
  ObservationsLazy as Observations,
  MissionLogLazy as MissionLog,
  GalleriesRedesignLazy as GalleriesRedesign,
  OndemandVideoCategoryLazy as OndemandVideoCategory,
  WorkspaceSettingsLazy as WorkspaceSettings,
  WorkspacesPageLazy as WorkspacesPage,
  MyPastMissionsLazy as MyPastMissions,
  ProfileLazy as Profile,
  WorkspacesLazy as Workspaces,
  CommunityLazy as Community,
  MyQuestMediaLazy as MyQuestMedia,
  ItemPhotosLazy as ItemPhotos,
  ShowLazy as Show,
  ReserveObjectsLazy as ReserveObjects,
  ReserveByCatalogLazy as ReserveByCatalog,
  ReserveByTelescopeLazy as ReserveByTelescope,
  PhotoRollLazy as PhotoRoll,
  MissionsLazy as Missions,
  MissionImagesLazy as MissionImages,
  GalleryImagesLazy as GalleryImages,
  GalleryImageDetailsLazy as GalleryImageDetails,
  GalleriesLazy as Galleries,
  ExistingMissionsLazy as ExistingMissions,
  GuideDetailsLazy as GuideDetails,
  GroupOverviewInfoLazy as GroupOverviewInfo,
  CommunityGroupOverviewLazy as CommunityGroupOverview,
  GroupImportGoogleClassroomsLazy as GroupImportGoogleClassrooms,
  GroupCreateLazy as GroupCreate,
  TelescopeLazy as Telescope,
  Slooh1000Lazy as Slooh1000,
  ConstellationLazy as Constellation,
  CatalogLazy as Catalog,
  DashboardLazy as Dashboard,
  StoriesHubLazy as StoriesHub,
  StaticAppContainerLazy as StaticAppContainer,
  SloohRecommendsLazy as SloohRecommends,
  ShowsHubLazy as ShowsHub,
  ReservationsLazy as Reservations,
  QuestCompleteLazy as QuestComplete,
  ObjectListLazy as ObjectList,
  ObjectDetailsStoriesLazy as ObjectDetailsStories,
  ObjectDetailsShowsLazy as ObjectDetailsShows,
  ObjectDetailsQuestsLazy as ObjectDetailsQuests,
  ObjectDetailsOverviewLazy as ObjectDetailsOverview,
  ObjectDetailsObservationsLazy as ObjectDetailsObservations,
  ObjectDetailsMissionsLazy as ObjectDetailsMissions,
  MyPicturesLazy as MyPictures,
  TopicGuidesLazy as TopicGuides,
  SubjectGuidesLazy as SubjectGuides,
  ObjectCategoryGuideLazy as ObjectCategoryGuide,
  GroupsHubLazy as GroupsHub,
  UpgradeAstronomerLazy as UpgradeAstronomer,
  UpgradeApprenticeLazy as UpgradeApprentice,
  UpgradeLazy as Upgrade,
  ResetPasswordLazy as ResetPassword,
  PlaceholderPageLazy as PlaceholderPage,
  PublicGalleriesLazy as PublicGalleries,
  CustomerServiceLazy as CustomerService,
  NewMissionsLazy as NewMissions,
  ObjectPostsLazy as  ObjectPosts,
  ObjectDetailsLazy as  ObjectDetails,
  GuidedQuestHomeLazy as GuidedQuestHome,
  CustomPosterPageLazy as CustomPosterPage,
} from "./lazy-loads";
import { QuestsPublicLazy } from 'app/modules/public/quests';
import { checkAndSetQuestNav } from './utils/quest-navbar';

// Create an enhanced history that syncs navigation events with the store
const history = syncHistoryWithStore(browserHistory, store);

// handle to the listen callback on changes to the history
history.listen(location => {
  const { pathname } = location;
  // Fire a Google Analytics pageview event as the route / path changed.
  fireSloohGAPageview({ pagePath: pathname });
  // Fire a Twitter pageview event as the route / path changed.
  fireSloohTwitterPageview({ pagePath: pathname });
});

const handlePageUpdate = () => {
  if (window?.Appcues) {
    const { cid, membershipType, isLMSAccount, lmsType } = getUserInfo();
    window.Appcues.page();
    if (cid){
      window.Appcues.identify(cid, { accountType: membershipType, isLMSAccount: isLMSAccount, lmsType: lmsType });
      // window.Appcues.on("all", function(e, a) {
      //   console.log(e); // logs the event name
      //   console.log(a); // logs the event object
      // });
    }
  }
  const lastRoute = getLastPath();
  const pathName = window.location.pathname; 
  if(lastRoute){
    checkAndSetQuestNav(store.getState(), lastRoute, pathName);
    setLastPath(pathName);
  }
  else
    setLastPath(pathName);
  globalOnRouteUpdate();
};

const AppRouter = ({ setPreviousInstrument }) => (
  <Router history={browserHistory} onUpdate={handlePageUpdate}>
    <Route path="redirect-confirmation" component={RedirectConfirmation} />

    <Route
      path="public/quest-details/:questId/:questUUID"
      component={QuestPublicLazy}
    >
      <Route path=":view" component={QuestPublicLazy} />
    </Route>

    <Route path="public/quests" component={QuestsPublicLazy}>
      <Route path=":view" component={QuestsPublicLazy} />
    </Route>

    <Route
      path="registration"
      component={StaticAppContainer}
      onEnter={validateRegistrationPaths}
    >
      <Route path="upgrade-apprentice" component={UpgradeApprentice} />
      <Route path="upgrade-astronomer" component={UpgradeAstronomer} />
      <Route path="upgrade" component={Upgrade} />
    </Route>

    <Route path="/" component={App}>
      <IndexRoute component={Dashboard} onEnter={validateUser} />
      <Route
        exact
        path="guestDashboard"
        component={Dashboard}
        onEnter={validateUser}
      />

      <Route exact path="login" component={LoginPage} onEnter={validateUser} />
      <Route exact path="lms/authorize/:authorizeType/:type" component={LMSAuthorize} onEnter={validateUser} />
      <Route exact path="lms/authorize-lti/:authorizeType/:type" component={LMSAuthorizeLTI} onEnter={validateUser} />
      <Route exact path="lms/:authorizeType/workspace-settings" component={LmsWorkspaceSetting} onEnter={validateUser} />
      <Route exact path="login/imp/:customerUUID/:token" component={ImpersonatePage} onEnter={validateUser} />
      
      <Route
        exact
        path="NewDashboard"
        component={DashboardRedesign}
        onEnter={validateUser}
      />

      <Route
        exact
        path="profile(/:param)"
        component={Profile}
        onEnter={validateUser}
      />

      <Route
        exact
        path="past-missions"
        component={MyPastMissions}
        onEnter={validateUser}
      />

      <Route
        exact
        path="missions/mission-log/:missionId"
        component={MissionLog}
        onEnter={validateUser}
      />

      <Route
        exact
        path="gravity-points"
        component={GravityActionPage}
        onEnter={validateUser}
      />

      <Route
        exact
        path="slooh-challenge"
        component={SloohChallengePage}
        onEnter={validateUser}
      />

      <Route
        exact
        path="bookmarks"
        component={BookmarkPage}
        onEnter={validateUser}
      />

      <Route
        exact
        path="quests(/:tab)"
        component={QuestsRedesign}
        onEnter={validateUser}
      />

      <Route
        exact
        path="quest-media"
        component={MyQuestMedia}
        onEnter={validateUser}
      />

      <Route
        exact
        path="photos"
        component={PhotosRedesign}
        onEnter={validateUser}
      />

      <Route
        exact
        path="photo-stack/:id"
        component={ItemPhotos}
        onEnter={validateUser}
      />

      <Route
        exact
        path="photo-stack/gallery/:galleryId"
        component={ItemPhotos}
        onEnter={validateUser}
      />

      <Route
        exact
        path="galleries"
        component={GalleriesRedesign}
        onEnter={validateUser}
      />

      <Route
        exact
        path="community"
        component={Community}
        onEnter={validateUser}
      />

      <Route
        exact
        path="community/category/:id"
        component={OndemandVideoCategory}
        onEnter={validateUser}
      />

      <Route
        exact
        path="community(/:tab)"
        component={Community}
        onEnter={validateUser}
      />

      <Route
        exact
        path="my-pictures/show-image/:customerImageId/:shareToken"
        component={Observations}
        onEnter={validateUser}
      />

      <Route
        exact
        path="u/:code"
        component={TakeATourLink}
        onEnter={validateUser}
      />

      <Route
        exact
        path="workspaces"
        component={WorkspacesPage}
        onEnter={validateUser}
      />

      <Route
        exact
        path="workspace-settings"
        component={WorkspaceSettings}
        onEnter={validateUser}
      />

      <Route
        exact
        path="my-community"
        component={myCommunity}
        onEnter={validateUser}
      />

      <Route
        path="at/:marketingTrackingId"
        component={MarketingTracking}
        onEnter={validateUser}
      />

      <Route path="join" component={Join} onEnter={validateUser}>
        <IndexRoute component={CheckoutRegistrationPage} />
        <Redirect from="purchaseConfirmation(/:tab)" to="/NewDashboard" />
        <Route
          path="purchaseConfirmation(/:tab)"
          component={PurchaseConfirmationMain}
          onEnter={validateUser}
        />
        <Redirect from="step1" to="/login" />
        <Redirect from="step2" to="/login" />
        <Redirect from="step3" to="/login" />
        <Route
          path="step1SchoolSelection"
          component={JoinStep1SchoolSelection}
        />

        <Route
          path="byLandingPage/:subscriptionPlanHashCode"
          component={Join}
        />

        <Route path="byGuestLandingPage" component={CheckoutRegistrationPage} />

        <Route
          path="inviteByEmail/:invitationCodeHash/:invitationCreationEpoch"
          component={JoinInviteByEmailStep1}
        />
        <Route path="inviteByCodeStep1" component={JoinInviteByCodeStep1} />
        <Route path="inviteByCodeStep2" component={JoinInviteByCodeStep2} />

        <Route
          path="inviteByDistrictAdmin/:invitationCodeHash/:invitationCreationEpoch"
          component={JoinInviteByDistrictAdmin}
        />

        <Route
          path="w/:workspaceSignupCode"
          component={JoinWorkspaceBySignupCodeStep1}
        />
        <Route
          path="resetPassword/:cid/:passwordResetToken"
          component={ResetPassword}
        />
        <Redirect from="*" to="/login" />
      </Route>

      <Route
        path="reservations"
        component={Reservations}
        onEnter={validateUser}
      >
        <IndexRedirect to="reserve-by-objects" />

        <Route path="slooh-recommends" component={SloohRecommends}>
          <IndexRedirect to="new" />
          <Route
            path="existing"
            name="existing-missions"
            component={ExistingMissions}
          />
          <Route path="new" name="new-missions" component={NewMissions} />
        </Route>

        <Route path="reserve-by-objects" component={ReserveObjects} />
        <Route path="reserve-by-catalog" component={ReserveByCatalog} />
      </Route>

      <Route
        path="reservations/reserve-by-telescope"
        component={Reservations}
        onEnter={validateUser}
      >
        <IndexRedirect to="telescope/d7f673a5-7908-11e6-a635-0eb2b1774883/1ff72faa-7909-11e6-a635-0eb2b1774883" />
        <Route
          path="telescope/:obsUniqueId/:teleUniqueId"
          component={ReserveByTelescope}
        />
      </Route>

      <Route
        path="telescope-details/:obsUniqueId/:teleUniqueId"
        component={TelescopeDetailsMain}
        onEnter={validateUser}
        onLeave={() => {
          if (!window.location.pathname.includes('telescope-details')) {
            setPreviousInstrument(null);
          }
        }}
      >
        <Route path=":instrumentId" component={TelescopeNavigation} />
      </Route>

      <Route path="objects" component={ObjectList} onEnter={validateUser}>
        <Route
          path=":entryType/:SlugLookupId/:filterType"
          component={ObjectPosts}
        />
      </Route>

      <Route
        path="shows/video-viewer(/:showId)"
        component={Show}
        onEnter={validateUser}
      />

      <Route
        path="my-pictures/show-image/:customerImageId/:shareToken(/:scheduledMissionId)"
        component={ImageDetailsMain}
        onEnter={validateUser}
      />

      <Route
        exact
        path="my-pictures/quest-details/:questId/:moduleId/:stepModuleId"
        component={QuestImageDetials}
        onEnter={validateUser}
      />

      <Route
        path="my-pictures/popular/show-image(/:customerImageId)(/:shareToken)"
        component={ImageDetailsMain}
        onEnter={validateUser}
      />

      <Route path="my-pictures" component={MyPictures} onEnter={validateUser}>
        <IndexRedirect to="missions" />
        <Route path="photo-roll" title="Photo roll" component={PhotoRoll} />
        <Route path="galleries" tite="Galleries" component={Galleries} />
        <Route
          path="galleries/:galleryId"
          tite="Galleries"
          component={GalleryImages}
        />
        <Route
          path="missions/:scheduledMissionId"
          title="Mission Images"
          component={MissionImages}
        />
        <Route path="missions" title="Missions" component={Missions} />

        <Route path="public-galleries/:cid" component={PublicGalleries} />
        <Route
          path="gallery/:galleryId/show-image(/:customerImageId)(/:shareToken)"
          component={GalleryImageDetails}
        />
      </Route>

      <Route path="help/customer-service" component={CustomerService} />

      <Route
        path="guides(/:filterType)"
        component={GuidesRedesign}
        onEnter={validateUser}
      />

      <Route
        path="guide-details/:guideId"
        component={GuideDetails}
        onEnter={validateUser}
      />

      <Route
        path="guides/subject/:guideId"
        component={SubjectGuides}
        onEnter={validateUser}
      />
      <Route
        path="guides/topic/:guideId"
        component={TopicGuides}
        onEnter={validateUser}
      />
      <Route
        path="guides/history/:guideId"
        component={ObjectCategoryGuide}
        onEnter={validateUser}
      />
      <Route
        path="guides/object-category/:guideId"
        component={ObjectCategoryGuide}
        onEnter={validateUser}
      />

      <Route
        path="object-details/:objectId/question/:threadId"
        component={QuestionMain}
        onEnter={validateUser}
      />
      <Route
        path="object-details/:objectId"
        component={ObjectDetails}
        onEnter={validateUser}
      >
        <IndexRedirect to="overview" />
        <Route
          path="overview"
          component={ObjectDetailsOverview}
          onEnter={validateUser}
        />
        <Route
          path="missions"
          component={ObjectDetailsMissions}
          onEnter={validateUser}
        />
        <Route
          path="quests"
          component={ObjectDetailsQuests}
          onEnter={validateUser}
        />
        <Route
          path="stories"
          component={ObjectDetailsStories}
          onEnter={validateUser}
        />
        <Route
          path="shows"
          component={ObjectDetailsShows}
          onEnter={validateUser}
        />
        <Route
          path="observations"
          component={ObjectDetailsObservations}
          onEnter={validateUser}
        />
      </Route>

      <Route
        path="shows(/:filterType)"
        component={ShowsHub}
        onEnter={validateUser}
      />

      <Route
        path="stories(/:filterType)"
        component={StoriesHub}
        onEnter={validateUser}
      />
      <Route
        path="community/post/:postId"
        component={StoryDetailsMain}
        onEnter={validateUser}
      />
      <Route
        path="stories/:filterType/create"
        component={CreateStory}
        onEnter={validateUser}
      />

      <Route
        path="quest-details/:questId"
        component={QuestDetailsLazy}
        onEnter={validateUser}
      />
      <Route
        path="/quest-completion/:questId/:questCompletionModuleId"
        component={QuestCompleteLazy}
        onEnter={validateUser}
      />
      <Route
        path="quest-details/:questId/completed-overview"
        component={QuestComplete}
        onEnter={validateUser}
      />
      <Route
        path="quest-details/:questId/:step"
        component={QuestStepLazy}
        onEnter={validateUser}
      />
      <Route
        path="quest-details/:questId/:moduleId/custom-poster"
        component={CustomPosterPage}
        onEnter={validateUser}
      />

      <Route
        path="missions-details/:missionId"
        component={MissionDetailsMain}
        onEnter={validateUser}
      />

      <Route
        path="gallery-details/:galleryId"
        component={GalleryDetailsMain}
        onEnter={validateUser}
      />

      <Route
        path="leaderboard(/:tab)"
        component={LeaderboardContainerLazy}
        onEnter={validateUser}
      />

      <Route
        path="groups/create"
        component={GroupCreate}
        onEnter={validateUser}
      />
      <Route
        path="groups/importGoogleClassrooms"
        component={GroupImportGoogleClassrooms}
        onEnter={validateUser}
      />
      <Route
        path="groups(/:filterType)"
        component={GroupsHub}
        onEnter={validateUser}
      />

      <Route
        path="district-admin/:groupId"
        onEnter={validateUser}
        component={DistrictAdminPage}
      />

      <Route
        path="community-groups/:groupId"
        onEnter={validateUser}
        component={CommunityGroupOverview}
      >
        <IndexRedirect to="discussions" />
      </Route>

      <Route
        path="community-groups/:groupId/edit=:edit"
        onEnter={validateUser}
        component={CommunityGroupEdit}
      />
      <Route
        path="community-groups/:groupId/info"
        onEnter={validateUser}
        component={GroupOverviewInfo}
      />

      <Route
        path="community-groups/:groupId/:tabId"
        onEnter={validateUser}
        component={CommunityGroupOverview}
      />

      <Route
        path="workspaces/:groupId(/:tab)(/:threadId)"
        onEnter={validateUser}
        component={Workspaces}
      />

      <Route
        path="workspaces/:groupId/edit=:edit"
        onEnter={validateUser}
        component={CommunityGroupEdit}
      />
      <Route
        path="workspaces/:groupId/info"
        onEnter={validateUser}
        component={GroupOverviewInfo}
      />

      <Route
        path="workspaces/:groupId/:tabId"
        onEnter={validateUser}
        component={CommunityGroupOverview}
      />

      <Route
        path="workspaces/:groupId/:tabId/:threadId"
        onEnter={validateUser}
        component={CommunityGroupOverview}
      />

      <Route
        path="account-settings"
        component={AccountSettingsMain}
        onEnter={validateUser}
      >
        <IndexRedirect to="account-details" />
        <Route path="account-details" component={AccountDetails} />
        <Route path="settings" component={AccountPreferences} />
      </Route>

      <Route
        path="admin-tools"
        component={CustomerAdminToolsMain}
        onEnter={validateUser}
      />

      <Route path="missions" component={MissionsMain} onEnter={validateUser}>
        <IndexRedirect to="bySlooh1000" />
        <Route path="bySlooh1000" component={Slooh1000} />
        <Route path="byConstellation" component={Constellation} />
        <Route path="byCatalog" component={Catalog} />
        <Route path="byTelescope" component={Telescope} />
      </Route>

      <Route
        path="guidedQuestsHome"
        component={GuidedQuestHome}
      />
    </Route>

    <Route path="sitemap" component={PlaceholderPage} onEnter={validateUser} />

    <Route path="patent" component={PlaceholderPage} onEnter={validateUser} />

    <Redirect from="/scouts" to="/about/memberships" />
    <Redirect from="*" to="/" />
  </Router>
);

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setPreviousInstrument,
    },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(AppRouter);
