import React, { FC, memo, ReactNode } from 'react';
import { Modal } from 'react-bootstrap';

import './style.scss';

interface Props {
  children: ReactNode;
  show: boolean;
  onHide: () => void;
  title?: string;
  subtitle?: string;
}

const ModalFullscreen: FC<Props> = memo(props => {
  const { children, show, onHide, title, subtitle } = props;

  return (
    <Modal className="modal-fullscreen" show={show} onHide={onHide}>
      <button className="close-button" onClick={onHide} />

      {title && <div className="title">{title}</div>}
      {subtitle && <div className="subtitle">{subtitle}</div>}

      <div className="content">{children}</div>
    </Modal>
  );
});

export default ModalFullscreen;
