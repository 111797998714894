import React, { FC, useMemo } from 'react';

import { browserHistory } from 'react-router';
import { Tooltip } from 'react-tippy';

import CardMenu from 'app/modules/new-dashboard/components/card-menu';

import './styles.scss';

interface Props {
  eventId: number;
  linkUrl: string;
  image: string;
  label: string;
  message: string;
  person?: string;
  date: string;
  isNew?: boolean;
  dismissAlert: (eventId: number) => void;
}

const NotificationButton: FC<Props> = props => {
  const {
    eventId,
    linkUrl = '',
    image,
    label,
    message,
    person,
    date,
    isNew = false,
    dismissAlert,
  } = props;

  const onClick = () => {
    if (linkUrl.length) browserHistory.push(linkUrl);
  };

  const menu = useMemo(
    () => [
      {
        title: 'Dismiss',
        action: () => dismissAlert(eventId),
      },
    ],
    [dismissAlert, eventId]
  );

  return (
    <div
      className={`notification-button ${linkUrl.length && 'notification-link'}`}
      onClick={onClick}
    >
      {isNew && <span className="fa fa-circle notification-indicator" />}

      <div className="notification-menu" onClick={e => e.stopPropagation()}>
        <CardMenu items={menu} />
      </div>

      <div className="notification-content">
        {false ? ( // Temporary hidden SDEV-14129
          <div className="notification-image-wrapper">
            <img
              className="notification-image"
              src={image}
              alt="notification"
            />
          </div>
        ) : (
          <></>
        )}

        <div>
          <Tooltip title={label}>
            <div className="notification-label">{label}</div>
          </Tooltip>
          <div
            role="button"
            tabIndex={0}
            className="notification-message"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        </div>
      </div>

      <div className="notification-footer">
        <span>{person}</span>
        <span>{date}</span>
      </div>
    </div>
  );
};

export default NotificationButton;
