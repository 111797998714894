import { API } from 'app/api';

export default function grabUpdatedSlot({
  cid,
  at,
  token,
  scheduledMissionId,
  callSource,
  missionType,
  missionStart,
  obsId,
  domeId,
  telescopeId,
  objectId,
  objectType,
  objectTitle,
  objectRA,
  objectDec,
  catalog,
  catName,
  designation,
  processingRecipe,
  uniqueId,
  targetName,
}) {
  return API.post('/api/reservation/grabUpdatedSlot', {
    cid,
    at,
    token,
    scheduledMissionId,
    callSource,
    missionType,
    missionStart,
    obsId,
    domeId,
    telescopeId,
    objectId,
    objectType,
    objectTitle,
    objectRA,
    objectDec,
    catalog,
    catName,
    designation,
    processingRecipe,
    uniqueId,
    targetName,
  });
}
