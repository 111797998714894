import { createSelector } from 'reselect';

export const selectAccessibility = state => state.accessibility;

export const accessibilitySelector = createSelector(
  selectAccessibility,
  accessibility => ({
    isElementFocused: accessibility.isElementFocused,
    focusedElementId: accessibility.focusedElementId,
  })
);
