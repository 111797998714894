export const TOGGLE_IS_FOCUSED = 'TOGGLE_IS_FOCUSED';
export const SET_FOCUS_ON_ELEMENT = 'SET_FOCUS_ON_ELEMENT';
export const REMOVE_FOCUS_FROM_ELEMENT = 'REMOVE_FOCUS_FROM_ELEMENT';

export const toggleIsFocused = () => ({
  type: TOGGLE_IS_FOCUSED,
});

export const setFocusOnElement = elementId => ({
  type: SET_FOCUS_ON_ELEMENT,
  payload: elementId,
});

export const removeFocusFromElement = elementId => ({
  type: REMOVE_FOCUS_FROM_ELEMENT,
  payload: elementId,
});
