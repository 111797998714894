import React from 'react';
import styled from "styled-components";
import { browserHistory } from "react-router";
import { primaryFont } from 'app/styles/variables/fonts';
import { blue200, brightBlue1000, gray100, gray200, gray300, gray500, gray600, gray700, romance } from 'app/styles/variables/colors_tiles_v4';

const LMSStudentsContainer = styled.div`
    padding: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const SuccessIcon = styled.img`
    display: flex;
    width: 60px;
    height: 60px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    margin-bottom: 16px;
`;

const WorkspaceHeadingContainer = styled.div`
    border-radius: 4px 4px 0px 0px;
    background: ${gray100};
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 8px;
`;

const WorkspaceHeadingItem = styled.div`
    color: ${gray700};
    font-family: ${primaryFont};
    font-size: 11px;
    font-style: normal;
    font-weight: 450;
    line-height: 140%;
    letter-spacing: 1px;
    text-transform: uppercase;
`;

const WorkspaceListContainer = styled.div`
    width: 100%;
`;

const WorkspaceItemContainer = styled.div`
    padding: 16px 8px;
    border-bottom: 1px solid ${blue200};
    display: flex;
    justify-content: space-between;
;`

const WorkspaceName = styled.h4`
    color: ${gray700};
    font-family: ${primaryFont};
    font-size: 18px;
    font-style: normal;
    font-weight: 420;
    line-height: 120%;
    flex: 1;
`;

const WorkspaceStudentContainer = styled.div`
    padding: 8px 12px;
    border-radius: 40px;
    background: ${gray100};
    height: fit-content;
    display: flex;
    align-items: center;
`;

const WorkspaceStudentsCountLabel = styled.span`
    color: black;
    text-align: right;
    font-family: ${primaryFont};
    font-size: 11px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    letter-spacing: 1px;
    text-transform: uppercase;
`;

const PersonIcon = styled.img`
    width: 15px;
    height: 16px;
    margin-left: 4px;
`;

const TitleElement = styled.h2`
    color: black;
    font-size: 22px;
    font-family: ${primaryFont};
    font-weight: 420;
    margin-bottom: 32px;
`;

const SubTitleElement = styled.h6`
    color: ${gray600};
    font-size: 14px;
    font-family: ${primaryFont};
    font-weight: 300;
`;

const SubmitButton = styled.button`
    background: none;
    border: none;
    margin: 0;
    padding: 12px 48px;
    transition: background-color 0.25s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 100px;
    background-color: ${brightBlue1000};
    font-weight: bold;
    font-family: ${primaryFont};
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: ${romance};
    width: 100%;
    outline: none;
    margin-top: 32px;
    box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.12), 0px 2px 5px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.08);

    :focus:not(.focus-visible){
        outline: none;
    }
`;

const WorkspaceHeadingList = ["WORKSPACE", "STUDENTS"];

export const LMSStudent = (props: any) => {

    const { workspaceList, handleFinishButton } = props;

    return(
        <LMSStudentsContainer>
            <SuccessIcon src="https://vega.slooh.com/assets/v4/site-redesign/icons/workspace_created_icon_green.svg"/>
            <TitleElement>
                {`${workspaceList.length} ${workspaceList.length > 1 ? 
                    'WORKSPACES' : 'WORKSPACE'} CREATED`
                }
            </TitleElement>
            <WorkspaceHeadingContainer>
                {WorkspaceHeadingList.map((heading: string) => (
                    <WorkspaceHeadingItem>
                        {heading}
                    </WorkspaceHeadingItem>
                ))}
            </WorkspaceHeadingContainer>
            <WorkspaceListContainer>
                {workspaceList.map((workspace: any) => (
                    <WorkspaceItemContainer>
                        <WorkspaceName>{workspace.workspaceTitle}</WorkspaceName>
                        <WorkspaceStudentContainer>
                            <WorkspaceStudentsCountLabel>{workspace.studentList.length}</WorkspaceStudentsCountLabel>
                            <PersonIcon src="https://vega.slooh.com/assets/v4/site-redesign/icons/icon_person_black.svg" />
                        </WorkspaceStudentContainer>
                    </WorkspaceItemContainer>
                ))}
            </WorkspaceListContainer>
            <SubmitButton onClick={()=>handleFinishButton("finish")}>FINISH</SubmitButton>
        </LMSStudentsContainer>
    )
}