import { AppDispatch, RootState } from 'app/store';
import { getAssignmentListApi } from "./api";

export const GET_ASSIGNMENT_LIST_START = "GET_ASSIGNMENT_LIST_START";
export const GET_ASSIGNMENT_LIST_SUCCESS = "GET_ASSIGNMENT_LIST_SUCCESS";
export const GET_ASSIGNMENT_LIST_ERROR = "GET_ASSIGNMENT_LIST_ERROR";

const getAssignmentListStart = () => ({
  type: GET_ASSIGNMENT_LIST_START    
});

const getAssignmentListSuccess = (payload) => ({
  type: GET_ASSIGNMENT_LIST_SUCCESS,
  payload    
});

const getAssignmentListError = () => ({
  type: GET_ASSIGNMENT_LIST_ERROR,
});

export const getAssignmentListAction = ({
  discussionGroupId,
  page,
  sortBy,
  }: {
    discussionGroupId: string;
    page: number;
    sortBy: string;
  }) => (dispatch: AppDispatch, getState: () => RootState) => {
    const { at, token, cid } = getState().user;
    dispatch(getAssignmentListStart());
  
    return getAssignmentListApi({
      at,
      token,
      cid,
      discussionGroupId,
      page,
      sortBy,
    }).then(result => dispatch(getAssignmentListSuccess(result.data)))
      .catch(error => dispatch(getAssignmentListError(error)));
};