import { AnyAction } from 'redux';
import { handleActions } from 'redux-actions';
import { actions, constants } from 'ducks-helpers';
import {
  ICommunityResponse,
  ILeaderboardTopMembers1kResponse,
  ILeaderboardTopMembersResponse,
  IGravityTiersResponse,
  IGravityTierLeadersResponse,
  IOndemandVideoCategoryResponse,
} from 'app/modules/community/types';

export interface CommunityState {
  communityStats: ICommunityResponse;
  leaderboardTopMembers1k: ILeaderboardTopMembers1kResponse;
  leaderboardTopMembers: ILeaderboardTopMembersResponse;
  gravityTiers: IGravityTiersResponse;
  gravityTierLeaders: IGravityTierLeadersResponse;
  ondemandVideoSeries: any;
  ondemandVideoCategory: IOndemandVideoCategoryResponse;
  isFetching: Record<string, boolean>;
  serverError: string | null;
}

export const TYPE = constants('community', [
  '~GET_COMMUNITY_STATS',
  '~GET_LEADERBOARD_TOP_MEMBERS_1K',
  '~GET_LEADERBOARD_TOP_MEMBERS',
  '~GET_GRAVITY_TIERS',
  '~GET_GRAVITY_TIER_LEADERS',
  '~GET_ONDEMAND_VIDEO_SERIES',
  '~GET_ONDEMAND_VIDEO_CATEGORY',
]);

export const ACTION = actions(TYPE);

export const initialState: CommunityState = {
  communityStats: {} as ICommunityResponse,
  leaderboardTopMembers1k: {} as ILeaderboardTopMembers1kResponse,
  leaderboardTopMembers: {} as ILeaderboardTopMembersResponse,
  gravityTiers: {} as IGravityTiersResponse,
  gravityTierLeaders: {} as IGravityTierLeadersResponse,
  ondemandVideoSeries: {},
  ondemandVideoCategory: {} as IOndemandVideoCategoryResponse,
  isFetching: {},
  serverError: null,
};

const setCommunityStatsFetching = (state: CommunityState) => ({
  ...state,
  isFetching: { ...state.isFetching, communityStats: true },
});

const setLeaderboardTopMembers1kFetching = (state: CommunityState) => ({
  ...state,
  isFetching: { ...state.isFetching, leaderboardTopMembers1k: true },
});

const setLeaderboardTopMembersFetching = (state: CommunityState) => ({
  ...state,
  isFetching: { ...state.isFetching, leaderboardTopMembers: true },
});

const setOndemandVideoSeriesFetching = (state: CommunityState) => ({
  ...state,
  isFetching: { ...state.isFetching, ondemandVideoSeries: true },
});

const getOndemandVideoSeriesSuccess = (
  state: CommunityState,
  action: AnyAction
) => ({
  ...state,
  isFetching: { ...state.isFetching, ondemandVideoSeries: false },
  ondemandVideoSeries: action.payload,
});

const setOndemandVideoCategoryFetching = (state: CommunityState) => ({
  ...state,
  isFetching: { ...state.isFetching, ondemandVideoCategory: true },
});

const getOndemandVideoCategorySuccess = (
  state: CommunityState,
  action: AnyAction
) => ({
  ...state,
  isFetching: { ...state.isFetching, ondemandVideoCategory: false },
  ondemandVideoCategory: action.payload,
});

const setGravityTiersFetching = (state: CommunityState) => ({
  ...state,
  isFetching: { ...state.isFetching, gravityTiers: true },
});

const setGravityTierLeadersFetching = (state: CommunityState) => ({
  ...state,
  isFetching: { ...state.isFetching, gravityTierLeaders: true },
});

const setServerError = (state: CommunityState, action: AnyAction) => ({
  ...state,
  isFetching: {},
  serverError: action.payload,
});

const getCommunityStatsSuccess = (
  state: CommunityState,
  action: AnyAction
) => ({
  ...state,
  isFetching: { ...state.isFetching, communityStats: false },
  communityStats: action.payload,
});

const getLeaderboardTopMembers1kSuccess = (
  state: CommunityState,
  action: AnyAction
) => ({
  ...state,
  isFetching: { ...state.isFetching, leaderboardTopMembers1k: false },
  leaderboardTopMembers1k: action.payload,
});

const getLeaderboardTopMembersSuccess = (
  state: CommunityState,
  action: AnyAction
) => ({
  ...state,
  isFetching: { ...state.isFetching, leaderboardTopMembers: false },
  leaderboardTopMembers: action.payload,
});

const getGravityTiersSuccess = (state: CommunityState, action: AnyAction) => ({
  ...state,
  isFetching: { ...state.isFetching, gravityTiers: false },
  gravityTiers: action.payload,
});

const getGravityTierLeadersSuccess = (
  state: CommunityState,
  action: AnyAction
) => ({
  ...state,
  isFetching: { ...state.isFetching, gravityTierLeaders: false },
  gravityTierLeaders: action.payload,
});

export default handleActions<CommunityState>(
  {
    [TYPE.GET_COMMUNITY_STATS]: setCommunityStatsFetching,
    [TYPE.GET_COMMUNITY_STATS_SUCCESS]: getCommunityStatsSuccess,
    [TYPE.GET_COMMUNITY_STATS_ERROR]: setServerError,

    [TYPE.GET_LEADERBOARD_TOP_MEMBERS_1K]: setLeaderboardTopMembers1kFetching,
    [TYPE.GET_LEADERBOARD_TOP_MEMBERS_1K_SUCCESS]: getLeaderboardTopMembers1kSuccess,
    [TYPE.GET_LEADERBOARD_TOP_MEMBERS_1K_ERROR]: setServerError,

    [TYPE.GET_LEADERBOARD_TOP_MEMBERS]: setLeaderboardTopMembersFetching,
    [TYPE.GET_LEADERBOARD_TOP_MEMBERS_SUCCESS]: getLeaderboardTopMembersSuccess,
    [TYPE.GET_LEADERBOARD_TOP_MEMBERS_ERROR]: setServerError,

    [TYPE.GET_GRAVITY_TIERS]: setGravityTiersFetching,
    [TYPE.GET_GRAVITY_TIERS_SUCCESS]: getGravityTiersSuccess,
    [TYPE.GET_GRAVITY_TIERS_ERROR]: setServerError,

    [TYPE.GET_GRAVITY_TIER_LEADERS]: setGravityTierLeadersFetching,
    [TYPE.GET_GRAVITY_TIER_LEADERS_SUCCESS]: getGravityTierLeadersSuccess,
    [TYPE.GET_GRAVITY_TIER_LEADERS_ERROR]: setServerError,

    [TYPE.GET_ONDEMAND_VIDEO_SERIES]: setOndemandVideoSeriesFetching,
    [TYPE.GET_ONDEMAND_VIDEO_SERIES_SUCCESS]: getOndemandVideoSeriesSuccess,
    [TYPE.GET_ONDEMAND_VIDEO_SERIES_ERROR]: setServerError,

    [TYPE.GET_ONDEMAND_VIDEO_CATEGORY]: setOndemandVideoCategoryFetching,
    [TYPE.GET_ONDEMAND_VIDEO_CATEGORY_SUCCESS]: getOndemandVideoCategorySuccess,
    [TYPE.GET_ONDEMAND_VIDEO_CATEGORY_ERROR]: setServerError,
  },
  initialState
);
