import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Footer = ({ copyright }) => {
  const returnFocusOnObservationsTitle = (e: any) => {
    if (e.key === 'ArrowUp') {
      const elementToFocus = document.getElementById('observations-title');
      if (elementToFocus) {
        e.preventDefault();
        elementToFocus.focus();
      }
    }
  };

  return (
    <div
      className={classnames('footer-container', {
        darkThemeFooter: window.location.pathname.includes('/login'),
      })}
    >
      <div className="copyright-container">
        <nav className="copyright-inner">
          <div className="copyright-text">{copyright?.copyrightLabel}</div>

          <div>
            {copyright?.copyrightArray?.map(item => (
              <a
                key={item?.linkURL}
                href={item?.linkURL}
                className="copy-right-item"
                onKeyDown={returnFocusOnObservationsTitle}
              >
                {item.label}
              </a>
            ))}
          </div>
        </nav>
        <img
          className="nsf-logo"
          src={copyright?.NSFIconURL}
          alt="Logo: NSF icon"
          role="link"
          tabIndex={0}
        />
      </div>
    </div>
  );
};

Footer.propTypes = {
  copyright: PropTypes.shape({
    copyrightArray: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        linkURL: PropTypes.string,
      })
    ),
    copyrightLabel: PropTypes.string,
    NSFIconURL: PropTypes.string,
  }),
};

Footer.defaultProps = {
  copyright: {},
  copyrightArray: [],
  NSFIconURL: '',
};

export default Footer;
