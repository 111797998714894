import React from 'react';
import PropTypes from 'prop-types';

const { func, any } = PropTypes;

class AddDividerBlock extends React.Component {
  static propTypes = {
    onAddHorizontalRule: func.isRequired,
    isMacOS: any,
  };

  static defaultProps = {
    isMacOS: true,
  };

  render() {
    return (
      <span
        role="img"
        tabIndex={0}
        aria-label={`Create a horizonal line below your text, write it by placing the cursor after and using the key combination ${
          this.props.isMacOS ? 'Command + Option' : 'Ctrl + Alt'
        } + W`}
        className="fa fa-arrows-h RichEditor-styleButton"
        onClick={this.props.onAddHorizontalRule}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            this.props.onAddHorizontalRule(e);
          }
        }}
      />
    );
  }
}

export default AddDividerBlock;
