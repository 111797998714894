import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';
import classnames from 'classnames';

import './style.scss';

const CardMenu = ({
  items,
  icon,
  dropdownDirection,
  autoclose = true,
  onClose,
}) => {
  const handleClick = (e, action) => {
    if (!action) {
      return;
    }

    e.preventDefault();
    action();
  };
  const [show, setShow] = useState(false);

  const handleToggle = (isOpen, event, metadata) => {
    if (metadata.source !== 'select') {
      setShow(isOpen);
    }
    if (onClose) {
      onClose();
    }
  };

  const handleKeyDown = (e, action) => {
    if (e.key === 'Enter' && action) {
      e.preventDefault();
      action();
    }
  };

  return (
    <div className="card-menu">
      <Dropdown
        show={autoclose ? undefined : show}
        onToggle={!autoclose && handleToggle}
        drop={dropdownDirection || 'up'}
      >
        <Dropdown.Toggle
          variant="success"
          id="dropdown-basic"
          autoclose="outside"
        >
          <img
            src={
              icon ||
              'https://vega.slooh.com/assets/v4/site-redesign/icons/icon_more.svg'
            }
            alt="Expand Options"
          />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {items.map(item => (
            <Dropdown.Item
              key={item.title}
              href={item.link}
              onClick={e => handleClick(e, item.action)}
              onKeyDown={e => handleKeyDown(e, item.action)}
              className={classnames(item.className, {
                mobile: item.mobile,
                separator: item.separator,
              })}
              disabled={item.disabled}
              as="button"
            >
              {item.customButton || (
                <>
                  {!!item.favicon && (
                    <i
                      className={`fa ${item.favicon} item-favicon`}
                      aria-hidden="true"
                    />
                  )}
                  {!!item.image && (
                    <img
                      className="item-image"
                      src={item.image}
                      alt="menu-icon"
                    />
                  )}
                  {item.title}
                </>
              )}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export const CardMenuItemsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    title: PropTypes.node.isRequired,
    image: PropTypes.string,
    className: PropTypes.string,
    favicon: PropTypes.string,
    url: PropTypes.string,
    action: PropTypes.func,
    customButton: PropTypes.node,
  })
);

CardMenu.propTypes = {
  items: CardMenuItemsPropType.isRequired,
  icon: PropTypes.string,
  dropdownDirection: PropTypes.string,
  onClose: PropTypes.func,
  onSkipInternalLinksHandler: PropTypes.any,
};

export default CardMenu;
