import createReducer from "../utils/createReducer";
import { FETCH_EVENT_DATA_START, FETCH_EVENT_DATA_SUCCESS, FETCH_HOME_DATA_START, FETCH_HOME_DATA_SUCCESS } from "./actions";

const initialState = {
    homeEventsData: {
        isFetching: true,
    },
    eventsData: {
        isFetching: true,
    },    
}

export default createReducer(initialState, {
    [FETCH_HOME_DATA_START](state) {
        return {
            ...state,
            homeEventsData: { isFetching: true, ...state.homeEventsData}
        }
    },
    [FETCH_HOME_DATA_SUCCESS](state, { payload }) {
        return {
            ...state,
            homeEventsData: { ...payload, isFetching: false},            
        }
    },
    [FETCH_EVENT_DATA_START](state) {
        return {
            ...state,
            homeEventsData: { isFetching: true, ...state.eventsData}
        }
    },
    [FETCH_EVENT_DATA_SUCCESS](state, { payload }) {
        return {
            ...state,
            eventData: { ...payload, isFetching: false},            
        }
    }
})