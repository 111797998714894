import { getAboutDataApi, getSectionApi } from "./api";


export const GET_ABOUT_DATA_START = "GET_ABOUT_DATA_START";
export const GET_ABOUT_DATA_SUCCESS = "GET_ABOUT_DATA_SUCCESS";
export const GET_ABOUT_DATA_ERROR = "GET_ABOUT_DATA_ERROR";

export const GET_SECTION_DATA_START = "GET_SECTION_DATA_START";
export const GET_SECTION_DATA_SUCCESS = "GET_SECTION_DATA_SUCCESS";
export const GET_SECTION_DATA_ERROR = "GET_SECTION_DATA_ERROR";

export const GET_SUB_SECTION_DATA_START = "GET_SUB_SECTION_DATA_START";
export const GET_SUB_SECTION_DATA_SUCCESS = "GET_SUB_SECTION_DATA_SUCCESS";
export const GET_SUB_SECTION_DATA_ERROR = "GET_SUB_SECTION_DATA_ERROR";

export const SET_ACTIVE_SUB_SECTION = "SET_ACTIVE_SUB_SECTION";

const getAboutDataStart = () => ({
    type: GET_ABOUT_DATA_START    
});

const getAboutDataSuccess = (payload) => ({
    type: GET_ABOUT_DATA_SUCCESS,
    payload    
});

const getSectionDataStart = () => ({
    type: GET_SECTION_DATA_START    
});

const getSectionDataSuccess = (payload) => ({
    type: GET_SECTION_DATA_SUCCESS,
    payload    
});

const getSubSectionDataStart = () => ({
    type: GET_SUB_SECTION_DATA_START    
});

const getSubSectionDataSuccess = (payload) => ({
    type: GET_SUB_SECTION_DATA_SUCCESS,
    payload    
});

export const getAboutData = (data) => (dispatch, getState) => {
    dispatch(getAboutDataStart());
    const { at, cid, token } = getState().user;
    return getAboutDataApi({at, cid, token, ...data}).then(result=>{
        const res = result.data;
        if(!res.apiError){
            dispatch(getAboutDataSuccess(res));
        }
    });
}

export const getSectionData = (data) => (dispatch, getState) => {
    dispatch(getSectionDataStart());
    const { at, cid, token } = getState().user;
    return getSectionApi({at, cid, token, ...data}).then(result=>{
        const res = result.data;
        if(!res.apiError){
            dispatch(getSectionDataSuccess(res));
            if (res.subMenuCount) {
                const { linkUrl } = res.subMenuItems[0];
                const sectionTag = linkUrl.substr(linkUrl.lastIndexOf('/') + 1);
                dispatch(getSubSectionData({sectionTag}));                
            }
        }
    })
}

export const getSubSectionData = (data) => (dispatch, getState) => {
    dispatch(getSubSectionDataStart());
    const { at, cid, token } = getState().user;
    return getSectionApi({at, cid, token, ...data}).then(result=>{
        const res = result.data;
        if(!res.apiError){
            dispatch(getSubSectionDataSuccess(res));
        }
    });
}