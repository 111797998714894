import React, { useState, useEffect } from "react";
import { screenMobile } from "app/styles/variables/breakpoints";
import styled from "styled-components";
import { GroupCreateOptions } from "app/pages/community-groups/GroupCreateOptions";
import { useDispatch, useSelector } from "react-redux";
import { getLMSData, getUserInfo, removeLMSData } from "../User";
import { browserHistory } from 'react-router';
import { logUserIn } from "../login/actions";
import ImportLMSClassroom from "./lms-classroom";
import { LMSStudent } from "./lms-students";
import { processLMSSignIn, setLMSWorkspace } from "./api";
import { Spinner } from 'app/components/spinner/index';
import { getWorkspaceTitle } from "./utils";
import { brightBlue1000 } from "app/styles/variables/colors_tiles_v4";
import { primaryFont } from "app/styles/variables/fonts";
import GroupCreate from 'app/pages/community-groups/GroupCreateNew';

const ContainerWrapper = styled.div`
    width: 600px;
    position: relative;
    margin: ${props => props.workspace ? '0px' : '50px'} auto 0px;

    @media ${screenMobile} {
        width: 100%;
    }
`;

const InnerWrapper = styled.div`
    width: 100%;
    background-color: rgb(255, 255, 255);
    box-shadow: ${props => props.workspace ? 'none' : 'rgba(88, 88, 88, 0.5) 0px 0px 5px 0px'};
`;

const BackButtonContainer = styled.div`
    display: flex;
    gap: 8px;
    position: absolute;
    top: 10px;
    left: 10px;
    cursor: pointer;
    width: fit-content;
`;

const LeftArrowIcon = styled.img`
    width: 15px;
`;

const BackButtonText = styled.span`
    color: ${brightBlue1000};
    font-family: ${primaryFont};
    font-size: 10px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    letter-spacing: 1px;
    text-transform: uppercase;
`;


export const LmsWorkspaceSetting = (props: any) => {

    const { isWorkspace, handleCloseWorkspacePopup, params, isLTI } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [action, setAction] = useState('showWorkspace');
    const [workspaceList, setWorkspaceList] = useState([]);
    const { oauth_token, type: lmsType, email, id_token, authorizeType, code } = getLMSData();

    const loginFailed = useSelector((state: any) => state.logIn.loginFailed);
    const user = useSelector((state: any) => state.user);
    const dispatch = useDispatch();

    useEffect(()=>{
        if(user.isAuthorized && window.location.pathname.includes(`/lms/${params?.authorizeType}/workspace-settings`)){
            removeLMSData();
            browserHistory.push("/NewDashboard");
            return;
        }
        if(((!oauth_token || !id_token || !code) && !email && !lmsType ) && window.location.pathname.includes(`/lms/${params?.authorizeType}/workspace-settings`)){
            removeLMSData();
            browserHistory.push('/login');
            return;
        }
    },[])

    useEffect(()=>{
        if(loginFailed)
            setIsLoading(false);
    },[loginFailed]);

    const handleApiResponse = (action: string, res: any) => {
        switch(action){
            case "login":
                setIsLoading(true);
                dispatch(logUserIn({
                    lmsLogin: res.loginArray.loginEmail,
                    lmsOauthToken: res.loginArray.oAuthToken,
                    lmsType: lmsType
                }));
                break;
            case "showWorkspace":
                break;
        }
        setAction(action);
        scrollTop();
    }

    const handleCreateWorkspaceOption = (type: string, data: any) => {
        switch(type){
            case "skip":
                if(isWorkspace){
                    handleCloseWorkspacePopup();
                    return;
                }

                handleApiResponse('login', data);
                break;
            case "showStudents":
                let selectedWorkspaces = Object.keys(data).map((key)=>{
                    return {
                        id: key,
                        desc: data[key].desc,
                        title: getWorkspaceTitle(data[key].sectionTitle, data[key].courseTitle),
                    };
                });
                setIsLoading(true);
                const { at, cid, token } = getUserInfo();
                setLMSWorkspace({
                    lmsType: lmsType,
                    oauth_token: oauth_token,
                    loginEmailAddress: email,
                    selectedWorkspaces: selectedWorkspaces,
                    at,
                    cid,
                    token,
                    callsource: authorizeType,
                    id_token,
                    code,
                }).then((result: any)=>{
                    const res = result.data;
                    if(!res.apiError){
                        setAction(res.action);
                        setWorkspaceList(res.workspaceArray);
                        //removeLMSData();
                    }
                    setIsLoading(false);
                    scrollTop();
                });
                break;
            case "finish":
                if(isWorkspace){
                    handleCloseWorkspacePopup();
                    return;
                }
                setIsLoading(true);
                processLMSSignIn({oauth_token: oauth_token, lmsType: lmsType, loginEmailAddress: email, callsource: params?.authorizeType, id_token: id_token, code}).then(result=>{
                    const res = result.data;
                    if(!res.apiError){
                        handleApiResponse(res.action, res);
                        return;
                    }
                    setIsLoading(false);
                });
                break;
            case "close":
                if(isWorkspace){
                    handleCloseWorkspacePopup();
                    return;
                }
                break;
            default:
                setAction('showClassroom');
                scrollTop();
                break;
        }
    }

    const scrollTop = () => {
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 100);
    }

    const handleGoBack = () => {
        setAction("showWorkspace");
    }

    const isLTIFlow = authorizeType?.toLowerCase().includes('lti') || isLTI || false;

    return (
        <ContainerWrapper workspace={isWorkspace}>
            {action === "showClassroom" && (
                <BackButtonContainer onClick={handleGoBack}>
                    <LeftArrowIcon src="https://vega.slooh.com/assets/v4/site-redesign/icons/icon_left_arrow.svg" />
                    <BackButtonText>Back</BackButtonText>
                </BackButtonContainer>
            )}
            <Spinner loading={isLoading} />
            <InnerWrapper workspace={isWorkspace}>
                {action === "showWorkspace" && !isLTIFlow && (                    
                    <GroupCreateOptions
                        type={lmsType}
                        callSource={"createLMSWorkspace"}
                        handleCreateWorkspace={handleCreateWorkspaceOption}
                        loginEmailAddress={email}
                    />    
                )}
                {action === "showClassroom" && (
                    <ImportLMSClassroom
                        token={oauth_token}
                        type={lmsType}
                        email={email}
                        authorizeType={authorizeType}
                        handleSelectedGoogleClassroom={handleCreateWorkspaceOption}
                        id_token={id_token}
                    />
                )}
                {action === "showStudents" && (
                    <LMSStudent
                        workspaceList={workspaceList}
                        handleFinishButton={handleCreateWorkspaceOption}
                    />
                )}
                {action === "showWorkspace" && isLTIFlow && (
                    <GroupCreate 
                        isLTIAccount
                        handleLTICreateWorkspace={handleCreateWorkspaceOption}
                        LTIParams={{
                            lmsType: lmsType,
                            loginEmailAddress: email,
                            oauth_token: oauth_token,
                            callsource: authorizeType,
                            id_token: id_token,
                        }}
                    />
                )}
            </InnerWrapper>
        </ContainerWrapper>
    )
}