import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { logout } from 'app/modules/User';

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    signOut: logout,
  }, dispatch);

const Logout = ({ buttonText, signOut, theme, render }) => (
  <a style={theme} onClick={logout}>
    {render ? render() : buttonText}
  </a>
);

Logout.propTypes = {
  // provide text or provide a render component
  buttonText: PropTypes.string,
  signOut: PropTypes.func.isRequired,
  render: PropTypes.func,
};

Logout.defaultProps = {
  buttonText: 'Sign out',
  render: null,
};

export default connect(
  null,
  mapDispatchToProps
)(Logout);
