import React, { useEffect, useRef } from 'react';
import { browserHistory, withRouter } from 'react-router';

import { useSelector } from 'react-redux';
import ConnectUser from 'app/redux/components/ConnectUser';
import AlertsButton from 'app/redux/components/AlertsButton';
import { guestDashboardUrl } from 'app/config/project-config';
import { isRouteWithoutTopBar } from 'app/utils/router';
import { useClock } from 'app/hooks/use-clock';
import { Livecast } from 'app/components/GlobalNavigation/Menus/livecast';
import CommonTopBar from 'app/components/common-topbar';
import { accessibilitySelector } from 'app/modules/accessibility/selectors';
import MENU_INTERFACE from './Menus/MenuInterface';
import Progress from './Progress';
import Button from './Button';
import './TopBarRedesignStyle.scss';

const SEARCH_LABEL = 'SEARCH';

const isActive = (menuName, activeMenu) => {
  if (menuName === SEARCH_LABEL) {
    document.body.classList.add('hide-overflow');
    document.documentElement.classList.add('hide-overflow');
  } else {
    document.body.classList.remove('hide-overflow');
    document.documentElement.classList.remove('hide-overflow');
  }
  return menuName === activeMenu;
};

const TopBar = ({
  activeMenu,
  handleMenuClick,
  handleNotificationClick,
  allLivecastsInProgress,
  upcomingStarPartyList,
  levelNbr,
  levelProgress,
  location,
  isMobile
}) => {
  const notificationsButtonRef = useRef(null);
  const profileButtonRef = useRef(null);
  const startPartiesButtonRef = useRef(null);
  const searchButtonRef = useRef(null);
  const searchIsActive = isActive(activeMenu, MENU_INTERFACE.SEARCH.name);
  const userIsActive = isActive(activeMenu, MENU_INTERFACE.PROFILE.name);

  const isNotificationMenuOpen = useSelector(
    state => state.globalNavigation.isNotificationMenuOpen
  );

  const isRightOpen = useSelector(state => state.globalNavigation.isRightOpen);

  const starParties = () => handleMenuClick(MENU_INTERFACE.STAR_PARTIES.name);
  const search = () => handleMenuClick(MENU_INTERFACE.SEARCH.name);
  const profile = () => handleMenuClick(MENU_INTERFACE.PROFILE.name);
  const hamburger = () => handleMenuClick(MENU_INTERFACE.MAIN.name);

  const alerts = e => {
    if (e.type === 'click' || (e.type === 'keydown' && e.key === 'Enter')) {
      handleNotificationClick(MENU_INTERFACE.ALERTS.name);
    }
  };

  const handleLogoClick = user => {
    if (user.isAuthorized) {
      browserHistory.push('/NewDashboard');
    } else {
      window.location.href = guestDashboardUrl;
    }
  };

  const timeInMsUTC = Date.now();

  const Clock = ({ value }) => {
    const { clock } = useClock(value);
    const timeInUTC = clock.time;
    return timeInUTC ? <div className="timer">{timeInUTC}</div> : <></>;
  };

  const withoutTopBar = isRouteWithoutTopBar(location.pathname);

  const { isElementFocused, focusedElementId } = useSelector(
    accessibilitySelector
  );

  useEffect(() => {
    const buttonRefs = {
      'alerts-button': notificationsButtonRef,
      'profile-button': profileButtonRef,
      'star-parties-button': startPartiesButtonRef,
      'search-button': searchButtonRef,
    };

    if (
      !isNotificationMenuOpen &&
      isElementFocused &&
      focusedElementId === 'alerts-button'
    ) {
      if (buttonRefs['alerts-button'].current) {
        buttonRefs['alerts-button'].current.focus();
      }
    } else if (
      !isRightOpen &&
      isElementFocused &&
      (focusedElementId === 'profile-button' ||
        focusedElementId === 'star-parties-button' ||
        focusedElementId === 'search-button')
    ) {
      if (
        buttonRefs[focusedElementId] &&
        buttonRefs[focusedElementId].current
      ) {
        buttonRefs[focusedElementId].current.focus();
      }
    }
  }, [isElementFocused, focusedElementId, isNotificationMenuOpen, isRightOpen]);

  return (
    <ConnectUser
      render={user => (
        <div className="topbar-wrapper">
          {withoutTopBar ? (
            <CommonTopBar isAuthorized={user.isAuthorized} />
          ) : (
            <>
              <div className="left-side">
                <button
                  onClick={() => handleLogoClick(user)}
                  className="logo-action"
                  aria-label="Site logo"
                >
                  <i className="top-nav-icon i-logo_astronaut" />
                </button>

                <span className="top-title">
                  WELCOME TO SLOOH, {user.fname}!
                </span>
              </div>

              <div className="left-side left-side-mobile">
                <i
                  className="fa fa-bars"
                  aria-hidden="true"
                  onClick={hamburger}
                />
              </div>

              <div className="right-side" id="notifications" tabIndex={-1}>
                {!isMobile && <Clock value={timeInMsUTC} />}
                <nav className="buttons">
                  <Button
                    role="button"
                    isActive={searchIsActive}
                    handleClick={search}
                    ref={searchButtonRef}
                    ariaLabel="Open search menu"
                    id="search-button"
                  >
                    <i className="top-nav-icon icon-search" />
                  </Button>
                  <div
                    tabIndex={0}
                    role="button"
                    ref={startPartiesButtonRef}
                    id="star-parties-button"
                    onClick={() => starParties()}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        starParties();
                      }
                    }}
                    aria-label="Open star parties"
                  >
                    <Livecast
                      tabIndex="-1"
                      user={user}
                      allLivecastsInProgress={allLivecastsInProgress}
                      upcomingStarPartyList={upcomingStarPartyList.eventList}
                    />
                  </div>

                  <AlertsButton
                    ref={notificationsButtonRef}
                    id="alerts-button"
                    onClick={alerts}
                    ariaLabel="Open notification menu"
                  />

                  <Button
                    ref={profileButtonRef}
                    id="profile-button"
                    isActive={userIsActive}
                    handleClick={profile}
                    mod="no-border"
                    ariaLabel="Open profile menu"
                  >
                    <i className="top-nav-icon i-user" tabIndex="-1" />
                  </Button>

                  <div onClick={profile} className="progress-container cursor">
                    <Progress
                      title={`level ${levelNbr}`}
                      progress={levelProgress}
                      tabIndex={0}
                      role="button"
                    />
                  </div>
                </nav>
              </div>
            </>
          )}
        </div>
      )}
    />
  );
};

export default withRouter(TopBar);
