// taken from the API
export const AVAILABLE_SLOT_MISSION_USER_HAS_HOLD_DEFAULT_PROPS = {
  "slotStatus": "available",
  "missionType": "none",
  "showSlotTimes": true,
  "showBrowseButton": true,
  "showCatalogButton": true,
  "showCoordinateButton": true,
  "showHoldOneHourButtonWhenExpanded": true,
  "showCancelHoldButtonWhenExpanded": false,
  "showCancelXWhenExpanded": true,
  "showEditCoordinatesButton": false,
  "showFinishReservationButton": false,
  "showPiggybackButton": false,
  "showShareMissionIcons": false,
  "shareMissionIconsText": "",
  "canEditMission": false,
  "canDeleteMission": false,
  "canDeletePiggyback": false,
  "showNoReservations": false,
  "noReservationsIconURL": "",
  "noReservationsExplanation": "",
  "slotTitle": "Schedule this slot by selecting Slooh 500, Catalog or Coordinates",
  "slotIconURL": "https://vega.slooh.com/icons/reservations/available.svg",
  "userHasReservation": false,
  "userReservationType": "none",
  "userHasHold": true,
  "showSloohUser": false,
  "showUserDetails": false,
  "missionAvailable": true,
};
