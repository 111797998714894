/** **********************************************************************************
 * V4 Join with an Invitation Code - Enter Email Address/Invitation Code
 *************************************************************************************/
import React, { Component, cloneElement, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { Link, browserHistory } from 'react-router';
import PropTypes from 'prop-types';
import { API } from 'app/api';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import cloneDeep from 'lodash/cloneDeep';
import noop from 'lodash/noop';
import InputField from 'app/components/form/InputField';
import { createValidator, required } from 'app/modules/utils/validation';

import Button from 'app/components/common/style/buttons/Button';
import Request from 'app/components/common/network/Request';
import {
  JOIN_PAGE_ENDPOINT_URL,
  JOIN_VALIDATE_INVITATIONCODE_ENDPOINT_URL,
  JOIN_VALIDATE_INVITATION_GIFT_CARD_ENDPOINT_URL,
  JOIN_VALIDATE_INVITATION_CODE_DETAILS_URL,
} from 'app/services/registration/registration.js';
import JoinHeader from './partials/JoinHeader';
import { JOIN_BY_WORKSPACESIGNUP_TABS } from './StaticNavTabs';
import { Spinner } from 'app/components/spinner/index';
import styles from './JoinStep2.style';
import { logGoogleUserIn } from 'app/modules/login/actions';
import { logout } from 'app/modules/User';

const { string, func } = PropTypes;
@withTranslation()
class JoinWorkspaceBySignupCodeStep1 extends Component {
  static propTypes = {
    pathname: string.isRequired,
    change: func,
  };

  static defaultProps = {
    change: noop,
  };

  constructor(props) {
    super(props);

    const { user} = props;
    if (user.isAuthorized) {          
      logout(true);
    }

    this.state = {
      isWorkspaceSignupLink: false,
      accountFormDetails: {
        loginEmailAddress: {
          label: '',
          editable: true,
          visible: true,
          value: '',
          hintText: '',
          errorText: '',
        },
        invitationCode: {
          label: '',
          visible: true,
          value: '',
          hintText: '',
          errorText: '',
          applyGiftCode: ''
        },
        clubInviteAndGiftCard: {
          label: '',
          visible: true,
          value: '',
          hintText: '',
          errorText: '',
        },
      },
    };
  }

  // Obtain access to the join api service response and update the accountFormDetails state to reflect the Join Page response (set form labels)
  handleJoinPageServiceResponse = result => {
    //ToddR - 1/1/2022 - remove any existing isWorkspaceSignupCodeProcess local storage setting before the api response is processed...
    window.localStorage.removeItem('isWorkspaceSignupCodeProcess');

    const newAccountFormData = cloneDeep(this.state.accountFormDetails);
    let formFieldData = result.formFieldLabels;

    result.formFieldLabels.map((formFieldData) => {
      newAccountFormData[formFieldData.key].label = formFieldData.label;
      newAccountFormData[formFieldData.key].hintText = formFieldData.hintText;
      newAccountFormData[formFieldData.key].value= formFieldData.currentValue;
      this.props.change(formFieldData.key, formFieldData.currentValue);
    })

    /* update the account form details state so the correct hinText will show on each form field */
    this.setState(() => ({
      accountFormDetails: newAccountFormData,
      isWorkspaceSignupCodeProcess: result.isWorkspaceSignupCodeProcess,
    }));
  };

  /* This function handles a field change in the form and sets the state accordingly */
  handleFieldChange = ({ field, value }) => {
    /* Get the existing state of the signup form, modify it and re-set the state */
    const newAccountFormData = cloneDeep(this.state.accountFormDetails);
    newAccountFormData[field].value = value;

    this.setState(() => ({
      accountFormDetails: newAccountFormData,
    }));
  };

  /* Submit the Join Form and perform any validations as needed */
  handleSubmit = formValues => {
    formValues.preventDefault();


    //assume the form is ready to submit unless validation issues occur.
    let formIsComplete = true;

    const { accountFormDetails } = this.state;
    const { t } = this.props;

    const accountFormDetailsData = cloneDeep(accountFormDetails);
    /* reset the error conditions */
    accountFormDetailsData.loginEmailAddress.errorText = '';
    accountFormDetailsData.invitationCode.errorText = '';

    if (accountFormDetailsData.loginEmailAddress.value === '') {
      accountFormDetailsData.loginEmailAddress.errorText = t(
        'Ecommerce.PleaseEnterEmail'
      );
      formIsComplete = false;
    }

    if (accountFormDetailsData.invitationCode.value === '') {
      accountFormDetailsData.invitationCode.errorText = t(
        'Ecommerce.PleaseEnterInvitationCode'
      );
      formIsComplete = false;
    }

    if (formIsComplete === true) {
      accountFormDetailsData.isFetching = true;
      const checkGiftCardType = API.post(
        JOIN_VALIDATE_INVITATION_CODE_DETAILS_URL,
        {
          giftCardCode: this.state.accountFormDetails.invitationCode.value,
          loginEmailAddress: this.state.accountFormDetails.loginEmailAddress.value,
	  isWorkspaceSignupCodeProcess: this.state.isWorkspaceSignupCodeProcess,
        }
      ).then(response => {
        const res = response.data;
       
        if (res.apiError == false) {
          const giftCardTypeResult = {
            apiStatusMessage: res.statusMessage,
            checkGiftCodeValid: res.isGiftCodeValid,
            giftCodeMessage: res.giftCodeNotValidMessage,
            apiStatus: res.status,
            invitationCodeType: res.invitationCodeType,
            AccountType: res.AccountType,
          };
          if (giftCardTypeResult.apiStatus === "failed") {


            accountFormDetailsData.loginEmailAddress.errorText = giftCardTypeResult.apiStatusMessage;
            formIsComplete = false;
            accountFormDetailsData.isFetching = false;
            this.setState({ accountFormDetails: accountFormDetailsData });

          }
          if (!giftCardTypeResult.checkGiftCodeValid) {
            /* Invitation Validation failed */

            accountFormDetailsData.invitationCode.errorText = giftCardTypeResult.giftCodeMessage;
            formIsComplete = false;
            accountFormDetailsData.isFetching = false;
            this.setState({ accountFormDetails: accountFormDetailsData });

          }
          /* need to force evaulation of "true"/"false" vs. true/false. */
          if (formIsComplete) {

            /* Gift Code Apply */
            accountFormDetailsData.invitationCode.applyGiftCode = giftCardTypeResult.giftCodeMessage;
            accountFormDetailsData.clubInviteAndGiftCard.value = giftCardTypeResult.invitationCodeType
            window.localStorage.setItem(
              'AccountType',
              giftCardTypeResult.AccountType
            );
            accountFormDetailsData.isFetching = false;
            this.setState({ accountFormDetails: accountFormDetailsData });

          } else {

            accountFormDetailsData.isFetching = false;
            this.setState({ accountFormDetails: accountFormDetailsData });


          }

        }
      }).then(() => {

        if (formIsComplete) {
          /* Validate the Invitation Email Address and Code */
          if (accountFormDetailsData.clubInviteAndGiftCard.value === "SloohCard") {
            const validInvitationCodeResult = API.post(
              JOIN_VALIDATE_INVITATION_GIFT_CARD_ENDPOINT_URL,
              {
                giftCardCode: this.state.accountFormDetails.invitationCode.value,
                loginEmailAddress: this.state.accountFormDetails.loginEmailAddress.value,
                callSource: 'joinbyinvitationaltstep2giftcard',
                accountType: 'Confluence',
                type: accountFormDetailsData.clubInviteAndGiftCard.value,

              }
            )
              .then(response => {
                const res = response.data;
               
                if (res.apiError == false) {
                  const giftCodeResult = {
                    invitationNotValidMessage: res.invitationNotValidMessage,
                    checkGiftCodeValid: res.isGiftCodeValid,
                    giftCodeMessage: res.giftCodeNotValidMessage,
                    apiStatus: res.status,
                    apiStatusMessage: res.statusMessage
                  };

                  if (giftCodeResult.apiStatus === "failed") {
                    accountFormDetailsData.loginEmailAddress.errorText = giftCodeResult.apiStatusMessage;
                    this.setState({ accountFormDetails: accountFormDetailsData });
                    formIsComplete = false;
                  }
                  if (!giftCodeResult.checkGiftCodeValid) {
                    /* Invitation Validation failed */
                    accountFormDetailsData.invitationCode.errorText = giftCodeResult.giftCodeMessage;
                    this.setState({ accountFormDetails: accountFormDetailsData });
                    formIsComplete = false;
                  }
                  /* need to force evaulation of "true"/"false" vs. true/false. */
                  else {
                    if (formIsComplete) {
                      /* set the email address and the invitation code */
                      window.localStorage.setItem(
                        'inviteeEmailAddress',
                        this.state.accountFormDetails.loginEmailAddress.value
                      );
                      window.localStorage.setItem(
                        'invitationCodeAlt',
                        this.state.accountFormDetails.invitationCode.value
                      );
                      window.localStorage.setItem(
                        'clubInviteAndGiftCardDetials',
                        accountFormDetailsData.clubInviteAndGiftCard.value
                      );

                      /* Gift Code Apply */
                      accountFormDetailsData.invitationCode.applyGiftCode = giftCodeResult.giftCodeMessage;
                      this.setState({ accountFormDetails: accountFormDetailsData });

                      browserHistory.push('/join/inviteByCodeStep2')


                    }
                  }
                }
              })
              .catch(err => {
                throw ('Error: ', err);
              });

          } else {

            const validInvitationCodeResult = API.post(
              JOIN_VALIDATE_INVITATIONCODE_ENDPOINT_URL,
              {
                invitationCode: this.state.accountFormDetails.invitationCode.value,
                loginEmailAddress: this.state.accountFormDetails.loginEmailAddress.value,
		isWorkspaceSignupCodeProcess: this.state.isWorkspaceSignupCodeProcess,
              }
            )
              .then(response => {
                const res = response.data;

                if (res.apiError == false) {
                  const invitationResult = {
                    isInvitationValid: res.isInvitationValid,
                    invitationNotValidMessage: res.invitationNotValidMessage,
                  };

                  /* need to force evaulation of "true"/"false" vs. true/false. */
                  if (invitationResult.isInvitationValid === 'true') {
                    formIsComplete = true;

                    /* set the email address and the invitation code */
                    window.localStorage.setItem(
                      'inviteeEmailAddress',
                      this.state.accountFormDetails.loginEmailAddress.value
                    );
                    window.localStorage.setItem(
                      'invitationCodeAlt',
                      this.state.accountFormDetails.invitationCode.value
                    );

                    window.localStorage.setItem(
                      'clubInviteAndGiftCardDetials',
                      accountFormDetailsData.clubInviteAndGiftCard.value
                    );

		    //ToddR - 1/1/2022 - Set the isWorkspaceSignupCodeProcess flag in localstorage
		    if (this.state.isWorkspaceSignupCodeProcess == true) {
	                    window.localStorage.setItem('isWorkspaceSignupCodeProcess', true);
		    }

                    browserHistory.push('/join/inviteByCodeStep2');
                  } else {
                    /* Invitation Validation failed */
                    accountFormDetailsData.invitationCode.errorText =
                      invitationResult.invitationNotValidMessage;

                    /* make sure to persist any changes to the account signup form (error messages) */
                    this.setState({ accountFormDetails: accountFormDetailsData });

                    formIsComplete = false;
                  }
                }
              })
              .catch(err => {
                throw ('Error: ', err);
              });
          }

        }
      })
        .catch(err => {
          throw ('Error: ', err);
        });
    } else {
      /* make sure to persist any changes to the account signup form (error messages) */

      this.setState(() => ({ accountFormDetails: accountFormDetailsData }));
    }
  };

  render() {
    const { t } = this.props;
    const { accountFormDetails } = this.state;
    const {workspaceSignupCode} = this.props.params;

    const pathname = "/join/workspace";

    return (
      <div>
        <Request
          serviceURL={JOIN_PAGE_ENDPOINT_URL}
          requestBody={{ callSource: 'joinByInvitationAltStep1', classLinkSignupCode: workspaceSignupCode }}
          serviceResponseHandler={this.handleJoinPageServiceResponse}
          render={({ fetchingContent, serviceResponse: joinPageRes }) => (
            <Fragment>
              {!fetchingContent && (
                <Fragment>
		 {joinPageRes.showErrorMessaging == true && <JoinHeader
                    mainHeading={joinPageRes.pageHeading1}
                    subHeading={joinPageRes.pageHeading2}
                    showErrorMessaging={joinPageRes.showErrorMessaging}
                    activeTab={pathname}
                    tabs={JOIN_BY_WORKSPACESIGNUP_TABS}
                  />
		 }
		 {joinPageRes.showErrorMessaging == false && <JoinHeader
                    mainHeading={joinPageRes.pageHeading1}
                    subHeading={joinPageRes.pageHeading2}
                    showErrorMessaging={joinPageRes.showErrorMessaging}
                    activeTab={pathname}
                    tabs={JOIN_BY_WORKSPACESIGNUP_TABS}
                  />
		  }
                  <div className="step-root">
                    <div className="inner-container">
                      <div className="section-heading">
                        {joinPageRes.sectionHeading}
                      </div>
                      <Spinner
                        loading={accountFormDetails.isFetching}
                        text="Please wait..."
                      />
		      {joinPageRes.showFormFields && <form onSubmit={this.handleSubmit}>
                        <div className="form-section">
                          <div className="form-field-container">
                            <span
                              className="form-label"
                              dangerouslySetInnerHTML={{
                                __html:
                                  accountFormDetails.loginEmailAddress.label,
                              }}
                            />
                            :
                            <span
                              className="form-error"
                              dangerouslySetInnerHTML={{
                                __html:
                                  accountFormDetails.loginEmailAddress
                                    .errorText,
                              }}
                            />
                          </div>
                          <Field
                            name="loginEmailAddress"
                            type="email"
                            className="form-field"
                            disabled={!accountFormDetails.loginEmailAddress.editable}
                            label={
                              accountFormDetails.loginEmailAddress.hintText
                            }
                            component={InputField}
                            onChange={event => {
                              this.handleFieldChange({
                                field: 'loginEmailAddress',
                                value: event.target.value,
                              });
                            }}
                          />
                        </div>

                        <div className="form-section">
                          <div className="form-field-container">
                            <span
                              className="form-label"
                              dangerouslySetInnerHTML={{
                                __html: accountFormDetails.invitationCode.label,
                              }}
                            />
                            :
                            <span
                              className="form-error"
                              dangerouslySetInnerHTML={{
                                __html:
                                  accountFormDetails.invitationCode.errorText,
                              }}
                            />
                            <span
                              className="form-success"
                              dangerouslySetInnerHTML={{
                                __html:
                                  accountFormDetails.invitationCode.applyGiftCode,
                              }}
                            />
                          </div>
                          <Field
                            name="invitationCode"
                            type="text"
                            className="form-field"
                            disabled={!accountFormDetails.invitationCode.editable}
                            label={accountFormDetails.invitationCode.hintText}
                            value={accountFormDetails.invitationCode.value}
                            component={InputField}
                            onChange={event => {
                              this.handleFieldChange({
                                field: 'invitationCode',
                                value: event.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="button-container">
                          <Button
                            type="button"
                            text={t('Ecommerce.GoBack')}
                            onClickEvent={() => {
                              browserHistory.push('/');
                            }}
                          />
                          <button className="submit-button" type="submit">
                            {t('Ecommerce.Continue')}
                          </button>
                        </div>
                      </form>
		      }
		      {joinPageRes.showErrorMessaging && <div>
                            <span style={{marginTop: '20px', marginLeft: '20px', marginRight: '20px', textAlign: 'center', fontSize: '16px', fontStyle: 'italic'}} className="form-error" dangerouslySetInnerHTML={{ __html: joinPageRes.errorMessageToDisplay }} />
		      </div>}
                    </div>
                  </div>
                </Fragment>
              )}
            </Fragment>
          )}
        />
        <style jsx>{styles}</style>
      </div>
    );
  }
}

const mapStateToProps = ({ joinAccountForm, user }) => ({
  joinAccountForm,
  user,
});

export default connect(
  mapStateToProps,
  null
)(
  reduxForm({ form: 'joinAccountForm', enableReinitialize: true })(
    JoinWorkspaceBySignupCodeStep1
  )
);
