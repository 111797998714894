import css from 'styled-jsx/css';
import { astronaut, romance } from 'app/styles/variables/colors_tiles_v4';
import { primaryFont } from 'app/styles/variables/fonts';

export default css`
  button {
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
    transition: background-color 0.25s ease-in-out;
  }

  button:focus {
    outline: none;
  }

  .button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 100px;
    padding: 10px 20px;
    background-color: transparent;
    border: 1px dashed ${astronaut};
    text-align: left;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: bold;
    font-family: ${primaryFont};
  }

  button:disabled,
  button[disabled]{
    border: 1px dashed #d9dee4;   
    color: #d9dee4;    
  }
  
  button:disabled,
  button[disabled] span{
    color: #d9dee4;
  }

  button:disabled,
  button[disabled] img.button-icon{
    filter: invert(.2);
  }
  

  .button-container.no-border {
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .button-container.auto {
    margin: auto;
  }

  .button-container:active,
  .button-container:focus {
    outline: none;
  }

  .circular {
    width: 40px;
    height: 40px;
    padding: 0;
  }

  .plain {
    padding: 0;
    margin: 0 10px;
    border: none;
  }

  .text {
    display: inline-block;
    font-size: 11px;
    margin: 0 auto;
    white-space: nowrap;
    color: ${astronaut};
  }

  .pad-right {
    padding-right: 20px;
  }

  .active.button-container {
    background-color: ${astronaut};
  }

 .custom{
    width: 15px;
  }
  
  .active .button-icon,
  .active .text {
    color: ${romance};
  }

`;
