import { AnyAction } from 'redux';
import { handleActions } from 'redux-actions';
import { actions, constants } from 'ducks-helpers';
import { WorkspacesResponse } from 'app/modules/workspaces/types';

export interface WorkspacesState {
  workspacesList: WorkspacesResponse;
  workspacesForDashboardList: WorkspacesResponse;
  isFetching: boolean;
  serverError: string | null;
}

export const TYPE = constants('workspaces', [
  '~GET_WORKSPACE_LIST',
  '~GET_WORKSPACE_FOR_DASHBOARD_LIST',
]);

export const ACTION = actions(TYPE);

export const initialState: WorkspacesState = {
  workspacesList: {} as WorkspacesResponse,
  workspacesForDashboardList: {} as WorkspacesResponse,
  isFetching: false,
  serverError: null,
};

const setFetching = (state: WorkspacesState) => ({
  ...state,
  isFetching: true,
});

const setServerError = (state: WorkspacesState, action: AnyAction) => ({
  ...state,
  isFetching: false,
  serverError: action.payload,
});

const getWorkspaceListSuccess = (
  state: WorkspacesState,
  action: AnyAction
) => ({
  ...state,
  isFetching: false,
  workspacesList: action.payload,
});

const getWorkspaceForDashboardListSuccess = (
  state: WorkspacesState,
  action: AnyAction
) => ({
  ...state,
  isFetching: false,
  workspacesForDashboardList: action.payload,
});

export default handleActions<WorkspacesState>(
  {
    [TYPE.GET_WORKSPACE_LIST]: setFetching,
    [TYPE.GET_WORKSPACE_LIST_SUCCESS]: getWorkspaceListSuccess,
    [TYPE.GET_WORKSPACE_LIST_ERROR]: setServerError,
    [TYPE.GET_WORKSPACE_FOR_DASHBOARD_LIST]: setFetching,
    [TYPE.GET_WORKSPACE_FOR_DASHBOARD_LIST_SUCCESS]: getWorkspaceForDashboardListSuccess,
    [TYPE.GET_WORKSPACE_FOR_DASHBOARD_LIST_ERROR]: setServerError,
  },
  initialState
);
