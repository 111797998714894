import React, { FC, ReactNode } from 'react';
import { browserHistory } from 'react-router';

import './styles.scss';

interface Props {
  linkUrl: string;
  date: string;
  message: string;
  host: string;
  isNow?: boolean;
  button?: ReactNode;
  role?: string;
  tabIndex?: number;
}

const StarPartiesButton: FC<Props> = props => {
  const { linkUrl, isNow, date, message, host, button, role, tabIndex } = props;

  const onClick = () => {
    browserHistory.push(linkUrl);
  };

  return (
    <div
      className="parties-button"
      onClick={onClick}
      role={role}
      tabIndex={tabIndex}
    >
      {isNow && <span className="fa fa-circle parties-indicator" />}

      <div className="parties-label">{date}</div>
      <div className="parties-message">{message}</div>
      <div className="parties-label">Hosted by {host}</div>
      {button}
    </div>
  );
};

export default StarPartiesButton;
