import css from 'styled-jsx/css';
import { secondaryFont } from 'app/styles/variables/fonts';
import { screenMedium } from 'app/styles/variables/breakpoints';

import {
  astronaut,
  shadows,
  lynch,
} from 'app/styles/variables/colors_tiles_v4';

export default css`
  .root {
    font-family: ${secondaryFont};
  }

  .title-container {
    height: 75px;
    border-top: 1px solid ${shadows};
    border-bottom: 1px solid ${shadows};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: ${astronaut};
  }

  .title-text {
    font-size: 20px;
  }

  .desc-text {
    display: block;
    font-size: 19px;
    padding: 15px;
    color: ${lynch};
  }

  @media ${screenMedium} {
    .title-text {
      font-size: 20px;
    }
  }
`;
