import React, { FC, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

import { selectShowCategories } from 'app/modules/alerts/selectors';
import PageButton from 'app/components/page-button';
import MenuHeader from 'app/components/GlobalNavigation/Menus/menuHeader/MenuHeader';
import NotificationButton from 'app/components/GlobalNavigation/Menus/partials/buttons/NotificationButton/NotificationButton';
import MenuLabelDivider from 'app/components/GlobalNavigation/Menus/menuLabelDivider/MenuLabelDivider';
import MenuEmpty from 'app/components/GlobalNavigation/Menus/menuEmpty/MenuEmpty';

import './styles.scss';
import { RootState } from 'app/store';

interface Notification {
  _ID: string;
  eventId: number;
  active: boolean;
  eventCountdown: number;
  interestTitle: string;
  interestLabel: string;
  hasInterestIcon: boolean;
  interestIconURL: string;
  eventLabel: string;
  eventTitle: string;
  eventSubtitle: string;
  hasLink: boolean;
  linkLabel: string;
  linkUrl: string;
  canDismiss: boolean;
  isNewEvent: boolean;
}

interface CategoryNotifications {
  new?: Notification[];
  earlier?: Notification[];
}

interface Props {
  items: Notification[];
  dismissAll: () => void;
  dismissAlert: (eventId: number) => void;
}

const NotificationsRedesign: FC<Props> = props => {
  const firstMenuElementRef = useRef(null);
  const { items = [], dismissAll, dismissAlert } = props;

  const showCategories = useSelector(selectShowCategories);
  const isNotificationMenuOpen = useSelector<RootState, boolean>(
    state => state.globalNavigation.isNotificationMenuOpen
  );

  const itemsByCategories: CategoryNotifications = useMemo(
    () =>
      items.reduce(
        (acc, item, idx) => {
          if (showCategories) {
            if (item.isNewEvent) acc.new.push(item);
            else acc.earlier.push(item);
          } else {
            acc.earlier.push(item);
          }

          if (idx === items.length - 1) {
            if (!acc.new.length) delete acc.new;
            if (!acc.earlier.length) delete acc.earlier;
          }

          return acc;
        },
        { new: [], earlier: [] }
      ),
    [items, showCategories]
  );

  useEffect(() => {
    if (firstMenuElementRef.current) {
      firstMenuElementRef.current.focus();
    }
  }, [items]);

  if (!items.length) {
    return (
      <aside className="notifications-redesign">
        {isNotificationMenuOpen && (
          <MenuEmpty
            ref={firstMenuElementRef}
            icon="https://vega.slooh.com/assets/v4/site-redesign/icons/notification_bell.svg"
            message="There are no new notifications."
          />
        )}
      </aside>
    );
  }

  return (
    <aside className="notifications-redesign">
      {isNotificationMenuOpen && (
        <>
          <MenuHeader
            title="Notifications"
            role="button"
            tabIndex={0}
            ref={firstMenuElementRef}
            button={
              <PageButton
                action={dismissAll}
                className="outlined-blue-button"
                title="Dismiss all"
              />
            }
          />

          <nav className="notifications-list-container">
            {Object.keys(itemsByCategories).map(category => (
              <div key={category} className="notifications-list">
                <MenuLabelDivider label={category} />
                {itemsByCategories[category as keyof CategoryNotifications].map(
                  item => (
                    <NotificationButton
                      key={item._ID}
                      eventId={item.eventId}
                      linkUrl={item.linkUrl}
                      image="https://via.placeholder.com/30/c2a068/000000?text=."
                      label={item.eventLabel}
                      message={item.eventTitle}
                      date={item.eventSubtitle}
                      dismissAlert={dismissAlert}
                      isNew={showCategories && item.isNewEvent}
                    />
                  )
                )}
              </div>
            ))}
          </nav>
        </>
      )}
    </aside>
  );
};

export default NotificationsRedesign;
