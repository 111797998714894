import { connect } from "react-redux";
import { processLMSSignIn } from "./api";
import React, { useEffect, useState } from "react";
import { browserHistory } from 'react-router';
import { Spinner } from 'app/components/spinner/index';

const LMSAuthorizeLTI = (props: any) => {

    const [isLoading, setIsLoading] = useState(false);

    useEffect(()=>{
        const { location, params, user } = props;
        const { query } = location;

        if(user.isAuthorized){
            browserHistory.push("/NewDashboard");
            return;
        }
        
        processLMSSignIn({callsource: params.authorizeType, lmsType: params.type, ...query}).then(result=>{
            const res = result.data;
            if(!res.apiError){
                window.location.href = res.loginURL;
                return;
            }
            browserHistory.push('/login');
            setIsLoading(false);
        });
    }, []);

    return(
        <Spinner loading={isLoading} />
    )
}

const mapStateToProps = ({ user }: any) => ({ user });

export default connect(
    mapStateToProps,
    null
)(LMSAuthorizeLTI);