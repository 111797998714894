import React from 'react';
import './styles.scss';
import { Link } from 'react-router';

type ModalEmptySectionCardType = {
  noMessageText: string;
  linkUrl: string;
  linkText: string;
};

const ModalEmptySectionCard = (props: ModalEmptySectionCardType) => {
  const { noMessageText, linkUrl, linkText } = props;

  return (
    <>
      <div className="modal-empty-card-container">
        <h5 className="no-message-txt">{noMessageText}</h5>
        <a className="no-msg-link" href={linkUrl}>
          {linkText}
        </a>
      </div>
    </>
  );
};

export default ModalEmptySectionCard;
