import React, { Component } from "react";
import classnames from 'classnames';
import './styles.scss';
import Request from "app/components/common/network/Request";
import { GET_GUIDED_QUEST_MAIN_NAVIGATION } from "app/services/navigation";
import { Link } from 'react-router';

export const GuidedQuestTopBar = () => (
    <Request
        serviceURL={GET_GUIDED_QUEST_MAIN_NAVIGATION}
        method="POST"
        serviceExpiresFieldName="expires"
        // models={[userMenuModel, mainMenuModel]}
        // serviceResponseHandler={response}
        render={({
        fetchingContent,
        serviceResponse,
        // modeledResponses: { USER_MENU, MAIN_MENU },
        }) => (
            !fetchingContent && (
                <div className="tab-div">                
                    <img
                        className="slooh-logo" 
                        src={"https://vega.slooh.com/assets/v4/dashboard-new/Slooh-white-logo.png"} 
                    />
                    <span className="spacewalks-txt">SPACEWALKS</span>
                    <ul className="tab-container">
                        {Object.keys(serviceResponse.menus).map(item => (
                            <li 
                                className="tab-item"                                                                
                            >
                                <Link
                                    activeClassName="active-tab"
                                    className="tab-header"
                                    to={serviceResponse.menus[item].menuLinkURL}
                                >
                                    <span>{serviceResponse.menus[item].menuLabel}</span>
                                </Link>
                                
                            </li>
                        ))}                        
                    </ul>
                </div>  
            )
            
        )}
    />
)