import React from 'react';
import Modal from 'react-modal';

import { customModalStylesFitContent } from 'app/styles/mixins/utilities';
import { GUIDE_PANEL_IMAGE_ENDPOINT_URL } from 'app/services/guides/guide-data';
import ImagePreview from './ImagePreview';
import style from './imageClickHandler.style';

export default class ImageClickHandler extends React.Component {
  state = {
    imageId: null,
    modalOpen: false,
    scrollOffset: 0,
  };

  handleClick = e => {
    if (this.state.imageId === null && e.target.tagName === 'IMG') {
      this.setState({
        imageId: e.target.getAttribute('sloohrelatedimagerecordid'),
        modalOpen: true,
        scrollOffset: document.getElementById('app').scrollTop,
      });
    }
  };

  closePopup = () => {
    this.setState({
      imageId: null,
      modalOpen: false,
    });

    setTimeout(() => {
      const { scrollOffset } = this.state;
      document.getElementById('app').scrollTo(0, scrollOffset);
    }, 1);
  };

  render() {
    const { imageUrl } = this.props;
    const { modalOpen, imageId } = this.state;
    return (
      <div onClick={this.handleClick}>
        <Modal
          ariaHideApp={false}
          isOpen={modalOpen}
          style={customModalStylesFitContent}
          onRequestClose={this.closePopup}
        >
          <i className="fa fa-close" onClick={this.closePopup} />
          <ImagePreview
            withMagnifier
            url={GUIDE_PANEL_IMAGE_ENDPOINT_URL}
            id={imageId}
            imageUrl={imageUrl}
          />
        </Modal>
        {this.props.children}
        <style jsx>{style}</style>
      </div>
    );
  }
}
