import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';

import ModalList from 'app/components/modal-redesign/modal-list';
import { getBookmarkListDataAction } from 'app/modules/new-dashboard/actions';
import { createStructuredSelector } from 'reselect';
import { makeBookmarkListSelector } from 'app/modules/new-dashboard/selectors';

const BookmarksModal = ({
  isOpen,
  onClose,
  bookmarkList,
  getBookmarkListDataAction,
}) => {
  const { itemList, emptyLinkText, emptyLinkURL, emptyMessage } =
    bookmarkList || {};
  const [isLoading, setIsLoading] = React.useState(true);

  const getModalList = () => {
    if (!itemList) return [];
    return itemList.map(item => ({
      title: item.itemTypeHuman,
      value: item.itemType === 'object' ? item.objectTitle : item.itemTitle,
      link: item.linkURL,
      action: item => browserHistory.push(item.link),
    }));
  };

  React.useEffect(() => {
    if (!isOpen) {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    getBookmarkListDataAction({
      readingListType: 'all',
      paginationStartIndex: 1,
      maxItemsPerPage: 100,
    });
  }, [isOpen]);

  React.useEffect(() => {
    if (itemList) setIsLoading(false);
  }, [bookmarkList]);

  return (
    <ModalList
      isOpen={isOpen}
      handleCloseModal={onClose}
      title="Bookmarks"
      list={getModalList()}
      isLoading={isLoading}
      emptyMessage={emptyMessage}
      emptyLinkURL={emptyLinkURL}
      emptyLinkText={emptyLinkText}
    />
  );
};

BookmarksModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  getBookmarkListDataAction,
};

const mapStateToProps = createStructuredSelector({
  bookmarkList: makeBookmarkListSelector(),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  BookmarksModal
);
