import React, { FC } from 'react';
import { browserHistory } from 'react-router';

import PageContainer from 'app/components/page-container';
import './style.scss';
import { guestDashboardUrl } from 'app/config/project-config';

interface Props {
  isAuthorized: boolean;
}

const CommonTopBar: FC<Props> = props => {
  const { isAuthorized } = props;

  const handleLogoClick = () => {
    if (isAuthorized) {
      browserHistory.push('/NewDashboard');
    } else {
      window.location.href = guestDashboardUrl;
    }
  };

  return (
    <PageContainer className="topbar-header-container" withoutHeight>
      {isAuthorized && (
        <div
          className="topbar-back-button"
          onClick={() => browserHistory.goBack()}
        >
          <img
            src="https://vega.slooh.com/assets/v4/site-redesign/icons/arrow_right.svg"
            alt="Back"
          />
        </div>
      )}

      <div className="topbar-logo">
        <button onClick={handleLogoClick} className="logo-action">
          <i className="top-nav-icon i-logo_astronaut" />
        </button>
      </div>
    </PageContainer>
  );
};

export default CommonTopBar;
