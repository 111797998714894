import React, { useState } from 'react';
import { Spinner } from 'app/components/spinner/index';
import styled from 'styled-components';
import { brightBlue1000, gray300 } from 'app/styles/variables/colors_tiles_v4';
import { primaryFont } from 'app/styles/variables/fonts';
import { API } from 'app/api';

const LMSButton = styled.button`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 48px;
    gap: 8px;
    background: white;
    border: 1px solid ${gray300};
    border-radius: 100px;
    box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.12), 0px 2px 5px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.08);
    margin-bottom: 10px;
    font-family: ${primaryFont};
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: ${brightBlue1000};
    width: 100%;
    justify-content: center;
`;

const ImageElement = styled.img`
    width: 20px;
    height: 20px;
`;

interface Props {
    buttonText: string;
    iconUrl: string;
    type: string;
}

export const LMSSSOButton = (props: Props) => {

    const { buttonText, iconUrl, type } = props;
    const [isLoading, setIsLoading] = useState(false);

    const handleButtonAction = () => {
        //call api and get the url 
        setIsLoading(true);
        API.post("/api/registration/processLMSSignin", {lmsType: type, callsource: 'SSO'}).then(result=>{
            const res = result.data;
            if(!res.apiError){
                window.location.href = res.loginURL;
            }
            setIsLoading(false);
        })
    }

    return (
        <>
            <Spinner loading={isLoading} />
            <LMSButton onClick={handleButtonAction}>
                {iconUrl && (
                    <ImageElement src={iconUrl} />
                )}
                {buttonText}
            </LMSButton>
        </>
    )

}