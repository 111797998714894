import React, { FC } from 'react';

import './styles.scss';

interface Props {
  text: string;
  isOnline: boolean;
  showEnhancedStatusMessage: boolean;
  enhancedStatusMessageTelescopeMenu: string;
  logoURL: string;
  handleClickOnItem: () => void;
  tabIndex?: number;
  role?: string;
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
}

const TelescopeButton: FC<Props> = props => {
  const {
    text,
    isOnline,
    showEnhancedStatusMessage,
    enhancedStatusMessageTelescopeMenu,
    logoURL,
    handleClickOnItem,
    tabIndex,
    role,
    onKeyDown
  } = props;

  return (
    <div
      className={`nav-button ${isOnline && 'telescope-button-online'}`}
      onClick={handleClickOnItem}
      onKeyDown={e => onKeyDown(e)}
      role={role}
      tabIndex={tabIndex}
    >
      {isOnline && <span className="fa fa-circle telescope-indicator" />}
      <div className="nav-button-data">
        <div className="nav-button-title">{text}</div>
        <div className="nav-button-info">
          {isOnline
            ? 'online'
            : showEnhancedStatusMessage && enhancedStatusMessageTelescopeMenu}
        </div>
      </div>
      <img src={logoURL || ''} className="nav-button-image" alt="link-item" />
    </div>
  );
};

export default TelescopeButton;
