import createReducer from '../utils/createReducer';
import {
  REMOVE_FOCUS_FROM_ELEMENT,
  SET_FOCUS_ON_ELEMENT,
  TOGGLE_IS_FOCUSED,
} from './actions';

const initialState = {
  isElementFocused: false,
  focusedElementId: null,
};

export default createReducer(initialState, {
  [TOGGLE_IS_FOCUSED](state) {
    return {
      ...state,
      isElementFocused: !state.isElementFocused,
    };
  },
  [SET_FOCUS_ON_ELEMENT](state, action) {
    return {
      ...state,
      focusedElementId: action.payload,
      isElementFocused: true,
    };
  },
  [REMOVE_FOCUS_FROM_ELEMENT](state, action) {
    return {
      ...state,
      focusedElementId:
        state.focusedElementId === action.payload
          ? null
          : state.focusedElementId,
      isElementFocused: false,
    };
  },
});
