import React from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { toggleGlobalNavMenu } from 'app/modules/global-navigation/actions';

import { romance } from '../../styles/variables/colors_tiles_v4';

const LEFT = 'left';
const RIGHT = 'right';
const MENU_WIDTH = 300;
const MENU_WIDTH_UNITS = 'px';

const propTypes = {
  isOpen: PropTypes.bool,
  position: PropTypes.oneOf([LEFT, RIGHT]),
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  render: PropTypes.func.isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  widthUnits: PropTypes.string,
};

const defaultProps = {
  isOpen: true,
  position: LEFT,
  title: '',
  width: MENU_WIDTH,
  widthUnits: MENU_WIDTH_UNITS,
};

const Menu = props => {
  const {
    handleClose,
    isOpen,
    position,
    render,
    theme,
    title,
    width,
    widthUnits,
    actions,
    className,
  } = props;
  const rootClasses = classnames({
    open: isOpen,
  });

  const { toggleGlobalNavMenu } = actions || {};

  const isLeft = position === LEFT;

  const inlineStyle = {
    ...theme,
    left: isLeft ? (isOpen ? 0 : `${-width}${widthUnits}`) : 'auto',
    right: isLeft ? 'auto' : isOpen ? 0 : `${-width}${widthUnits}`,
    width: isOpen ? `${width}${widthUnits}` : 0,
  };

  const closeMenu = () => {
    const DEFAULT_MENU = 'DEFAULT';

    toggleGlobalNavMenu({
      activeLeft: DEFAULT_MENU,
      activeMenu: DEFAULT_MENU,
      activeRight: DEFAULT_MENU,
      isLeftOpen: false,
      isRightOpen: false,
      isNotificationMenuOpen: false,
    });
  };

  return (
    <div className={className}>
      {rootClasses && rootClasses.includes('open') && (
        <div className="test" onClick={closeMenu} />
      )}
      <div className={`root ${rootClasses}`} style={inlineStyle}>
        <div className="menu-list">{render({ isOpen })}</div>
      </div>

      <style jsx>
        {`
          .test {
            position: fixed;
            width: 100%;
            height: 100%;
            background: rgba(1, 1, 1, 0.8);
            z-index: 9999;
          }

          .root {
            position: fixed;
            z-index: 9999;
            width: 300px;
            top: 0;
            overflow-y: auto;
            min-height: 100vh;
            height: 100%;
            background: ${romance};
            transition-property: left, right;
            transition-duration: 0.15s;
            transition-timing-function: ease-in-out;
            display: grid;
          }

          .menu-list {
            overflow: auto;
          }
        `}
      </style>
    </div>
  );
};

Menu.propTypes = propTypes;
Menu.defaultProps = defaultProps;

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      toggleGlobalNavMenu,
    },
    dispatch
  ),
});

export default compose(
  connect(
    null,
    mapDispatchToProps
  )
)(Menu);
