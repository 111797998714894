import React, {useEffect} from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import css from 'styled-jsx/css';
import { primaryFont } from 'app/styles/variables/fonts';
import { useDispatch, useSelector } from 'react-redux';
import { resetTriggerGoogleSignUp } from 'app/modules/Login';

export const GoogleSignInButton = (props) => {

    const login = useGoogleLogin({
        scope: props.scope,
        onSuccess: codeResponse => {
            console.log(codeResponse)
            props.onSuccess(codeResponse)
        },
        onError: errorResponse => {
            console.log(errorResponse)
            props.onFailure(errorResponse)
        },
        flow: 'auth-code',
    });

    const triggerGoogleSignUp = useSelector(state => state.login.triggerGoogleSignUp);
    const dispatch = useDispatch();

    useEffect(()=>{
        if(triggerGoogleSignUp){
            dispatch(resetTriggerGoogleSignUp())
            login();
        }
    },[triggerGoogleSignUp])

    return (
        <>
            <button 
                className={props.className}
                onClick={login}
            >
                <img src={"https://vega.slooh.com/assets/v4/site-redesign/icons/google_G_icon.svg"} />
                {props.buttonText}
            </button>
            <style jsx>{style}</style>
        </>
    )
}

const style = css`
    .google-button{
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 48px;
        gap: 8px;
        background: #FFFFFF;
        border: 1px solid #CBCBCB;
        box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.12), 0px 2px 5px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.08);
        border-radius: 100px;

        font-family: ${primaryFont};
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-feature-settings: 'tnum' on, 'lnum' on;
        color: #1177D0;
        width: 100%;
    }
`

