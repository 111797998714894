import { API } from 'app/api';

// QUEST STEP PAGE
export const getQuestStepApi = data => API.post('/api/quests/getStep', data);
export const getQuestOutputApi = data =>
  API.post('/api/quests/getOutputPanel', data);
export const getDataCollectionApi = data =>
  API.post('/api/quests/getDataCollection', data);
export const getQuestCompletedApi = data =>
  API.post('/api/quests/getQuestCompleted', data);
export const setQuestCompletedApi = data =>
  API.post('/api/quests/setQuestCompleted', data);
export const getDataCollectionSlotImagesApi = data =>
  API.post('/api/quests/getDataCollectionSlotImages', data);
export const setDataCollectionSlotImageApi = data =>
  API.post('/api/quests/setDataCollectionSlotImage', data);
// END: QUEST STEP PAGE

export const getQuestionSetApi = data =>
  API.post('/api/quests/getQuestionSet', data);
export const getQuestionSetStateApi = data =>
  API.post('/api/quests/getQuestionSetState', data);
export const setQuestionSetStateApi = data =>
  API.post('/api/quests/setQuestionSetState', data);

// QUEST QA MODULES
export const getQaFreeFormApi = data =>
  API.post('/api/quests/getQAFreeform', data);
export const setQaFreeFormApi = data =>
  API.post('/api/quests/setQAFreeform', data);
export const getQaFillBlanksApi = data =>
  API.post('/api/quests/getQAFillBlanks', data);
export const setQaFillBlanksApi = data =>
  API.post('/api/quests/setQAFillBlanks', data);
export const getQaMultipleChoiceApi = data =>
  API.post('/api/quests/getQAMultipleChoice', data);
export const setQaMultipleChoiceApi = data =>
  API.post('/api/quests/setQAMultipleChoice', data);
// END: QUEST QA MODULES

export const getQuestGuidePanelApi = data =>
  API.post('/api/quests/getQuestGuidePanel', data);
export const getCustomerQuestsApi = data =>
  API.post('/api/profiles/getCustomerQuests', data);

// QUEST ANIMATION MODULE
export const getAnimationApi = data =>
  API.post('/api/quests/getAnimation', data);
export const getAnimationFramesApi = data =>
  API.post('/api/quests/getAnimationFrames', data);
export const setAnimationApi = data =>
  API.post('/api/quests/setAnimation', data);
// END: QUEST ANIMATION MODULE

// RICH TEXT INPUT MODULE
export const getRichTextInputModuleApi = data =>
  API.post('/api/quests/getTextInput', data);
export const setRichTextInputModuleApi = data =>
  API.post('/api/quests/setTextInput', data);

// IMAGEORDERING (MONTAGE) MODULE
export const getImageorderingModuleApi = data =>
  API.post('/api/quests/getMontage', data);

export const setImageorderingModuleApi = data =>
  API.post('/api/quests/setMontage', data);

//Custom Poster
export const getCustomPosterApi = data =>
  API.post('/api/quests/getCustomPoster', data);
export const getCustomPosterAssets = data =>
  API.post('/api/quests/getCustomPosterAssets', data);
export const setCustomPosterApi = data =>
  API.post('/api/quests/setCustomPoster', data);
export const editCustomPosterApi = data =>
  API.post('/api/quests/editCustomPoster', data);
export const initializeCustomPosterApi = data =>
  API.post('/api/quests/initializeCustomPoster', data);
export const deleteCustomPosterApi = data =>
  API.post('/api/quests/deleteCustomPoster', data);

//Calculator Module
export const getCalculatorApi = data =>
  API.post('/api/quests/getCalculatorModule', data);

//Paragraph Fill in the Blanks Module
export const getParaFillBlanksApi = data =>
  API.post('/api/quests/getParaFillBlanks', data);
export const setParaFillBlanksApi = data =>
  API.post('/api/quests/setParaFillBlanks', data);

// Step Section Module
export const getStepSectionApi = data =>
  API.post('/api/quests/getStepSection', data);
