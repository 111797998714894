import { Component, Fragment, PureComponent } from "react";
import React, { useRef } from "react";
import { GoogleOAuthProvider } from '@react-oauth/google';
import "./styles.scss";
import Request from 'app/components/common/network/Request';
import cloneDeep from 'lodash/cloneDeep';
import SloohGiftCard from 'app/components/GlobalNavigation/Menus/SloohGiftCard/SloohGiftCard';
import {
    GOOGLE_CLIENT_ID_ENDPOINT_URL,
    GOOGLE_SSO_SIGNIN_ENDPOINT_URL,
    FORGOT_PASSWORD_REQUEST_APP_ENDPOINT_URL,
  } from 'app/services/registration/registration';
import { API } from "app/api";
import { createValidator, required } from "../utils/validation";
import { connect } from "react-redux";
import { Field, reduxForm } from 'redux-form';
import { bindActionCreators } from "redux";
import {
    resetLogIn,
    logUserIn,
    logGoogleUserIn,
  } from 'app/modules/login/actions';
import { withTranslation } from "react-i18next";
import InputField from 'app/components/form/InputFieldNew';
import PropTypes from 'prop-types';
import LargeButtonWithRightIcon from "app/components/common/style/buttons/LargeButtonWithRightIcon";
import { horizontalArrowRightWhite } from "app/styles/variables/iconURLs";
import Modal from 'react-modal';
import { nightfall, romance } from "app/styles/variables/colors_tiles_v4";
import { browserHistory, Link } from 'react-router';
import { guestDashboardUrl } from "app/config/project-config";
import { GoogleSignInButton } from "app/components/google-sign-in-button";
import { LMSSSOButton } from "app/components/lms-sso-btn";
import { TermsAndConditions } from "app/components/tems-conditions";

const customModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '300px',
    width: '400px',
    maxWidth: '650px',
    padding: '25px 25px',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
};

const propTypes = {
  actions: PropTypes.shape({
    logUserIn: PropTypes.func.isRequired,
    resetLogIn: PropTypes.func.isRequired,
    logGoogleUserIn: PropTypes.func.isRequired,
  }).isRequired,
};

const defaultProps = {};


@withTranslation()
class LoginPage extends PureComponent {

    static propTypes = propTypes;

    static defaultProps = defaultProps;

    state = {
      inForgotPasswordMode: false,
      forgotPasswordLoading: false,
      forgotPasswordStatusMessage: '',
      googleProfileData: {
        googleProfileId: '',
        googleProfileEmail: '',
        googleProfileGivenName: '',
        googleProfileFamilyName: '',
        googleProfilePictureURL: '',
      },
      loginFormDetails: {
        loginEmailAddress: {
          value: '',
          errorText: '',
        },
        password: {
          value: '',
          errorText: '',
        },
      },
    };

    constructor(props) {
        super(props)
    }

    componentDidMount() {
      if(this.props?.user?.isAuthorized){
        browserHistory.push('/NewDashboard');
      }
    }

    componentWillReceiveProps(newProps) {
    }

    componentWillUnmount() {
      const { actions } = this.props;
      actions.resetLogIn();
    }

    validateEmail = (email) => {
      return email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    };

    startForgotPassword = () => {
      const { loginFormDetails } = this.state;
      const newLoginFormData = cloneDeep(loginFormDetails);
      const { t, actions } = this.props;
      this.clearCurrentErrors();
      actions.resetLogIn();
      if (loginFormDetails.loginEmailAddress.value === '') {
        newLoginFormData.loginEmailAddress.errorText = t(
          'Dashboard.ForgotPasswordError'
        );
  
        this.setState(() => ({
          loginFormDetails: newLoginFormData,
          inForgotPasswordMode: false,
          forgotPasswordLoading: false,
        }));
      }
      else if(!this.validateEmail(loginFormDetails.loginEmailAddress.value)){
        newLoginFormData.loginEmailAddress.errorText = t(
          'Dashboard.ForgotPasswordEmailError'
        );
  
        this.setState(() => ({
          loginFormDetails: newLoginFormData,
          inForgotPasswordMode: false,
          forgotPasswordLoading: false,
        }));
      }
      else {
        this.setState(() => ({
          inForgotPasswordMode: true,
          forgotPasswordLoading: true,
          forgotPasswordStatusMessage: t('Dashboard.ForgotPasswordRequest'),
        }));
  
        API.post(FORGOT_PASSWORD_REQUEST_APP_ENDPOINT_URL, {
          loginEmailAddress: loginFormDetails.loginEmailAddress.value,
        })
          .then(response => {
            const { actions } = this.props;
  
            const res = response.data;
            if (res.apiError == false) {
              const forgotResult = {
                status: res.status,
                statusMessage: res.statusMessage,
              };
  
              this.setState(() => ({
                forgotPasswordStatusMessage: forgotResult.statusMessage,
                forgotPasswordLoading: false
              }));
            }
          })
          .catch(err => {
            throw ('Error: ', err);
          });
      }
    };

    processGoogleFailureResponse = googleMessageData => {
	    console.log(googleMessageData);
    };

    processGoogleSuccessResponse = googleTokenData => {  
        this.clearCurrentErrors();
        /* Process the token and get back information about this user, etc. */
        const googleSSOResult = API.post(GOOGLE_SSO_SIGNIN_ENDPOINT_URL, {
          authenticationCode: googleTokenData.code
        })
          .then(response => {
            const { actions } = this.props;
    
            const res = response.data;
            if (res.apiError == false) {
              const googleProfileResult = {
                googleProfileId: res.googleProfileId,
                googleProfileEmail: res.googleProfileInfo.email,
                googleProfileGivenName: res.googleProfileInfo.givenName,
                googleProfileFamilyName: res.googleProfileInfo.familyName,
                googleProfilePictureURL: res.googleProfileInfo.profilePictureURL,
              };
    
              this.setState({ googleProfileData: googleProfileResult });
    
              window.localStorage.setItem(
                'googleProfileId',
                googleProfileResult.googleProfileId
              );
    
              /* Log this user in via Google SSO */
              const googleProfileLoginData = {
                googleProfileId: googleProfileResult.googleProfileId,
                googleProfileEmail: googleProfileResult.googleProfileEmail,
              };
              actions.logGoogleUserIn(googleProfileLoginData);
            }
          })
          .catch(err => {
            throw ('Error: ', err);
          });
    };

    handleSubmit = formValues => {
        this.clearCurrentErrors();
        const { actions } = this.props;
        actions.logUserIn(formValues);
    
        this.setState(() => ({
          inForgotPasswordMode: false,
        }));
    };

    //capture the change to the email address field
    handleFieldChange = ({ field, value }) => {
      this.clearCurrentErrors()
      const { loginFormDetails } = this.state;
      const newLoginFormData = cloneDeep(loginFormDetails);
      newLoginFormData[field].value = value;

      this.setState(() => ({
        loginFormDetails: newLoginFormData,
      }));
    };

    clearCurrentErrors = () => {
      const { loginFormDetails } = this.state;
      //clear out any login form errors
      const newLoginFormData = cloneDeep(loginFormDetails);
      newLoginFormData.loginEmailAddress.errorText = '';
  
      this.setState(() => ({
        loginFormDetails: newLoginFormData,
      }));
    };

    closeForgotPassword = () => {
      this.setState(() => ({
        inForgotPasswordMode: false,
      }));
    };

    handleNavigateToMarketingSite = () => {
      window.location.href = guestDashboardUrl;
    }

    render(){

        const googleClientIDModel = {
            name: 'GOOGLE_CLIENT_ID_MODEL',
            model: resp => ({
                googleClientID: resp.googleClientID,
                googleClientScope: resp.googleClientScope,
                googleClientAccessType: resp.googleClientAccessType,
                googleClientResponseType: resp.googleClientResponseType,
                loginButtonText: resp.loginButtonText,
            }),
        };

        const {
          inForgotPasswordMode,
          forgotPasswordStatusMessage,
          forgotPasswordLoading,
          loginFormDetails,
        } = this.state;
    
        const { handleSubmit, loginFailed, t, loginMenuLinks } = this.props;

        return(
            <div className="login-container">
                <div className="login-contents">
                    <img onClick={this.handleNavigateToMarketingSite} className="slooh-logo" src={"https://vega.slooh.com/assets/v4/site-redesign/icons/slooh_logo_without_text.svg"} />
                    <h3 className="welcome-greet">Welcome to Slooh!</h3>
                    {/* <LMSSSOButton
                      buttonText={"Login with Schoology"}
                      iconUrl={"https://vega.slooh.com/assets/v4/site-redesign/icons/schoology_icon.svg"}
                      type={"schoology"}
                    />
                    <LMSSSOButton
                      buttonText={"Login with Brightspace"}
                      iconUrl={"https://vega.slooh.com/assets/v4/site-redesign/icons/brightspace_icon.svg"}
                      type={"brightspace"}
                    /> */}
                    <div style={{width: "100%"}}>
                        <Request
                            serviceURL={GOOGLE_CLIENT_ID_ENDPOINT_URL}
                            model={googleClientIDModel}
                            requestBody={{ callSource: 'login' }}
                            render={({
                                fetchingContent,
                                modeledResponses: { GOOGLE_CLIENT_ID_MODEL },
                            }) => (
                                <Fragment>
                                {!fetchingContent && (
                                    <Fragment>
                                    <div className="google-container">
                                      <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID_MODEL.googleClientID}>
                                        <GoogleSignInButton
                                          className={"google-button"}
                                          buttonText={GOOGLE_CLIENT_ID_MODEL.loginButtonText}
                                          scope={GOOGLE_CLIENT_ID_MODEL.googleClientScope}
                                          onSuccess={this.processGoogleSuccessResponse}
                                          onFailure={this.processGoogleFailureResponse}
                                        />
                                      </GoogleOAuthProvider>
                                        
                                    </div>
                                    </Fragment>
                                )}
                                </Fragment>
                            )}
                        />
                    </div>
                    <div className="or-separater">
                        <hr className="fixed-width-40"/>
                        <span className="or-text">Or</span>
                        <hr className="fixed-width-40"/>
                    </div>
                    <form name="loginForm" onSubmit={handleSubmit(this.handleSubmit)} className="login-form">
                      {loginFormDetails.loginEmailAddress.value !== "" && loginFormDetails.password.value !== "" && loginFailed && loginFormDetails.loginEmailAddress.errorText === "" ? (
                        <div className="field-error" style={{ marginLeft: '25px', marginBottom: '15px' }}>
                          {t('Dashboard.LoginFailed')}
                        </div>
                      ) : null}
                      {loginFormDetails.loginEmailAddress.errorText.length > 0 && (
                        <div
                          className="field-error"
                          style={{ marginBottom: '15px', marginLeft: '25px' }}
                        >
                          {loginFormDetails.loginEmailAddress.errorText}
                        </div>
                      )}
                      <div className="form-container">
                        <div>
                          <label className="form-label-style">Email Address</label>
                          <Field
                            className="formField"
                            name="username"
                            type="email"
                            label={t('Dashboard.Email')}
                            component={InputField}
                            onChange={event => {
                              this.handleFieldChange({
                                field: 'loginEmailAddress',
                                value: event.target.value,
                              });
                            }}
                            value={loginFormDetails.loginEmailAddress.value}
                          />
                        </div>
                        <div>
                          <label className="form-label-style">Password</label>
                          <Field
                            className="formField"
                            name="pwd"
                            type="password"
                            label={t('Dashboard.Password')}
                            component={InputField}
                            onChange={event => {
                              this.handleFieldChange({
                                field: 'password',
                                value: event.target.value,
                              });
                            }}
                            value={loginFormDetails.password.value}
                          />
                        </div>
                      </div>
                      <label className="forgot-password" onClick={this.startForgotPassword}>Forgot Password</label>
                      <button type="submit" className="login-btn">LOGIN</button>                        
                      <TermsAndConditions/>
                    </form>
                    <hr className="separator-line" />
                    <div className="register-container">
                      <Link className="action-btns" to="/join/inviteByCodeStep1">
                        Redeem Invitation or gift code
                      </Link>
                      <a className="action-btns" href="https://www.slooh.com/educator-form-page">
                        Get Your Free Educator Account
                      </a>
                    </div>
                </div>
                
                <Modal
                  isOpen={inForgotPasswordMode}
                  contentLabel="Bio"
                  onRequestClose={this.closeForgotPassword}
                  style={customModalStyles}
                  
                >
                  <div className="form">
                    <div className="forgot-password-req">              
                      {forgotPasswordLoading ? (
                        <p className="loadingMessage"
                          dangerouslySetInnerHTML={{
                            __html: forgotPasswordStatusMessage,
                          }}
                        />):(
                        <p
                          dangerouslySetInnerHTML={{
                            __html: forgotPasswordStatusMessage,
                          }}
                        />)
                      }
                      <div className="close-button-container">
                        <LargeButtonWithRightIcon
                          icon={horizontalArrowRightWhite}
                          theme={{
                            backgroundColor: nightfall,
                            color: romance,
                            border: 0,
                          }}
                          text={t('Dashboard.Close')}
                          onClickEvent={this.closeForgotPassword}
                        />
                      </div>
                    </div>
                  </div>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = ({ appConfig, googleProfileData, logIn, newDashboard, user }) => ({
    loginFailed: logIn.loginFailed,
    googleProfileData,
    loginMenuLinks: newDashboard?.topNav?.mainMenu?.loginMenuLinks,
    user
  });
  
  const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
      {
        resetLogIn,
        logUserIn,
        logGoogleUserIn,
      },
      dispatch
    ),
  });
  
  const loginValidation = createValidator({
    username: [required],
    pwd: [required],
  });
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(reduxForm({ form: 'loginForm', validate: loginValidation })(LoginPage));
