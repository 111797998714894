import createReducer from "../utils/createReducer"
import { FETCH_MY_COMMUNITY_DATA_START, FETCH_MY_COMMUNITY_DATA_SUCCESS } from "./action"


const initialState = {
    isFetching: true,    
}

export default createReducer(initialState, {
    [FETCH_MY_COMMUNITY_DATA_START](state){
        return{
            ...state,
            isFetching: true,
        }
    },
    [FETCH_MY_COMMUNITY_DATA_SUCCESS](state, { payload }){
        return {
            ...state,
            isFetching: false,
            myCommunity: payload,
        }
    }
})