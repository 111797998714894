import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import Request from 'app/components/common/network/Request';
import { ALERTS_INFORMATION } from 'app/services/notifications';
import { setNotifications } from 'app/modules/alerts/actions';
import TimedNotifications from './partials/TimedNotifications';
import NOTIFICATION_CONFIGURATION, {
  modelNotificationsFromApiRes,
} from './notificationsConfiguration';

const Notifications = ({
  actions,
  dismissNotification,
  notificationsCount,
  updateNotificationsCount,
  isOpen,
}) => {
  const { setNotifications } = actions || {};

  return (
    <Request
      leaveClub={`${isOpen}`}
      method="POST"
      authorizationRedirect
      serviceURL={ALERTS_INFORMATION}
      serviceExpiresFieldName="expires"
      model={modelNotificationsFromApiRes}
      serviceResponseHandler={result => {
        setNotifications({
          notifications: result.alertList,
          showNewAlertNotificationFlag: result.showNewAlertNotificationFlag,
        });
        updateNotificationsCount({
          count: result.notificationsCount,
        });
      }}
      render={({
        fetchingContent,
        modeledResponses: { ALERTS_ONLY },
        serviceResponse,
      }) => (
        <Fragment>
          {
            <TimedNotifications
              alertsOnly={ALERTS_ONLY}
              dismissNotification={dismissNotification}
              notificationConfig={NOTIFICATION_CONFIGURATION}
              notificationsCount={notificationsCount}
              updateNotificationsCount={updateNotificationsCount}
            />
          }
        </Fragment>
      )}
    />
  );
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      setNotifications,
    },
    dispatch
  ),
});

export default compose(
  connect(
    null,
    mapDispatchToProps
  )
)(Notifications);
