import { API } from 'app/api';

export const UPDATE_NOTIFICATIONS_COUNT = 'UPDATE_NOTIFICATIONS_COUNT';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const SET_NOTIFICATIONS_CATEGORIES = 'SET_NOTIFICATIONS_CATEGORIES';

const updateNotificationsCountAction = payload => ({
  type: UPDATE_NOTIFICATIONS_COUNT,
  payload,
});

const setNotificationsAction = payload => ({
  type: SET_NOTIFICATIONS,
  payload,
});

const setNotificationsCategoriesAction = payload => ({
  type: SET_NOTIFICATIONS_CATEGORIES,
  payload,
});

export const updateNotificationsCount = payload => dispatch =>
  dispatch(updateNotificationsCountAction(payload));

export const dismissNotification = ({ eventId, lang, ver }) => (
  dispatch,
  getState
) => {
  const { cid, at, token } = getState().user;
  const { notificationsCount } = getState().alerts;
  return API.post('/api/notify/markAsRead', {
    at,
    cid,
    eventId,
    lang,
    token,
    ver,
  }).then(result => {
    dispatch(
      updateNotificationsCount({
        count: notificationsCount - 1,
      })
    );
    return Object.assign({ eventId }, result.data);
  });
};

export const setNotifications = payload => dispatch =>
  dispatch(setNotificationsAction(payload));

export const setNotificationsCategories = payload => dispatch =>
  dispatch(setNotificationsCategoriesAction(payload));

export const setViewedAlerts = ({ eventId }) => (dispatch, getState) => {
  const { cid, at, token } = getState().user;
  dispatch(setNotificationsCategoriesAction({ isShowCategories: true }));

  return API.post('/api/notify/setLastViewedAlertInformation', {
    cid,
    at,
    token,
    latestNotificationId: eventId,
  });
};
