

export const getWorkspaceTitle = (sectionTitle: string, courseTitle: string | undefined) => {
    return courseTitle ? 
        `${courseTitle}: ${sectionTitle}` :
        `${sectionTitle}`;
}

export const checkDivider = (classroom: any, classroomList: any, index: number) => {
    const nextItemIndex = index + 1;
    return classroom?.courseTitle ? 
        ((nextItemIndex) >= classroomList.length ? false :
            ((classroomList[nextItemIndex]?.courseTitle) !== classroom?.courseTitle ? 
                true : false)
        ) : true;
}