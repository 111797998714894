import css from 'styled-jsx/css';
import { secondaryFont } from 'app/styles/variables/fonts';
import {
  astronaut,
  shadows,
  lynch,
} from 'app/styles/variables/colors_tiles_v4';

export default css`
  .multi-select-option {
    display: none;
  }
`;
