import React, { useEffect, useRef } from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router';

import { toggleGlobalNavMenu } from 'app/modules/global-navigation/actions';
import {
  blue800,
  blue900,
  golda,
  moodyBleu,
  romance,
} from 'app/styles/variables/colors_tiles_v4';
import { primaryFont } from 'app/styles/variables/fonts';
import { SCREEN_MEDIUM } from 'app/styles/variables/breakpoints';
import { accessibilitySelector } from 'app/modules/accessibility/selectors';
import { setFocusOnElement } from 'app/modules/accessibility/actions';
import { setCardLabel } from 'app/modules/pageLevelMetaContent/seo-actions';
import MENU_INTERFACE from './Menus/MenuInterface';

const TopButtonsBar = ({ observatoryList, actions }) => {
  const { telescopesOnlineFlag } = observatoryList || {};
  const { toggleGlobalNavMenu } = actions || {};
  const dispatch = useDispatch();
  const telescopeButtonRef = useRef(null);
  const dashboardsButtonRef = useRef(null);
  const isLeftOpen = useSelector(state => state.globalNavigation.isLeftOpen);
  const activeMenu = useSelector(state => state.globalNavigation.activeMenu);
  const openTelescopeMenu = () => {
    toggleGlobalNavMenu({
      activeLeft: MENU_INTERFACE.TELESCOPES.name,
      activeMenu: MENU_INTERFACE.TELESCOPES.name,
      activeRight: MENU_INTERFACE.DEFAULT.name,
      isLeftOpen: true,
      isRightOpen: false,
      isNotificationMenuOpen: false,
    });
  };

  const handleTelescopeInteraction = event => {
    if (
      event.type === 'click' ||
      (event.type === 'keydown' && event.key === 'Enter') ||
      (event.type === 'keydown' &&
        event.ctrlKey &&
        event.altKey &&
        event.key === ' ')
    ) {
      event.preventDefault();
      openTelescopeMenu();
      dispatch(setCardLabel('Telescopes'));
    }
    skipLinksHandler(event);
  };

  const skipLinksHandler = e => {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      const targetElement = document.getElementById('telescopes-section-title');
      if (targetElement) {
        targetElement.focus();
      }
    }
  };

  const { isElementFocused, focusedElementId } = useSelector(
    accessibilitySelector
  );

  useEffect(() => {
    const handleKeyDown = event => {
      if (event.ctrlKey && event.altKey && event.code === 'Space') {
        event.preventDefault();
      }

      if (
        event.key === 'Escape' ||
        (event.ctrlKey && event.altKey && event.code === 'Space')
      ) {
        const DEFAULT_MENU = 'DEFAULT';
        dispatch(
          toggleGlobalNavMenu({
            activeLeft: DEFAULT_MENU,
            activeMenu: DEFAULT_MENU,
            activeRight: DEFAULT_MENU,
            isLeftOpen: false,
            isRightOpen: false,
            isNotificationMenuOpen: false,
          })
        );

        let focusElement = 'alerts-button';
        switch (activeMenu) {
          case MENU_INTERFACE.PROFILE.name:
            focusElement = 'profile-button';
            break;
          case MENU_INTERFACE.STAR_PARTIES.name:
            focusElement = 'star-parties-button';
            break;
          case MENU_INTERFACE.TELESCOPES.name:
            focusElement = 'telescopes-button';
            break;
          case MENU_INTERFACE.SEARCH.name:
            focusElement = 'search-button';
            break;
          default:
            break;
        }
        dispatch(setFocusOnElement(focusElement));
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [dispatch, activeMenu]);

  useEffect(() => {
    if (
      focusedElementId === 'telescopes-button' &&
      isElementFocused &&
      !isLeftOpen
    ) {
      telescopeButtonRef?.current?.focus();
    }
  }, [isElementFocused, focusedElementId, isLeftOpen]);

  useEffect(() => {
    if (focusedElementId === 'dashboards-button' && isElementFocused) {
      dashboardsButtonRef?.current?.focus();
    }
  }, [focusedElementId]);

  return (
    <header className="top-buttons-bar">
      <h1 className="visually-hidden">Slooh</h1>
      <Link
        to="/NewDashboard"
        className="link"
        activeClassName="link-active"
        innerRef={ref => {
          dashboardsButtonRef.current = ref;
        }}
        id="dashboards-button"
        tabIndex={0}
        role="link"
        onKeyDown={e => skipLinksHandler(e)}
      >
        <div className="link-container">
          <img
            src="https://vega.slooh.com/assets/v4/site-redesign/icons/group_icon.svg"
            className="link-image"
            alt="dashboard-icon"
          />
          <h2 className="link-title">Dashboard</h2>
        </div>
      </Link>

      <div
        className="link-item"
        onClick={handleTelescopeInteraction}
        onKeyDown={handleTelescopeInteraction}
        ref={telescopeButtonRef}
        id="telescopes-button"
        tabIndex={0}
        role="button"
      >
        <Link
          tabIndex={-1}
          to="/telescopes"
          className="link disabled-link"
          activeClassName="link-active"
        >
          <div className="link-container">
            {telescopesOnlineFlag && (
              <span className="fa fa-circle link-indicator" />
            )}
            <img
              src="https://vega.slooh.com/assets/v4/site-redesign/icons/telescope_icon_gold.svg"
              className="link-image"
              alt="telescopes-icon"
            />
            <h2 className="link-title">Telescopes</h2>
          </div>
        </Link>
      </div>

      <Link
        to="/quests/my-quests"
        className="link"
        activeClassName="link-active"
        onKeyDown={e => skipLinksHandler(e)}
      >
        <div className="link-container">
          <img
            src="https://vega.slooh.com/assets/v4/site-redesign/icons/badge_icon_gold.svg"
            className="link-image"
            alt="quests-icon"
          />
          <h2 className="link-title">Quests</h2>
        </div>
      </Link>

      <Link
        to="/community"
        className="link"
        activeClassName="link-active"
        onKeyDown={e => skipLinksHandler(e)}
      >
        <div className="link-container">
          <img
            src="https://vega.slooh.com/assets/v4/site-redesign/icons/eye_icon_gold.svg"
            className="link-image"
            alt="community-icon"
          />
          <h2 className="link-title">Community</h2>
        </div>
      </Link>

      <Link
        to="/workspaces"
        className="link"
        activeClassName="link-active"
        onKeyDown={e => skipLinksHandler(e)}
      >
        <div className="link-container">
          <img
            src="https://vega.slooh.com/assets/v4/site-redesign/icons/comment_icon_gold.svg"
            className="link-image"
            alt="workspaces-icon"
          />
          <h2 className="link-title">Workspaces</h2>
        </div>
      </Link>

      <Link
        to="/guides"
        className="link no-border"
        activeClassName="link-active"
        onKeyDown={e => skipLinksHandler(e)}
      >
        <div className="link-container">
          <img
            src="https://vega.slooh.com/assets/v4/site-redesign/icons/compass_icon_gold.svg"
            className="link-image"
            alt="guides-icon"
          />
          <h2 className="link-title">Guides</h2>
        </div>
      </Link>

      {/* eslint-disable-next-line react/no-unknown-property */}
      <style jsx>
        {`
          .top-buttons-bar {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 96px;
            // margin-top: 48px;
            background-color: ${blue800};
          }

          .top-buttons-bar :global(a.link) {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;
            min-width: 150px;
            margin-right: 0;
            border-right: 2px solid ${blue900};
          }

          .top-buttons-bar :global(a.link.no-border) {
            border: none;
          }

          .top-buttons-bar :global(a.link-active .link-title) {
            color: ${romance};
          }

          .top-buttons-bar :global(a.link-active .link-title:after) {
            content: '';
            display: block;
            position: absolute;
            bottom: -22px;
            height: 2px;
            width: 100%;
            background: ${golda};
          }

          .link-open-dropdown :global(a.link-active .link-title) {
            color: ${moodyBleu};
          }

          .link-open-dropdown :global(a.link-active .link-title:after) {
            display: none;
          }

          .link-item {
            cursor: pointer;
            position: relative;
            height: 100%;
          }

          .link-open-dropdown {
            background: white;
          }

          .link-dropdown {
            position: absolute;
          }

          .link-container {
            position: relative;
            text-align: center;
          }

          .link-container .link-indicator {
            position: absolute;
            top: -2px;
            left: 13px;
            color: #65cb41;
            font-size: 8px;
          }

          .link-container .link-image {
            height: 30px;
          }

          .link-title {
            font-family: ${primaryFont};
            font-size: 10px;
            line-height: 14px;
            letter-spacing: 1px;
            margin-top: 8px;
            color: ${moodyBleu};
            text-transform: uppercase;
            position: relative;
            font-weight: 500;
          }

          .top-buttons-bar :global(a.link.disabled-link) {
            pointer-events: none;
          }

          .visually-hidden {
            position: absolute;
            clip: rect(1px, 1px, 1px, 1px);
            clip-path: inset(0px 0px 99.9% 99.9%);
            overflow: hidden;
            height: 1px;
            width: 1px;
            padding: 0;
            border: 0;
          }

          @media screen and (max-width: ${SCREEN_MEDIUM}px) {
            .top-buttons-bar {
              display: none;
            }
          }
        `}
      </style>
    </header>
  );
};

const mapStateToProps = state => ({
  observatoryList: state.observatoryList.obsList,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      toggleGlobalNavMenu,
    },
    dispatch
  ),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(withRouter(TopButtonsBar));
