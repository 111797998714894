import React, { FC, forwardRef, Ref } from 'react';

import MenuHeader from 'app/components/GlobalNavigation/Menus/menuHeader/MenuHeader';

import './styles.scss';

interface Props {
  icon: string;
  message: string;
  ref?: Ref<HTMLImageElement>;
}

const MenuEmpty: FC<Props> = forwardRef<HTMLImageElement, Props>(
  (props, ref) => {
    const { icon, message } = props;

    return (
      <div className="menu-empty">
        <MenuHeader />
        <img
          role="button"
          ref={ref}
          tabIndex={0}
          src={icon}
          className="menu-empty-icon"
          alt="There are no new notifications"
        />

        <div className="menu-empty-message">{message}</div>
      </div>
    );
  }
);

export default MenuEmpty;
