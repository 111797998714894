import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import uniqueId from 'lodash/uniqueId';
import { useTranslation } from 'react-i18next';
import { sloohLogoAstronaut } from 'app/styles/variables/iconURLs';
import { primaryFont } from 'app/styles/variables/fonts';
import { toggleGlobalNavMenu } from 'app/modules/global-navigation/actions';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import MenuList from './partials/MenuList';
import MenuTitleBar from './partials/MenuTitleBar';
import SocialMenu from './partials/SocialMenu';
import SloohGiftCard from './SloohGiftCard/SloohGiftCard';
import {
  PRIMARY_CONFIGURATION,
  SECONDARY_CONFIGURATION,
} from './mainConfiguration';
import PROFILE_CONFIGURATION from './profileConfiguration';
import ProfileMenuList from './partials/ProfileMenuList';
import MENU_INTERFACE from './MenuInterface';

const Main = ({ mainMenu, actions }) => {
  const { t } = useTranslation();

  const DEFAULT_MENU = 'DEFAULT';

  const closeMenu = () => {
    actions.toggleGlobalNavMenu({
      activeLeft: DEFAULT_MENU,
      activeMenu: DEFAULT_MENU,
      activeRight: DEFAULT_MENU,
      isLeftOpen: false,
      isRightOpen: false,
      isNotificationMenuOpen: false,
    });
  };

  const openTelescopeMenu = () => {
    actions.toggleGlobalNavMenu({
      activeLeft: MENU_INTERFACE.TELESCOPES.name,
      activeMenu: MENU_INTERFACE.TELESCOPES.name,
      activeRight: MENU_INTERFACE.DEFAULT.name,
      isLeftOpen: true,
      isRightOpen: false,
      isNotificationMenuOpen: false,
    });
  };

  return (
    <Fragment>
      <div className="root">
        <div className="slooh-logo-container" onClick={closeMenu}>
          <img
            className="slooh-logo"
            src="https://vega.slooh.com/assets/v4/site-redesign/icons/arrow_icon_gray.svg"
          />
          Back
        </div>
        <div className="section-divider" />
        <div className="section-menu">
          <ProfileMenuList
            menuOnClick={openTelescopeMenu}
            items={PROFILE_CONFIGURATION(mainMenu.primaryLinks)}
          />
        </div>

        <style jsx>{`
          .root {
            width: 100%;
            font-family: ${primaryFont};
            letter-spacing: 1px;
            position: relative;
          }

          .slooh-logo-container {
            width: 100%;
            padding: 30px 10px;
            font-family: ${primaryFont};
            color: #41566f;
            font-size: 16px;
            cursor: pointer;
          }

          .slooh-logo {
            width: 10%;
            height: auto;
            margin-bottom: 5px;
            margin-right: 10px;
          }

          .section-divider {
            background: rgb(217, 222, 228);
            height: 1px;
            margin: 0px 4px 25px 4px;
          }
        `}</style>
      </div>
    </Fragment>
  );
};

Main.propTypes = {
  mainMenu: PropTypes.shape({
    primaryLinks: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        link: PropTypes.string,
      })
    ),
    secondaryLinks: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        link: PropTypes.string,
      })
    ),
  }),
};

Main.defaultProps = {
  mainMenu: {
    primaryLinks: [],
    secondaryLinks: [],
  },
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      toggleGlobalNavMenu,
    },
    dispatch
  ),
});

export default compose(connect(null, mapDispatchToProps))(Main);
