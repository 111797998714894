import { AnyAction } from 'redux';
import { handleActions } from 'redux-actions';
import { actions, constants } from 'ducks-helpers';

import { CustomUpcomingEventsListResponse } from 'app/modules/upcoming-events/types';

interface CustomUpcomingEventsListState {
  upcomingEvents: CustomUpcomingEventsListResponse;
  isFetching: boolean;
  serverError: string | null;
}

const initialState: CustomUpcomingEventsListState = {
  upcomingEvents: {} as CustomUpcomingEventsListResponse,
  isFetching: false,
  serverError: null,
};

export const TYPE = constants('customUpcomingEventsList', [
  '~GET_CUSTOM_UPCOMING_EVENTS_LIST',
]);

export const ACTION = actions(TYPE);

const setFetching = (state: CustomUpcomingEventsListState) => ({
  ...state,
  isFetching: true,
});

const setServerError = (
  state: CustomUpcomingEventsListState,
  action: AnyAction
) => ({
  ...state,
  isFetching: false,
  serverError: action.payload,
});

const getCustomUpcomingEventsListSuccess = (
  state: CustomUpcomingEventsListState,
  action: AnyAction
) => ({
  ...state,
  isFetching: false,
  upcomingEvents: action.payload,
});

export default handleActions<CustomUpcomingEventsListState>(
  {
    [TYPE.GET_CUSTOM_UPCOMING_EVENTS_LIST]: setFetching,
    [TYPE.GET_CUSTOM_UPCOMING_EVENTS_LIST_SUCCESS]: getCustomUpcomingEventsListSuccess,
    [TYPE.GET_CUSTOM_UPCOMING_EVENTS_LIST_ERROR]: setServerError,
  },
  initialState
);
