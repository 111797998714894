export const horizontalArrow = 'https://vega.slooh.com/assets/v4/common/arrow_horz.svg';
export const horizontalArrowRightAstronaut = 'https://vega.slooh.com/assets/v4/icons/horz_arrow_right_astronaut.svg';
export const horizontalArrowRightWhite = 'https://vega.slooh.com/assets/v4/icons/horz_arrow_right_white.svg';

export const threeLinesAstronaut = 'https://vega.slooh.com/assets/v4/icons/three_lines_astronaut.svg';
export const searchAstronaut = 'https://vega.slooh.com/assets/v4/icons/search_astronaut.svg';
export const sloohLogoAstronaut = 'https://vega.slooh.com/assets/v4/icons/slooh_logo_astronaut.svg';
export const telescopeAstronaut = 'https://vega.slooh.com/assets/v4/icons/telescope_astronaut.svg';

export const checkAstronaut = 'https://vega.slooh.com/assets/v4/icons/check_astronaut.svg';
export const closeAstronaut = 'https://vega.slooh.com/assets/v4/icons/close_astronaut.svg';
export const closeWhite = 'https://vega.slooh.com/assets/icons/menu/menu-close.svg';
export const userAstronaut = 'https://vega.slooh.com/assets/v4/icons/user_astronaut.svg';
export const commentWhite = 'https://vega.slooh.com/assets/v4/icons/comment_white.svg';
export const commentAstronaut = 'https://vega.slooh.com/assets/v4/common/comment.svg';

export const goldCompass = 'https://vega.slooh.com/assets/v4/icons/gold_compass.svg';

export const missions = 'https://vega.slooh.com/assets/v4/dashboard/icon_missions_2.svg';
export const guides = 'https://vega.slooh.com/assets/v4/dashboard/icon_guides_2.svg';
export const quests = 'https://vega.slooh.com/assets/v4/dashboard/icon_quests_2.svg';
export const shows = 'https://vega.slooh.com/assets/v4/dashboard/icon_shows_2.svg';
export const stories = 'https://vega.slooh.com/assets/v4/dashboard/icon_stories_2.svg';
export const groups = 'https://vega.slooh.com/assets/v4/dashboard/icon_groups_2.svg';

export const resources = 'https://vega.slooh.com/assets/v4/icons/resources.svg';
export const info = 'https://vega.slooh.com/assets/v4/common/info_icon.svg';
export const complete = 'https://vega.slooh.com/assets/v4/icons/complete_icon.svg';
export const incomplete = 'https://vega.slooh.com/assets/v4/icons/incomplete_icon.svg';
export const triangleUp = 'https://vega.slooh.com/assets/v4/common/status_triangle_up.svg';

export const plus = 'https://vega.slooh.com/assets/v4/common/plus_icon.svg';

export const downwardFacingChevron = 'https://vega.slooh.com/assets/v4/common/arrow_down.svg';

export const guideCorner = 'https://vega.slooh.com/assets/v4/common/guide_corner.svg';

export const googleButton = 'https://vega.slooh.com/assets/v4/icons/google_button.svg';
export const questShield = 'https://vega.slooh.com/assets/v4/common/quest_shield.png';

export const envelope = 'https://vega.slooh.com/assets/v4/icons/envelope.svg';
export const phone = 'https://vega.slooh.com/assets/v4/icons/old-phone.svg';
export const liveShow = 'https://vega.slooh.com/assets/v4/icons/live_show_icon.svg';

export const settings = 'https://vega.slooh.com/assets/v4/dashboard-new/workspace/settings_icon.svg';
export const myCommunity = 'https://vega.slooh.com/assets/v4/icons/myCommunity_icon.svg';