import React, { FC, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'app/store';
import {
  IEventItem,
  IUpcomingEventsObject,
} from 'app/modules/upcoming-events/upcoming-events-interface';
import { selectUpcomingEvents } from 'app/modules/upcoming-events/upcoming-events-selectors';

import PageButton from 'app/components/page-button';
import MenuHeader from 'app/components/GlobalNavigation/Menus/menuHeader/MenuHeader';
import MenuLabelDivider from 'app/components/GlobalNavigation/Menus/menuLabelDivider/MenuLabelDivider';
import StarPartiesButton from 'app/components/GlobalNavigation/Menus/partials/buttons/StarPartiesButton/StarPartiesButton';
import MenuEmpty from 'app/components/GlobalNavigation/Menus/menuEmpty/MenuEmpty';

import './styles.scss';

enum CATEGORY {
  eventIsLive = 'now',
  eventIsUpcoming = 'upcoming',
  eventIsComingSoon = 'happening soon',
}

interface CategoryParties {
  [CATEGORY.eventIsLive]?: IEventItem[];
  [CATEGORY.eventIsUpcoming]?: IEventItem[];
  [CATEGORY.eventIsComingSoon]?: IEventItem[];
}

const StarPartiesRedesign: FC = () => {
  const upcomingEventsObject = useSelector<RootState, IUpcomingEventsObject>(
    selectUpcomingEvents
  );
  const headerRef = useRef<HTMLImageElement>(null);

  const {
    upcomingEvents: { eventList = [] },
    fetchingEvents = false,
  } = upcomingEventsObject;

  const eventsByCategories: CategoryParties = useMemo(
    () =>
      eventList.reduce((acc: CategoryParties, item) => {
        let category = CATEGORY.eventIsUpcoming;

        if (item.eventIsLive) category = CATEGORY.eventIsLive;
        if (item.eventIsUpcoming) category = CATEGORY.eventIsUpcoming;
        if (item.eventIsComingSoon) category = CATEGORY.eventIsComingSoon;

        acc[category] = [...(acc[category] || []), item];

        return acc;
      }, {}),
    [eventList]
  );

  useEffect(() => {
    if (headerRef.current) {
      headerRef.current.focus();
    }
  }, []);

  if (!fetchingEvents && !eventList.length) {
    return (
      <aside className="parties-redesign parties-empty-redesign">
        <MenuEmpty
          icon="https://vega.slooh.com/assets/v4/site-redesign/icons/calendar_icon.svg"
          message="There are currently no scheduled Star Parties happening soon."
        />
        <PageButton
          tabIndex={0}
          role="button"
          className="outlined-blue-button"
          title="View past Star Parties"
          link="/shows"
        />
      </aside>
    );
  }

  return (
    <aside className="parties-redesign">
      <MenuHeader
        ref={headerRef}
        tabIndex={0}
        role="button"
        title="Star Parties"
        button={
          <PageButton
            className="outlined-blue-button"
            title="View all"
            link="/shows"
          />
        }
      />

      <nav className="parties-list-container">
        {Object.keys(eventsByCategories).map(category => (
          <div key={category} className="parties-list">
            <MenuLabelDivider label={category} />
            {eventsByCategories[category as keyof CategoryParties].map(
              event => (
                <StarPartiesButton
                  tabIndex={0}
                  role="button"
                  isNow={event.eventIsLive}
                  key={event.linkUrl}
                  linkUrl={event.linkUrl}
                  date={event.displayDate}
                  message={event.eventTitle}
                  host={event.eventHostName}
                  button={
                    event.eventIsLive && (
                      <PageButton
                        className="filled-blue-button"
                        title="Watch live"
                        link="#"
                      />
                    )
                  }
                />
              )
            )}
          </div>
        ))}
      </nav>
    </aside>
  );
};

export default StarPartiesRedesign;
