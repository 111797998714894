import React, { FC, forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  setNotificationsCategories,
  setViewedAlerts,
} from 'app/modules/alerts/actions';
import AlertsIcon from 'app/redux/components/AlertsIcon';
import Button from 'app/components/GlobalNavigation/Button';
import {
  selectAlertFlag,
  selectNotifications,
} from 'app/modules/alerts/selectors';

interface Props {
  onClick: (e: React.MouseEvent | React.KeyboardEvent) => void;
  ariaLabel?: string;
  id?: string;
}

const AlertsButton: FC<Props> = forwardRef((props: Props, ref) => {
  const { onClick, ariaLabel, id } = props;
  const dispatch = useDispatch();

  const notifications = useSelector(selectNotifications);
  const alertFlag = useSelector(selectAlertFlag);

  const handleOnClick = (e: React.MouseEvent | React.KeyboardEvent) => {
    if (notifications && notifications.length && alertFlag) {
      const { eventId } = notifications[0];
      dispatch(setViewedAlerts({ eventId }));
    } else {
      dispatch(setNotificationsCategories({ isShowCategories: false }));
    }
    onClick(e);
  };

  return (
    <Button
      id={id}
      handleClick={handleOnClick}
      onKeyDown={handleOnClick}
      ariaLabel={ariaLabel && ariaLabel}
      ref={ref}
      children={<AlertsIcon />}
    />
  );
});

export default AlertsButton;
