import React, {
  FC,
  memo,
  ReactNode,
  KeyboardEvent,
  CSSProperties,
} from 'react';
import cn from 'classnames';
import { Link } from 'react-router';

import './style.scss';

interface Props {
  children: ReactNode;
  color?: 'blue' | 'light' | 'light-grey' | 'dark' | 'transparent' | 'link' | 'red' | 'transparent-blue';
  size?: 'small' | 'medium' | 'large';
  onClick?: () => void;
  href?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  className?: string;
  tabIndex?: number;
  onKeyDown?: (e: any) => void;
  ariaLabel?: string;
  style?: CSSProperties;
  outline?: boolean;
}

const CommonButton: FC<Props> = memo(props => {
  const {
    children,
    onClick,
    href,
    color = 'blue',
    size = 'small',
    fullWidth,
    disabled,
    className,
    tabIndex,
    onKeyDown,
    ariaLabel,
    style,
    outline,
  } = props;

  const classes = cn(
    'common-button',
    { 'common-button-full-width': fullWidth },
    { 'common-button-blue': color === 'blue' },
    { 'common-button-light': color === 'light' },
    { 'common-button-light-grey': color === 'light-grey' },
    { 'common-button-dark': color === 'dark' },
    { 'common-button-transparent': color === 'transparent' },
    { 'common-button-link': color === 'link' },
    { 'common-button-large': size === 'large' },
    { 'common-button-medium': size === 'medium' },
    { 'common-button-outline': outline },
    { 'common-button-blue-transparent' : color === 'transparent-blue'},
    { 'common-button-red': color === 'red' },
    className
  );

  return href ? (
    <Link
      aria-label={ariaLabel}
      to={href}
      className={classes}
      onClick={onClick}
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
    >
      {children}
    </Link>
  ) : (
    <button
      aria-label={ariaLabel}
      className={classes}
      disabled={disabled}
      onClick={onClick}
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      style={style}
    >
      {children}
    </button>
  );
});

export default CommonButton;
