import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router';
import InstrumentNavigation from 'app/components/telescope-details/InstrumentNavigation';
import './large-screen-format.scss';

const LargeScreenFormat = props => {
  const {
    options,
    selectedIndex,
    // activeInstrumentID,
    // updateCurrentInstrument,
  } = props;

  // const handleClick = instrument => () => {
  //   if (instrument.instrUniqueId === activeInstrumentID) return;
  //   return updateCurrentInstrument(instrument);
  // };

  return (
    <nav className="large-format-nav-root">
      <ul className="option-list">
        {options.map((telescope, index) => (
          <Link
            to={`/telescope-details/${telescope.observatoryUniqueID}/${telescope.telescopeUniqueID}/${telescope.instruments[0].instrUniqueId}`}
            key={`dt-obs-nav-${telescope.telescopeUniqueID}`}
            className={classnames('option-container', {
              active: selectedIndex === index,
              offline: !telescope.online,
            })}
          >
            <div className="instrument-navigation-btn">
              {telescope.online && (
                <span className="fa fa-circle link-indicator" />
              )}

              <img
                className="instrument-navigation-image"
                src={telescope.thumbnailURL}
                alt=""
              />
            </div>

            <div
              className="option-telescope-name"
              // onClick={handleClick(telescope.instruments[0])}
            >
              {telescope.name}
            </div>
          </Link>
        ))}
      </ul>
    </nav>
  );
};

// LargeScreenFormat.propTypes = commonProps;

export { LargeScreenFormat };
