export const white = '#fff';

export const brightBlue1000 = '#1177D0';
export const brightBlue900 = '#2582D1';
export const brightBlue100 = '#EEF7FF';
export const brightBlue500 = '#92B6E0';
export const blue900 = '#0B1016';
export const blue700 = '#BBDEFB';
export const blue600 = '#32465E';
export const blue500 = '#41566F';
export const blue400 = '#8D969F';
export const blue200 = '#D9DEE4';
export const blue50 = '#F8F8F8';
export const red900 = '#CE0101';
export const red1000 = '#D72C0D';
export const red1100 = '#FFF4F4';
export const gray100 = '#EEEEEE';
export const gray200 = '#E2E2E2';
export const gray300 = '#CBCBCB';
export const gray400 = '#AFAFAF';
export const gray500 = '#757575';
export const gray600 = '#545454';
export const gray700 = '#333333';
export const gray900 = '#141414';
export const green700 = '#65CB41';
export const green800 = '#C8E6C9';
export const green900 = '#B2DFDB';
export const purple700 = '#E1BEE7';
export const pink500 = '#F8BBD0';
export const pink700 = '#FFCDD2';
export const yellow700 = '#FFECB3';
export const violet500 = '#D1C4E9';

export const midnight_express = '#1a2636';
export const blue800 = '#242D3C';
export const astronaut = '#415671';
export const nightfall = '#253446';
export const lightHeadedAstronaut = '#41566f';
export const moodyBleu = '#8d969f';
export const lynch = '#6e7b8c';
export const hawkesBlue = '#d9dee4';
export const glitter = '#eaf0f9';
export const geyser = '#d9dee5';
export const gainsboro = '#dddddd';
export const iron = '#d8d8d8';
export const mystic = '#e7eced';
export const seashell = '#f2f2f2';
export const paleGrey = '#f4f5f6';
export const paleGreyTwo = '#edf0f2';
export const romance = '#ffffff';
export const failedRomance = '#b1b8c1';
export const golden_yellow = '#ffde00';
export const golden = '#c2a068';
export const golda = '#e2b979';
export const shadows = '#ced2d8';
export const dukeBlue = '#1c2633';
export const chillBlue = '#9cb4d2';
export const faintBlue = '#BCC2CA';
export const thatBlue = '#253446';
export const faintGray = '#f8f8f8';
export const online = '#4AB375';
export const thatGrayWeForgot = '#a2a8b0';
export const black = '#000000';
export const yellow = '#fad59a';
export const yellow900 = '#65563E';
export const darkGray = '#979797';
export const raven = '#616e7d';

export const white_tile =
  'https://vega.slooh.com/assets/v4/common/LightBlueDiamondPattern_NO_TEXTURE.png';
export const white_tile_texture =
  'https://vega.slooh.com/assets/v4/common/LightBlueDiamondPattern_WithTexture.png';
export const white_tile_paper =
  'https://vega.slooh.com/assets/v4/common/White_Paper_Subtle.png';
export const blue_tile_feat =
  'https://vega.slooh.com/assets/v4/common/Dark_Pattern_Featured_LargeCanvas.png';
export const blue_tile_guides =
  'https://vega.slooh.com/assets/v4/common/Dark_Pattern_GUIDES.png';
export const guide_header_bg_image =
  'https://vega.slooh.com/assets/v4/common/guide_header_bg_image.jpg';
export const blue_tile_canvas =
  'https://vega.slooh.com/assets/v4/common/Darkest_Pattern_Canvas.png';
export const questTexture =
  'https://vega.slooh.com/assets/v4/common/quest_card_bg.png';
export const questDetailsTexture =
  'https://vega.slooh.com/assets/v4/common/quest_details_texture.png';
export const white_texture_bg =
  'https://vega.slooh.com/assets/v4/dashboard/white-texture.png';
