import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ModalList from 'app/components/modal-redesign/modal-list';
import { makeRecentGravityActionSelector } from 'app/modules/new-dashboard/selectors';
import { getRecentGravityDataAction } from 'app/modules/new-dashboard/actions';
import { createStructuredSelector } from 'reselect';

import './styles.scss';

const GravityPointsModal = ({
  isOpen,
  onClose,
  getRecentGravityDataAction,
  recentGravityAction,
}) => {
  const { actions, emptyLinkText, emptyLinkURL, emptyMessage } =
    recentGravityAction || {};
  const [isLoading, setIsLoading] = React.useState(true);

  const getModalList = () => {
    if (!actions) return [];
    return actions.map(item => ({
      title: '',
      value: item.title,
      rightPanel: <div className="gp-modal-item">{item.gravityPoints}</div>,
    }));
  };

  React.useEffect(() => {
    if (!isOpen) {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    getRecentGravityDataAction();
  }, [isOpen]);

  React.useEffect(() => {
    if (actions) setIsLoading(false);
  }, [recentGravityAction]);

  return (
    <ModalList
      className="gp-modal"
      isOpen={isOpen}
      handleCloseModal={onClose}
      title="Recent Gravity points"
      list={getModalList()}
      isLoading={isLoading}
      emptyMessage={emptyMessage}
      emptyLinkURL={emptyLinkURL}
      emptyLinkText={emptyLinkText}
    />
  );
};

GravityPointsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  getRecentGravityDataAction,
};

const mapStateToProps = createStructuredSelector({
  recentGravityAction: makeRecentGravityActionSelector(),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  GravityPointsModal
);
