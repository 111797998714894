import React, { useEffect, useRef } from 'react';

import MenuHeader from 'app/components/GlobalNavigation/Menus/menuHeader/MenuHeader';
import BrowseTaggedDataSearch from 'app/components/browse-tagged-data/BrowseTaggedDataSearch';

import './styles.scss';

const SearchRedesign = () => {
  const firstSearchMenuElementRef = useRef(null);

  useEffect(() => {
    if (firstSearchMenuElementRef.current) {
      firstSearchMenuElementRef.current.focus();
    }
  }, []);
  return (
    <aside className="search-redesign">
      <MenuHeader
        ref={firstSearchMenuElementRef}
        title="Search"
        tabIndex={0}
        role="link"
      />

      <nav className="search-container">
        <BrowseTaggedDataSearch isOpen />
      </nav>
    </aside>
  );
};

export default SearchRedesign;
