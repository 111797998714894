import React, { FC } from 'react';

import './styles.scss';

interface Props {
  label: string;
}

const MenuLabelDivider: FC<Props> = props => {
  const { label } = props;

  return (
    <div className="menu-divider" tabIndex={0} role="button">
      <div className="divider-item" />
      <div className="menu-label">{label}</div>
      <div className="divider-item" />
    </div>
  );
};

export default MenuLabelDivider;
