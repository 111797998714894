import React, { Component } from 'react';
import './style.scss';

export class Button extends Component {
  render() {
    const {
      text,
      style,
      onClickEvent,
      type,
      icon,
      disabled,
      right,
      theme,
    } = this.props;

    return (
      <button
        className={`button-container ${style}`}
        style={theme}
        onClick={onClickEvent}
        type={type}
        disabled={disabled}
      >
        {!right && icon && (
          <img alt="" className="new-button-icon" src={icon} />
        )}
        {text}
        {right && icon && (
          <img alt="" className="new-button-icon-right" src={icon} />
        )}
      </button>
    );
  }
}
