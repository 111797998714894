import { validateResponseAccess } from "../authorization/actions";
import { fetchMyCommunityData } from "./api";


export const FETCH_MY_COMMUNITY_DATA_START = "FETCH_MY_COMMUNITY_DATA_START";
export const FETCH_MY_COMMUNITY_DATA_SUCCESS = "FETCH_MY_COMMUNITY_DATA_SUCCESS";

const fetchMyCommunityDataStart = () => ({
    type: FETCH_MY_COMMUNITY_DATA_START
});

const fetchMyCommunityDataSuccess = (payload) => ({
    type: FETCH_MY_COMMUNITY_DATA_SUCCESS,
    payload,
});

export const getMyCommunityData = (data) => (dispatch, getState) => {
    dispatch(fetchMyCommunityDataStart());
    const { token, at, cid } = getState().user;
    return fetchMyCommunityData({
        token,
        at,
        cid,
        ...data,
    }).then(result => {
        const res = result.data;
        if (res.apiError)
            dispatch(validateResponseAccess(res));
        else
            dispatch(fetchMyCommunityDataSuccess(res));
    });
}