import { bindActionCreators, compose } from 'redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { createStructuredSelector } from 'reselect';

import Logout from 'redux/components/Logout';
import React, { useEffect, useRef } from 'react';
import { userAstronaut } from 'app/styles/variables/iconURLs';
import { primaryFont } from 'app/styles/variables/fonts';
import {
  black,
  blue200,
  blue400,
  blue500,
  blue600,
  brightBlue900,
  romance,
} from 'app/styles/variables/colors_tiles_v4';

import { makeUserGravityStatusSelector } from 'app/modules/new-dashboard/selectors';
import { toggleGlobalNavMenu } from 'app/modules/global-navigation/actions';
import Progress from 'app/components/GlobalNavigation/Progress';
import ProfileMenuList from 'app/components/GlobalNavigation/Menus/partials/ProfileMenuList';
import ProfileMenuItem from 'app/components/GlobalNavigation/Menus/partials/buttons/ProfileMenuItem';

import { Spinner } from 'app/components/spinner/index';

const getIconStyle = url => ({
  backgroundImage: `url(${url})`,
});

const LoggedInRedesign = ({
  displayName,
  avatarURL,
  menuItems,
  userGravityStatus,
  actions,
}) => {
  const { t } = useTranslation();
  const profileMenuFirstElementRef = useRef(null);
  const DEFAULT_MENU = 'DEFAULT';

  const {
    gravityPoints,
    currentTierProgress,
    maxTierProgress,
    currentTierName,
    currentBadgeCount,
    currentTierLevelNbr,
    slooh1kObjectCollectedCount,
  } = userGravityStatus || {};

  const closeMenu = () => {
    actions.toggleGlobalNavMenu({
      activeLeft: DEFAULT_MENU,
      activeMenu: DEFAULT_MENU,
      activeRight: DEFAULT_MENU,
      isLeftOpen: false,
      isRightOpen: false,
      isNotificationMenuOpen: false,
    });
  };

  useEffect(() => {
    if (profileMenuFirstElementRef?.current) {
      profileMenuFirstElementRef.current.focus();
    }
  }, []);

  if (!userGravityStatus) {
    return <Spinner loading />;
  }

  return (
    <aside className="root">
      <div className="close-icon" onClick={closeMenu}>
        &#215;
      </div>
      <header className="top-section">
        <div
          className="avatar-container"
          ref={profileMenuFirstElementRef}
          tabIndex={0}
          role="button"
        >
          <div
            className="avatar"
            style={getIconStyle(avatarURL || userAstronaut)}
          />
          <div className="user-badge">{currentBadgeCount}</div>
        </div>
        <div className="username" tabIndex={0} role="button">
          {displayName}
        </div>
        <div className="progress-container">
          <Progress
            tabIndex={0}
            role="button"
            title={`Level ${currentTierLevelNbr}: ${currentTierName}`}
            progressLabel={`${currentTierProgress} / ${maxTierProgress}`}
            progress={parseInt(
              (currentTierProgress / maxTierProgress) * 100,
              10
            )}
          />
        </div>
        <div className="statistic">
          <div className="statistic-item" tabIndex={0} role="button">
            <div className="statistic-value">
              {gravityPoints.toLocaleString()}
            </div>
            <div className="statistic-title">Gravity points</div>
          </div>
          <div className="statistic-item" tabIndex={0} role="button">
            <div className="statistic-value">{slooh1kObjectCollectedCount}</div>
            <div className="statistic-title">Objects collected</div>
          </div>
        </div>
        <Link to="/profile" tabIndex={0} role="button">
          <div className="profile-btn">View my profile</div>
        </Link>
      </header>

      <div className="section-divider" />

      <nav className="section-menu">
        <ProfileMenuList items={menuItems} tabIndex={0} role="button" />
      </nav>

      <div className="section-divider" />

      <div className="section-menu">
        <Logout
          tabIndex={0}
          role="button"
          render={() => (
            <ProfileMenuItem
              text={t('Dashboard.LogOut')}
              anchor="#"
              icon="https://vega.slooh.com/assets/v4/site-redesign/icons/icon_logout.svg"
            />
          )}
        />
      </div>

      <style jsx>{`
        .root {
          width: 100%;
          font-family: ${primaryFont};
          letter-spacing: 1px;
          position: relative;
        }

        .close-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 16px;
          right: 16px;
          cursor: pointer;
          width: 18px;
          height: 18px;
          color: ${blue500};
          font-size: 29px;
        }

        .close-icon:hover {
          color: ${black};
          font-size: 35px;
        }

        .top-section {
          display: flex;
          align-items: center;
          flex-direction: column;
          padding: 28px 0 24px;
        }

        .section-divider {
          background: ${blue200};
          height: 1px;
          margin: 0 4px;
        }

        .avatar-container {
          width: 72px;
          height: 72px;
          background-color: ${blue600};
          border-radius: 100%;
          margin-bottom: 10px;
          position: relative;
        }

        .user-badge {
          display: flex;
          justify-content: center;
          padding-top: 6px;
          position: absolute;
          right: -5px;
          bottom: -5px;
          width: 32px;
          height: 32px;
          background: url('https://vega.slooh.com/assets/v4/site-redesign/icons/badge_outline.svg');
          color: white;
          font-size: 10px;
          line-height: 14px;
          letter-spacing: 1px;
        }

        .avatar {
          width: 100%;
          height: 100%;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          filter: brightness(0) invert(1);
        }

        .username {
          font-size: 24px;
          color: ${blue500};
          margin-bottom: 16px;
        }

        .progress-container {
          width: 200px;
          min-height: 26px;
          margin-bottom: 15px;
        }

        .statistic {
          display: flex;
          margin-bottom: 20px;
          width: 220px;
          justify-content: space-evenly;
        }

        .statistic-item {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .statistic-value {
          color: ${blue500};
          font-size: 24px;
          line-height: 34px;
        }

        .statistic-title {
          color: ${blue400};
          font-size: 10px;
          line-height: 14px;
          text-transform: uppercase;
          width: 70px;
          text-align: center;
        }

        .profile-btn {
          background: ${brightBlue900};
          color: ${romance};
          text-transform: uppercase;
          font-size: 10px;
          line-height: 22px;
          padding: 5px;
          width: 200px;
          text-align: center;
          border-radius: 40px;
        }

        .section-menu {
          margin: 4px 0;
        }
      `}</style>
    </aside>
  );
};

LoggedInRedesign.propTypes = {
  displayName: PropTypes.string.isRequired,
  userInfo: PropTypes.shape({
    displayName: PropTypes.string,
  }),
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      link: PropTypes.string,
    })
  ),
};

LoggedInRedesign.defaultProps = {};

const mapStateToProps = createStructuredSelector({
  userGravityStatus: makeUserGravityStatusSelector(),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      toggleGlobalNavMenu,
    },
    dispatch
  ),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  LoggedInRedesign
);
